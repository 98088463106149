import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModalCheckBoxes3SonoComponent } from '@components/dashboard/modais/Modal9-CheckBoxes3Sono/modal-checkBoxes3Sono.component';
import { AppService } from '@services/app.service';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { ModalSaudEmocFuncComponent } from '../Modal7-SaudEmocFunc/modal-saudEmocFunc.component';

@Component({
    selector: 'app-modal-checkBoxes2',
    templateUrl: './modal-checkBoxes2.component.html',
    styleUrls: ['./modal-checkBoxes2.component.scss']
})
export class ModalCheckBoxes2Component implements OnInit {
    @ViewChild('Saude') SaudeRef: ElementRef;
    @ViewChild('SaudeComparada') SaudeComparadaRef: ElementRef;
    @ViewChild('AtivVigorosas') AtivVigorosasRef: ElementRef;
    @ViewChild('AtivModeradas') AtivModeradasRef: ElementRef;
    @ViewChild('Compras') ComprasRef: ElementRef;
    @ViewChild('SubirEscadas') SubirEscadasRef: ElementRef;
    @ViewChild('SubirEscada') SubirEscadaRef: ElementRef;
    @ViewChild('Ajoelhar') AjoelharRef: ElementRef;
    @ViewChild('AndarAcimaUmKm') AndarAcimaUmKmRef: ElementRef;
    @ViewChild('AndarVariosQuarteiroes') AndarVariosQuarteiroesRef: ElementRef;
    @ViewChild('AndarUmQuarteirao') AndarUmQuarteiraoRef: ElementRef;
    @ViewChild('Banho') BanhoRef: ElementRef;
    @ViewChild('DiminuiuTempoAtiv') DiminuiuTempoAtivRef: ElementRef;
    @ViewChild('MenosTarefas') MenosTarefasRef: ElementRef;
    @ViewChild('NaoTrabalhou') NaoTrabalhouRef: ElementRef;
    @ViewChild('DificuldadeTrabalho') DificuldadeTrabalhoRef: ElementRef;
    @ViewChild('QtdTempoTrabalho') QtdTempoTrabalhoRef: ElementRef;
    @ViewChild('MenosTarefasGostaria') MenosTarefasGostariaRef: ElementRef;
    @ViewChild('TarefasCuidado') TarefasCuidadoRef: ElementRef;
    @ViewChild('InterferenciaSocial') InterferenciaSocialRef: ElementRef;
    @ViewChild('DorCorpo') DorCorpoRef: ElementRef;
    @ViewChild('InterferenciaDorCorpo') InterferenciaDorCorpoRef: ElementRef;
    @ViewChild('Vigor') VigorRef: ElementRef;
    @ViewChild('TempoNervoso') TempoNervosoRef: ElementRef;
    @ViewChild('TempoDeprimido') TempoDeprimidoRef: ElementRef;
    @ViewChild('TempoCalmo') TempoCalmoRef: ElementRef;
    @ViewChild('TempoEnergia') TempoEnergiaRef: ElementRef;
    @ViewChild('TempoAbatido') TempoAbatidoRef: ElementRef;
    @ViewChild('TempoEsgotado') TempoEsgotadoRef: ElementRef;
    @ViewChild('TempoFeliz') TempoFelizRef: ElementRef;
    @ViewChild('TempoCansado') TempoCansadoRef: ElementRef;
    @ViewChild('TempoSaudeInterferiu') TempoSaudeInterferiuRef: ElementRef;
    @ViewChild('AdoecerComparado') AdoecerComparadoRef: ElementRef;
    @ViewChild('Saudavel') SaudavelRef: ElementRef;
    @ViewChild('SaudePiorar') SaudePiorarRef: ElementRef;
    @ViewChild('SaudeExcelente') SaudeExcelenteRef: ElementRef;

    public erroAvancarAnamnese: string | any = '';
    public formGroupEtapa8: FormGroup;
    public campoinvalido: string | any = '';
    public carregandoAtualizarAnamnese: boolean = false;
    public desativarCampos: boolean = false;
    public opcoesDificuldade = [
        {
            label: 'Sim. Dificulta muito',
            value: 1
        },
        {
            label: 'Sim. Dificulta um pouco',
            value: 2
        },
        {
            label: 'Não. Não dificulta de modo algum',
            value: 3
        }
    ];
    opcoesSimOuNao = [
        {
            label: 'Sim',
            value: true
        },
        {
            label: 'Não',
            value: false
        }
    ];
    opcoesGravidade = [
        {
            label: 'Nenhum',
            value: 1
        },
        {
            label: 'Muito leve',
            value: 2
        },
        {
            label: 'Leve',
            value: 3
        },
        {
            label: 'Moderada',
            value: 4
        },
        {
            label: 'Grave',
            value: 5
        },
        {
            label: 'Muito grave',
            value: 6
        }
    ];
    opcoesTempo = [
        {
            label: 'Todo tempo',
            value: 1
        },
        {
            label: 'Maioria do tempo',
            value: 2
        },
        {
            label: 'Uma boa parte do tempo',
            value: 3
        },
        {
            label: 'Alguma parte do tempo',
            value: 4
        },
        {
            label: 'Uma pequena parte do tempo',
            value: 5
        },
        {
            label: 'Nunca',
            value: 6
        }
    ];

    opcoesVeracidade = [
        {
            label: 'Definitivamente verdadeira',
            value: 1
        },
        {
            label: 'A maioria das vezes verdadeira',
            value: 2
        },
        {
            label: 'Não sei',
            value: 3
        },
        {
            label: 'A maioria das vezes falsa',
            value: 4
        },
        {
            label: 'Definitivamente falsa',
            value: 5
        }
    ];

    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador');
        const anamnese = this.anamneseService.getAnamnese();

        this.formGroupEtapa8 = new FormGroup({
            NumeroEtapa: new FormControl(anamnese?.Etapa8?.NumeroEtapa),
            AnamneseID: new FormControl(anamnese?.Etapa8?.AnamneseID),
            Saude: new FormControl({
                value: anamnese?.Etapa8?.Saude,
                disabled: this.desativarCampos
            }),
            SaudeComparada: new FormControl({
                value: anamnese?.Etapa8?.SaudeComparada,
                disabled: this.desativarCampos
            }),
            AtivVigorosas: new FormControl({
                value: anamnese?.Etapa8?.AtivVigorosas,
                disabled: this.desativarCampos
            }),
            AtivModeradas: new FormControl({
                value: anamnese?.Etapa8?.AtivModeradas,
                disabled: this.desativarCampos
            }),
            Compras: new FormControl({
                value: anamnese?.Etapa8?.Compras,
                disabled: this.desativarCampos
            }),
            SubirEscadas: new FormControl({
                value: anamnese?.Etapa8?.SubirEscadas,
                disabled: this.desativarCampos
            }),
            SubirEscada: new FormControl({
                value: anamnese?.Etapa8?.SubirEscada,
                disabled: this.desativarCampos
            }),
            Ajoelhar: new FormControl({
                value: anamnese?.Etapa8?.Ajoelhar,
                disabled: this.desativarCampos
            }),
            AndarAcimaUmKm: new FormControl({
                value: anamnese?.Etapa8?.AndarAcimaUmKm,
                disabled: this.desativarCampos
            }),
            AndarVariosQuarteiroes: new FormControl({
                value: anamnese?.Etapa8?.AndarVariosQuarteiroes,
                disabled: this.desativarCampos
            }),
            AndarUmQuarteirao: new FormControl({
                value: anamnese?.Etapa8?.AndarUmQuarteirao,
                disabled: this.desativarCampos
            }),
            Banho: new FormControl({
                value: anamnese?.Etapa8?.Banho,
                disabled: this.desativarCampos
            }),
            DiminuiuTempoAtiv: new FormControl({
                value: anamnese?.Etapa8?.DiminuiuTempoAtiv,
                disabled: this.desativarCampos
            }),
            MenosTarefas: new FormControl({
                value: anamnese?.Etapa8?.MenosTarefas,
                disabled: this.desativarCampos
            }),
            NaoTrabalhou: new FormControl({
                value: anamnese?.Etapa8?.NaoTrabalhou,
                disabled: this.desativarCampos
            }),
            DificuldadeTrabalho: new FormControl({
                value: anamnese?.Etapa8?.DificuldadeTrabalho,
                disabled: this.desativarCampos
            }),
            QtdTempoTrabalho: new FormControl({
                value: anamnese?.Etapa8?.QtdTempoTrabalho,
                disabled: this.desativarCampos
            }),
            MenosTarefasGostaria: new FormControl({
                value: anamnese?.Etapa8?.MenosTarefasGostaria,
                disabled: this.desativarCampos
            }),
            TarefasCuidado: new FormControl({
                value: anamnese?.Etapa8?.TarefasCuidado,
                disabled: this.desativarCampos
            }),
            InterferenciaSocial: new FormControl({
                value: anamnese?.Etapa8?.InterferenciaSocial,
                disabled: this.desativarCampos
            }),
            DorCorpo: new FormControl({
                value: anamnese?.Etapa8?.DorCorpo,
                disabled: this.desativarCampos
            }),
            InterferenciaDorCorpo: new FormControl({
                value: anamnese?.Etapa8?.InterferenciaDorCorpo,
                disabled: this.desativarCampos
            }),
            Vigor: new FormControl({
                value: anamnese?.Etapa8?.Vigor,
                disabled: this.desativarCampos
            }),
            TempoNervoso: new FormControl({
                value: anamnese?.Etapa8?.TempoNervoso,
                disabled: this.desativarCampos
            }),
            TempoDeprimido: new FormControl({
                value: anamnese?.Etapa8?.TempoDeprimido,
                disabled: this.desativarCampos
            }),
            TempoCalmo: new FormControl({
                value: anamnese?.Etapa8?.TempoCalmo,
                disabled: this.desativarCampos
            }),
            TempoEnergia: new FormControl({
                value: anamnese?.Etapa8?.TempoEnergia,
                disabled: this.desativarCampos
            }),
            TempoAbatido: new FormControl({
                value: anamnese?.Etapa8?.TempoAbatido,
                disabled: this.desativarCampos
            }),
            TempoEsgotado: new FormControl({
                value: anamnese?.Etapa8?.TempoEsgotado,
                disabled: this.desativarCampos
            }),
            TempoFeliz: new FormControl({
                value: anamnese?.Etapa8?.TempoFeliz,
                disabled: this.desativarCampos
            }),
            TempoCansado: new FormControl({
                value: anamnese?.Etapa8?.TempoCansado,
                disabled: this.desativarCampos
            }),
            TempoSaudeInterferiu: new FormControl({
                value: anamnese?.Etapa8?.TempoSaudeInterferiu,
                disabled: this.desativarCampos
            }),
            AdoecerComparado: new FormControl({
                value: anamnese?.Etapa8?.AdoecerComparado,
                disabled: this.desativarCampos
            }),
            Saudavel: new FormControl({
                value: anamnese?.Etapa8?.Saudavel,
                disabled: this.desativarCampos
            }),
            SaudePiorar: new FormControl({
                value: anamnese?.Etapa8?.SaudePiorar,
                disabled: this.desativarCampos
            }),
            SaudeExcelente: new FormControl({
                value: anamnese?.Etapa8?.SaudeExcelente,
                disabled: this.desativarCampos
            }),
            DataInicio: new FormControl(new Date()),
            DataFim: new FormControl(null)
        });
    }

    handleClickFecharModal() {
        this.appService.closeModal();
    }

    handleClickVoltarModal() {
        this.appService.closeModal();
        this.appService.openModal(ModalSaudEmocFuncComponent, {
            size: 'lg',
            backdrop: false
        });
    }

    scrollToField(fieldName: string) {
        const elementMap = {
            Saude: this.SaudeRef,
            SaudeComparada: this.SaudeComparadaRef,
            AtivVigorosas: this.AtivVigorosasRef,
            AtivModeradas: this.AtivModeradasRef,
            Compras: this.ComprasRef,
            SubirEscadas: this.SubirEscadasRef,
            SubirEscada: this.SubirEscadaRef,
            Ajoelhar: this.AjoelharRef,
            AndarAcimaUmKm: this.AndarAcimaUmKmRef,
            AndarVariosQuarteiroes: this.AndarVariosQuarteiroesRef,
            AndarUmQuarteirao: this.AndarUmQuarteiraoRef,
            Banho: this.BanhoRef,
            DiminuiuTempoAtiv: this.DiminuiuTempoAtivRef,
            MenosTarefas: this.MenosTarefasRef,
            NaoTrabalhou: this.NaoTrabalhouRef,
            DificuldadeTrabalho: this.DificuldadeTrabalhoRef,
            QtdTempoTrabalho: this.QtdTempoTrabalhoRef,
            MenosTarefasGostaria: this.MenosTarefasGostariaRef,
            TarefasCuidado: this.TarefasCuidadoRef,
            InterferenciaSocial: this.InterferenciaSocialRef,
            DorCorpo: this.DorCorpoRef,
            InterferenciaDorCorpo: this.InterferenciaDorCorpoRef,
            Vigor: this.VigorRef,
            TempoNervoso: this.TempoNervosoRef,
            TempoDeprimido: this.TempoDeprimidoRef,
            TempoCalmo: this.TempoCalmoRef,
            TempoEnergia: this.TempoEnergiaRef,
            TempoAbatido: this.TempoAbatidoRef,
            TempoEsgotado: this.TempoEsgotadoRef,
            TempoFeliz: this.TempoFelizRef,
            TempoCansado: this.TempoCansadoRef,
            TempoSaudeInterferiu: this.TempoSaudeInterferiuRef,
            AdoecerComparado: this.AdoecerComparadoRef,
            Saudavel: this.SaudavelRef,
            SaudePiorar: this.SaudePiorarRef,
            SaudeExcelente: this.SaudeExcelenteRef
        };

        const elementRef = elementMap[fieldName];

        // Detecta mudanças e aguarda o render do DOM
        this.cdr.detectChanges();

        if (elementRef && elementRef.nativeElement) {
            // Usamos `setTimeout` para garantir que o elemento esteja disponível
            setTimeout(() => {
                elementRef.nativeElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest'
                });
            }, 100); // Atraso de 100ms
        }
    }

    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal();
            this.appService.openModal(ModalCheckBoxes3SonoComponent);
            return;
        }

        const anamnese = this.anamneseService.getAnamnese();
        const classeContexto = this;

        this.carregandoAtualizarAnamnese = true;

        const novaAnamneseAtualizada = {
            ...anamnese,
            Etapa8: {
                ...anamnese?.Etapa8,
                ...this.formGroupEtapa8.getRawValue(),
                DataFim: new Date()
            }
        };

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                if (
                    // @ts-ignore
                    typeof response?.etapaInvalida?.mensagem == 'string' &&
                    // @ts-ignore
                    response?.etapaInvalida?.etapa === 8
                ) {
                    const campo =
                        // @ts-ignore
                        response?.etapaInvalida?.campo;
                    // @ts-ignore
                    classeContexto.scrollToField(campo);
                    classeContexto.erroAvancarAnamnese =
                        // @ts-ignore
                        response?.etapaInvalida?.mensagem;
                    classeContexto.campoinvalido =
                        // @ts-ignore
                        response?.etapaInvalida?.campo;
                    classeContexto.carregandoAtualizarAnamnese = false;
                    return;
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService
                    .putAnamnese(novaAnamneseAtualizada)
                    .subscribe({
                        next(response2) {
                            classeContexto.carregandoAtualizarAnamnese = false;
                            classeContexto.anamneseService.setAnamnese(
                                response2
                            );
                            classeContexto.appService.closeModal();
                            classeContexto.appService.openModal(
                                ModalCheckBoxes3SonoComponent
                            );
                        },
                        error(response) {
                            classeContexto.carregandoAtualizarAnamnese = false;
                            if (typeof response?.error?.Message == 'string') {
                                classeContexto.erroAvancarAnamnese =
                                    response?.error?.Message;
                            } else {
                                classeContexto.erroAvancarAnamnese =
                                    'Ocorreu um erro ao atualizar a anamnese';
                            }
                        }
                    });
            },
            error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false;
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message;
                } else {
                    classeContexto.erroAvancarAnamnese =
                        'Ocorreu um erro ao validar as informações preenchidas';
                }
            }
        });
    }
}
