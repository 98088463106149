<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">
 
        <div>
            <div class="w-100 d-flex justify-content-between">
                <h5 class="home-dash mt-5">
                    Tabela de Gordura
                </h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logoSafeTextoBranco.png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <form (ngSubmit)="atualizarListagemRelatorio()" [formGroup]="formTabelaGordura" class="container-tabela p-4">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Filtrar Relatórios
            </h6>

            <div class="col-12 mt-6">
                <div class="row">

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Avaliador</span>


                        <select
                            [value]="formTabelaGordura.get('AvaliadorID').value"
                            class="form-control form-input-select"
                            formControlName="AvaliadorID"
                        >
                            <option value="0">Selecione</option>
                            <option
                                *ngFor="let avaliador of listaAvaliadores"
                                [value]="avaliador.ID"
                            >
                                {{avaliador.Nome }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Inicio</span>

                        <input
                            class="form-input-box-date form-control"
                            id="PeriodoDe"
                            formControlName="PeriodoDe"
                            type="date"
                        >

                    </div>


                    <div class="col-md-3 col-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Fim</span>

                        <input
                            class="form-input-box-date form-control"
                            id="PeriodoAte"
                            formControlName="PeriodoAte"
                            type="date"
                        >
                    </div>


                    <div class="col-md-3 col-12 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="submit"
                        [disabled]="loading"
                        >
                            {{
                                loading ? 'Carregando...' : 'Pesquisar'
                            
                            }}
                        </button>
                    </div>
                </div>
            </div>

        </form>

        <div class="container-tabela mt-5" style="max-height: 600px; overflow-y: auto;">
            

            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Relatórios
            </h2>

            <table class="table table-striped" style="max-height: 600px; overflow-y: auto;">
                <thead>
                <tr>
                    <th class="th-tabela text-azul-claro-plataforma-safe">#</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Data</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Nome</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Gênero</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Idade</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Pescoço</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Altura</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Cintura</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Quadril</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Percentual</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Resultado</th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngIf="!nenhumRegistro && !loading">
                    <tr *ngFor="let relatorio of relatorioTabelaGorduraList">
                    <td>{{relatorio.ID}}</td>
                    <td>{{dateToDDMMYYYY(relatorio.Data)}}</td>
                    <td>{{relatorio.Usuario.Nome}}</td>
                    <td>{{getGenero(relatorio.Genero)}}</td>
                    <td>{{relatorio.Idade}}</td>
                    <td>{{relatorio.Pescoco}}</td>
                    <td>{{relatorio.Altura}}</td>
                    <td>{{relatorio.Cintura}}</td>
                    <td>{{relatorio.Quadril}}</td>
                    <td>{{relatorio.Percentual}}</td>
                    <td>
                        <img
                            *ngIf="(relatorio.Resultado == 1 || relatorio.Resultado == 2 || relatorio.Resultado == 3) && (relatorio.Usuario.Sexo == 2)"
                            aria-hidden="true"
                            class="ng-hide"
                            src="/assets/img/magro-antigo.png" width="82" height="100" 
                        >

                        <img
                            *ngIf="(relatorio.Resultado == 4 || relatorio.Resultado == 5) && (relatorio.Usuario.Sexo == 2)"
                            aria-hidden="true"
                            class="ng-hide"
                            src="/assets/img/gordo-antigo.png" width="82" height="100" 
                        >

                        <img *ngIf="(relatorio.Resultado == 1 || relatorio.Resultado == 2 || relatorio.Resultado == 3) && (relatorio.Usuario.Sexo == 1)"
                             aria-hidden="false"
                             src="/assets/img/magra-antigo.png" width="82" height="100" >

                        <img *ngIf="(relatorio.Resultado == 4 || relatorio.Resultado == 5) && (relatorio.Usuario.Sexo == 1)"
                             aria-hidden="true"
                             class="ng-hide"
                             src="/assets/img/gorda-antigo.png" width="82" height="100" >
                    </td>
                    </tr>
                </ng-container>
                <tr *ngIf="loading">
                    <td class="text-center align-middle" colspan="12">
                        <div class="spinner-border text-azul-plataforma-safe" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr *ngIf="nenhumRegistro && !loading">
                    <td class="text-center" colspan="12">Nenhum relatório encontrado, por favor insira um filtro.</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>
    </div>
</div>
