import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Component({
  selector: 'app-dados-aluno', templateUrl: './dados-aluno.component.html', styleUrls: ['./dados-aluno.component.scss']
})
export class DadosAlunoComponent implements OnInit {
  public usuarioId: number | string

  constructor(private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.usuarioId = this.route.snapshot.paramMap.get('alunoId')

    // Método 2: Usando subscribe para detectar alterações nos parâmetros de rota
    this.route.paramMap.subscribe(params => {
      this.usuarioId = params.get('alunoId');
    });
  }
}
