import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import { Router } from '@angular/router';
import {CupomService} from "@services/dashboard/cupom/cupom.service";
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-editar-cupom',
    templateUrl: './editar-cupom.component.html',
    styleUrls: ['./editar-cupom.component.scss']
})
export class EditarCupomComponent implements OnInit {
    public formGroupCupom: FormGroup
    public loadingSalvarCupom: boolean = false
    public ativos = []
    public avaliadores = []
    public ItensLista = []
    public urlSafe =  ''
    public cuponsCadastrados = []
    opcoesDiasCheckup = [{
        value: 7, label: '7 Dias'
    }, {
        value: 15, label: '15 Dias'
    }, {
        value: 30, label: '30 Dias'
    }, {
        value: 60, label: '60 Dias'
    }, {
        value: 90, label: '90 Dias'
    }, {
        value: 120, label: '120 Dias'
    }, {
        value: 0, label: 'Sem prazo'
    },]

    constructor(private cupomService: CupomService, private toastr: ToastrService, private router: Router) {
    }

    ngOnInit() {
        this.formGroupCupom = new FormGroup({
            Descricao: new FormControl({value: '', disabled: false}, [Validators.required]),
            Codigo: new FormControl({value: '', disabled: false}, [Validators.required]),
            Quantidade: new FormControl({value: 0, disabled: false}, [Validators.required]),
            AvaliadorID: new FormControl({value: '', disabled: false}, [Validators.required]),
            DiasProdutoCheckup: new FormControl({value: '', disabled: false}, [Validators.required]),
            Vencimento: new FormControl({value: new Date(), disabled: false}, [Validators.required]),
            TornarAvaliador: new FormControl({value: false, disabled: false}, [Validators.required]),
            Ativo: new FormControl({value: false, disabled: false}, [Validators.required]),
            BloquearLaudo: new FormControl({value: false, disabled: false}, [Validators.required]),
            Produto: new FormControl({value: '', disabled: false}, [Validators.required]),
            Itens: new FormControl({value: [], disabled: false}, [Validators.required]),
            Desconto: new FormControl({value: 0, disabled: false}, [Validators.required]),
            url: new FormControl({value: '', disabled: true}, [Validators.required]),
            ID: new FormControl({value: '', disabled: false}, [Validators.required]),
        })

        this.cupomService.listarAtivos().subscribe({
            next: response => {
                this.ativos = response as []
             }
        })

        this.cupomService.getCupomByHash(this.router.url.split('/')[5]).subscribe({
            next: response => {
                //tipagem do response
                const cupom = {
                    ID: response['ID'],
                    Ativo: response['Ativo'],
                    Avaliador: null,
                    AvaliadorID: response['AvaliadorID'],
                    BloquearLaudo: response['BloquearLaudo'],
                    Checkups: response['Checkups'],
                    Codigo: response['Codigo'],
                    DataAlteracao: response['DataAlteracao'],
                    DataInclusao: response['DataInclusao'],
                    Descricao: response['Descricao'],
                    DiasProdutoCheckup: response['DiasProdutoCheckup'],
                    Grupo: null,
                    GrupoID: null,
                    IdentificadorHash: response['IdentificadorHash'],
                    Itens: response['Itens'],
                    Quantidade: response['Quantidade'],
                    TornarAvaliador: response['TornarAvaliador'],
                    URL: response['URL'],
                    UsuarioAlteracao: response['UsuarioAlteracao'],
                    UsuarioInclusao: response['UsuarioInclusao'],
                    Vencimento: response['Vencimento']
                }
                this.urlSafe =  window.location.origin;
                
                this.formGroupCupom.get('ID').setValue(cupom?.ID)
                this.formGroupCupom.get('Descricao').setValue(cupom?.Descricao)
                this.formGroupCupom.get('Codigo').setValue(cupom?.Codigo)
                this.formGroupCupom.get('Quantidade').setValue(cupom?.Quantidade)
                this.formGroupCupom.get('url').setValue(this.urlSafe + '/cupom/promocao/' + cupom?.Codigo)
                this.formGroupCupom.get('AvaliadorID').setValue(cupom?.AvaliadorID)
                this.formGroupCupom.get('DiasProdutoCheckup').setValue(cupom?.DiasProdutoCheckup)
                this.formGroupCupom.get('Vencimento').setValue(this.onTransformaDateTimeEmData(cupom?.Vencimento))
                this.formGroupCupom.get('TornarAvaliador').setValue(cupom?.TornarAvaliador)
                this.formGroupCupom.get('Ativo').setValue(cupom?.Ativo)
                this.formGroupCupom.get('BloquearLaudo').setValue(cupom?.BloquearLaudo)
                this.formGroupCupom.get('Produto').setValue(cupom?.Itens[0]?.ProdutoID)
                this.formGroupCupom.get('Itens').setValue(cupom?.Itens)
                this.ItensLista = cupom?.Itens
                this.formGroupCupom.get('Desconto').setValue(cupom?.Itens[0]?.Desconto)
            
            }
            
        })


        this.cupomService.listaAvaliadores().subscribe({
            next: response => {
                this.avaliadores = response as []
            }
        })
     }

     onTransformaDateTimeEmData(data: string): string {
        // Converte a string para um objeto Date
        const dataFormatada = new Date(data);
    
        // Formata a data para "YYYY-MM-DD" e retorna
        const dataSomente = dataFormatada.toISOString().slice(0, 10);
    
        console.log(dataSomente);
        return dataSomente;
    }
    

    onSalvar() {

         const body = {
             ...this.formGroupCupom.value,

        }
 

        this.loadingSalvarCupom = true
        this.cupomService.salvarCupom(body).subscribe({
            next: response => {
                this.loadingSalvarCupom = false
                this.toastr.success('Cupom salvo com sucesso')
                this.router.navigate(['/dashboard/acesso-avaliador/cupons'])
                
            },
            error: err => {
                this.loadingSalvarCupom = false
                this.toastr.error('Erro ao salvar cupom')
            }
        })
    }

    onVoltar() {
        this.router.navigate(['/dashboard/acesso-avaliador/cupons'])
    }

    onRemover(item) {
        this.formGroupCupom.get('Itens').setValue(this.formGroupCupom.get('Itens').value.filter(i => i !== item))
        this.ItensLista = this.ItensLista.filter(i => i !== item)
    }

    onAdicionar() {
        const produto = this.ativos.find(ativo => ativo?.ID == this.formGroupCupom.get('Produto').value)

         this.formGroupCupom.get('Itens').setValue([...this.formGroupCupom.get('Itens').value, {
            ID: 0,
            ValorProduto: produto.Valor,
            Desconto: this.formGroupCupom.get('Desconto').value,
            ValorFinal:  produto.Valor - this.formGroupCupom.get('Desconto').value,
            Produto:  produto,
            ProdutoID: produto?.ID,
            Cupom: null
        }])

        this?.ItensLista.push({
            ID: 0,
            ValorProduto: produto.Valor,
            Desconto: this.formGroupCupom.get('Desconto').value,
            ValorFinal:  produto.Valor - this.formGroupCupom.get('Desconto').value,
            Produto:  produto,
            ProdutoID: produto?.ID,
            Cupom: null
        })
      
    }
}
