<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->


    <div class="container-fluid bg-transparent">
 

        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h6 class="home-dash mt-5">
                    CADASTRO
                </h6>
                <img src="assets/img/logoSafeTextoBranco.png" alt="plat-safe-logo" height="122px" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <h6 class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe">
            Avaliações
        </h6>

        <div class="container-tabela mt-5">
            <div class="loading-container d-flex justify-content-center align-items-center" *ngIf="loadingBuscarAvaliacao">
                <div class="spinner-border text-azul-plataforma-safe" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div *ngIf="isAvaliacaoFinalizada" class="d-flex justify-content-start" style="margin-left: 10px;">
                <button class="btn btn-primary btn-sm border-radius-10" (click)="onSair()">
                    Voltar
                </button>
            </div>

            <div class="stepper-wrapper">
                <div class="stepper-item" [ngClass]="{'selected': stepperSelected === stepper.number}"
                     *ngFor="let stepper of steppers" (click)="onSelectStepper(stepper.number)">
                    <div class="step-counter">{{ stepper.number }}</div>
                </div>
            </div>
            <div *ngIf="isAvaliacaoFinalizada" class="d-flex justify-content-center">
                <p class="text-success">Avaliação já finalizada, não é possível alterar.</p>
            </div>
            <div *ngIf="isAvaliacaoFinalizada" class="d-flex justify-content-center" style="margin-left: 10px;">
                <p>Para alterar selecione avaliação novamente e clique em 'Devolver'.</p>
            </div>

            <div *ngIf="stepperSelected === 1" class="p-4 scrollable-content">
                <div class="p-4">
                    <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                        Informações Básicas
                    </h6>
                    <div class="row vertical-content">
                        <form class="form-form p-3" [formGroup]="etapa1Data">
                            <div class="col-md-12 row">
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Data/hora início</label>
                                    <input
                                        type="date"
                                        id="dataInicial"
                                        name="dataInicial"
                                        class="form-control registration-input-box-dataNascimento"
                                        [value]="getCurrentDate()"
                                        disabled
                                    >
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Pressão arterial sistólica:</label>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="PressaoArterialSistolica"  mask="00.00 || 000.00">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Pressão arterial diastólica:</label>
                                    <input type="text" class="form-control form-input-box"
                                    mask="00.00 || 000.00"   formControlName="PressaoArterialDiastolica">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Data/hora final</label>
                                    <input
                                        type="date"
                                        id="dataFinal"
                                        name="dataFinal"
                                        class="form-control input-disabled"
                                        disabled
                                    >
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Frequência cardíaca:</label>
                                    <input type="text" class="form-control form-input-box"
                                    mask="00.00 || 000.00" formControlName="FrequenciaCardiaca">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Saturação de oxigênio:</label>
                                    <input type="text" class="form-control form-input-box"
                                    mask="00.00 || 000.00"   formControlName="SaturacaoOxigenio">
                                </div>

                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Suplementos:</label>
                                    <textarea class="form-control form-input-box h-100 input-disabled"
                                              formControlName="suplementos"></textarea>
                                </div>

                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Alergia a medicamentos:</label>
                                    <textarea class="form-control form-input-box h-100 input-disabled"
                                              formControlName="alergiaMedicamentos"></textarea>
                                </div>

                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Medicação:</label>
                                    <textarea class="form-control form-input-box h-100 input-disabled"
                                              formControlName="Medicacao"></textarea>
                                </div>

                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Enfermidades:</label>
                                    <textarea class="form-control form-input-box h-100 input-disabled"
                                              formControlName="enfermidades"></textarea>
                                </div>

                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Exercício:</label>
                                    <textarea class="form-control form-input-box h-100 input-disabled"
                                              formControlName="exercicio"></textarea>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Anemia:</label>
                                    <select class="form-control form-input-box" formControlName="Anemia">
                                        <option value="true">Sim</option>
                                        <option value="false">Não</option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Vitamina D:</label>
                                    <input type="text" class="form-control form-input-box" formControlName="VitaminaD">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Vitamina B12:</label>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="VitaminaB12">
                                </div>
                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Rotina Diária:</label>
                                    <textarea class="form-control form-input-box h-100"
                                              formControlName="RotinaDiaria"></textarea>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <label class="form-label-texts">Observação:</label>
                                    <textarea class="form-control form-input-box h-100"
                                              formControlName="Observacao"></textarea>
                                </div>
                                <div class="col-md-12 mt-5 d-flex justify-content-end">
                                    <button class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                    (click)="onSair()"
                                    >Sair
                                    </button>
                                    <button *ngIf="!isAvaliacaoFinalizada"
                                            class="btn btn-primary mr-3 border-radius-10 btn-md" (click)="onSalvar()"
                                            [disabled]="isLoadingFinalizar || loadingBuscarAvaliacao"
                                    >{{ isLoadingFinalizar ? 'Carregando' : 'Salvar'}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <div *ngIf="stepperSelected === 2" class="p-4 scrollable-content">
                <div class="p-4">
                    <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                        Informações Básicas
                    </h6>
                    <div class="row vertical-content">
                        <form class="form-form p-3" [formGroup]="etapa2Data">
                            <div class="col-md-12 row">
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Estatura Auto-relatada (cm)</span>
                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="Altura"
                                        disabled
                                    >
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Peso Auto-relatado (kg)</span>
                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="Peso"
                                        disabled
                                    >
                                </div>
                                <div class="col-md-2 mt-5">
                                    <span class="form-label-texts">Estatura (cm)</span>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="Estatura"
                                    >
                                        <option *ngFor="let estatura of opcoesEstatura"
                                                [value]="estatura">{{ estatura }} cm
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-2 mt-5">
                                    <span class="form-label-texts">Peso (kg)</span>
                                    <select class="form-control form-input-box" formControlName="MassaCorporal">
                                        <option *ngFor="let peso of opcoesPeso" [value]="peso">{{ peso }} kg</option>
                                    </select>
                                </div>
                                <div class="col-md-2 mt-5">
                                    <span class="form-label-texts">Glicemia de jejum</span>
                                    <input type="text" class="form-control form-input-box" formControlName="Glicemia"  mask="00.00">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Percentual de massa magra</span>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="MassaMagra">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Percentual de massa Gorda</span>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="MassaGorda">
                                </div>
                                <div class="col-md-2 mt-5">
                                    <span class="form-label-texts">Gordura visceral</span>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="GorduraVisceral">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Taxa Metabólica Basal:</span>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="TaxaMetabolica">
                                </div>
                                <div class="col-md-12 mt-5 d-flex justify-content-end">
                                    <button class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                    (click)="onSair()"
                                    >Sair
                                    </button>
                                    <button class="btn btn-primary mr-3 border-radius-10 btn-md" (click)="onSalvar()"
                                    [disabled]="isLoadingFinalizar || loadingBuscarAvaliacao"
                                            *ngIf="!isAvaliacaoFinalizada">
                                            {{ isLoadingFinalizar ? 'Carregando' : 'Salvar'}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 3" class="p-4 scrollable-content">
                <div class="p-4">

                    <div class="row vertical-content">
                        <form class="form-form p-3" [formGroup]="etapa3Data">
                            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe justify-content-center d-flex">
                                Medidas
                            </h6>
                            <div class="col-md-12 row">
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Pescoço (cm)</span>


                                    <select class="form-control form-input-box" formControlName="CircunferenciaPescoco"
                                            (blur)="efetuarCalculos()">
                                        <option *ngFor="let pescoco of opcoesPescocoCM" [value]="pescoco"
                                                [selected]="pescoco === etapa3Data.controls['CircunferenciaPescoco'].value"
                                        >{{ pescoco }} cm
                                        </option>
                                    </select>


                                </div>

                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Cintura (cm)</span>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="CircunferenciaCintura"
                                        (change)="circunferenciaCinturaQuadril()"
                                        (blur)="efetuarCalculos()"
                                    >
                                        <option *ngFor="let cintura of opcoesPescocoCM" [value]="cintura"
                                                [selected]="cintura === etapa3Data.get('CircunferenciaCintura').value"
                                        >{{ cintura }} cm
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Quadril (cm)</span>
                                    <select
                                        class="form-control form-input-box"
                                        formControlName="CircunferenciaQuadril"
                                        (change)="circunferenciaCinturaQuadril()"
                                        (blur)="efetuarCalculos()"
                                    >
                                        <option *ngFor="let quadril of opcoesPescocoCM" [value]="quadril"
                                                [selected]="quadril === etapa3Data.get('CircunferenciaQuadril').value"
                                        >{{ quadril }} cm
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Razão Cintura/Estatura (cm)</span>
                                    <input type="text" class="form-control input-disabled"
                                           formControlName="RazaoCinturaEstatura">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Razão Cintura/Quadril (cm)</span>
                                    <input type="text" class="form-control input-disabled"
                                           formControlName="ProporcaoCircunferenciaCinturaQuadril">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Índice de Adiposidade Corporal</span>
                                    <input type="text" class="form-control input-disabled"
                                           formControlName="IndiceAdiposidadeCorporal">
                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">IMC</span>
                                    <input type="text" class="form-control input-disabled" formControlName="Imc">
                                </div>

                            </div>
                            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe justify-content-center d-flex mt-5">
                                Outras Medidas
                            </h6>
                            <div class="col-md-12 row">

                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Braço Direito Relaxado (cm)</span>

                                    <input type="text"
                                           class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('BracoDireitoRelaxado', 'BracoEsquerdoRelaxado', 'AssimetriaBracoRelaxado')"
                                           formControlName="BracoDireitoRelaxado"
                                           mask="00.00"
                                           (blur)="efetuarCalculos()"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Braço Esquerdo Relaxado (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="BracoEsquerdoRelaxado"
                                           (change)="calcularAssimetriaETP3('BracoDireitoRelaxado', 'BracoEsquerdoRelaxado', 'AssimetriaBracoRelaxado')"
                                           mask="00.00"
                                           (blur)="efetuarCalculos()"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Assimetria (%)</span>

                                    <input type="text" disabled
                                           class="form-control input-disabled"
                                           [value]="etapa3Data.controls['AssimetriaBracoRelaxado'].value">

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Braço Direito Contraído (cm) </span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('BracoDireitoContraido', 'BracoEsquerdoContraido', 'AssimetriaBracoContraido')"
                                           formControlName="BracoDireitoContraido"
                                            mask="00.00"
                                           (blur)="efetuarCalculos()"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">  Braço Esquerdo Contraído (cm) </span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('BracoDireitoContraido', 'BracoEsquerdoContraido', 'AssimetriaBracoContraido')"
                                           formControlName="BracoEsquerdoContraido"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Assimetria (%)</span>

                                    <input type="text" class="form-control input-disabled" disabled
                                           [value]="etapa3Data.controls['AssimetriaBracoContraido'].value">

                                </div>

                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">  Ante-Braço Direito (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('AnteBracoDireito', 'AnteBracoEsquerdo', 'AssimetriaAnteBraco')"
                                           formControlName="AnteBracoDireito"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Ante-Braço Esquerdo (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('AnteBracoDireito', 'AnteBracoEsquerdo', 'AssimetriaAnteBraco')"
                                           formControlName="AnteBracoEsquerdo"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Assimetria (%)</span>

                                    <input type="text" class="form-control input-disabled" disabled
                                           [value]="etapa3Data.controls['AssimetriaAnteBraco'].value">

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Coxa Direita (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('CoxaDireita', 'CoxaEsquerda', 'AssimetriaCoxa')"
                                           formControlName="CoxaDireita"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Coxa Esquerda (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('CoxaDireita', 'CoxaEsquerda', 'AssimetriaCoxa')"
                                           formControlName="CoxaEsquerda"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Assimetria (%)</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa3Data.controls['AssimetriaCoxa'].value">


                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">  Panturrilha Direita (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('PanturrilhaDireita', 'PanturrilhaEsquerda', 'AssimetriaPanturrilha')"
                                           formControlName="PanturrilhaDireita"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">  Panturrilha Esquerda (cm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="calcularAssimetriaETP3('PanturrilhaDireita', 'PanturrilhaEsquerda', 'AssimetriaPanturrilha')"
                                           formControlName="PanturrilhaEsquerda"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts"> Assimetria (%)</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa3Data.controls['AssimetriaPanturrilha'].value">


                                </div>


                                <div class="col-md-12 mt-5 d-flex justify-content-end">
                                    <button class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                    (click)="onSair()"
                                    >Sair
                                    </button>
                                    <button class="btn btn-primary mr-3 border-radius-10 btn-md" (click)="onSalvar()"
                                    [disabled]="isLoadingFinalizar || loadingBuscarAvaliacao"
                                            *ngIf="!isAvaliacaoFinalizada"
                                    >{{ isLoadingFinalizar ? 'Carregando' : 'Salvar'}}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 4" class="p-4 scrollable-content">
                <div class="p-4">

                    <div class="row vertical-content " >
                        <form class="form-form p-3" [formGroup]="etapa4Data">
                            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe justify-content-center d-flex">
                                Teste de força de preensão manual (KG)
                            </h6>
                            <div class="col-md-12 row" *ngIf="!isMobile">

                                <div class="col-md-4 col-sm-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">
                                        Lado Esquerdo</p>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">
                                        Assimetria</p>
                                </div>
                                <div class="col-md-4 col-sm-4 mt-2">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="forcaMaiorDireita()"
                                           formControlName="ForcaDirPriTentativa"  mask="00.00 || 000.00">

                                </div>
                                <div class="col-md-4 col-sm-4 mt-2">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="forcaMaiorEsquerda()"
                                        formControlName="ForcaEsqPriTentativa"
                                         mask="00.00 || 000.00"
                                    >

                                </div>

                                <div class="col-md-4 col-sm-4 mt-2">
                                    <span class="form-label-texts">Maior tentativa em relação as duas</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                            
                                           [value]="etapa4Data.controls['MaiRelacaoDuas'].value"
                                    >

                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="forcaMaiorDireita()"
                                        formControlName="ForcaDirSegTentativa"
                                         mask="00.00 || 000.00"
                                    >

                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="forcaMaiorEsquerda()"
                                        formControlName="ForcaEsqSegTentativa"
                                         mask="00.00 || 000.00"
                                    >

                                </div>

                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Assimetria (%)</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['Assimetria'].value"
                                    >

                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['ForcaDirMaiTentativa'].value"
                                     >

                                </div>
                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['ForcaEsqMaiTentativa'].value"
                                     >

                                </div>

                                <div class="col-md-4 col-sm-4 mt-5">
                                    <span class="form-label-texts">Força Relativa</span>

                                    <input type="text" class="form-control input-disabled"
                                           formControlName="ForcaRelativa"/>


                                </div>


                            </div>
                             <div class="row" *ngIf="isMobile">
                                <div class="col-12 mt-5">
                                         <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                     <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" (change)="forcaMaiorDireita()" formControlName="ForcaDirPriTentativa">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" (change)="forcaMaiorDireita()" formControlName="ForcaDirSegTentativa">
                                         <span class="form-label-texts">Maior tentativa</span>
                                        <input type="text" class="form-control input-disabled"
                                               disabled
                                               [value]="etapa4Data.controls['ForcaDirMaiTentativa'].value"
                                         >
                                 </div>
                            
                                <div class="col-12 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Esquerdo</p>
                                    <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" (change)="forcaMaiorEsquerda()" formControlName="ForcaEsqPriTentativa">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" (change)="forcaMaiorEsquerda()" formControlName="ForcaEsqSegTentativa">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text" class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['ForcaEsqMaiTentativa'].value"
                                     >
                                </div>
                            
                                <div class="col-12 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Assimetria e Força Relativa</p>
                                    <!-- Coloque aqui os campos relacionados à assimetria -->
                                    <span class="form-label-texts">Assimetria (%)</span>
                                    <input type="text" class="form-control input-disabled"
                                    disabled [value]="etapa4Data.controls['Assimetria'].value">
                                    <!-- Adicione os outros campos conforme necessário -->
                                 

                                    <span class="form-label-texts">Força Relativa</span>
                                    
                                    <input type="text" class="form-control input-disabled"
                                    formControlName="ForcaEsqRelativa"/>
                                </div>

                            </div>

                            <div class="col-md-12">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5">
                                Membro inferior sentar e alcançar
                            </p>
                            <div class="col-md-12 row" *ngIf="!isMobile">

                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                    <b>Valor deve ser de -100 a 100!</b>
                                    
                                </div>
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">
                                        Lado Esquerdo</p>
                                    <b>Valor deve ser de -100 a 100!</b>
                                </div>
                                <div class="col-md-12 mt-2">
                                </div>
                                <div class="col-md-4 mt-2">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="DireitaInferiorPri"
                                        (change)="membroInferiorDireito()"
                                    >


                                </div>
                                <div class="col-md-4 mt-2">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="EsquerdaInferiorPri"
                                           (change)="membroInferiorEsquerda()"
                                    >

                                </div>

                                <div class="col-md-4 mt-5"></div>

                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="DireitaInferiorSeg"
                                        (change)="membroInferiorDireito()"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="EsquerdaInferiorSeg"
                                        (change)="membroInferiorEsquerda()"
                                    >
                                </div>

                                <div class="col-md-4 mt-5"></div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text"
                                           class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['DireitaInferiorMaior'].value"
                                     >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text"
                                           class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['EsquerdaInferiorMaior'].value"
                                     >

                                </div>

                            </div>
                            <div class="row" *ngIf="isMobile">
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                    <!-- Campos relacionados ao lado direito -->
                                    <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="DireitaInferiorPri" (change)="membroInferiorDireito()">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="DireitaInferiorSeg" (change)="membroInferiorDireito()">
                                    <span class="form-label-texts">Maior tentativa</span>
                                    <input type="text" class="form-control input-disabled" disabled 
                                     [value]="etapa4Data.controls['DireitaInferiorMaior'].value">

                                </div>
                            
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Esquerdo</p>
                                    <!-- Campos relacionados ao lado esquerdo -->
                                    <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="EsquerdaInferiorPri" (change)="membroInferiorEsquerda()">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="EsquerdaInferiorSeg" (change)="membroInferiorEsquerda()">
                                    <span class="form-label-texts">Maior tentativa</span>
                                    <input type="text" class="form-control input-disabled" disabled
                                      [value]="etapa4Data.controls['EsquerdaInferiorMaior'].value">

                                </div>
                            
                               
                            </div>
                            <div class="col-md-12">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex  mt-5">
                                Membro superior alcançar atrás das costas
                            </p>
                            <div class="col-md-12 row" *ngIf="!isMobile">

                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                    <b>Valor deve ser de -100 a 100!</b>
                                </div>
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">
                                        Lado Esquerdo</p>
                                        <b>Valor deve ser de -100 a 100!</b>

                                </div>
                                <div class="col-md-4 mt-5"></div>

                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="DireitaSuperiorPri"
                                           (change)="membroSuperiorDireito()"
                                    >

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="EsquerdaSuperiorPri"
                                           (change)="membroSuperiorEsquerdo()"
                                    >

                                </div>

                                <div class="col-md-4 mt-5"></div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input type="text" class="form-control form-input-box"
                                           (change)="membroSuperiorDireito()"
                                           formControlName="DireitaSuperiorSeg">

                                </div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="membroSuperiorEsquerdo()"
                                        formControlName="EsquerdaSuperiorSeg"
                                    >

                                </div>
                                <div class="col-md-4 mt-5"></div>
                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa </span>

                                    <input
                                        type="text"
                                        class="form-control input-disabled"
                                        disabled
                                        [value]="etapa4Data.get('DireitaSuperiorMaior').value"
                                     >

                                </div>


                                <div class="col-md-4 mt-5">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text"
                                           class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.get('EsquerdaSuperiorMaior').value"
                                     >

                                </div>

                            </div>
                            <div class="row" *ngIf="isMobile">
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Direito</p>
                                     <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="DireitaSuperiorPri" (change)="membroSuperiorDireito()">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="DireitaSuperiorSeg" (change)="membroSuperiorDireito()">
                                    <span class="form-label-texts">Maior tentativa</span>
                                    <input type="text" class="form-control input-disabled"
                                      disabled [value]="etapa4Data.get('DireitaSuperiorMaior').value">
                                </div>
                            
                                <div class="col-md-4 mt-5">
                                    <p class="titulo-forca text-azul-claro-plataforma-safe">Lado Esquerdo</p>
                                     <span class="form-label-texts">Primeira tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="EsquerdaSuperiorPri" (change)="membroSuperiorEsquerdo()">
                                    <span class="form-label-texts">Segunda tentativa</span>
                                    <input type="text" class="form-control form-input-box" formControlName="EsquerdaSuperiorSeg" (change)="membroSuperiorEsquerdo()">
                                    <span class="form-label-texts">Maior tentativa</span>
                                    <input type="text" class="form-control input-disabled" disabled
                                      [value]="etapa4Data.get('EsquerdaSuperiorMaior').value">
                                </div>
                            </div>
                            
                            <div class="col-md-12">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Sentar e levantar do solo
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Pontuação</span>

                                    <select class="form-control form-input-box" formControlName="SoloPontuacao">
                                        <option *ngFor="let soloPontuacao of opcoesSoloPontuacao"
                                                [value]="soloPontuacao"
                                        >{{ soloPontuacao }}
                                        </option>
                                    </select>

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Sentar e levantar em 30 segundos (repetições)
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="SentarLevantarTentativa1"
                                        (change)="sentarLevantarMaior()"
                                        mask="00.00 || 000.00"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Percepção Subjetiva do Esforço</span>

                                    <select
                                        class="form-control form-input-box"
                                        formControlName="PercepcaoSubjetivaEsforco"
                                    >
                                        <option *ngFor="let percep of opcoesPercepcaoSubjetivaEsforco" [value]="percep">{{ percep }} </option>
                                    </select>

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">SPO2 final</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="SPO2LevantarSentar"
                                           mask="00.00 || 000.00"
                                           >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Frequência cardíaca final (bpm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="FrequenciaCardiacaSentarLevantar"
                                           mask="00.00 || 000.00"
                                           >

                                </div>
                                <div class="col-md-12 mt-5 d-flex justify-content-center">
                                    <img alt="" class="imagem-slider" src="../../../assets/img/range-slider.png"
                                         height="65px" width="100%">
                                </div>

                            </div>
                            <div class="col-md-12 mt-5">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Flexão de cotovelo, 30 segundos (repetições)
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Primeira tentativa</span>


                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="flexaoCotoveloMaior()"
                                        formControlName="CotoveloPrimeira"
                                        mask="00.00 || 000.00"
                                    >
                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="flexaoCotoveloMaior()"
                                        formControlName="CotoveloSegunda"
                                        mask="00.00 || 000.00"
                                    >

                                </div>

                                <div class="col-md-4">
                                    <span class="form-label-texts">Maior tentativa</span>

                                    <input type="text"
                                           class="form-control input-disabled"
                                           disabled
                                           [value]="etapa4Data.controls['CotoveloMaior'].value"
                                     >

                                </div>
                            </div>
                            <div class="col-md-12">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Flexão de braço, 60 segundos (repetições)
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="BracoPrimeira"
                                        (change)="flexaoBracoMaior()"
                                        mask="00.00 || 000.00"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Percepção Subjetiva do Esforço</span>

                                    <select class="form-control form-input-box"
                                            formControlName="FlexaoBracoPercepcaoSubjetiva">
                                        <option *ngFor="let percep of opcoesPercepcaoSubjetivaEsforco" [value]="percep"
                                                [selected]="percep === etapa4Data.controls['FlexaoBracoPercepcaoSubjetiva'].value"
                                        >{{ percep }}  </option>
                                    </select>

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">SPO2 final</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="FlexaoBracoSPO2"
                                           mask="00.00 || 000.00"
                                           >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Frequência cardíaca final (bpm)</span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="FlexaoBracoFrequenciaCardiaca"
                                           mask="00.00 || 000.00">

                                </div>
                                <div class="col-md-12 mt-5 d-flex justify-content-center">
                                    <img alt="" class="imagem-slider" src="../../../assets/img/range-slider.png"
                                         height="65px" width="80%">
                                </div>

                            </div>
                            <div class="col-md-12 mt-5">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Teste de equilíbrio Unipodal de 30 segundos
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <select
                                        class="form-control form-input-box"
                                        formControlName="UnipodalPrimeira"
                                        (change)="forcaMaiorUnipodal()"
                                    >
                                        <option *ngFor="let percep of opcoesEquilibrio" [value]="percep"
                                            mask="00.00 || 000.00"
                                                [selected]="percep === etapa4Data.controls['UnipodalPrimeira'].value"
                                        >{{ percep }}  </option>
                                    </select>

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <select
                                        class="form-control form-input-box"
                                        formControlName="UnipodalSegunda"
                                        (change)="forcaMaiorUnipodal()"
                                    >
                                        <option *ngFor="let percep of opcoesEquilibrio" [value]="percep"
                                                [selected]="percep === etapa4Data.controls['UnipodalSegunda'].value"
                                        >{{ percep }}  </option>
                                    </select>

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Maior tentativa: </span>

                                    <input type="text"
                                           class="form-control input-disabled"
                                           formControlName="UnipodalMaior"

                                    >

                                </div>
                            </div>
                            <div class="col-md-12 mt-5">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5 ">
                                Teste de ir e vir (Timed Up and Go - TUG) (seg.)
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts">Primeira tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="testeIrVirMenorTempo()"
                                        formControlName="IrVirPrimeira"
                                    >


                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Segunda tentativa</span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        (change)="testeIrVirMenorTempo()"
                                        formControlName="IrVirSegunda"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">Menor tentativa </span>

                                    <input
                                        type="text"
                                        class="form-control input-disabled"
                                        disabled
                                        [value]="etapa4Data.controls['IrVirMenor'].value"
                                    >

                                </div>
                            </div>
                            <div class="col-md-12 mt-5">
                                <div class="divider-dash"></div>
                            </div>
                            <p class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mt-6 justify-content-center d-flex mt-5 mb-5">
                                Testes cardiovasculares
                            </p>
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span class="form-label-texts"> Caminhada 2 min estatica (repetições) </span>
                                    <input type="text" class="form-control form-input-box"
                                           formControlName="CaminhadaEstatica"
                                           mask="00.00 || 000.00"
                                           >
                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts">  Caminhada 6 min (metros percorridos):   </span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="CaminhadaSeisMin"
                                        (change)="percentualDistanciaPercorridaEsperada()"
                                        (blur)="efetuarCalculos()"
                                        mask="00.00 || 000.00"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts"> Distância esperada (metros): </span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="DistanciaEsperada"
                                        (change)="percentualDistanciaPercorridaEsperada()"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts"> SPO2 final: </span>
                                    <input type="text" class="form-control form-input-box" formControlName="SpoFinal"
                                    mask="00.00 || 000.00"
                                    >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts"> SPO2 final: </span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="SpoDoisFinal"
                                           mask="00.00 || 000.00"
                                           >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts"> FC máxima predita (bpm):  </span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="FcMaxPredita"
                                    >

                                </div>

                                <div class="col-md-4">
                                    <span class="form-label-texts">  Percepção subjetiva do Esforço (0-10):  </span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="PercepcaoSubEsforco"
                                           mask="00.00"
                                           >

                                </div>
                                <div class="col-md-4">
                                    <span class="form-label-texts"> Percepção Subjetiva do Esforço (0-10): </span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="PercSubEsforco"
                                           mask="00.00"
                                           >

                                </div>
                                <div class="col-md-4">
                                    <span
                                        class="form-label-texts">Percentual da distância percorrida esperada (%): </span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="PercDistPerc"
                                    >

                                </div>

                                <div class="col-md-4">
                                    <span class="form-label-texts"> Frequencia cardíaca final (bpm):  </span>

                                    <input type="text" class="form-control form-input-box"
                                           formControlName="FreqCardFinal"
                                           mask="00.00 || 000.00"
                                           >

                                </div>

                                <div class="col-md-4">
                                    <span class="form-label-texts"> Frequência cardíaca final (bpm): </span>

                                    <input type="text"
                                           class="form-control form-input-box"

                                           formControlName="FreqCardiacaFinal"
                                           (blur)="efetuarCalculos()"
                                           mask="00.00 || 000.00"
                                    >

                                </div>


                                <div class="col-md-4">
                                    <span
                                        class="form-label-texts"> Percentual da FC máxima atingida no teste (%):  </span>

                                    <input
                                        type="text"
                                        class="form-control form-input-box input-disabled"
                                        formControlName="PercFcMax"
                                    >

                                </div>

                            </div>
                           
                            <div class="col-md-12 mt-5 d-flex justify-content-end">
                                <button class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                (click)="onSair()"
                                >Sair
                                </button>
                                <button *ngIf="!isAvaliacaoFinalizada"
                                    class="btn btn-secondary mr-3 border-radius-10 btn-md" (click)="onSalvar()"
                                    [disabled]="isLoadingFinalizar || loadingBuscarAvaliacao"
                                >{{ isLoadingFinalizar ? 'Carregando' : 'Salvar'}}
                                </button>
                                <button class="btn btn-primary mr-3 border-radius-10 btn-md"
                                        (click)="onFinalizarAvaliacao()"
                                        *ngIf="!isAvaliacaoFinalizada"
                                        [disabled]="isLoadingFinalizar || loadingBuscarAvaliacao"
                                > {{ isLoadingFinalizar ? 'Carregando' : 'Finalizar'}}
                                </button>
                                    
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
