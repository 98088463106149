import {Component, OnInit} from '@angular/core';
import {ProfessorService} from '@services/dashboard/professor/professor.service';
import {ToastrService} from 'ngx-toastr';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {FormArray, FormControl, FormGroup} from '@angular/forms';
import {createMask} from '@ngneat/input-mask';
import {Router} from "@angular/router";
import { formatarDataParaDDMMYYYY } from '@/utils/mascaras';


interface Checkup {
    ID: number
    Idade: number
    Sexo: number
}

@Component({
    selector: 'app-anamneses-visualizar',
    templateUrl: './anamneses-visualizar.html',
    styleUrls: ['./anamneses-visualizar.scss']
})
export class AnamnesesVisualizar implements OnInit {
    public stepperSelected: number = 1;
 
    public steppers = [
        {number: 1, isSelected: false}, 
        {number: 2, isSelected: false}, 
        {number: 3, isSelected: false},
        {number: 4, isSelected: false},
        {number: 5, isSelected: false},
        {number: 6, isSelected: false},
        {number: 7, isSelected: false},
        {number: 8, isSelected: false},
        {number: 9, isSelected: false},
        {number: 10, isSelected: false}
    ];
    public anamneseProtocolo: any;
    public loadingAnamnese: boolean = false;    
    public formGroupEtapa2: FormGroup
    public formGroupEtapa3: FormGroup
    public formGroupEtapa4: FormGroup
    public formGroupEtapa5: FormGroup
    public formGroupEtapa6: FormGroup
    public formGroupEtapa7: FormGroup
    public formGroupEtapa8: FormGroup
    public formGroupEtapa9: FormGroup
    public formGroupEtapa10: FormGroup
    public desativarCampos = true;
    public listaFilhos = [];
    public opcoesFrequencia: Array<number> = [1, 2, 3, 4, 5, 6, 7]
    public opcoesCheckboxSimOuNao = [{
        label: 'Sim', value: true
    }, {
        label: 'Não', value: false
    }]
    public opcoesCheckboxSimOuNaoMed = [{
        label: 'Sim', value: true
    }, {
        label: 'Não', value: false
    }]
    public opcoesIntensidade = [{
        label: 'Baixa', value: 1
    }, {
        label: 'Média', value: 2,
    }, {
        label: 'Alta', value: 3
    }]
    public opcoesDiasDaSemana = [0, 1, 2, 3, 4, 5, 6, 7]
    public opcoesTempo = [{
        label: "Selecione", value: null
    }, {
        label: "00:00", value: "00:00:00"
    }, {
        label: "00:05", value: "00:05:00"
    }, {
        label: "00:10", value: "00:10:00"
    }, {
        label: "00:30", value: "00:30:00"
    }, {
        label: "00:40", value: "00:40:00"
    }, {
        label: "01:00", value: "01:00:00"
    }, {
        label: "01:05", value: "01:05:00"
    }, {
        label: "01:10", value: "01:10:00"
    }, {
        label: "01:30", value: "01:30:00"
    }, {
        label: "01:40", value: "01:40:00"
    }, {
        label: "02:00", value: "02:00:00"
    }, {
        label: "02:05", value: "02:05:00"
    }, {
        label: "02:10", value: "02:10:00"
    }, {
        label: "02:30", value: "02:30:00"
    }, {
        label: "02:40", value: "02:40:00"
    }, {
        label: "03:00", value: "03:00:00"
    }, {
        label: "03:05", value: "03:05:00"
    }, {
        label: "03:10", value: "03:10:00"
    }, {
        label: "03:30", value: "03:30:00"
    }, {
        label: "03:40", value: "03:40:00"
    }, {
        label: "04:00", value: "04:00:00"
    }, {
        label: "04:05", value: "04:05:00"
    }, {
        label: "04:10", value: "04:10:00"
    }, {
        label: "04:30", value: "04:30:00"
    }, {
        label: "04:40", value: "04:40:00"
    }, {
        label: "05:00", value: "05:00:00"
    }, {
        label: "05:05", value: "05:05:00"
    }, {
        label: "05:10", value: "05:10:00"
    }, {
        label: "05:30", value: "05:30:00"
    }, {
        label: "05:40", value: "05:40:00"
    }, {
        label: "06:00", value: "06:00:00"
    }, {
        label: "06:05", value: "06:05:00"
    }, {
        label: "06:10", value: "06:10:00"
    }, {
        label: "06:30", value: "06:30:00"
    }, {
        label: "06:40", value: "06:40:00"
    }, {
        label: "07:00", value: "07:00:00"
    }, {
        label: "07:05", value: "07:05:00"
    }, {
        label: "07:10", value: "07:10:00"
    }, {
        label: "07:30", value: "07:30:00"
    }, {
        label: "07:40", value: "07:40:00"
    }, {
        label: "08:00", value: "08:00:00"
    }, {
        label: "08:05", value: "08:05:00"
    }, {
        label: "08:10", value: "08:10:00"
    }, {
        label: "08:30", value: "08:30:00"
    }, {
        label: "08:40", value: "08:40:00"
    }, {
        label: "09:00", value: "09:00:00"
    }, {
        label: "09:05", value: "09:05:00"
    }, {
        label: "09:10", value: "09:10:00"
    }, {
        label: "09:30", value: "09:30:00"
    }, {
        label: "09:40", value: "09:40:00"
    }, {
        label: "10:00", value: "10:00:00"
    }, {
        label: "10:05", value: "10:05:00"
    }, {
        label: "10:10", value: "10:10:00"
    }, {
        label: "10:30", value: "10:30:00"
    }, {
        label: "10:40", value: "10:40:00"
    }, {
        label: "11:00", value: "11:00:00"
    }, {
        label: "11:05", value: "11:05:00"
    }, {
        label: "11:10", value: "11:10:00"
    }, {
        label: "11:30", value: "11:30:00"
    }, {
        label: "11:40", value: "11:40:00"
    }, {
        label: "12:00", value: "12:00:00"
    }, {
        label: "12:05", value: "12:05:00"
    }, {
        label: "12:10", value: "12:10:00"
    }, {
        label: "12:30", value: "12:30:00"
    }, {
        label: "12:40", value: "12:40:00"
    },]
    opcoesCochilar = [{
        label: "Nunca cochilaria", value: 0
    }, {
        label: "Pequena probabilidade de cochilar", value: 1
    }, {
        label: "Probabilidade média de cochilar", value: 2
    }, {
        label: "Grande probabilidade de cochilar", value: 3
    },]

    opcoesExtremo = [{
        label: "Nada", value: 1
    }, {
        label: "Muito pouco", value: 2
    }, {
        label: "Mais ou menos", value: 3
    }, {
        label: "Bastante", value: 4
    }, {
        label: "Extremamente", value: 5
    },]

    opcoesSimNao = [{
        label: "Sim", value: true
    }, {
        label: "Não", value: false
    }]

    opcoesTempoCheck8 = [{
        label: 'Todo tempo', value: 1
    }, {
        label: 'Maioria do tempo', value: 2
    }, {
        label: 'Uma boa parte do tempo', value: 3
    }, {
        label: 'Alguma parte do tempo', value: 4
    }, {
        label: 'Uma pequena parte do tempo', value: 5
    }, {
        label: 'Nunca', value: 6
    },]
    opcoesFrequenciaSemanal = [{
        label: "Mais do que uma vez por semana", value: 1
    }, {
        label: "Uma vez por semana", value: 2
    }, {
        label: "Duas a três vezes por mês", value: 3
    }, {
        label: "Algumas vezes por ano", value: 4
    }, {
        label: "Uma vez por ano mais ou menos", value: 5
    }, {
        label: "Nunca", value: 6
    },]

    opcoesFrequenciaDiaria = [{
        label: "Mais do que uma vez ao dia", value: 1
    }, {
        label: "Diariamente", value: 2
    }, {
        label: "Duas ou mais vezes por semana", value: 3
    }, {
        label: "Uma vez por semana", value: 4
    }, {
        label: "Poucas vezes por mês", value: 5
    }, {
        label: "Raramente ou nunca", value: 6
    },]
    public opcoesDificuldade = [{
        label: 'Sim. Dificulta muito', value: 1,
    }, {
        label: 'Sim. Dificulta um pouco', value: 2,
    }, {
        label: 'Não. Não dificulta de modo algum', value: 3,
    },]
    public opcoesSimOuNao = [{
        label: 'Sim', value: true
    }, {
        label: 'Não', value: false
    }]
    public opcoesGravidade = [{
        label: 'Nenhum', value: 1
    }, {
        label: 'Muito leve', value: 2
    }, {
        label: 'Leve', value: 3
    }, {
        label: 'Moderada', value: 4
    }, {
        label: 'Grave', value: 5
    }, {
        label: 'Muito grave', value: 6
    },]
 

    public opcoesVeracidade = [{
        label: 'Definitivamente verdadeira', value: 1,
    }, {
        label: 'A maioria das vezes verdadeira', value: 2,
    }, {
        label: 'Não sei', value: 3,
    }, {
        label: 'A maioria das vezes falsa', value: 4,
    }, {
        label: 'Definitivamente falsa', value: 5,
    },]

    public opcoesVeracidadeCheck9 = [{
        label: "Totalmente verdade para mim", value: 1
    }, {
        label: "Em geral é verdade", value: 2
    }, {
        label: "Nunca", value: 3
    }, {
        label: "Em geral não é verdade", value: 4
    }, {
        label: "Não é verdade", value: 5
    },]

    //10
    opcoesSimNaoSuicidio = [{
        label: "Sim", value: 1
    }, {
        label: "Não", value: 2
    }]

    opcoesClassificacaoFezes = [{
        label: "1 - Pequenas bolinhas", value: 1
    }, {
        label: "2 - Pequenas grumos", value: 2
    }, {
        label: "3 - Fezes alongadas com fissuras", value: 3
    }, {
        label: "4 - Fezes alongadas lisas", value: 4
    }, {
        label: "5 - Pedaços macios", value: 5
    }, {
        label: "6 - Massa pastosa", value: 6
    }, {
        label: "7 - Fezes líquidas", value: 7
    },]


    opcoesConcordancia = [{
        label: "Discordo plenamente", value: 1
    }, {
        label: "Discordo", value: 2
    }, {
        label: "Discordo um pouco", value: 3
    }, {
        label: "Não concordo e nem discordo", value: 4
    }, {
        label: "Concordo um pouco", value: 5
    }, {
        label: "Concordo", value: 6
    }, {
        label: "Concordo plenamente", value: 7
    },]

    opcoesSemelhancaComigo = [{
        label: "Nada parecido comigo", value: 1
    }, {
        label: "Pouco parecido comigo", value: 2
    }, {
        label: "Algo parecido comigo", value: 3
    }, {
        label: "Bastante parecido comigo", value: 4
    }, {
        label: "Muito parecido comigo", value: 5
    },]
    public listaMedicamentos: FormArray = new FormArray([]);


    constructor(
        private professorService: ProfessorService,
        private toastr: ToastrService,
        private anamneseService: AnamneseService,
        private router: Router) {
    }

    ngOnInit(): void {
       
        //getVisualizarAnamnese é um método do serviço anamneseService que é responsável por buscar os dados da anamnese
        //por meio de um hash que é passado como parâmetro
        const hashAnamnese = window.location.pathname.split('/').pop();
        this.loadingAnamnese = true;
        this.anamneseService.getVisualizarAnamnese(hashAnamnese).subscribe({
            next: (anamnese: any) => {
                this.loadingAnamnese = false;
                this.anamneseProtocolo = anamnese?.Protocolo;
                 this.formGroupEtapa2 = new FormGroup({
                    NumeroEtapa: new FormControl({value: 2, disabled: this.desativarCampos}),
                    AnamneseID: new FormControl({value: anamnese?.Etapa2?.AnamneseID, disabled: this.desativarCampos}),
                    Peso: new FormControl({value: anamnese?.Etapa2?.Peso, disabled: this.desativarCampos}),
                    Altura: new FormControl({value: anamnese?.Etapa2?.Altura, disabled: this.desativarCampos}),
                    Pressao: new FormControl({value: anamnese?.Etapa2?.Pressao, disabled: this.desativarCampos}),
                    PressaoPart1: new FormControl({value: anamnese?.Etapa2?.PressaoPart1, disabled: this.desativarCampos}),
                    PressaoPart2: new FormControl({value: anamnese?.Etapa2?.PressaoPart2, disabled: this.desativarCampos}),
                    Gestante: new FormControl({value: anamnese?.Etapa2?.Gestante, disabled: this.desativarCampos}),
                    MesesGestacao: new FormControl({value: anamnese?.Etapa2?.MesesGestacao, disabled: this.desativarCampos}),
                    TemConjuge: new FormControl({value: !!anamnese?.Etapa2?.TemConjuge, disabled: this.desativarCampos}),
                    DataNascConjuge: new FormControl(
                        {
                            value: anamnese?.Etapa2?.DataNascConjuge ? this.formataDataParaAmericana(anamnese?.Etapa2?.DataNascConjuge) : null,
                            disabled: this.desativarCampos
                        }
                    ),
                    DatasNascFilhosTexto: new FormControl({
                        value: anamnese?.Etapa2?.DatasNascFilhosTexto,
                        disabled: this.desativarCampos
                    }),
                    DatasNascFilhos: new FormControl({
                        value: anamnese?.Etapa2?.DatasNascFilhos,
                        disabled: this.desativarCampos
                    }),
                    FazendoDieta: new FormControl({value: anamnese?.Etapa2?.FazendoDieta, disabled: this.desativarCampos}),
                    TemNutricionista: new FormControl({
                        value: anamnese?.Etapa2?.TemNutricionista,
                        disabled: this.desativarCampos
                    }),
                    DescricaoDieta: new FormControl({value: anamnese?.Etapa2?.DescricaoDieta, disabled: this.desativarCampos}),
                    ProblemaCardiaco: new FormControl({
                        value: anamnese?.Etapa2?.ProblemaCardiaco,
                        disabled: this.desativarCampos
                    }),
                    DorToraxAtivFisica: new FormControl({
                        value: anamnese?.Etapa2?.DorToraxAtivFisica,
                        disabled: this.desativarCampos
                    }),
                    UltimoMesDorTorax: new FormControl({
                        value: anamnese?.Etapa2?.UltimoMesDorTorax,
                        disabled: this.desativarCampos
                    }),
                    PerdeuEquilibrio: new FormControl({
                        value: anamnese?.Etapa2?.PerdeuEquilibrio,
                        disabled: this.desativarCampos
                    }),
                    ProblemaOsseo: new FormControl({value: anamnese?.Etapa2?.ProblemaOsseo, disabled: this.desativarCampos}),
                    RemedioPressao: new FormControl({value: anamnese?.Etapa2?.RemedioPressao, disabled: this.desativarCampos}),
                    FisicoImpedeAtivFisica: new FormControl({
                        value: anamnese?.Etapa2?.FisicoImpedeAtivFisica,
                        disabled: this.desativarCampos
                    }),
                    TesteEsforco: new FormControl({value: anamnese?.Etapa2?.TesteEsforco, disabled: this.desativarCampos}),
                    NivelAtividadeFisica: new FormControl({
                        value: anamnese?.Etapa2?.NivelAtividadeFisica,
                        disabled: this.desativarCampos
                    }),
                    Tabagista: new FormControl({value: anamnese?.Etapa2?.Tabagista, disabled: this.desativarCampos}),
                    QtdCigarros: new FormControl({value: anamnese?.Etapa2?.QtdCigarros, disabled: this.desativarCampos}),
                    DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
                    DataFim: new FormControl({value: anamnese?.Etapa2?.DataFim, disabled: this.desativarCampos}),
        
                    // campos por fora (temporarios para armazenar a data no DatasNascFilhos
                    temFilhos: new FormControl({
                        value: anamnese?.Etapa2?.DatasNascFilhos?.length > 0,
                        disabled: this.desativarCampos
                    }),
                    dataNascimentoFilho: new FormControl({value: '', disabled: this.desativarCampos})
                });
                this.formGroupEtapa3 = new FormGroup({
                    NumeroEtapa: new FormControl(3),
                    AnamneseID: new FormControl({value: anamnese?.Etapa3?.AnamneseID, disabled: this.desativarCampos}),
                    DadosMedico: new FormControl({value: anamnese?.Etapa3?.DadosMedico, disabled: this.desativarCampos}),
                    HipArtSist: new FormControl({value: anamnese?.Etapa3?.HipArtSist, disabled: this.desativarCampos}),
                    HipArtSistMeses: new FormControl({
                        value: anamnese?.Etapa3?.HipArtSistMeses,
                        disabled: this.desativarCampos
                    }),
                    Fibromialgia: new FormControl({value: anamnese?.Etapa3?.Fibromialgia, disabled: this.desativarCampos}),
                    FibromialgiaMeses: new FormControl({
                        value: anamnese?.Etapa3?.FibromialgiaMeses,
                        disabled: this.desativarCampos
                    }),
                    Lupos: new FormControl({value: anamnese?.Etapa3?.Lupos, disabled: this.desativarCampos}),
                    LuposMeses: new FormControl({value: anamnese?.Etapa3?.LuposMeses, disabled: this.desativarCampos}),
                    Hipotireoidismo: new FormControl({
                        value: anamnese?.Etapa3?.Hipotireoidismo,
                        disabled: this.desativarCampos
                    }),
                    HipotireoidismoMeses: new FormControl({
                        value: anamnese?.Etapa3?.HipotireoidismoMeses,
                        disabled: this.desativarCampos
                    }),
                    Diabetes: new FormControl({value: anamnese?.Etapa3?.Diabetes, disabled: this.desativarCampos}),
                    DiabetesMeses: new FormControl({value: anamnese?.Etapa3?.DiabetesMeses, disabled: this.desativarCampos}),
                    SindromeSjogren: new FormControl({
                        value: anamnese?.Etapa3?.SindromeSjogren,
                        disabled: this.desativarCampos
                    }),
                    SindromeSjogrenMeses: new FormControl({
                        value: anamnese?.Etapa3?.SindromeSjogrenMeses,
                        disabled: this.desativarCampos
                    }),
                    NefriteLupica: new FormControl({value: anamnese?.Etapa3?.NefriteLupica, disabled: this.desativarCampos}),
                    NefriteLupicaMeses: new FormControl({
                        value: anamnese?.Etapa3?.NefriteLupicaMeses,
                        disabled: this.desativarCampos
                    }),
                    Dislipidemia: new FormControl({value: anamnese?.Etapa3?.Dislipidemia, disabled: this.desativarCampos}),
                    DislipidemiaMeses: new FormControl({
                        value: anamnese?.Etapa3?.DislipidemiaMeses,
                        disabled: this.desativarCampos
                    }),
                    ArtriteReumatoide: new FormControl({
                        value: anamnese?.Etapa3?.ArtriteReumatoide,
                        disabled: this.desativarCampos
                    }),
                    ArtriteReumatoideMeses: new FormControl({
                        value: anamnese?.Etapa3?.ArtriteReumatoideMeses,
                        disabled: this.desativarCampos
                    }),
                    Osteoartrite: new FormControl({value: anamnese?.Etapa3?.Osteoartrite, disabled: this.desativarCampos}),
                    OsteoartriteMeses: new FormControl({
                        value: anamnese?.Etapa3?.OsteoartriteMeses,
                        disabled: this.desativarCampos
                    }),
                    Osteoporose: new FormControl({value: anamnese?.Etapa3?.Osteoporose, disabled: this.desativarCampos}),
                    OsteoporoseMeses: new FormControl({
                        value: anamnese?.Etapa3?.OsteoporoseMeses,
                        disabled: this.desativarCampos
                    }),
                    Neoplasia: new FormControl({value: anamnese?.Etapa3?.Neoplasia, disabled: this.desativarCampos}),
                    NeoplasiaMeses: new FormControl({value: anamnese?.Etapa3?.NeoplasiaMeses, disabled: this.desativarCampos}),
                    Osteopenia: new FormControl({value: anamnese?.Etapa3?.Osteopenia, disabled: this.desativarCampos}),
                    OsteopeniaMeses: new FormControl({
                        value: anamnese?.Etapa3?.OsteopeniaMeses,
                        disabled: this.desativarCampos
                    }),
                    NeoplasiaPreExistente: new FormControl({
                        value: anamnese?.Etapa3?.NeoplasiaPreExistente,
                        disabled: this.desativarCampos
                    }),
                    NeoplasiaPreExistenteMeses: new FormControl({
                        value: anamnese?.Etapa3?.NeoplasiaPreExistenteMeses,
                        disabled: this.desativarCampos
                    }),
                    Depressao: new FormControl({value: anamnese?.Etapa3?.Depressao, disabled: this.desativarCampos}),
                    DepressaoMeses: new FormControl({value: anamnese?.Etapa3?.DepressaoMeses, disabled: this.desativarCampos}),
                    TranstornoDeAnsiedade: new FormControl({
                        value: anamnese?.Etapa3?.TranstornoDeAnsiedade,
                        disabled: this.desativarCampos
                    }),
                    TranstornoDeAnsiedadeMeses: new FormControl({
                        value: anamnese?.Etapa3?.TranstornoDeAnsiedadeMeses,
                        disabled: this.desativarCampos
                    }),
                    CriseDePanico: new FormControl({value: anamnese?.Etapa3?.CriseDePanico, disabled: this.desativarCampos}),
                    CriseDePanicoMeses: new FormControl({
                        value: anamnese?.Etapa3?.CriseDePanicoMeses,
                        disabled: this.desativarCampos
                    }),
                    Anorexia: new FormControl({value: anamnese?.Etapa3?.Anorexia, disabled: this.desativarCampos}),
                    AnorexiaMeses: new FormControl({value: anamnese?.Etapa3?.AnorexiaMeses, disabled: this.desativarCampos}),
                    Bulimia: new FormControl({value: anamnese?.Etapa3?.Bulimia, disabled: this.desativarCampos}),
                    BulimiaMeses: new FormControl({value: anamnese?.Etapa3?.BulimiaMeses, disabled: this.desativarCampos}),
                    Alcoolismo: new FormControl({value: anamnese?.Etapa3?.Alcoolismo, disabled: this.desativarCampos}),
                    AlcoolismoMeses: new FormControl({
                        value: anamnese?.Etapa3?.AlcoolismoMeses,
                        disabled: this.desativarCampos
                    }),
                    LocalStOsteoartrite: new FormControl({
                        value: anamnese?.Etapa3?.LocalStOsteoartrite,
                        disabled: this.desativarCampos
                    }),
                    OutraEnfermidade: new FormControl({
                        value: anamnese?.Etapa3?.OutraEnfermidade,
                        disabled: this.desativarCampos
                    }),
                    HistQuedas: new FormControl({value: anamnese?.Etapa3?.HistQuedas, disabled: this.desativarCampos}),
                    DtEnfermidadesPreExistentes: new FormControl({
                        value: anamnese?.Etapa3?.DtEnfermidadesPreExistentes,
                        disabled: this.desativarCampos
                    }),
                    Covid19: new FormControl({value: anamnese?.Etapa3?.Covid19, disabled: this.desativarCampos}),
                    DataCovid19: new FormControl({
                        value: anamnese?.Etapa3?.DataCovid19 ? this.formataDataParaAmericana(anamnese?.Etapa3?.DataCovid19) : null,
                        disabled: this.desativarCampos
                    }),
        
                    DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
                    DataFim: new FormControl({value: null, disabled: true})
                })
                this.formGroupEtapa4 = new FormGroup({
                    NumeroEtapa: new FormControl(4),
                    AnamneseID: new FormControl(anamnese?.Etapa4?.AnamneseID),
                    AlergiaMedicamentos: new FormControl({
                        value: anamnese?.Etapa4?.AlergiaMedicamentos, disabled: this.desativarCampos
                    }),
                    MedicamentosAlergenicos: new FormControl({
                        value: anamnese?.Etapa4?.MedicamentosAlergenicos, disabled: this.desativarCampos
                    }),
                    AlergiaOutros: new FormControl({value: anamnese?.Etapa4?.AlergiaOutros, disabled: this.desativarCampos}),
                    Produtos: new FormControl({value: anamnese?.Etapa4?.Produtos, disabled: this.desativarCampos}),
                    FrequenciaHorarioMedicamentos: new FormControl({
                        value: anamnese?.Etapa4?.FrequenciaHorarioMedicamentos, disabled: this.desativarCampos
                    }),
                    ExercicioRegular: new FormControl({
                        value: anamnese?.Etapa4?.ExercicioRegular, disabled: this.desativarCampos
                    }),
                    Caminhada: new FormControl({value: anamnese?.Etapa4?.Caminhada, disabled: this.desativarCampos}),
                    CaminhadaFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.CaminhadaFrequencia, disabled: this.desativarCampos
                    }),
                    CaminhadaIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.CaminhadaIntensidade, disabled: this.desativarCampos
                    }),
                    CaminhadaDuracao: new FormControl({
                        value: anamnese?.Etapa4?.CaminhadaDuracao, disabled: this.desativarCampos
                    }),
                    Corrida: new FormControl({value: anamnese?.Etapa4?.Corrida, disabled: this.desativarCampos}),
                    CorridaFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.CorridaFrequencia, disabled: this.desativarCampos
                    }),
                    CorridaIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.CorridaIntensidade, disabled: this.desativarCampos
                    }),
                    CorridaDuracao: new FormControl({value: anamnese?.Etapa4?.CorridaDuracao, disabled: this.desativarCampos}),
                    Musculacao: new FormControl({value: anamnese?.Etapa4?.Musculacao, disabled: this.desativarCampos}),
                    MusculacaoFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.MusculacaoFrequencia, disabled: this.desativarCampos
                    }),
                    MusculacaoIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.MusculacaoIntensidade, disabled: this.desativarCampos
                    }),
                    MusculacaoDuracao: new FormControl({
                        value: anamnese?.Etapa4?.MusculacaoDuracao, disabled: this.desativarCampos
                    }),
                    Danca: new FormControl({value: anamnese?.Etapa4?.Danca, disabled: this.desativarCampos}),
                    DancaFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.DancaFrequencia, disabled: this.desativarCampos
                    }),
                    DancaIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.DancaIntensidade, disabled: this.desativarCampos
                    }),
                    DancaDuracao: new FormControl({value: anamnese?.Etapa4?.DancaDuracao, disabled: this.desativarCampos}),
                    Hidroginastica: new FormControl({value: anamnese?.Etapa4?.Hidroginastica, disabled: this.desativarCampos}),
                    HidroginasticaFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.HidroginasticaFrequencia, disabled: this.desativarCampos
                    }),
                    HidroginasticaIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.HidroginasticaIntensidade, disabled: this.desativarCampos
                    }),
                    HidroginasticaDuracao: new FormControl({
                        value: anamnese?.Etapa4?.HidroginasticaDuracao, disabled: this.desativarCampos
                    }),
                    OutroExercicio: new FormControl({value: anamnese?.Etapa4?.OutroExercicio, disabled: this.desativarCampos}),
                    OutroExercicioFrequencia: new FormControl({
                        value: anamnese?.Etapa4?.OutroExercicioFrequencia, disabled: this.desativarCampos
                    }),
                    OutroExercicioIntensidade: new FormControl({
                        value: anamnese?.Etapa4?.OutroExercicioIntensidade, disabled: this.desativarCampos
                    }),
                    OutroExercicioDuracao: new FormControl({
                        value: anamnese?.Etapa4?.OutroExercicioDuracao, disabled: this.desativarCampos
                    }),
                    DescOutroExercicio: new FormControl({
                        value: anamnese?.Etapa4?.DescOutroExercicio, disabled: this.desativarCampos
                    }),
                    QtdExercicioSemana: new FormControl({
                        value: anamnese?.Etapa4?.QtdExercicioSemana, disabled: this.desativarCampos
                    }),
                    Intensidade: new FormControl({value: anamnese?.Etapa4?.Intensidade, disabled: this.desativarCampos}),
                    DuracaoSessao: new FormControl({value: anamnese?.Etapa4?.DuracaoSessao, disabled: this.desativarCampos}),
                    SomaSemanal: new FormControl({value: anamnese?.Etapa4?.SomaSemanal, disabled: this.desativarCampos}),
                    SuplementoAlimentar: new FormControl({
                        value: anamnese?.Etapa4?.SuplementoAlimentar, disabled: this.desativarCampos
                    }),
                    DescSuplemento: new FormControl({value: anamnese?.Etapa4?.DescSuplemento, disabled: this.desativarCampos}), // MedicamentosEmUso: [],
                    DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
                    DataFim: new FormControl(null),
                    CafeDaManha: new FormControl({
                        value: anamnese?.Etapa4?.CafeDaManha, disabled: this.desativarCampos
                    }),
                    Lanche1: new FormControl({value: anamnese?.Etapa4?.Lanche1, disabled: this.desativarCampos}),
                    Almoco: new FormControl({value: anamnese?.Etapa4?.Almoco, disabled: this.desativarCampos}),
                    Lanche2: new FormControl({value: anamnese?.Etapa4?.Lanche2, disabled: this.desativarCampos}),
                    Jantar: new FormControl({value: anamnese?.Etapa4?.Jantar, disabled: this.desativarCampos}),
                    Ceia: new FormControl({value: anamnese?.Etapa4?.Ceia, disabled: this.desativarCampos}),
                    MedicamentosEmUso: new FormControl({ value: anamnese?.Etapa4?.MedicamentosEmUso, disabled: this.desativarCampos }),
                    MedicamentosEmUsoSIM: new FormControl({value: '', disabled: this.desativarCampos}),
                    listaMedicamentos: new FormArray([]),
                })
                this.formGroupEtapa5 = new FormGroup({
                    NumeroEtapa: new FormControl({value: anamnese?.Etapa5.NumeroEtapa, disabled: this.desativarCampos}),
                    AnamneseID: new FormControl({value: anamnese?.Etapa5.AnamneseID, disabled: this.desativarCampos}),
                    ProxIdeal: new FormControl({value: anamnese?.Etapa5.ProxIdeal, disabled: this.desativarCampos}),
                    VidaExcelente: new FormControl({value: anamnese?.Etapa5.VidaExcelente, disabled: this.desativarCampos}),
                    SatisfeitoVida: new FormControl({value: anamnese?.Etapa5.SatisfeitoVida, disabled: this.desativarCampos}),
                    DesejeiVida: new FormControl({value: anamnese?.Etapa5.DesejeiVida, disabled: this.desativarCampos}),
                    MudariaNada: new FormControl({value: anamnese?.Etapa5.MudariaNada, disabled: this.desativarCampos}),
                    DataInicio: new FormControl({value: new Date(), disabled: this.desativarCampos}),
                    DataFim: new FormControl(null),
                })
                this.formGroupEtapa6 = new FormGroup({
                    NumeroEtapa: new FormControl(anamnese?.Etapa6?.NumeroEtapa),
                    AnamneseID: new FormControl(anamnese?.Etapa6?.AnamneseID),
                    Dor: new FormControl({value: anamnese?.Etapa6?.Dor || 0, disabled: this.desativarCampos}),
                    LocalDor: new FormControl({value: anamnese?.Etapa6?.LocalDor || '', disabled: this.desativarCampos}),
                    Ansiedade: new FormControl({value: anamnese?.Etapa6?.Ansiedade || 0, disabled: this.desativarCampos}),
                    Fadiga: new FormControl({value: anamnese?.Etapa6?.Fadiga || 0, disabled: this.desativarCampos}),
                    Gratidao: new FormControl({value: anamnese?.Etapa6?.Gratidao || 0, disabled: this.desativarCampos}),
                    Animo: new FormControl({value: anamnese?.Etapa6?.Animo || 0, disabled: this.desativarCampos}),
                    Sono: new FormControl({value: anamnese?.Etapa6?.Sono || 0, disabled: this.desativarCampos}),
                    Disposicao: new FormControl({value: anamnese?.Etapa6?.Disposicao || 0, disabled: this.desativarCampos}),
                    Percepcao: new FormControl({value: anamnese?.Etapa6?.Percepcao || 0, disabled: this.desativarCampos}),
                    CapacidadeFuncional: new FormControl({
                        value: anamnese?.Etapa6?.CapacidadeFuncional || 0,
                        disabled: this.desativarCampos
                    }),
                    Estresse: new FormControl({value: anamnese?.Etapa6?.Estresse || 0, disabled: this.desativarCampos}),
                    Felicidade: new FormControl({value: anamnese?.Etapa6?.Felicidade || 0, disabled: this.desativarCampos}),
                    DataInicio: new FormControl(new Date()),
                    DataFim: new FormControl(null)
                })
                this.formGroupEtapa7 = new FormGroup({
                    NumeroEtapa: new FormControl(anamnese?.Etapa7?.NumeroEtapa),
                    AnamneseID: new FormControl(anamnese?.Etapa7?.AnamneseID),
                    IpacDiasCaminhando: new FormControl({
                        value: anamnese?.Etapa7?.IpacDiasCaminhando, disabled: this.desativarCampos
                    }),
                    IpacTempoCaminhando: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoCaminhando, disabled: this.desativarCampos
                    }),
                    IpacDiasAtivModerada: new FormControl({
                        value: anamnese?.Etapa7?.IpacDiasAtivModerada, disabled: this.desativarCampos
                    }),
                    IpacTempoAtivModerada: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoAtivModerada, disabled: this.desativarCampos
                    }),
                    IpacDiasAtivVigorosa: new FormControl({
                        value: anamnese?.Etapa7?.IpacDiasAtivVigorosa, disabled: this.desativarCampos
                    }),
                    IpacTempoAtivVigorosa: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoAtivVigorosa, disabled: this.desativarCampos
                    }),
                    IpacTempoTrabalhoDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTrabalhoDia, disabled: this.desativarCampos
                    }),
                    IpacTempoTransporteDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTransporteDia, disabled: this.desativarCampos
                    }),
                    IpacTempoTvDia: new FormControl({value: anamnese?.Etapa7?.IpacTempoTvDia, disabled: this.desativarCampos}),
                    IpacTempoComputadorDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoComputadorDia, disabled: this.desativarCampos
                    }),
                    IpacTempoDescansoDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoDescansoDia, disabled: this.desativarCampos
                    }),
                    IpacTempoLeituraDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoLeituraDia, disabled: this.desativarCampos
                    }),
                    IpacTempoTrabalhoFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTrabalhoFDS, disabled: this.desativarCampos
                    }),
                    IpacTempoTransporteFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTransporteFDS, disabled: this.desativarCampos
                    }),
                    IpacTempoTvFDS: new FormControl({value: anamnese?.Etapa7?.IpacTempoTvFDS, disabled: this.desativarCampos}),
                    IpacTempoComputadorFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoComputadorFDS, disabled: this.desativarCampos
                    }),
                    IpacTempoDescansoFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoDescansoFDS, disabled: this.desativarCampos
                    }),
                    IpacTempoLeituraFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoLeituraFDS, disabled: this.desativarCampos
                    }),
                    TotaisTempoSentado: new FormControl({
                        value: anamnese?.Etapa7?.TotaisTempoSentado, disabled: this.desativarCampos
                    }),
                    IpacTempoTotalSentadoDia: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTotalSentadoDia, disabled: this.desativarCampos
                    }),
                    IpacTempoTotalSentadoFDS: new FormControl({
                        value: anamnese?.Etapa7?.IpacTempoTotalSentadoFDS, disabled: this.desativarCampos
                    }),
        
                    DataInicio: new FormControl(new Date()),
                    DataFim: new FormControl(null)
                })
                this.formGroupEtapa8 = new FormGroup({
                    NumeroEtapa: new FormControl(anamnese?.Etapa8?.NumeroEtapa),
                    AnamneseID: new FormControl(anamnese?.Etapa8?.AnamneseID),
                    Saude: new FormControl({value: anamnese?.Etapa8?.Saude, disabled: this.desativarCampos}),
                    SaudeComparada: new FormControl({value: anamnese?.Etapa8?.SaudeComparada, disabled: this.desativarCampos}),
                    AtivVigorosas: new FormControl({value: anamnese?.Etapa8?.AtivVigorosas, disabled: this.desativarCampos}),
                    AtivModeradas: new FormControl({value: anamnese?.Etapa8?.AtivModeradas, disabled: this.desativarCampos}),
                    Compras: new FormControl({value: anamnese?.Etapa8?.Compras, disabled: this.desativarCampos}),
                    SubirEscadas: new FormControl({value: anamnese?.Etapa8?.SubirEscadas, disabled: this.desativarCampos}),
                    SubirEscada: new FormControl({value: anamnese?.Etapa8?.SubirEscada, disabled: this.desativarCampos}),
                    Ajoelhar: new FormControl({value: anamnese?.Etapa8?.Ajoelhar, disabled: this.desativarCampos}),
                    AndarAcimaUmKm: new FormControl({value: anamnese?.Etapa8?.AndarAcimaUmKm, disabled: this.desativarCampos}),
                    AndarVariosQuarteiroes: new FormControl({
                        value: anamnese?.Etapa8?.AndarVariosQuarteiroes, disabled: this.desativarCampos
                    }),
                    AndarUmQuarteirao: new FormControl({
                        value: anamnese?.Etapa8?.AndarUmQuarteirao, disabled: this.desativarCampos
                    }),
                    Banho: new FormControl({value: anamnese?.Etapa8?.Banho, disabled: this.desativarCampos}),
                    DiminuiuTempoAtiv: new FormControl({
                        value: anamnese?.Etapa8?.DiminuiuTempoAtiv, disabled: this.desativarCampos
                    }),
                    MenosTarefas: new FormControl({value: anamnese?.Etapa8?.MenosTarefas, disabled: this.desativarCampos}),
                    NaoTrabalhou: new FormControl({value: anamnese?.Etapa8?.NaoTrabalhou, disabled: this.desativarCampos}),
                    DificuldadeTrabalho: new FormControl({
                        value: anamnese?.Etapa8?.DificuldadeTrabalho, disabled: this.desativarCampos
                    }),
                    QtdTempoTrabalho: new FormControl({
                        value: anamnese?.Etapa8?.QtdTempoTrabalho, disabled: this.desativarCampos
                    }),
                    MenosTarefasGostaria: new FormControl({
                        value: anamnese?.Etapa8?.MenosTarefasGostaria, disabled: this.desativarCampos
                    }),
                    TarefasCuidado: new FormControl({value: anamnese?.Etapa8?.TarefasCuidado, disabled: this.desativarCampos}),
                    InterferenciaSocial: new FormControl({
                        value: anamnese?.Etapa8?.InterferenciaSocial, disabled: this.desativarCampos
                    }),
                    DorCorpo: new FormControl({value: anamnese?.Etapa8?.DorCorpo, disabled: this.desativarCampos}),
                    InterferenciaDorCorpo: new FormControl({ value: anamnese?.Etapa8?.InterferenciaDorCorpo, disabled: this.desativarCampos}),
                    Vigor: new FormControl({value: anamnese?.Etapa8?.Vigor, disabled: this.desativarCampos}),
                    TempoNervoso: new FormControl({value: anamnese?.Etapa8?.TempoNervoso, disabled: this.desativarCampos}),
                    TempoDeprimido: new FormControl({value: anamnese?.Etapa8?.TempoDeprimido, disabled: this.desativarCampos}),
                    TempoCalmo: new FormControl({value: anamnese?.Etapa8?.TempoCalmo, disabled: this.desativarCampos}),
                    TempoEnergia: new FormControl({value: anamnese?.Etapa8?.TempoEnergia, disabled: this.desativarCampos}),
                    TempoAbatido: new FormControl({value: anamnese?.Etapa8?.TempoAbatido, disabled: this.desativarCampos}),
                    TempoEsgotado: new FormControl({value: anamnese?.Etapa8?.TempoEsgotado, disabled: this.desativarCampos}),
                    TempoFeliz: new FormControl({value: anamnese?.Etapa8?.TempoFeliz, disabled: this.desativarCampos}),
                    TempoCansado: new FormControl({value: anamnese?.Etapa8?.TempoCansado, disabled: this.desativarCampos}),
                    TempoSaudeInterferiu: new FormControl({
                        value: anamnese?.Etapa8?.TempoSaudeInterferiu, disabled: this.desativarCampos
                    }),
                    AdoecerComparado: new FormControl({
                        value: anamnese?.Etapa8?.AdoecerComparado, disabled: this.desativarCampos
                    }),
                    Saudavel: new FormControl({value: anamnese?.Etapa8?.Saudavel, disabled: this.desativarCampos}),
                    SaudePiorar: new FormControl({value: anamnese?.Etapa8?.SaudePiorar, disabled: this.desativarCampos}),
                    SaudeExcelente: new FormControl({value: anamnese?.Etapa8?.SaudeExcelente, disabled: this.desativarCampos}),
                    DataInicio: new FormControl(new Date()),
                    DataFim: new FormControl(null)
                })
                this.formGroupEtapa9 = new FormGroup({
                    NumeroEtapa: new FormControl({value: anamnese?.Etapa9?.NumeroEtapa, disabled: this.desativarCampos}),
                    AnamneseID: new FormControl({value: anamnese?.Etapa9?.AnamneseID, disabled: this.desativarCampos}),
                    SentadoLendo: new FormControl({value: anamnese?.Etapa9?.SentadoLendo, disabled: this.desativarCampos}),
                    AssistindoTv: new FormControl({value: anamnese?.Etapa9?.AssistindoTv, disabled: this.desativarCampos}),
                    QuietoLugarPublico: new FormControl({
                        value: anamnese?.Etapa9?.QuietoLugarPublico,
                        disabled: this.desativarCampos
                    }),
                    AndandoCarro: new FormControl({value: anamnese?.Etapa9?.AndandoCarro, disabled: this.desativarCampos}),
                    QuietoAlmoco: new FormControl({value: anamnese?.Etapa9?.QuietoAlmoco, disabled: this.desativarCampos}),
                    ParadoCarro: new FormControl({value: anamnese?.Etapa9?.ParadoCarro, disabled: this.desativarCampos}),
                    SentadoConversando: new FormControl({
                        value: anamnese?.Etapa9?.SentadoConversando,
                        disabled: this.desativarCampos
                    }),
                    DeitadoDescanso: new FormControl({
                        value: anamnese?.Etapa9?.DeitadoDescanso,
                        disabled: this.desativarCampos
                    }),
                    SentidoVida: new FormControl({value: anamnese?.Etapa9?.SentidoVida, disabled: this.desativarCampos}),
                    MedidaSentidoVida: new FormControl({
                        value: anamnese?.Etapa9?.MedidaSentidoVida,
                        disabled: this.desativarCampos
                    }),
                    ForcaDificuldade: new FormControl({
                        value: anamnese?.Etapa9?.ForcaDificuldade,
                        disabled: this.desativarCampos
                    }),
                    EntenderDificuldades: new FormControl({
                        value: anamnese?.Etapa9?.EntenderDificuldades,
                        disabled: this.desativarCampos
                    }),
                    TemReligiao: new FormControl({value: anamnese?.Etapa9?.TemReligiao, disabled: this.desativarCampos}),
                    Religiao: new FormControl({value: anamnese?.Etapa9?.Religiao, disabled: this.desativarCampos}),
                    FrequentaSemanalmente: new FormControl({
                        value: anamnese?.Etapa9?.FrequentaSemanalmente,
                        disabled: this.desativarCampos
                    }),
                    LocalFrequenta: new FormControl({value: anamnese?.Etapa9?.LocalFrequenta, disabled: this.desativarCampos}),
                    FrequentaVezes: new FormControl({value: anamnese?.Etapa9?.FrequentaVezes, disabled: this.desativarCampos}),
                    EspiritualidadeFrequenciaTemplo: new FormControl({
                        value: anamnese?.Etapa9?.EspiritualidadeFrequenciaTemplo,
                        disabled: this.desativarCampos
                    }),
                    EspiritualidadeLeituraFrequencia: new FormControl({
                        value: anamnese?.Etapa9?.EspiritualidadeLeituraFrequencia,
                        disabled: this.desativarCampos
                    }),
                    EspiritualidadePresencaDeus: new FormControl({
                        value: anamnese?.Etapa9?.EspiritualidadePresencaDeus,
                        disabled: this.desativarCampos
                    }),
                    EspiritualidadeCrencasReligiosas: new FormControl({
                        value: anamnese?.Etapa9?.EspiritualidadeCrencasReligiosas,
                        disabled: this.desativarCampos
                    }),
                    EspiritualidadeEsforcoViver: new FormControl({
                        value: anamnese?.Etapa9?.EspiritualidadeEsforcoViver,
                        disabled: this.desativarCampos
                    }),
                    DataInicio: new FormControl(new Date()),
                    DataFim: new FormControl(null)
                })
                this.formGroupEtapa10 = new FormGroup({
                    NumeroEtapa: new FormControl({value: anamnese?.Etapa10?.NumeroEtapa, disabled: this.desativarCampos}),
                    AnamneseID: new FormControl({value: anamnese?.Etapa10?.AnamneseID, disabled: this.desativarCampos}),
                    AgradecerVida: new FormControl({value: anamnese?.Etapa10?.AgradecerVida, disabled: this.desativarCampos}),
                    ListaMuitoLonga: new FormControl({
                        value: anamnese?.Etapa10?.ListaMuitoLonga, disabled: this.desativarCampos
                    }),
                    MotivosAgradecer: new FormControl({
                        value: anamnese?.Etapa10?.MotivosAgradecer, disabled: this.desativarCampos
                    }),
                    MuitaGente: new FormControl({value: anamnese?.Etapa10?.MuitaGente, disabled: this.desativarCampos}),
                    HistoriaVida: new FormControl({value: anamnese?.Etapa10?.HistoriaVida, disabled: this.desativarCampos}),
                    AlguemAlgumaCoisa: new FormControl({
                        value: anamnese?.Etapa10?.AlguemAlgumaCoisa, disabled: this.desativarCampos
                    }),
                    EscalaBristol: new FormControl({value: anamnese?.Etapa10?.EscalaBristol, disabled: this.desativarCampos}),
                    Perdao: new FormControl({value: anamnese?.Etapa10?.Perdao, disabled: this.desativarCampos}),
                    SuicidioPensou: new FormControl({value: anamnese?.Etapa10?.SuicidioPensou, disabled: this.desativarCampos}),
                    SuicidioUltimoAno: new FormControl({
                        value: anamnese?.Etapa10?.SuicidioUltimoAno, disabled: this.desativarCampos
                    }),
                    SuicidioTentou: new FormControl({value: anamnese?.Etapa10?.SuicidioTentou, disabled: this.desativarCampos}),
                    CodigoSuicidio: new FormControl({value: anamnese?.Etapa10?.CodigoSuicidio, disabled: this.desativarCampos}),
                    DistraemAnteriores: new FormControl({
                        value: anamnese?.Etapa10?.DistraemAnteriores, disabled: this.desativarCampos
                    }),
                    NaoDesanimam: new FormControl({value: anamnese?.Etapa10?.NaoDesanimam, disabled: this.desativarCampos}),
                    PerdiInteresse: new FormControl({value: anamnese?.Etapa10?.PerdiInteresse, disabled: this.desativarCampos}),
                    MuitoTrabalhador: new FormControl({
                        value: anamnese?.Etapa10?.MuitoTrabalhador, disabled: this.desativarCampos
                    }),
                    SubstituoOutra: new FormControl({value: anamnese?.Etapa10?.SubstituoOutra, disabled: this.desativarCampos}),
                    SeremConcluidos: new FormControl({
                        value: anamnese?.Etapa10?.SeremConcluidos, disabled: this.desativarCampos
                    }),
                    QueConheco: new FormControl({value: anamnese?.Etapa10?.QueConheco, disabled: this.desativarCampos}),
                    SouEsforcado: new FormControl({value: anamnese?.Etapa10?.SouEsforcado, disabled: this.desativarCampos}),
                    DataInicio: new FormControl(new Date()),
                    DataFim: new FormControl(null),
                    Termo: new FormControl(null),
                })

                //se MedicamentosEmUso.length > 0, então MedicamentosEmUsoSIM = true
                if (this.formGroupEtapa4.get('MedicamentosEmUso').value.length > 0) {
                    this.formGroupEtapa4.get('MedicamentosEmUsoSIM').setValue(true);
                }

                this.addMedicamentosToForm(anamnese?.Etapa4?.MedicamentosEmUso);

            },
            error(error) {
                //toast
                this.loadingAnamnese = false;
                this.toastr.error('Erro ao buscar anamnese, tente novamente mais tarde');
            }
          })

     

  
    }

    addMedicamentosToForm(medicamentos: any[]) {
        medicamentos.forEach(med => {
            const medicamentoGroup = new FormGroup({
                Produto: new FormControl({ value: med.Medicamento.Produto.Produto, disabled: this.desativarCampos }),
                Nome: new FormControl({ value: med.Nome, disabled: this.desativarCampos }),
                Dose: new FormControl({ value: med.Dose, disabled: this.desativarCampos }),
                Periodicidade: new FormControl({ value: med.Periodicidade, disabled: this.desativarCampos })
            });
            this.listaMedicamentos.push(medicamentoGroup);
        });
    }

    formataDataParaAmericana(data) {
        // recebe 2024-04-12T00:00:00
        // retorna 2024-04-12
        return data.split('T')[0];
    }
 
    toggleCheckbox(checkboxName, newValue = false) {
        this.formGroupEtapa2.get(checkboxName).setValue(newValue);
    }

    toggleCheckboxWidthValue(checkboxName: string, value: any) {
        this.formGroupEtapa2.get(checkboxName).setValue(value);
    }

    formatarDataNascimento(data) {
        let dataFormatada = data.split('T')[0];
        return formatarDataParaDDMMYYYY(dataFormatada);
    }

    deletarFilho(id) {
        this.listaFilhos = this.listaFilhos.filter(f => f.id != id);
        this.formGroupEtapa2.get('DatasNascFilhos').setValue(this.listaFilhos);
    }
    
    adicionarFilho() {
        const dataNascimentoFilho = this.formGroupEtapa2.get('dataNascimentoFilho').value;
        this.listaFilhos.push({id: Math.random() * 10000000, value: dataNascimentoFilho});
        this.formGroupEtapa2.get('DatasNascFilhos').setValue(this.listaFilhos);
    }

    onSelectStepper(stepperSelected: number) {

        this.stepperSelected = stepperSelected;
        this.steppers =  this.steppers.map((stepper, index) => {
            stepper.isSelected = index + 1 === stepperSelected;
            return stepper
        });
    }

 
}



