
/**
 * 
 * Login na API da plataforma safe 
 * 
 */
export class LoginModel {
    public email: string = "";
    public senha: string = "";
  }
  