import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {getHeaders} from "@/utils/getHeaders";

export interface Cupom {
    Ativo: boolean
    Avaliador: null
    AvaliadorID: number
    BloquearLaudo: boolean
    Checkups: Array<any>
    Codigo: string
    DataAlteracao: Date
    DataInclusao: Date
    Descricao: string
    DiasProdutoCheckup: number
    Grupo: null
    GrupoID: null
    ID: number
    IdentificadorHash: string
    Itens: Array<any>
    Quantidade: number
    TornarAvaliador: boolean
    URL: string
    UsuarioAlteracao: string
    UsuarioInclusao: string
    Vencimento: string
}

@Injectable({
    providedIn: 'root'
})
export class CupomService {
    private api = environment.endpoint_api

    constructor(private http: HttpClient) {
    }

    getCuponsAtivos() {
        const headers = getHeaders()
        return this.http.get(this.api + "/Cupom/ListarAtivos", {headers})
    }

    getTodosCupons(body) {
        const headers = getHeaders()
        return this.http.post(this.api + "/Cupom/ConsultarGrid", body, {headers})
    }

    listarAtivos() {
        const headers = getHeaders()
        return this.http.get(this.api + '/ProdutoServico/ListarAtivos', {headers})
    }

    listaAvaliadores() {
        const headers = getHeaders()
        return this.http.get(this.api + '/Usuario/ObterListaAvaliadores', {headers})
    }

    getCupomByHash(hash) {
        const headers = getHeaders()
        return this.http.get(this.api + '/Cupom/ObterPorHash/' + hash, {headers})
    }

    salvarCupom(body) {
        const headers = getHeaders()
        return this.http.post(this.api + '/Cupom', body, {headers})
    }

}
