import { LoginModel } from '@/models/login.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalBaixarAppComponent } from '@components/dashboard/modais/Modal-BaixarApp/modal-baixar-app.component';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-loading-login-page',
  templateUrl: './loading-login-page.component.html',
  styleUrls: ['./loading-login-page.component.scss']
})
export class LoadingLoginPage implements OnInit {
  public formLogin: FormGroup;
  public carregandoLogin: boolean = false
  public router = window
  // public mostrarModal: boolean = false;

  ngOnInit() {
    const login = new LoginModel()
    this.formLogin = new FormGroup({
      email: new FormControl(login.email, [Validators.required]),
      senha: new FormControl(login.senha, [Validators.required])
    });

/*     this.verificarTamanhoDaTela();
    window.addEventListener('resize', () => {
      this.verificarTamanhoDaTela();
    }); */
  }

  constructor(
    private toastr: ToastrService,
    private appService: AppService
  ) { }

/*   verificarTamanhoDaTela() {
    if (window.innerWidth <= 768) {
      this.mostrarModal = true;
      const modalRef = this.appService.openModal(ModalBaixarAppComponent, {size: 'lg', backdrop: false})
    } else {
      this.mostrarModal = false;
      this.appService.closeModal();
    }
  } */

  async onClickLogin() {
    try {
      this.carregandoLogin = true;
      if (this.formLogin.valid) {
        await this.appService.loginByAuth(this.formLogin.value);
        this.carregandoLogin = false;
      } else {
        this.toastr.error('Preencha os campos do formulário corretamente', 'Campos inválidos');
        this.carregandoLogin = false;
      }
    } catch (error) {
      if (error?.error?.error_description.length < 30) {
        this.toastr.error(error?.error?.error_description);
      } else{
        this.toastr.error('Erro ao realizar login, contate o suporte');
      }
      this.carregandoLogin = false;
    }
    this.carregandoLogin = false; // Defina como false, independentemente do resultado
  }

  async handleCadastrarClick() {
    //redirecionar para a página de cadastro
    this.router.location.href = ''
    }
}
