import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProfessorService } from "@services/dashboard/professor/professor.service";
import { RelatorioCadastroService } from '@services/relatorios/relatorio-cadastros/relatorio-cadastros.service';

@Component({
    selector: 'app-relatorioAniversariantesComponent',
    templateUrl: './relatorio-aniversariantes.component.html',
    styleUrls: ['./relatorio-aniversariantes.component.scss']
})
export class AniversariantesComponent implements OnInit {
    public relatorioList: Array<any> = [];
    public formCadastro: FormGroup;
    public listaMeses: Array<any> = [
        { id: 1, nome: 'Janeiro' },
        { id: 2, nome: 'Fevereiro' },
        { id: 3, nome: 'Março' },
        { id: 4, nome: 'Abril' },
        { id: 5, nome: 'Maio' },
        { id: 6, nome: 'Junho' },
        { id: 7, nome: 'Julho' },
        { id: 8, nome: 'Agosto' },
        { id: 9, nome: 'Setembro' },
        { id: 10, nome: 'Outubro' },
        { id: 11, nome: 'Novembro' },
        { id: 12, nome: 'Dezembro' },
    ];
    public loading = false
    public nenhumRegistro = true


    constructor(private relatorioCadastroService: RelatorioCadastroService, private professorService: ProfessorService) {
    }

    ngOnInit() {
        this.formCadastro = new FormGroup({
            mesID: new FormControl(0, []),
      
        });

       
    }

    atualizarListagemRelatorio() {
        const classeContexto = this
        this.loading = true
        this.relatorioCadastroService.getRelatorioAniversariantes(this.formCadastro.value).subscribe({
            next(response) {
                //em ordem dos nomes que começam com A ao Z
                classeContexto.relatorioList = (response as Array<any>).sort((a, b) => {
                    if (a.Nome < b.Nome) {
                        return -1;
                    } else if (a.Nome > b.Nome) {
                        return 1;
                    } else {
                        return 0;
                    }
                });
                classeContexto.nenhumRegistro = classeContexto.relatorioList.length === 0
                classeContexto.loading = false
                
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }

    formatarCelular(celular: string) {
        if (!celular) return celular
        let celularFormatado = `(${celular.substring(0, 2)}) ${celular.substring(2, 7)}-${celular.substring(7, 11)}`
        return  celularFormatado
    }
 

    baixarLaudo(id: number) {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
      }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    getGenero(idGenero: number) {
        switch (idGenero) {
            case 1:
                return 'Masculino'
            case 2:
                return 'Feminino'
            default:
                return ''
        }
    }

}
