import { Component, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { ModalPercepSaudeComponent } from '../Modal6-PercepSaude/modal-percepSaude.component';

@Component({
  selector: 'app-modal-bioAnterior',
  templateUrl: './modal-bioAnterior.component.html',
  styleUrls: ['./modal-bioAnterior.component.scss']
})
export class ModalBioAnteriorComponent implements OnInit {
  valorSlider: number = 0
  constructor(
    private appService: AppService
  ) {
  }

  ngOnInit(): void {

  }

  handleClickFecharModal() {
    this.appService.closeModal()
  }


  handleClickProximoModal() {
    this.appService.closeModal()
    // const modalRef = this.appService.openModal(ModalLimAspectosFisicosComponent, { size: 'lg', backdrop: false })
  }
}
