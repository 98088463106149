import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {getHeaders} from "@/utils/getHeaders";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class RelatorioTabelaGorduraService {

    private api = environment.endpoint_api

    constructor(private http: HttpClient) {
    }

    getRelatorioTabelaGordura(body) {
        const headers = getHeaders()
        return this.http.post(this.api + '/Relatorio/RelatorioTabelaGordura', body, {headers})
    }
}
