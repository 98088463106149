export function formatarDataParaDDMMYYYY(dataString: string): string {
    // Dividir a string da data nos componentes ano, mês e dia
    const [ano, mes, dia] = dataString.split('-');

    // Formatar a data no formato 'dd/mm/yyyy'
    const dataFormatada = `${dia}/${mes}/${ano}`;

    return dataFormatada;
}

export function formatarDataDDMMYYYY2(dataString: string): string {
    const data = dataString.split('T')[0];
    const ano = data.substring(0, 4);
    const mes = data.substring(5, 7);
    const dia = data.substring(8, 10);

    // Formatar a data no formato 'dd/mm/yyyy'
    const dataFormatada = `${dia}/${mes}/${ano}`;
    return dataFormatada;
}


export function formatarDataParaYYYYMMDD(dataString: string): string {
    //se ja estiver no formato yyyy-mm-dd
    if (dataString.indexOf('-') > 0) {
        return dataString;
    }
    if (dataString === '') {
        return '';
    }
    if (dataString === null) {
        return '';
    }
    // Dividir a string da data nos componentes dia, mês e ano
    const dia = dataString.substring(0, 2);
    const mes = dataString.substring(2, 4);
    const ano = dataString.substring(4, 8);

    // Formatar a data no formato 'yyyy-mm-dd'
    const dataFormatada = `${ano}-${mes}-${dia}`;
    return dataFormatada;
}