
<div>
    <section class="content">
        <div class="loading-container" *ngIf="loadingGetDadosConsultaZporGenero">
            <div class="spinner-border text-azul-plataforma-safe" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <canvas id="ChartConsultarNiveisPacientesPorGenero">{{ chart }}</canvas>
    </section>
</div>