import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ModalDadosPessoaisComponent} from '../Modal2-DadosPessoais/modal-dadospessoais.component';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-1inicio',
    templateUrl: './modal-1inicio.component.html',
    styleUrls: ['./modal-1inicio.component.scss']
})
export class Modal1InicioComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public carregandoAtualizarAnamnese: boolean = false
    public desativarCampos: boolean = false

    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router
    ) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.handleClickFecharModal()
    }

    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalDadosPessoaisComponent)
            return
        }

        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa1: {
                ...anamnese?.Etapa1, DataInicioPreenchimento: new Date(),
            }
        }

        this.anamneseService.comecarAnamnese(anamnese?.IdentificadorHash).subscribe({
            next(res) {
                classeContexto.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response) {
                        // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                        // @ts-ignore
                        if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 1) {
                            // @ts-ignore
                            classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                            classeContexto.carregandoAtualizarAnamnese = false
                            return
                        }

                        // atualiza a anamnese se tudo estiver ok
                        classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                            next(response2) {

                                classeContexto.carregandoAtualizarAnamnese = false
                                classeContexto.anamneseService.setAnamnese(response2)
                                classeContexto.appService.closeModal()
                                classeContexto.appService.openModal(ModalDadosPessoaisComponent)
                            }, error(response) {
                                classeContexto.carregandoAtualizarAnamnese = false
                                if (typeof response?.error?.Message == 'string') {
                                    classeContexto.erroAvancarAnamnese = response?.error?.Message
                                } else {
                                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                                }
                            }
                        })

                    }, error(erro) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof erro?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = erro?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                        }
                    }
                })
            }, error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao iniciar  a anamnese"
                }
            }
        })
    }
}
