import {Component, OnInit} from '@angular/core';
import {
    RelatorioTabelaGorduraService
} from "@services/relatorios/relatorio-tabela-gordura/relatorio-tabela-gordura.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ProfessorService} from "@services/dashboard/professor/professor.service";
import {
    declarationFunctions
} from "@angular/compiler-cli/linker/src/file_linker/partial_linkers/partial_linker_selector";

@Component({
    selector: 'app-tabelaGordura',
    templateUrl: './relatorio-tabela-gordura.component.html',
    styleUrls: ['./relatorio-tabela-gordura.component.scss']
})
export class RelatorioTabelaGorduraComponent implements OnInit {
    public relatorioTabelaGorduraList: Array<any> = [];
    public formTabelaGordura: FormGroup;
    public listaAvaliadores: Array<any> = [];
    public loading = false
    public nenhumRegistro = true


    constructor(private relatorioTabelaGorduraService: RelatorioTabelaGorduraService, private professorService: ProfessorService) {
    }

    ngOnInit() {
        this.formTabelaGordura = new FormGroup({
            AvaliadorID: new FormControl(0, []),
            PeriodoDe: new FormControl('', []),
            PeriodoAte: new FormControl('', []),
        });

        this.atualizarListagemRelatorio()
        this.atualizarListaAvaliadores()
    }

    atualizarListagemRelatorio() {
        const classeContexto = this
        this.loading = true
        this.relatorioTabelaGorduraService.getRelatorioTabelaGordura(this.formTabelaGordura.value).subscribe({
            next(response) {
                classeContexto.relatorioTabelaGorduraList = response as Array<any>
                classeContexto.nenhumRegistro = classeContexto.relatorioTabelaGorduraList.length === 0
                classeContexto.loading = false
                
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }

    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    getGenero(idGenero: number) {
        switch (idGenero) {
            case 1:
                return 'Masculino'
            case 2:
                return 'Feminino'
            default:
                return ''
        }
    }

}
