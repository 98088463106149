import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppService} from '@services/app.service';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ModalCheckBoxesComponent} from '../Modal5-CheckBoxes/modal-checkBoxes.component';
import {ModalSaudEmocFuncComponent} from '../Modal7-SaudEmocFunc/modal-saudEmocFunc.component';
import {Router} from "@angular/router";

@Component({
    selector: 'app-modal-percepSaude',
    templateUrl: './modal-percepSaude.component.html',
    styleUrls: ['./modal-percepSaude.component.scss']
})
export class ModalPercepSaudeComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public formGroupEtapa6: FormGroup
    public carregandoAtualizarAnamnese: boolean = false
    public desativarCampos: boolean = false


    constructor(private appService: AppService, private anamneseService: AnamneseService, private router: Router) {
    }

    ngOnInit(): void {
        this.desativarCampos = this.router.url.includes('acesso-avaliador')
        const anamnese = this.anamneseService.getAnamnese()

        this.formGroupEtapa6 = new FormGroup({
            NumeroEtapa: new FormControl(anamnese?.Etapa6?.NumeroEtapa),
            AnamneseID: new FormControl(anamnese?.Etapa6?.AnamneseID),
            Dor: new FormControl({value: anamnese?.Etapa6?.Dor || 0, disabled: this.desativarCampos}),
            LocalDor: new FormControl({value: anamnese?.Etapa6?.LocalDor || '', disabled: this.desativarCampos}),
            Ansiedade: new FormControl({value: anamnese?.Etapa6?.Ansiedade || 0, disabled: this.desativarCampos}),
            Fadiga: new FormControl({value: anamnese?.Etapa6?.Fadiga || 0, disabled: this.desativarCampos}),
            Gratidao: new FormControl({value: anamnese?.Etapa6?.Gratidao || 0, disabled: this.desativarCampos}),
            Animo: new FormControl({value: anamnese?.Etapa6?.Animo || 0, disabled: this.desativarCampos}),
            Sono: new FormControl({value: anamnese?.Etapa6?.Sono || 0, disabled: this.desativarCampos}),
            Disposicao: new FormControl({value: anamnese?.Etapa6?.Disposicao || 0, disabled: this.desativarCampos}),
            Percepcao: new FormControl({value: anamnese?.Etapa6?.Percepcao || 0, disabled: this.desativarCampos}),
            CapacidadeFuncional: new FormControl({
                value: anamnese?.Etapa6?.CapacidadeFuncional || 0,
                disabled: this.desativarCampos
            }),
            Estresse: new FormControl({value: anamnese?.Etapa6?.Estresse || 0, disabled: this.desativarCampos}),
            Felicidade: new FormControl({value: anamnese?.Etapa6?.Felicidade || 0, disabled: this.desativarCampos}),
            DataInicio: new FormControl(new Date()),
            DataFim: new FormControl(null)
        })
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

    handleClickVoltarModal() {
        this.appService.closeModal()
        this.appService.openModal(ModalCheckBoxesComponent, {size: 'lg', backdrop: false})
    }


    handleClickProximoModal() {
        // caso os campos esteja desativados,
        // significa que é o professor acessando a tela
        // E ele só pode visualizar as informacoes
        // Logo, a gente nao atualiza a anamnese, apenas
        // Redireciona para a nova tela
        if (this.desativarCampos) {
            this.appService.closeModal()
            this.appService.openModal(ModalSaudEmocFuncComponent)
            return
        }


        const anamnese = this.anamneseService.getAnamnese()
        const classeContexto = this

        this.carregandoAtualizarAnamnese = true

        const novaAnamneseAtualizada = {
            ...anamnese, Etapa6: {
                ...anamnese?.Etapa6, ...this.formGroupEtapa6.getRawValue(), DataFim: new Date(),
            }
        }

        // primeiro, validamos a anamnese, para ver se os campos obrigatórios foram todos preenchidos
        // Caso estejam preenchidos, deixamos avançar
        this.anamneseService.validarAnamnese(novaAnamneseAtualizada).subscribe({
            next(response) {
                // verifico se há etapas inválidas (endpoint retorna 200 mesmo pra dados inválidos)
                // @ts-ignore
                if (typeof response?.etapaInvalida?.mensagem == 'string' && response?.etapaInvalida?.etapa === 6) {
                    // @ts-ignore
                    classeContexto.erroAvancarAnamnese = response?.etapaInvalida?.mensagem
                    classeContexto.carregandoAtualizarAnamnese = false
                    return
                }

                // atualiza a anamnese se tudo estiver ok
                classeContexto.anamneseService.putAnamnese(novaAnamneseAtualizada).subscribe({
                    next(response2) {

                        classeContexto.carregandoAtualizarAnamnese = false
                        classeContexto.anamneseService.setAnamnese(response2)
                        classeContexto.appService.closeModal()
                        classeContexto.appService.openModal(ModalSaudEmocFuncComponent)
                    }, error(response) {
                        classeContexto.carregandoAtualizarAnamnese = false
                        if (typeof response?.error?.Message == 'string') {
                            classeContexto.erroAvancarAnamnese = response?.error?.Message
                        } else {
                            classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao atualizar a anamnese"
                        }
                    }
                })

            }, error(erro) {
                classeContexto.carregandoAtualizarAnamnese = false
                if (typeof erro?.Message == 'string') {
                    classeContexto.erroAvancarAnamnese = erro?.Message
                } else {
                    classeContexto.erroAvancarAnamnese = "Ocorreu um erro ao validar as informações preenchidas"
                }
            }
        })

    }
}
