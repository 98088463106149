import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { AppService } from '@services/app.service';
import { CheckupClienteService } from '@services/dashboard/checkup-cliente/checkup-cliente.service';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-meus-clientes',
  templateUrl: './meus-clientes.component.html',
  styleUrls: ['./meus-clientes.component.scss']
})
export class MeusClientesComponent {
  public loadingListaClientes: boolean = false;
  public listaClientes: any[] = []


  

  constructor(private appService: AppService, private professorService: ProfessorService, private toastr: ToastrService,) {
  }


  ngOnInit(): void {
    const classeContexto = this
    this.loadingListaClientes = true
    this.professorService.getListaClientesProfessor().subscribe((res: any) => {
      this.listaClientes = res
      this.loadingListaClientes = false
    }
    ), (error) => {
      this.toastr.error('Erro ao carregar lista de clientes')
      this.loadingListaClientes = false
    }
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }


}
