<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Questionário Farmacêutico Preliminar
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">4/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa4">
                <div class="col-md-12 row">
                    <div class="col-6 mt-1">
                        <div [ngClass]="{'input-error': campoinvalido === 'AlergiaMedicamentos'}">

                        <div>
                            <span class="form-label-texts"> Alergia a medicamentos: </span>
                        </div>

                        <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                            <input class="form-input-checkbox" type="radio" formControlName="AlergiaMedicamentos"
                                   [checked]="formGroupEtapa4.get('AlergiaMedicamentos').value === opcao.value"
                                   [value]="opcao.value">

                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                    </div>
                    </div>

                    <div class="col-6 mt-1">
                        <div *ngIf="formGroupEtapa4.get('AlergiaMedicamentos').value === true">
                            <span class="form-label-texts"> Se você marcou "Sim", informe qual medicamento:
                            </span>
                        </div>
                        <div class="mt-2" *ngIf="formGroupEtapa4.get('AlergiaMedicamentos').value === true">
                            <input formControlName="MedicamentosAlergenicos" name="MedicamentosAlergenicos"
                                   id="MedicamentosAlergenicos" type="input" class="form-control form-input-box"/>
                        </div>
                    </div>
                    <div class="col-6 mt-1">
                        <div [ngClass]="{'input-error': campoinvalido === 'AlergiaOutros'}">
                        <div>
                            <span class="form-label-texts"> Alergias a outros produtos: </span>
                        </div>


                        <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                            <input class="form-input-checkbox" type="radio" formControlName="AlergiaOutros"
                                   [checked]="formGroupEtapa4.get('AlergiaOutros').value === opcao.value"
                                   [value]="opcao.value">

                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                    </div>
                    </div>
                    <div class="col-6 mt-1">
                        <div *ngIf="formGroupEtapa4.get('AlergiaOutros').value === true">
                            <span class="form-label-texts"> Se você marcou "Sim", informe qual produto:
                            </span>
                        </div>
                        <div class="mt-2" *ngIf="formGroupEtapa4.get('AlergiaOutros').value === true">
                            <input formControlName="Produtos" name="Produtos" id="Produtos" type="input"
                                   class="form-control form-input-box"/>
                        </div>
                    </div>

                    <div class="col-12">
                        <hr/>
                    </div>

                    <div class="col-12">
                        <span class="form-label-texts"> Utiliza algum medicamento? Em caso positivo, informe qual(is) medicamento(s), finalidade, dose, frequência e horário: </span>
                        <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao2">
                            <input class="form-input-checkbox" type="radio" formControlName="MedicamentosEmUsoSIM" [checked]="formGroupEtapa4.get('MedicamentosEmUsoSIM').value === opcao.value" [value]="opcao.value">
                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                        
                    </div>
                    
                    <div *ngIf="formGroupEtapa4.get('MedicamentosEmUsoSIM').value === true" class="row mt-2">
                        <div class="col-12 col-md-4 col-sm-6">
                          <span class="form-label-texts">Produto</span>
                          <ng-select [items]="opcoesMedicamentos" bindLabel="Produto" bindValue="ID" class="form-input-box-select"
                                     *ngIf="!loadingBuscaMed" formControlName="MedicamentoId">
                          </ng-select>
                          <div *ngIf="loadingBuscaMed">
                            <div class="spinner-border text-primary" role="status" style="width: 1rem; height: 1rem;">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                        </div>
                        <div class="col-12 col-md-3 col-sm-6">
                          <span class="form-label-texts">Nome Comercial</span>
                          <input formControlName="Nome" type="text"
                          [disabled]="formGroupEtapa4.get('MedicamentoId').value != 22962" 
                            
                          class="form-control form-input-box" />
                        </div>
                        <div class="col-12 col-md-2 col-sm-6">
                          <span class="form-label-texts">Dose</span>
                          <input formControlName="Dose" type="text" class="form-control form-input-box" />
                        </div>
                        <div class="col-12 col-md-3 col-sm-6">
                          <span class="form-label-texts">Periodicidade</span>
                          <select class="form-control form-input-box" formControlName="Periodicidade">
                            <option value="1">1 vez por dia</option>
                            <option value="2">2 vezes por dia</option>
                            <option value="3">3 vezes por dia</option>
                            <option value="4">4 vezes por dia</option>
                            <option value="5">5 vezes por dia</option>
                            <option value="6">6 vezes por dia</option>
                            <option value="7">7 vezes por dia</option>
                            <option value="15">Quinzenal</option>
                            <option value="30">Mensal</option>
                            <option value="99">Outras</option>
                          </select>
                        </div>
                        <button class="btn btn-sm mx-1 btn-amarelo-plataforma-safe"
                         (click)="handleAdicionarMedicamento()"
                         style="
                         height: 40px;
                         margin-top: 10px;
                         "
                         >Adicionar</button>
                      </div>
                      <div formArrayName="listaMedicamentos">

                          <div 
                          *ngFor="let medicamento of listaMedicamentos.controls; let i = index" [formGroupName]="i" class=" row mt-2">
                              <div class="col-12 col-md-12 col-sm-12">
                                  <span class="form-label-texts">Medicamentos cadastrados</span>
                             </div>
                            <div class="col-12 col-md-3 col-sm-6">
                                <span class="form-label-texts">Produto</span>
                            <input type="text" class="form-control form-input-box" formControlName="Produto"
                            disabled />
                        </div>
                              <div class="col-12 col-md-3 col-sm-6">
                                <span class="form-label-texts">Nome Comercial</span>
                            <input type="text" class="form-control form-input-box" 
                            formControlName="Nome" 
                            disabled />
                              </div>
                              <div class="col-12 col-md-2 col-sm-6" >
                                <span class="form-label-texts">Dose</span>
                            <input type="text" class="form-control form-input-box" formControlName="Dose" disabled />
                        </div>
                        <div class="col-12 col-md-2 col-sm-6" >
                            <span class="form-label-texts">Periodicidade</span>
                        <input type="text" class="form-control form-input-box" formControlName="Periodicidade" disabled />
                    </div>
            <button class="btn btn-sm btn-danger mx-1" 
            style="
            height: 40px;
            margin-top: 10px;
            "
            (click)="handleRemoverMedicamento(i)">Remover</button>
        </div>
                        </div>
                           
                       
                      
                      
                      
                    
                    

                    <div class="col-12">
                        <hr/>
                    </div>
                    <div class="col-12 mt-2">
                        <div [ngClass]="{'input-error': campoinvalido === 'ExercicioRegular'}">

                        <span class="form-label-texts"> Faz exercício regularmente há mais de 3 meses:
                        </span>

                        <br>

                        <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                            <input class="form-input-checkbox" type="radio" formControlName="ExercicioRegular"
                                   [checked]="formGroupEtapa4.get('ExercicioRegular').value === opcao.value"
                                   [value]="opcao.value">

                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                    </div>
                    </div>
                    <div class="col-12 mt-2" *ngIf="formGroupEtapa4.get('ExercicioRegular').value === true">
                        <span class="form-label-texts"> Em caso positivo. Qual exercício?
                        </span>
                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="Caminhada" name="Caminhada" id="Caminhada" type="checkbox"
                                           class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check" for="Caminhada">Caminhada</label>
                                </div>
                            </div>

                            <div *ngIf="formGroupEtapa4.get('Caminhada').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio"
                                           formControlName="CaminhadaFrequencia" [value]="value"
                                           [checked]="formGroupEtapa4.get('CaminhadaFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="CaminhadaIntensidade" type="radio"
                                           class="form-input-checkbox" [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('CaminhadaIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.value}}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="CaminhadaDuracao" name="CaminhadaDuracao"
                                           id="CaminhadaDuracao" type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- caminhada -->

                        <!-- corrida -->
                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="Corrida" name="Corrida" id="Corrida" type="checkbox"
                                           class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check" for="Corrida">Corrida</label>
                                </div>
                            </div>


                            <div *ngIf="formGroupEtapa4.get('Corrida').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio" formControlName="CorridaFrequencia"
                                           [value]="value"
                                           [checked]="formGroupEtapa4.get('CorridaFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="CorridaIntensidade" type="radio" class="form-input-checkbox"
                                           [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('CorridaIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.label }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="CorridaDuracao" name="CorridaDuracao" id="CorridaDuracao"
                                           type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- corrida -->


                        <!-- musculação -->
                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="Musculacao" name="Musculacao" id="Musculacao"
                                           type="checkbox" class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check" for="Musculacao">Musculação</label>
                                </div>
                            </div>


                            <div *ngIf="formGroupEtapa4.get('Musculacao').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio"
                                           formControlName="MusculacaoFrequencia" [value]="value"
                                           [checked]="formGroupEtapa4.get('MusculacaoFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="MusculacaoIntensidade" type="radio"
                                           class="form-input-checkbox" [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('MusculacaoIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.label }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="MusculacaoDuracao" name="MusculacaoDuracao"
                                           id="MusculacaoDuracao" type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- musculação -->

                        <!-- Dança -->
                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="Danca" name="Danca" id="Danca" type="checkbox"
                                           class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check" for="Danca">Dança</label>
                                </div>
                            </div>


                            <div *ngIf="formGroupEtapa4.get('Danca').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio" formControlName="DancaFrequencia"
                                           [value]="value"
                                           [checked]="formGroupEtapa4.get('DancaFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="DancaIntensidade" type="radio" class="form-input-checkbox"
                                           [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('DancaIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.label }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="DancaDuracao" name="DancaDuracao" id="DancaDuracao"
                                           type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- Dança -->

                        <!-- Hidroginastica -->

                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="Hidroginastica" name="Hidroginastica" id="Hidroginastica"
                                           type="checkbox" class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check"
                                           for="Hidroginastica">Hidroginástica</label>
                                </div>
                            </div>


                            <div *ngIf="formGroupEtapa4.get('Hidroginastica').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio"
                                           formControlName="HidroginasticaFrequencia" [value]="value"
                                           [checked]="formGroupEtapa4.get('HidroginasticaFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="HidroginasticaIntensidade" type="radio"
                                           class="form-input-checkbox" [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('HidroginasticaIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.label }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="HidroginasticaDuracao" name="HidroginasticaDuracao"
                                           id="HidroginasticaDuracao" type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- Hidroginastica -->

                        <!-- Outro -->
                        <div>
                            <div class="mt-2">
                                <div class="d-flex align-items-center">
                                    <input formControlName="OutroExercicio" name="OutroExercicio" id="OutroExercicio"
                                           type="checkbox" class="form-input-checkbox"/>
                                    <label class="form-label-texts-helper-check" for="OutroExercicio">Outro</label>
                                </div>
                            </div>


                            <div *ngIf="formGroupEtapa4.get('OutroExercicio').value === true">
                                <span class="form-label-texts"> Frequencia semanal</span>

                                <br>
                                <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                    <input class="form-input-checkbox" type="radio"
                                           formControlName="OutroExercicioFrequencia" [value]="value"
                                           [checked]="formGroupEtapa4.get('OutroExercicioFrequencia').value === value">

                                    <label class="form-label-texts-helper-check">
                                        {{ value }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Intensidade</span>
                                <br>

                                <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                    <input formControlName="OutroExercicioIntensidade" type="radio"
                                           class="form-input-checkbox" [value]="opcao.value"
                                           [checked]="formGroupEtapa4.get('OutroExercicioIntensidade').value === opcao.value">
                                    <label class="form-label-texts-helper-check">
                                        {{ opcao.label }}
                                    </label>
                                </div>

                                <br>
                                <span class="form-label-texts">Duração (meses)</span>
                                <br>

                                <div class="form-check form-check-inline">
                                    <input formControlName="OutroExercicioDuracao" name="OutroExercicioDuracao"
                                           id="OutroExercicioDuracao" type="number" class="form-control form-input-box">
                                </div>
                                <br>
                                <br>
                            </div>
                        </div>
                        <!-- Outro -->
                    </div>
                </div>

                <div class="col-12 row" *ngIf="formGroupEtapa4.get('ExercicioRegular').value === true">
                    <div class="col-12 mt-2">
                        <span class="form-label-texts"> Informar se pratica "outro" tipo de exercício:
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="DescOutroExercicio" name="DescOutroExercicio"
                                      id="DescOutroExercicio" type="input" class="form-control form-input-box w-100">
                            </textarea>
                        </div>
                    </div>
                    <div class="col-12">
                        <hr/>
                    </div>
                    <div class="col-12 mt-2">
                        <span class="form-label-texts"> Quantas vezes por semana?
                        </span>
                        <div class="mt-2">
                            <div class="form-check form-check-inline" *ngFor="let opcao of [
                                { label: '2 vezes', value: 0 },
                                { label: '3 vezes', value: 1 },
                                { label: '4 vezes', value: 2 },
                                { label: '5 vezes ou mais', value: 3 },
                            ]">
                                <input class="form-input-checkbox" type="radio" formControlName="QtdExercicioSemana"
                                       [checked]="formGroupEtapa4.get('QtdExercicioSemana').value === opcao.value"
                                       [value]="opcao.value">

                                <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-2">
                        <span class="form-label-texts"> Qual a intensidade?
                        </span>
                        <div class="mt-2">
                            <div class="form-check form-check-inline" *ngFor="let opcao of [
                            { label: 'Leve (2,5 mets)', value: 0 },
                            { label: 'Moderada (4,0 mets)', value: 1 },
                            { label: 'Intensa (6,0 mets)', value: 2 },
                            { label: 'Muito intenso (8,5 mets)', value: 3 },
                        ]">
                                <input class="form-input-checkbox" type="radio" formControlName="Intensidade"
                                       [checked]="formGroupEtapa4.get('Intensidade').value === opcao.value"
                                       [value]="opcao.value">

                                <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <span class="form-label-texts"> A duração de cada sessão (tempo)?
                        </span>
                        <span class="form-label-texts-helper"> Informe os minutos </span>
                        <input formControlName="DuracaoSessao" type="number" name="DuracaoSessao" id="DuracaoSessao"
                               class="form-control form-input-box">
                    </div>
                    <div class="col-12">
                        <span class="form-label-texts"> Somatório semanal em minutos:
                        </span>
                        <span class="form-label-texts-helper"> Informe os minutos </span>
                        <input formControlName="SomaSemanal" type="number" name="SomaSemanal" id="SomaSemanal"
                               class="form-control form-input-box">
                    </div>
                    <div class="col-12">
                        <hr/>
                    </div>
                </div>


                <div class="center col-12 mb-5 mt-4">
                    <h4>Suplementação Alimentar</h4>
                </div>
                <div class="col-12">
                    <span class="form-label-texts"> Faz uso de suplemento alimentar?
                    </span>
                    <div class="mt-2">
                        <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                            <input
                                class="form-input-checkbox"
                                type="radio"
                                formControlName="SuplementoAlimentar"
                                [checked]="formGroupEtapa4.get('SuplementoAlimentar').value === opcao.value"
                                [value]="opcao.value"
                            >

                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                        </div>
                    </div>
                </div>

                <div class="col-12" *ngIf="formGroupEtapa4.get('SuplementoAlimentar').value === true">
                    <span class="form-label-texts"> Em caso positivo. Favor especificar qual suplemento alimentar,
                        dose e frequëncia.
                    </span>
                    <div class="mt-2">
                        <input
                            formControlName="DescSuplemento"
                            name="DescSuplemento"
                            id="DescSuplemento"
                            type="input"
                            class="form-control form-input-box"
                        >
                    </div>
                </div>

                <div class="col-12">
                    <hr/>
                </div>

                <div class="center col-12 mb-5">
                    <h4>Alimentação Diária</h4>
                </div>
                <div class="row">
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'CafeDaManha'}">
                        <span class="form-label-texts"> Café da manhã
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="CafeDaManha" name="CafeDaManha" id="CafeDaManha" type="input"
                                      class="form-control form-input-box">
                            </textarea>
                        </div>
                    </div>
                    </div>
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'Lanche1'}">
                        <span class="form-label-texts"> Lanche
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="Lanche1" name="Lanche1" id="Lanche1" type="input"
                                      class="form-control form-input-box">
                            </textarea>
                        </div>
                    </div>
                    </div>
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'Almoco'}">
                        <span class="form-label-texts"> Almoço
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="Almoco" name="Almoco" id="Almoco" type="input"
                                      class="form-control form-input-box">
                        </textarea>
                        </div>
                    </div>
                    </div>
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'Lanche2'}">
                        <span class="form-label-texts"> Lanche
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="Lanche2" name="Lanche2" id="Lanche2" type="input"
                                      class="form-control form-input-box">
                        </textarea>
                        </div>
                    </div>
                    </div>
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'Jantar'}">
                        <span class="form-label-texts"> Jantar
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="Jantar" name="Jantar" id="Jantar" type="input"
                                      class="form-control form-input-box">
                        </textarea>
                        </div>
                    </div>
                    </div>
                    
                    <div class="col-6">
                        <div [ngClass]="{'input-error': campoinvalido === 'Ceia'}">
                        <span class="form-label-texts">Ceia
                        </span>
                        <div class="mt-2">
                            <textarea formControlName="Ceia" name="Ceia" id="Ceia" type="input"
                                      class="form-control form-input-box">
                            </textarea>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="col-12">
                    <hr/>
                </div>

                <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                    <div class="alert alert-danger" role="alert">
                        {{ erroAvancarAnamnese }}
                    </div>
                </div>
                
                <div class="col-12 form-group-buttons row" style="margin-left: 20px;">
                    <div class="col-md-3 mb-3 ">
                        <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickFecharModal()">Fechar
                        </button>
                    </div>
                    <div class="col-md-3 mb-3 ">
                        <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickVoltarModal()">Voltar
                        </button>
                    </div>

                    <div class="col-md-6 mb-3" *ngIf="!carregandoAtualizarAnamnese">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()">Próximo
                        </button>
                    </div>

                    <div class="col-md-6 mb-3" *ngIf="carregandoAtualizarAnamnese">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()" disabled>Carregando
                        </button>
                    </div>
                </div>
                 
            </form>
        </div>
    </div>
</div>
