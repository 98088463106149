import { LoginModel } from '@/models/login.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-recuperar-senha-page',
  templateUrl: './recuperar-senha-page.component.html',
  styleUrls: ['./recuperar-senha-page.component.scss']
})
export class RecuperarSenhaPageComponent implements OnInit {
  public formRecover: FormGroup;
  public carregandoEnviar: boolean = false
  public submitRecover: boolean = false
  public router = window

  ngOnInit() {
     this.formRecover = new FormGroup({
      email: new FormControl('', [Validators.required]),
     });
     this.submitRecover = false
  }

  constructor(
    private toastr: ToastrService,
    private appService: AppService
  ) { }


  async onClickRecover() {
    try {
      this.carregandoEnviar = true;
      const email = this.formRecover.get('email').value;
      if (email?.length < 6) {
        this.toastr.error('E-mail é obrigatório.');
        this.carregandoEnviar = false;
        return;
      }
      await this.appService.recuperarSenha(email);
      this.toastr.success('E-mail enviado com sucesso, verifique sua caixa de entrada e faça o Login.');
      this.submitRecover = true;
    } catch (error) {
      this.toastr.error(error?.error?.Message || 'Erro ao enviar e-mail.');
    }
    this.carregandoEnviar = false;
  }



  async handleVoltarClick() {
    this.router.location.href = '/login'
    }
}
