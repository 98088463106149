import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {
    RelatorioTabelaGorduraService
} from "@services/relatorios/relatorio-tabela-gordura/relatorio-tabela-gordura.service";
import {ProfessorService} from "@services/dashboard/professor/professor.service";
import {
    RelatorioAutoConscienciaService
} from "@services/relatorios/relatorio-auto-consciencia/relatorio-auto-consciencia.service";

@Component({
    selector: 'app-relatorio-auto-consciencia',
    templateUrl: './relatorio-auto-consciencia.component.html',
    styleUrls: ['./relatorio-auto-consciencia.component.scss']
})
export class RelatorioAutoConscienciaComponent implements OnInit {
    public formAutoConsciencia: FormGroup;
    public listaAvaliadores: Array<any> = [];
    public relatorioAutoConscienciaList: Array<any> = []
    public nenhumRegistro = false
    public loading = false
    constructor(private relatorioAutoConscienciaService: RelatorioAutoConscienciaService, private professorService: ProfessorService) {

    }


    ngOnInit() {
        this.formAutoConsciencia = new FormGroup({
            AvaliadorID: new FormControl(0, []),
            PeriodoDe: new FormControl('', []),
            PeriodoAte: new FormControl('', []),
        });

        this.atualizarListagemRelatorio()
        this.atualizarListaAvaliadores()
    }

    atualizarListagemRelatorio() {
        const classeContexto = this
        this.loading = true
        this.relatorioAutoConscienciaService.getRelatoriosAutoConsciencia(this.formAutoConsciencia.value).subscribe({
            next(response) {
                classeContexto.relatorioAutoConscienciaList = response as Array<any>
                classeContexto.nenhumRegistro = classeContexto.relatorioAutoConscienciaList.length == 0
                classeContexto.loading = false
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }
    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    retornarRelatoriosDoUsuario(usuarioID) {
        return this.relatorioAutoConscienciaList.filter(r => r?.Usuario?.ID == usuarioID)
    }
}
