import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AppService} from "@services/app.service";

@Injectable({
  providedIn: 'root'
})
export class AcessoAvaliadorGuard implements CanActivate, CanActivateChild {

  /**
   *
   * @private
   *
   * Se o perfil do avaliador (appService.user.Perfil.ID) tiver algum desses IDS, ele tem total acesso as features de avaliador
   *
   */
  private avaliadorPerfisIDS = [4, 3, 1]

  constructor(private appService: AppService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.avaliadorPerfisIDS.includes(this.appService.user?.Perfil?.ID)
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this.canActivate(next, state);
  }

}
