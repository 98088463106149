<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>
    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Reenviar Senha Cliente
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
             </div>
        </div>

        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
         <form class="form-form p-3" [formGroup]="dataForm">
            <div class="row vertical-content">
                <div class="col-md-12 row mb-3">
                    <div class="col-md-8">
                        <label class="form-label-texts">Selecione o cliente</label>
                        <ng-select 
                          [items]="listaClientes" 
                          bindLabel="Nome" 
                          bindValue="ID" 
                          class="form-input-box-select"
                          formControlName="ID"
                          [virtualScroll]="true"
                          [searchable]="true"
                          *ngIf="!loadingListaClientes"
                           >
                        </ng-select>
                         
                        <div *ngIf="loadingListaClientes">
                          <div class="spinner-border text-primary" role="status" style="width: 1rem; height: 1rem;">
                            <span class="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                </div>
              

                <div class="col-12 form-group-buttons" style="margin-top: 20px;">
                    <div class="col-6 ">
                        <button class="btn btn-secondary btn-block btn-sm form-bnt-voltar"
                                (click)="handleClickFecharModal()">Fechar
                        </button>
                    </div>

                    <div class="col-6" *ngIf="!carregandoEnviarSenha">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickEnviar()">Enviar
                        </button>
                    </div>

                    <div class="col-6" *ngIf="carregandoEnviarSenha">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                disabled>Enviando...
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
