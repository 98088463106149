

<section class="screen-half" id="home">
    <!-- <div class="bg-overlay"></div> -->
    <div class="screen-center">
        <div class="screen-desc-center">
            <div class="container">
                <div class="row vertical-content">
                    <div class="col-lg-6 col-md-12 col-sm-12 registration-logo-img">
                            <img src="../../../assets/img/logoSafeTextoBranco.png" alt=""  height="200" class="registration-logo-img">
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-12 ml-auto ">
                        <div class="screen-registration-form bg-white p-5">
                            <h4 class="form-title">LOGIN</h4>
                            <p class="form-subtitle">ENTRE COM OS DADOS FORNECIDOS PELO SEU AVALIADOR</p>
                            <form class="registration-form p-3"  (ngSubmit)="onClickLogin()" >

                                <label class="form-texts">Token</label>
                                <input
                                    formControlName="token"
                                    name="token"
                                    id="token"
                                    type="token"
                                    class="form-control registration-input-box"
                                />

                                <label class="form-texts mt-2">Senha Temporária</label>
                                <input
                                    formControlName="senha"
                                    type="password"
                                    id="senha"
                                    name="senha"
                                    class="form-control registration-input-box"
                                >

                                <div class="form-check mt-2">
                                    <input class="form-check-input" type="checkbox" value="" id="form1Example3" checked />
                                    <label class="form-check-label" for="form1Example3"> Lembrar de mim </label>
                                    <a routerLink="/recuperar-senha" routerLinkActive="router-link-active"  class="ml-3">Esqueci minha senha?</a>
                                </div>

                                <button class="btn btn-azul-entrar w-100 text-uppercase mt-4" type="submit" [class.spinner]="carregandoLogin" [disabled]="carregandoLogin" >
                                     Entrar
                                </button>
                                <button class="btn btn-cinza-cadastrar w-100 text-uppercase mt-4" type="button"  (click)="handleVoltarClick()">Voltar</button>


                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
