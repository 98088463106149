<div class="screen-container" style="overflow-y: scroll; max-height: 100vh">
    <div class="container-fluid bg-transparent">
        <div>
            <h5 class="titulo-dashboard">
                AVALIADOR: {{ appService.user?.Nome }}
            </h5>
        </div>

        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5">Seja Bem Vindo!</h5>
                <img
                    src="assets/img/logo-plat_branco(1).png"
                    alt="plat-safe-logo"
                    height="138px"
                    width="170px"
                />
            </div>
            <div class="divider-dash"></div>
        </div>

        <!-- Professores -->
        <div class="mt-3">
            <div class="d-flex align-items-center position-relative mb-3">
                <!-- Botão Tutorial -->
                <button
                    class="btn btn-primary"
                    style="
                        background-color: #ffd500;
                        border-color: #ffd500;
                        color: #0f65af;
                        z-index: 1;
                    "
                    *ngIf="!isMobile"
                    (click)="startTour()"
                >
                    Tutorial
                </button>

                <!-- Card Black Friday -->
                <div
                    class="black-friday-card position-absolute"
                    style="
                        background-color: #ffd500;
                        color: #000;
                        font-weight: bold;
                        padding: 10px 20px;
                        text-align: center;
                        border-radius: 10px;
                        left: 50%;
                        transform: translateX(-50%);
                    "
                >
                    <a
                        href="https://vendas.metodosafe.com.br/black-friday-safeano/"
                        target="_blank"
                        style="
                            color: #000;
                            font-weight: bold !important;
                            text-align: center;
                        "
                    >
                        BLACK FRIDAY <br />
                        Aproveite Formação SAFE®️ vitalícia
                    </a>
                </div>
            </div>

            <h6 class="titulo-dash-biomarcadores m-3" *ngIf="fezCheckup">
                Link para cadastrar novos clientes:
            </h6>

            <div
                class="d-flex mb-4"
                style="gap: 10px; width: 100%; max-width: 800px"
                *ngIf="fezCheckup"
            >
                <input
                    type="text"
                    class="meu-link-input"
                    [value]="meuLink"
                    style="flex: 1"
                    id="meu-link-avaliador-input"
                />
                <button
                    class="btn btn-secondary btn-sm"
                    style="
                        background-color: #ffd500;
                        border-color: #ffd500;
                        color: #0f65af;
                    "
                    (click)="copiarMeuLink()"
                >
                    COPIAR
                </button>
            </div>
            <div
                class="d-flex mb-4"
                style="gap: 10px; width: 100%; max-width: 800px"
            >
                <button
                    class="btn form-bnt-avaliador btn-sm"
                    (click)="handleClickAbrirModalReenviarSenha()"
                >
                    Reenviar Senha Cliente
                </button>
                <button
                    class="btn form-bnt-avaliador btn-sm"
                    (click)="handleClickAbrirModalSoliciarCheckup()"
                >
                    Novo CHECK-UP p/ cliente
                </button>
            </div>

            <!-- <app-consultar-biomarcadores></app-consultar-biomarcadores>    -->
            <app-consultar-contadores></app-consultar-contadores>
        </div>

        <div class="graficos-prof row mt-3">
            <div class="graficoProf area-1">
                <h6 class="lista-alunos-title text-center mt-3 mb-3">
                    Classificação SAFE® Do Estilo De Vida Avaliados
                </h6>
                <app-consultar-niveis-pacientes-por-genero></app-consultar-niveis-pacientes-por-genero>
            </div>

            <div class="lista-Prof area-2">
                <h6 class="lista-alunos-title text-center mt-3">
                    Lista de Avaliados com CHECK-UP SAFE®
                </h6>

                <div class="lista-alunos-content">
                    <!-- Checkbox -->
                    <div
                        class="d-flex justify-content-between mb-3"
                        *ngIf="!loadingListaPacientes"
                    >
                        <div class="form-check form-check-inline">
                            <input
                                class="form-check-input"
                                type="checkbox"
                                (click)="toggleCheckboxBaixarLaudo()"
                                [checked]="laudoSemDados"
                            />
                            <label
                                class="form-check-label"
                                for="inlineCheckbox1"
                                >Laudo Sem dados pessoais</label
                            >
                        </div>
                    </div>

                    <div
                        class="loading-container"
                        *ngIf="loadingListaPacientes"
                        style="
                            justify-content: center;
                            align-items: center;
                            display: flex;
                            height: 100px;
                            width: 100%;
                            margin-top: 20px;
                            margin-bottom: 20px;
                        "
                    >
                        <div
                            class="spinner-border text-azul-plataforma-safe"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div *ngFor="let item of buscaFiltrada" class="aluno-item">
                        <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <img
                                src="https://cdn-icons-png.flaticon.com/512/147/147144.png"
                                alt="Avatar do paciente"
                            />
                            <a
                                [routerLink]="[
                                    '/dashboard/acesso-avaliador/anamneses/visualizar',
                                    item?.UltimaAnamneseHash
                                ]"
                            >
                                <p
                                    *ngIf="item.ClassificaoZ > 0"
                                    class="m-0 mb-0"
                                >
                                    Z{{ item.ClassificaoZ }} -
                                    {{ item.Cliente }}
                                </p>
                                <p
                                    *ngIf="item.ClassificaoZ == 0"
                                    class="m-0 mb-0"
                                >
                                    {{ item.Cliente }}
                                </p>
                            </a>
                        </div>
                        <div class="d-flex justify-content-between">
                            <button
                                class="btn btn-primary btn-sm mr-3"
                                (click)="baixarLaudo(item?.UltimaAnamneseHash)"
                                [disabled]="item.ClassificaoZ === 0"
                            >
                                Laudo
                            </button>
                        </div>
                    </div>
                    <div class="divider-aluno"></div>

                    <div class="pesquisa-alunos" *ngIf="!loadingListaPacientes">
                        <input
                            type="text"
                            placeholder="Digite o nome do paciente"
                            [(ngModel)]="filtroBuscaInput"
                        />
                        <button
                            type="button"
                            class="btn btn-primary"
                            (click)="buscarPacientesPorFiltro()"
                        >
                            Buscar
                        </button>
                    </div>
                </div>
            </div>

            <div class="graficoProf">
                <h6 class="lista-alunos-title text-center mt-3">
                    Idade dos Avaliados
                </h6>
                <app-consultar-por-idade></app-consultar-por-idade>
            </div>
            <div class="graficoProf" *ngIf="!loadingCupom && cupom.length > 0">
                <h6 class="lista-alunos-title text-center mt-3">Meus cupons</h6>
                <div class="divider-aluno"></div>
                <div class="lista-alunos-content">
                    <div *ngFor="let item of cupom" class="aluno-item">
                        <div
                            class="d-flex align-items-center justify-content-center"
                        >
                            <p class="m-0 mb-0">
                                {{ item.Codigo }}
                                <br />
                                <span
                                    class="text-muted"
                                    style="font-size: smaller"
                                    >Válido até:
                                    {{
                                        item.Validade | date: 'dd/MM/yyyy'
                                    }}</span
                                >
                                <br />
                                <span
                                    class="text-muted"
                                    style="font-size: smaller"
                                    >Uso: {{ item.Quantidade }}/{{
                                        item.Total
                                    }}</span
                                >
                            </p>
                        </div>
                        <div
                            class="d-flex flex-column justify-content-between ml-3"
                        >
                            <button
                                class="btn btn-primary btn-sm mr-3 custom-btn"
                                (click)="copiarLink(item.Codigo)"
                                style="width: 100px"
                            >
                                Copiar Link
                            </button>
                            <button
                                class="btn btn-primary btn-sm mr-3 custom-btn"
                                (click)="copiarCupom(item.Codigo)"
                                style="width: 110px; margin-top: 10px"
                            >
                                Copiar Cupom
                            </button>
                        </div>
                    </div>
                    <div class="divider-aluno"></div>
                </div>
            </div>
        </div>

        <!-- FIM Professores -->
    </div>
</div>
