<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div
            class="d-flex justify-content-center align-items-center"
            style="flex: 1"
        >
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe"
                        >Biomarcadores SAFE®</span
                    >
                </div>
                <div>
                    <span
                        class="text-yellow modal-biomarcador-header-tipo-biomarcador"
                    >
                        Dados Pessoais
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px">
                <img alt="" src="assets/svg/biomarcador-1.svg" />
            </div>
        </div>

        <div
            class="d-flex justify-content-end align-items-center"
            style="flex: 1"
        >
            <div class="identificador-pagina">
                <span class="text-yellow">2/</span>
                <span class="text-light">10</span>
            </div>
        </div>

        <div (click)="handleClickFecharModal()" class="fechar-modal">
            <img alt="" src="assets/svg/close-icon.svg" />
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form [formGroup]="formGroupEtapa2" class="form-form p-3">
                <div class="col-md-12 row">
                    <div class="col-md-4">
                        <span
                            ><span class="form-label-texts">Peso:</span
                            ><span class="form-label-texts-helper"
                                ><br />Informe seu peso atual em KG</span
                            ></span
                        >
                        <input
                            class="form-control form-input-box"
                            formControlName="Peso"
                            id="Peso"
                            #Peso
                            name="Peso"
                            mask="00.00 || 000.00"
                            [dropSpecialCharacters]="false"
                            type="text"
                            [ngClass]="{
                                'input-error': campoinvalido === 'Peso'
                            }"
                        />
                    </div>
                    <div class="col-md-4">
                        <span
                            ><span class="form-label-texts">Altura:</span
                            ><br /><span class="form-label-texts-helper"
                                >Informe sua altura em CM</span
                            ></span
                        >

                        <input
                            class="form-control form-input-box"
                            formControlName="Altura"
                            id="Altura"
                            #Altura
                            name="Altura"
                            mask="000.00"
                            [dropSpecialCharacters]="false"
                            type="text"
                            [ngClass]="{
                                'input-error': campoinvalido === 'Altura'
                            }"
                        />
                    </div>
                    <div class="col-md-4">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'PressaoPart1'
                            }"
                        >
                            <span
                                ><span class="form-label-texts"
                                    >Pressão arterial:</span
                                ><br /><span class="form-label-texts-helper"
                                    >Informe sua pressão em mmHg</span
                                ></span
                            >

                            <div class="d-flex align-items-center">
                                <input
                                    class="form-control form-input-box mb-0 pb-0"
                                    formControlName="PressaoPart1"
                                    type="number"
                                />
                                <div
                                    class="d-flex justify-content-center align-items-center"
                                >
                                    <span>por</span>
                                </div>
                                <input
                                    class="form-control form-input-box mb-0 pb-0"
                                    formControlName="PressaoPart2"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-3 mt-4">
                        <div>
                            <span class="form-label-texts">Tem Cônjuge:</span>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('TemConjuge', true)"
                                [checked]="
                                    formGroupEtapa2.get('TemConjuge').value ===
                                    true
                                "
                                class="form-input-checkbox"
                                id="conjugueSim"
                                #conjugueSim
                                name="conjugueSim"
                                type="checkbox"
                                [disabled]="desativarCampos"
                            />

                            <label
                                class="form-label-texts-helper-check"
                                for="conjugueSim"
                                >Sim</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('TemConjuge', false)"
                                [checked]="
                                    formGroupEtapa2.get('TemConjuge').value ===
                                    false
                                "
                                class="form-input-checkbox"
                                id="conjugueNao"
                                #conjugueNao
                                name="conjugueNao"
                                type="checkbox"
                                [disabled]="desativarCampos"
                            />
                            <label
                                class="form-label-texts-helper-check"
                                for="conjugueNao"
                                >Não</label
                            >
                        </div>
                    </div>
                    <div
                        *ngIf="formGroupEtapa2.get('TemConjuge').value === true"
                        class="col-6 mt-4"
                    >
                        <span class="form-label-texts">Nascimento Cônjuge</span>
                        <div>
                            <input
                                type="date"
                                class="form-control form-input-box"
                                style="width: 200px"
                                formControlName="DataNascConjuge"
                            />
                        </div>
                    </div>

                    <div class="col-12 mt-4">
                        <div>
                            <span class="form-label-texts">Tem Filhos:</span>
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('temFilhos', true)"
                                [checked]="
                                    formGroupEtapa2.get('temFilhos').value ===
                                    true
                                "
                                class="form-input-checkbox"
                                id="temFilhos"
                                #temFilhos
                                type="checkbox"
                                [disabled]="desativarCampos"
                            />

                            <label
                                class="form-label-texts-helper-check"
                                for="temFilhos"
                                >Sim</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('temFilhos', false)"
                                [checked]="
                                    formGroupEtapa2.get('temFilhos').value ===
                                    false
                                "
                                class="form-input-checkbox"
                                id="temFilhosNao"
                                #temFilhosNao
                                [disabled]="desativarCampos"
                                type="checkbox"
                            />
                            <label
                                class="form-label-texts-helper-check"
                                for="temFilhosNao"
                                >Não</label
                            >
                        </div>
                    </div>

                    <div
                        *ngIf="formGroupEtapa2.get('temFilhos').value === true"
                        class="col-12 mt-2"
                    >
                        <span class="form-label-texts"
                            >Digite a data de nascimento do filho</span
                        >
                        <div>
                            <input
                                type="date"
                                class="form-control form-input-box"
                                style="width: 200px"
                                formControlName="dataNascimentoFilho"
                            />

                            <button
                                (click)="adicionarFilho()"
                                class="btn btn-sm mx-1 btn-amarelo-plataforma-safe"
                            >
                                Adicionar
                            </button>
                        </div>

                        <div *ngIf="listaFilhos.length > 0" class="mt-2">
                            <span class="form-label-texts"
                                >Filhos cadastrados</span
                            >

                            <div class="row" style="gap: 10px">
                                <div
                                    *ngFor="let filho of listaFilhos"
                                    class="col-3"
                                >
                                    <div class="row">
                                        <div
                                            class="d-flex justify-content-center align-items-center"
                                            style="gap: 10px"
                                        >
                                            <input
                                                [value]="
                                                    formatarDataNascimento(
                                                        filho.value
                                                    )
                                                "
                                                class="form-control form-input-box mb-0"
                                                type="text"
                                                [disabled]="desativarCampos"
                                            />

                                            <div
                                                (click)="deletarFilho(filho.id)"
                                                class="bg-danger d rounded d-flex justify-content-center align-items-center p-1 btn btn-danger"
                                            >
                                                <img
                                                    alt=""
                                                    src="../../../../../assets/svg/delete.svg"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-4 mt-4">
                        <div>
                            <span class="form-label-texts"
                                >Está fazendo alguma dieta?</span
                            >
                        </div>

                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('FazendoDieta', true)"
                                [checked]="
                                    formGroupEtapa2.get('FazendoDieta')
                                        .value === true
                                "
                                class="form-input-checkbox"
                                id="FazendoDietaSim"
                                #FazendoDietaSim
                                type="checkbox"
                                [disabled]="desativarCampos"
                            />

                            <label
                                class="form-label-texts-helper-check"
                                for="FazendoDietaSim"
                                >Sim</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="toggleCheckbox('FazendoDieta', false)"
                                [checked]="
                                    formGroupEtapa2.get('FazendoDieta')
                                        .value === false
                                "
                                class="form-input-checkbox"
                                id="FazendoDietaNao"
                                #FazendoDietaNao
                                type="checkbox"
                                [disabled]="desativarCampos"
                            />
                            <label
                                class="form-label-texts-helper-check"
                                for="FazendoDietaNao"
                                >Não</label
                            >
                        </div>
                    </div>
                    <div class="col-8 mt-4">
                        <div>
                            <span class="form-label-texts"
                                >Tem algum acompanhamento por
                                nutricionista?</span
                            >
                        </div>

                        <div class="form-check form-check-inline">
                            <input
                                (click)="
                                    toggleCheckbox('TemNutricionista', true)
                                "
                                [checked]="
                                    formGroupEtapa2.get('TemNutricionista')
                                        .value === true
                                "
                                class="form-input-checkbox"
                                id="TemNutricionistaSim"
                                #TemNutricionistaSim
                                type="checkbox"
                                [disabled]="
                                    formGroupEtapa2.get('FazendoDieta')
                                        .value === false
                                "
                            />

                            <label
                                class="form-label-texts-helper-check"
                                for="TemNutricionistaSim"
                                >Sim</label
                            >
                        </div>
                        <div class="form-check form-check-inline">
                            <input
                                (click)="
                                    toggleCheckbox('TemNutricionista', false)
                                "
                                [checked]="
                                    formGroupEtapa2.get('TemNutricionista')
                                        .value === false
                                "
                                class="form-input-checkbox"
                                id="TemNutricionistaNao"
                                #TemNutricionistaNao
                                type="checkbox"
                                [disabled]="
                                    formGroupEtapa2.get('FazendoDieta')
                                        .value === false
                                "
                            />
                            <label
                                class="form-label-texts-helper-check"
                                for="TemNutricionistaNao"
                                >Não</label
                            >
                        </div>
                    </div>
                    <div class="col-12 mt-4">
                        <span class="form-label-texts">Descreva sua Dieta</span>
                        <textarea
                            class="form-control form-input-box"
                            formControlName="DescricaoDieta"
                            id="DescricaoDieta"
                            #DescricaoDieta
                            name="DescricaoDieta"
                            type="DescricaoDieta"
                        >
                        </textarea>

                        <span class="form-label-texts-helper"
                            >Descreva sua dieta atual, se não estiver fazendo
                            nenhuma dieta, descreva sua dieta habitual.</span
                        >
                    </div>
                    <div class="col-12 mt-4">
                        <p class="form-label-texts-helper">
                            Este questionário tem objetivo de identificar a
                            necessidade de avaliação clínica e médica antes do
                            início da atividade física. Caso você marque um SIM,
                            é fortemente sugerida a realização da avaliação
                            clínica e médica. Contudo, qualquer pessoa pode
                            participar de uma atividade física de esforço
                            moderado, respeitando as restrições médicas.<br />
                            O PAR-Q foi elaborado para auxiliar você a se
                            auto-ajudar. Os exercícios praticados regularmente
                            estão associados a muitos benefícios de saúde.
                            Completar o PAR-Q representa o primeiro passo
                            importante a ser tomado, principalmente se você está
                            interessado em incluir a atividade física com maior
                            freqüência e regularidade no seu dia a dia. O bom
                            senso é o seu melhor guia ao responder estas
                            questões. Por favor, leia atentamente cada questão e
                            marque SIM ou NÃO.
                        </p>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'ProblemaCardiaco'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Alguma vez seu médico disse que você possui
                                    algum problema cardíaco e recomendou que
                                    você só praticasse atividade física sob
                                    prescrição médica?
                                </span>
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('ProblemaCardiaco', true)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('ProblemaCardiaco')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="ProblemaCardiacoSim"
                                    #ProblemaCardiacoSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="ProblemaCardiacoSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'ProblemaCardiaco',
                                            false
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('ProblemaCardiaco')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="ProblemaCardiacoNao"
                                    #ProblemaCardiacoNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="ProblemaCardiacoNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'DorToraxAtivFisica'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Você sente dor no tórax quando pratica uma
                                    atividade física?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'DorToraxAtivFisica',
                                            true
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get(
                                            'DorToraxAtivFisica'
                                        ).value === true
                                    "
                                    class="form-input-checkbox"
                                    id="DorToraxAtivFisicaSim"
                                    #DorToraxAtivFisicaSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="DorToraxAtivFisicaSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'DorToraxAtivFisica',
                                            false
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get(
                                            'DorToraxAtivFisica'
                                        ).value === false
                                    "
                                    class="form-input-checkbox"
                                    id="DorToraxAtivFisicaNao"
                                    #DorToraxAtivFisicaNao
                                    [disabled]="desativarCampos"
                                    type="checkbox"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="DorToraxAtivFisicaNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'UltimoMesDorTorax'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    No último mês você sentiu dor torácica
                                    quando não estava praticando atividade
                                    física?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'UltimoMesDorTorax',
                                            true
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('UltimoMesDorTorax')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="UltimoMesDorToraxSim"
                                    #UltimoMesDorToraxSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="UltimoMesDorToraxSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'UltimoMesDorTorax',
                                            false
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('UltimoMesDorTorax')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="UltimoMesDorToraxNao"
                                    #UltimoMesDorToraxNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="UltimoMesDorToraxNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'PerdeuEquilibrio'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Você perdeu o equilíbrio em virtude de
                                    tonturas ou perdeu a consciência quando
                                    estava praticando atividade física?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('PerdeuEquilibrio', true)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('PerdeuEquilibrio')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="PerdeuEquilibrioSim"
                                    #PerdeuEquilibrioSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="PerdeuEquilibrioSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'PerdeuEquilibrio',
                                            false
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('PerdeuEquilibrio')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="PerdeuEquilibrioNao"
                                    #PerdeuEquilibrioNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="PerdeuEquilibrioNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'ProblemaOsseo'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Você tem algum problema ósseo ou articular
                                    que poderia ser agravado com a prática de
                                    atividades físicas?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('ProblemaOsseo', true)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('ProblemaOsseo')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="ProblemaOsseoSim"
                                    #ProblemaOsseoSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="ProblemaOsseoSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('ProblemaOsseo', false)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('ProblemaOsseo')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="ProblemaOsseoNao"
                                    #ProblemaOsseoNao
                                    [disabled]="desativarCampos"
                                    type="checkbox"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="ProblemaOsseoNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'RemedioPressao'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Seu médico já recomendou o uso de
                                    medicamentos para controle da sua pressão
                                    arterial ou condição cardiovascular?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('RemedioPressao', true)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('RemedioPressao')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="RemedioPressaoSim"
                                    #RemedioPressaoSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="RemedioPressaoSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('RemedioPressao', false)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('RemedioPressao')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="RemedioPressaoNao"
                                    #RemedioPressaoNao
                                    [disabled]="desativarCampos"
                                    type="checkbox"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="RemedioPressaoNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'FisicoImpedeAtivFisica'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Você tem conhecimento de alguma outra razão
                                    física que o impeça de participar de
                                    atividades físicas?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'FisicoImpedeAtivFisica',
                                            true
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get(
                                            'FisicoImpedeAtivFisica'
                                        ).value === true
                                    "
                                    class="form-input-checkbox"
                                    id="FisicoImpedeAtivFisicaSim"
                                    #FisicoImpedeAtivFisicaSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="FisicoImpedeAtivFisicaSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox(
                                            'FisicoImpedeAtivFisica',
                                            false
                                        )
                                    "
                                    [checked]="
                                        formGroupEtapa2.get(
                                            'FisicoImpedeAtivFisica'
                                        ).value === false
                                    "
                                    class="form-input-checkbox"
                                    id="FisicoImpedeAtivFisicaNao"
                                    #FisicoImpedeAtivFisicaNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="FisicoImpedeAtivFisicaNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'TesteEsforco'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Realizou teste de esforço com
                                    eletrocardiograma com o cardiologista no
                                    último ano?
                                </span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('TesteEsforco', true)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('TesteEsforco')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="TesteEsforcoSim"
                                    #TesteEsforcoSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="TesteEsforcoSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="
                                        toggleCheckbox('TesteEsforco', false)
                                    "
                                    [checked]="
                                        formGroupEtapa2.get('TesteEsforco')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="TesteEsforcoNao"
                                    #TesteEsforcoNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="TesteEsforcoNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <p class="form-label-texts-helper">
                            Este instrumento é apenas uma ferramenta na
                            tentativa da identificação dos riscos com a prática
                            da atividade física e não é infalível.<br />
                            Instituições como o Colégio Americano de Medicina
                            Desportiva e a Sociedade Brasileira de Cardiologia,
                            recomendam a realização de exames complementares
                            como o teste de esforço, e o exame sangüíneo.<br />
                            Procedimentos recomendados principalmente para
                            indivíduos sintomáticos, indivíduos com doenças
                            conhecidas como a hipertensão arterial, a diabetes,
                            a obesidade, as doenças cardiovasculares ou para
                            indivíduos acima dos trinta e cinco anos.
                        </p>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'NivelAtividadeFisica'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">
                                    Nível de atividade física:
                                </span>
                            </div>
                            <div class="col-12 mt-1">
                                <div class="mt-2 d-flex align-items-center">
                                    <input
                                        (click)="
                                            toggleCheckboxWidthValue(
                                                'NivelAtividadeFisica',
                                                1
                                            )
                                        "
                                        [checked]="
                                            formGroupEtapa2.get(
                                                'NivelAtividadeFisica'
                                            ).value === 1
                                        "
                                        class="form-input-checkbox"
                                        id="sedentario"
                                        #sedentario
                                        name="sedentario"
                                        type="checkbox"
                                        [disabled]="desativarCampos"
                                    />
                                    <label
                                        class="form-label-texts-helper-check"
                                        for="sedentario"
                                    >
                                        Não realiza nenhum tipo de atividade
                                        física: Sedentário</label
                                    >
                                </div>
                            </div>
                            <div class="col-12 mt-1">
                                <div class="mt-2 d-flex align-items-center">
                                    <input
                                        (click)="
                                            toggleCheckboxWidthValue(
                                                'NivelAtividadeFisica',
                                                2
                                            )
                                        "
                                        [checked]="
                                            formGroupEtapa2.get(
                                                'NivelAtividadeFisica'
                                            ).value === 2
                                        "
                                        class="form-input-checkbox"
                                        type="checkbox"
                                        [disabled]="desativarCampos"
                                    />
                                    <label
                                        class="form-label-texts-helper-check"
                                        for=""
                                        >Exercita de forma regular 3-6 dias na
                                        semana: Ativo</label
                                    >
                                </div>
                            </div>
                            <div class="col-12 mt-1">
                                <div class="mt-2 d-flex align-items-center">
                                    <input
                                        (click)="
                                            toggleCheckboxWidthValue(
                                                'NivelAtividadeFisica',
                                                3
                                            )
                                        "
                                        [checked]="
                                            formGroupEtapa2.get(
                                                'NivelAtividadeFisica'
                                            ).value === 3
                                        "
                                        class="form-input-checkbox"
                                        id="NivelAtividadeFisica3"
                                        #NivelAtividadeFisica3
                                        type="checkbox"
                                        [disabled]="desativarCampos"
                                    />

                                    <label
                                        class="form-label-texts-helper-check"
                                        for="NivelAtividadeFisica3"
                                        >Realiza de 5 a 7 vezes na semana
                                        treinamento de alta intensidade de mais
                                        de 45' ou moderado acima de 90':
                                        Atleta</label
                                    >
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mt-1">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'Tabagista'
                            }"
                        >
                            <div>
                                <span class="form-label-texts">Tabagista?</span>
                            </div>

                            <div class="form-check form-check-inline">
                                <input
                                    (click)="toggleCheckbox('Tabagista', true)"
                                    [checked]="
                                        formGroupEtapa2.get('Tabagista')
                                            .value === true
                                    "
                                    class="form-input-checkbox"
                                    id="TabagistaSim"
                                    #TabagistaSim
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />

                                <label
                                    class="form-label-texts-helper-check"
                                    for="TabagistaSim"
                                    >Sim</label
                                >
                            </div>
                            <div class="form-check form-check-inline">
                                <input
                                    (click)="toggleCheckbox('Tabagista', false)"
                                    [checked]="
                                        formGroupEtapa2.get('Tabagista')
                                            .value === false
                                    "
                                    class="form-input-checkbox"
                                    id="TabagistaNao"
                                    #TabagistaNao
                                    type="checkbox"
                                    [disabled]="desativarCampos"
                                />
                                <label
                                    class="form-label-texts-helper-check"
                                    for="TabagistaNao"
                                    >Não</label
                                >
                            </div>
                        </div>
                        <div class="col-6 mt-1">
                            <div>
                                <span class="form-label-texts">
                                    Quantos cigarros por dia:</span
                                >
                                <input
                                    [disabled]="
                                        formGroupEtapa2.get('Tabagista')
                                            .value === false
                                    "
                                    class="form-input-box mx-2"
                                    formControlName="QtdCigarros"
                                    id="QtdCigarros"
                                    #QtdCigarros
                                    name="QtdCigarros"
                                    type="number"
                                />
                            </div>
                        </div>
                    </div>

                    <div *ngIf="erroAvancarAnamnese" class="col-12 mb-2 mt-4">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div
                        class="col-12 form-group-buttons row"
                        style="margin-left: 20px"
                    >
                        <div class="col-md-3 mb-3">
                            <button
                                (click)="handleClickFecharModal()"
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                            >
                                Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3">
                            <button
                                (click)="handleClickVoltarModal()"
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                            >
                                Voltar
                            </button>
                        </div>

                        <div
                            *ngIf="!carregandoAtualizarAnamnese"
                            class="col-md-6 col-12 mb-3"
                        >
                            <button
                                (click)="handleClickProximoModal()"
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            >
                                Próximo
                            </button>
                        </div>

                        <div
                            *ngIf="carregandoAtualizarAnamnese"
                            class="col-md-6 col-12"
                        >
                            <button
                                (click)="handleClickProximoModal()"
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                disabled
                            >
                                Carregando
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
