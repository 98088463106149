import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-checkups',
  templateUrl: './checkups.component.html',
  styleUrls: ['./checkups.component.scss']
})
export class CheckupsComponent {
  public listaPacientes: any;
  public nenhumUsuarioEncontrado: boolean = true;
  public pacientesSelecionados: number[] = [];
  public loadingListaPacientes: boolean = false;
  public opcoesSituacao = [
    { value: '', descricao: 'Selecione...' },
    { value: '0', descricao: 'Em andamento' },
    { value: '1', descricao: 'Finalizado' },
    { value: '2', descricao: 'Cancelado' },
    { value: '3', descricao: 'Bloqueado' }  
  ];
 
  public dataPesquisa: any = {
    Avaliador: '', 
    Cliente: '', 
    DataCadastro: '', 
    Protocolo: '',
    Situacao: '' 
  };


  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    ) {}

  ngOnInit(): void {
    // Código de inicialização, se necessário
  }


  getListaPacientes(): void {
    this.loadingListaPacientes = true;
    this.professorService.postConsultarCheckup(this.dataPesquisa).subscribe({
      next: (response) => {
        this.listaPacientes = response;
        this.nenhumUsuarioEncontrado = this.listaPacientes.length === 0;
        this.loadingListaPacientes = false;

        setTimeout(() => {
          this.scrollToElementById('listaPacientes');
        }, 1000);
      },
      error: (error) => {
         if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar pacientes, contate o suporte')
        }

        this.loadingListaPacientes = false;
      }
    });
  }

selectAll(event: any): void {
  const checkboxes = document.querySelectorAll('input[type="checkbox"]');
  checkboxes.forEach((checkbox: any) => {
      checkbox.checked = event.target.checked;
  });
  this.pacientesSelecionados = event.target.checked ? this.listaPacientes.map(item => item.ID) : [];
}

novoCheckup(): void {
  window.location.href = `/dashboard/acesso-avaliador/checkups/adicionar`;
}

scrollToElementById(id: string) {
     
  const element = document.getElementById(id)
  element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

}

alterarCheckup(): void {
  if (this.pacientesSelecionados.length !== 1) {
      this.toastr.warning('Selecione um paciente para alterar um checkup');
      return;
  }
  window.location.href = `/dashboard/acesso-avaliador/checkups/alterar/${this.pacientesSelecionados[0]}`;
}
  

selecionarPaciente(pacienteId: number): void {
  const index = this.pacientesSelecionados.indexOf(pacienteId);
  if (index === -1) {
      this.pacientesSelecionados.push(pacienteId);
  } else {
      this.pacientesSelecionados.splice(index, 1);
  }
}

formatDate(date) {
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
}

}
