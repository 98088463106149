<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">
 

 

        <div class="w-100 d-flex justify-content-between  ">
            <h6  >
                 
            </h6>
            <img src="assets/img/logoSafeTextoBranco.png" alt="plat-safe-logo" height="102px" width="134px">
        </div>
 

        <div class="container-tabela mt-5" 
        style="max-height: 600px; overflow-y: auto; max-width: 800px; margin: 0 auto;">
            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                 Redefina sua senha
            </h2>
            <div class="divider-dash"></div>
            <div>
                <p class="ml-3">
                    Para alterar sua senha no SISTEMA SAFE, digite abaixo a sua senha e a senha atual.
                </p>
            </div>
            <div class="row">
                <div class="col-12 p-5">
                    <form class="registration-form p-3" [formGroup]="formRecover" (ngSubmit)="onClickRecover()">
                        <div class="form-group">
                            <label for="SenhaAtual" class="text-azul-claro-plataforma-safe">Senha atual</label>
                            <input type="password" class="form-control w-100" style="max-width: 400px;"
                            id="SenhaAtual" formControlName="SenhaAtual"
                            autocomplete="off"
                            >
                        </div>
                        <div class="form-group">
                            <label for="SenhaNova" class="text-azul-claro-plataforma-safe">Nova Senha</label>
                            <input type="password" class="form-control w-100" style="max-width: 400px;"
                             id="SenhaNova" formControlName="SenhaNova"
                             autocomplete="off"
                             >
                        </div>
                        <div class="form-group">
                            <label for="ConfirmacaoSenha" class="text-azul-claro-plataforma-safe">Confirmar Senha</label>
                            <input type="password" class="form-control w-100" style="max-width: 400px;"
                             id="ConfirmacaoSenha" formControlName="ConfirmacaoSenha"
                             autocomplete="off"
                             >
                        </div>
                        <div class="d-flex justify-content-end" >
                            <button type="submit" [disabled]="loadingRecover"
                             class="btn btn-primary">
                            {{
                                loadingRecover ? 'Carregando...' : 'Redefinir Senha'
                            }}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
            


 
        </div>


    </div>
</div>
