<div class="app-background-professor">
    <!-- <app-header></app-header> -->
    <app-menu-sidebar [perfilTipo]="4"></app-menu-sidebar>
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div>
    <!-- <app-footer></app-footer> -->
    <!-- <app-control-sidebar></app-control-sidebar> -->
    <div id="sidebar-overlay" (click)="onToggleMenuSidebar()"></div>
</div>
