<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div> 
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Biomarcador 
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">1/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
         <div class="row vertical-content">
            <form class="form-form p-3">
                <div class="col-md-12 row"> 
                    <div class="center col-12 mt-1">
                        <span class="form-label-texts">
                            Você precisa responder o biomarcador anterior.
                        </span>
                    </div>
                    <div class="center col-12 mt-4">
                        <button class="btn btn-secondary btn-block btn-sm form-bnt-voltar" (click)="handleClickFecharModal()">Fechar</button>
                    </div>
                 </div>
            </form>
        </div>
    </div>
</div>