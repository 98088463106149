import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NO_ERRORS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from '@/app-routing.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FooterComponent} from '@modules/main/footer/footer.component';
import {HeaderComponent} from '@modules/main/header/header.component';
import {MessagesComponent} from '@modules/main/header/messages/messages.component';
import {NotificationsComponent} from '@modules/main/header/notifications/notifications.component';
import {MenuSidebarComponent} from '@modules/main/menu-sidebar/menu-sidebar.component';
import {BlankComponent} from '@pages/blank/blank.component';
import {ProfileComponent} from '@pages/profile/profile.component';
import {ToastrModule} from 'ngx-toastr';
import {AppComponent} from './app.component';

import {CommonModule, registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import {ConsultarBiomarcadoresProfComponent} from '@components/dashboard/graficos/consultar-biomarcadores-prof/consultar-biomarcadores-prof.component';
import {ModalBaixarAppComponent} from '@components/dashboard/modais/Modal-BaixarApp/modal-baixar-app.component';
import {ModalBioAnteriorComponent} from '@components/dashboard/modais/Modal-BioAnterior/modal-bioAnterior.component';
import {ModalBioConfirmarEnvioComponent} from '@components/dashboard/modais/Modal-BioConfirmarEnvio/modal-bioConfirmarEnvio.component';
import {ModalRetaFinalComponent} from '@components/dashboard/modais/Modal10-RetaFinal/modal-retaFinal.component';
import {ModalDadosPessoaisComponent} from '@components/dashboard/modais/Modal2-DadosPessoais/modal-dadospessoais.component';
import {ModalQuestFarmPrelimComponent} from '@components/dashboard/modais/Modal4-QuestFarmPrelim/modal-questFarmPrelim.component';
import {ModalCheckBoxesComponent} from '@components/dashboard/modais/Modal5-CheckBoxes/modal-checkBoxes.component';
import {ModalPercepSaudeComponent} from '@components/dashboard/modais/Modal6-PercepSaude/modal-percepSaude.component';
import {ModalSaudEmocFuncComponent} from '@components/dashboard/modais/Modal7-SaudEmocFunc/modal-saudEmocFunc.component';
import {ModalCheckBoxes2Component} from '@components/dashboard/modais/Modal8-CheckBoxes2/modal-checkBoxes2.component';
import {ModalCheckBoxes3SonoComponent} from '@components/dashboard/modais/Modal9-CheckBoxes3Sono/modal-checkBoxes3Sono.component';
import {Modal1InicioComponent} from '@components/dashboard/modais/modal1-Inicio/modal-1inicio.component';
import {ModalInfoSobreSaudeComponent} from '@components/dashboard/modais/modal3-InfoSobreSaude/modal-infoSobreSaude.component';
import {LoadingLoginPage} from '@components/loading-login-page/loading-login-page.component';
import {ForgotPasswordComponent} from '@modules/forgot-password/forgot-password.component';
import {ContainerMainAlunoComponent} from '@modules/main/container/container-main-aluno/container-main-aluno.component';
import {ContainerMainAvaliadorComponent} from '@modules/main/container/container-main-avaliador/container-main-avaliador.component';
import {LanguageComponent} from '@modules/main/header/language/language.component';
import {UserComponent} from '@modules/main/header/user/user.component';
import {RecoverPasswordComponent} from '@modules/recover-password/recover-password.component';
import {
    NgbDatepickerModule,
    NgbModalModule,
    NgbModule
} from '@ng-bootstrap/ng-bootstrap';
import {InputMaskModule} from '@ngneat/input-mask';
import {StoreModule} from '@ngrx/store';
import {DashboardAlunoComponent} from '@pages/aluno/dashboard-aluno/dashboard-aluno.component';
import {RecuperarSenhaPageComponent} from '@pages/recuperar-senha-page/recuperar-senha-page.component';
import {RegisterPageComponent} from '@pages/register-page/register-page.component';
import {UsuariosAvaliacoesAlterar} from '@pages/usuarios-avaliacoes-alterar/usuarios-avaliacoes-alterar';
import {UsuariosAvaliacoesComponent} from '@pages/usuarios-avaliacoes/usuarios-avaliacoes.component';
import {ProfabricComponentsModule} from '@profabric/angular-components';
import {CalendarModule} from 'primeng/calendar';
import {SliderModule} from 'primeng/slider';
import {BreadcrumbComponent} from '@components/dashboard/breadcrumb/breadcrumb.component';
import {ConsultarBiomarcadoresComponent} from '@components/dashboard/graficos/consultar-biomarcadores/consultar-biomarcadores.component';
import {ConsultarContadoresComponent} from '@components/dashboard/graficos/consultar-contadores/consultar-contadores.component';
import {ConsultarEvolucaoPacientesPorGeneroComponent} from '@components/dashboard/graficos/consultar-evolucao-pacientes-por-genero/consultar-evolucao-pacientes-por-genero.component';
import {ConsultarNiveisPacientesPorGeneroComponent} from '@components/dashboard/graficos/consultar-niveis-pacientes-por-genero/consultar-niveis-pacientes-por-genero.component';
import {ConsultarPorIdadeComponent} from '@components/dashboard/graficos/consultar-por-idade/consultar-por-idade.component';
import {ConsultarRelacaoAlturaPesoDosPacientesComponent} from '@components/dashboard/graficos/consultar-relacao-altura-peso-dos-pacientes/consultar-relacao-altura-peso-dos-pacientes.component';
import {ConsultarRelacaoCheckupsPorUfComponent} from '@components/dashboard/graficos/consultar-relacao-checkups-por-uf/consultar-relacao-checkups-por-uf.component';
import {DadosTeiaComponent} from '@components/dashboard/graficos/dados-teia/dados-teia.component';
import {ModalBiomarcadorComponent} from '@components/dashboard/modais/modal1-CapFuncionalPercebida/modal-capFuncionalPercebida.component';
import {UsuariosChatComponent} from '@components/dashboard/usuarios/usuarios-chat/usuarios-chat.component';
import {UsuariosListarComponent} from '@components/dashboard/usuarios/usuarios-listar/usuarios-listar.component';
import {MenuItemComponent} from '@components/menu-item/menu-item.component';
import {SidebarSearchComponent} from '@components/sidebar-search/sidebar-search.component';
import {ControlSidebarComponent} from '@modules/main/control-sidebar/control-sidebar.component';
import {DashboardAvaliadorComponent} from '@pages/avaliador/dashboard-avaliador/dashboard-avaliador.component';
import {AnamnesesComponent} from '@pages/cadastros/anamneses/anamneses/anamneses.component';
import {CheckupsComponent} from '@pages/cadastros/checkups/checkups/checkups.component';
import {DadosAlunoComponent} from '@pages/cadastros/dados-aluno/dados-aluno/dados-aluno.component';
import {MeusClientesComponent} from '@pages/cadastros/meus-clientes/meus-clientes/meus-clientes.component';
import {SenhasProvisoriasComponent} from '@pages/cadastros/senhas-provisorias/senhas-provisorias/senhas-provisorias.component';

import {RelatorioBiomarcadoresSafeComponent} from '@pages/avaliador/relatorios/relatorio-biomarcadores-safe/relatorio-biomarcadores-safe.component';
import {BiomarcadorItemComponent} from '@pages/dashboard/biomarcador-item/biomarcador-item.component';
import {BiomarcadoresComponent} from '@pages/dashboard/biomarcadores/biomarcadores.component';
import {LoginPageComponent} from '@pages/login-page/login-page.component';
import {MainMenuComponent} from '@pages/main-menu/main-menu.component';
import {SubMenuComponent} from '@pages/main-menu/sub-menu/sub-menu.component';
import {UsuariosComponent} from '@pages/usuarios/usuarios.component';
import {authReducer} from './store/auth/reducer';
import {uiReducer} from './store/ui/reducer';
import {RelatorioPreCadastroComponent} from '@pages/avaliador/relatorios/relatorio-pre-cadastro/relatorio-pre-cadastro.component';
import {UsuariosIncluir} from '@pages/usuarios-incluir/usuarios-incluir';
import {UsuariosAlterar} from '@pages/usuarios-alterar/usuarios-alterar';
import {RelatorioTabelaGorduraComponent} from '@pages/avaliador/relatorios/relatorio-tabela-gordura/relatorio-tabela-gordura.component';
import {RelatorioAutoConscienciaComponent} from '@pages/avaliador/relatorios/relatorio-auto-consciencia/relatorio-auto-consciencia.component';
import {CheckupsAdicionar} from '@pages/cadastros/checkups/checkups-adicionar/checkups-adicionar';
import {AppInterceptor} from '@services/app.interceptor';
import {AppService} from '@services/app.service';
import {CuponsComponent} from '@pages/cadastros/cupons/cupons.component';
import {AdicionarCupomComponent} from './pages/cadastros/cupons/adicionar-cupom/adicionar-cupom.component';
import {RedefinirSenhaComponent} from '@pages/redefinir-senha/redefinir-senha.component';
import {MeusDados} from '@pages/aluno/meus-dados/meus-dados';
import {redefinirSenhaAluno} from '@pages/aluno/redefinir-senha-aluno/redefinir-senha-aluno';
import {checkupsAluno} from '@pages/aluno/checkups-aluno/checkups-aluno';
import {RegisterPageCupomComponent} from '@pages/register-page-cupom/register-page-cupom.component';
import {EditarCupomComponent} from '@pages/cadastros/cupons/editar-cupom/editar-cupom.component';
import {NgxMaskModule, IConfig} from 'ngx-mask';
import {AnamnesesVisualizar} from '@pages/anamneses-visualizar/anamneses-visualizar';
import {NgSelectModule} from '@ng-select/ng-select';
import {ModalReenviarSenhaComponent} from '@components/dashboard/modais/Modal-ReenviarSenha/modal-reenviarSenha.component';
import {ModalSoliciarCheckupComponent} from '@components/dashboard/modais/Modal-SoliciarCheckup/modal-SoliciarCheckup.component';
import {CheckupsPeriodoComponent} from '@pages/avaliador/relatorios/relatorio-checkups-periodo/relatorio-checkups-periodo.component';
import {AniversariantesComponent} from '@pages/avaliador/relatorios/relatorio-aniversariantes/relatorio-aniversariantes.component';
import {CheckupsEvolucaoComponent} from '@pages/avaliador/relatorios/relatorio-checkups-evolucao/relatorio-checkups-evolucao.component';
import {ebooksComponent} from '@pages/ebooks/ebooks.component';
import {duvidasComponent} from '@pages/duvidas/duvidas.component';
import {RelatorioRankingAvaliadores} from '@pages/avaliador/relatorios/relatorio-ranking-avaliadores/relatorio-ranking-avaliadores';
import {UsuariosOnlinesComponent} from '@pages/cadastros/usuarios-onlines/usuarios-onlines.component';
import {ModalTermineCadastroComponent} from '@components/dashboard/modais/ModalTermineCadastro/modal-termine-cadastro.component';

registerLocaleData(localeEn, 'en-EN');

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        MenuSidebarComponent,
        BlankComponent,
        ProfileComponent,
        MessagesComponent,
        NotificationsComponent,
        UserComponent,
        ForgotPasswordComponent,
        RecoverPasswordComponent,
        LanguageComponent,
        MainMenuComponent,
        SubMenuComponent,
        MenuItemComponent,
        ControlSidebarComponent,
        SidebarSearchComponent,
        LoginPageComponent,
        ConsultarContadoresComponent,
        ConsultarPorIdadeComponent,
        ConsultarEvolucaoPacientesPorGeneroComponent,
        ConsultarBiomarcadoresComponent,
        ConsultarRelacaoAlturaPesoDosPacientesComponent,
        ConsultarRelacaoCheckupsPorUfComponent,
        ConsultarNiveisPacientesPorGeneroComponent,
        BreadcrumbComponent,
        UsuariosChatComponent,
        UsuariosListarComponent,
        BiomarcadoresComponent,
        BiomarcadorItemComponent,
        Modal1InicioComponent,
        ModalDadosPessoaisComponent,
        ModalBiomarcadorComponent,
        ModalQuestFarmPrelimComponent,
        ModalCheckBoxesComponent,
        ModalInfoSobreSaudeComponent,
        ModalPercepSaudeComponent,
        ModalSaudEmocFuncComponent,
        ModalBioAnteriorComponent,
        ModalCheckBoxes2Component,
        ModalCheckBoxes3SonoComponent,
        ModalRetaFinalComponent,
        ModalBioConfirmarEnvioComponent,
        DadosTeiaComponent,
        ConsultarContadoresComponent,
        ConsultarBiomarcadoresProfComponent,
        MeusClientesComponent,
        UsuariosComponent,
        UsuariosAvaliacoesComponent,
        AnamnesesComponent,
        SenhasProvisoriasComponent,
        DadosAlunoComponent,
        ContainerMainAvaliadorComponent,
        ContainerMainAlunoComponent,
        DashboardAvaliadorComponent,
        CheckupsComponent,
        RegisterPageComponent,
        DashboardAlunoComponent,
        RecuperarSenhaPageComponent,
        ModalBaixarAppComponent,
        LoadingLoginPage,
        UsuariosAvaliacoesAlterar,
        RelatorioBiomarcadoresSafeComponent,
        RelatorioPreCadastroComponent,
        RelatorioTabelaGorduraComponent,
        UsuariosIncluir,
        UsuariosAlterar,
        RelatorioAutoConscienciaComponent,
        CheckupsAdicionar,
        CuponsComponent,
        AdicionarCupomComponent,
        RedefinirSenhaComponent,
        MeusDados,
        redefinirSenhaAluno,
        checkupsAluno,
        RegisterPageCupomComponent,
        EditarCupomComponent,
        AnamnesesVisualizar,
        ModalReenviarSenhaComponent,
        ModalSoliciarCheckupComponent,
        CheckupsPeriodoComponent,
        AniversariantesComponent,
        CheckupsEvolucaoComponent,
        ebooksComponent,
        duvidasComponent,
        RelatorioRankingAvaliadores,
        UsuariosOnlinesComponent,
        ModalTermineCadastroComponent
    ],

    imports: [
        ProfabricComponentsModule,
        CommonModule,
        FormsModule,
        BrowserModule,
        StoreModule.forRoot({auth: authReducer, ui: uiReducer}),
        HttpClientModule,
        AppRoutingModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000 || 10000,
            positionClass: 'toast-top-right' || 'toast-top-center',
            preventDuplicates: true
        }),
        NgbModule,
        NgbModalModule,
        InputMaskModule,
        NgbDatepickerModule,
        CalendarModule,
        SliderModule,
        NgxMaskModule.forRoot(),
        NgSelectModule
    ],
    providers: [
        AppService,
        {provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true} // Registre o interceptor
    ],
    bootstrap: [AppComponent],
    schemas: [NO_ERRORS_SCHEMA]
})
export class AppModule {}
