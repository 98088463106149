<hr class="mx-4 my-2" />
<!-- <a (click)="menuItem.onclick()" class="nav-link m-0 px-4 py-0 {{ textColor }}" [ngClass]="{active: isMainActive || isOneOfChildrenActive}"> -->
<!-- <i class="nav-icon {{ menuItem.iconClasses }}"></i> -->
<!-- <p>{{ menuItem.name }}</p> -->
<!-- </a> -->


<a
    (click)="handleMainMenuAction()"
    class="nav-link m-0 px-4 py-0 {{ textColor }}"
>
    <div
        class="d-flex align-items-center justify-content-between {{
            menuItem.id
        }}"
    >
        <p>{{ menuItem.name }}</p>
        <svg
            *ngIf="isExpandable && menuItem.children.length > 0"
            class="rotate"
            [@rotate]="isMenuExtended"
            width="16"
            height="16"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M8 5L16 12L8 19V5Z" fill="currentColor" />
        </svg>
    </div>
</a>

<ul
    class="nav nav-treeview"
    *ngFor="let item of menuItem.children"
    [@openClose]="isMenuExtended"
>
    <li class="nav-item {{ item.id }}">
        <a
            [routerLink]="item.path"
            class="nav-link m-0 px-5 py-2 {{ textColor }}"
        >
            <p class="nav-submenu">{{ item.name }}</p>
        </a>
    </li>
</ul>
