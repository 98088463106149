<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">
 

        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5 text-amarelo-plataforma-safe">
                    Cupons
                </h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logoSafeTextoBranco.png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="container-tabela">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Filtrar cupons
            </h6>

            <div class="col-12 mt-6">
                <form [formGroup]="formGroupCupom" class="pesquisa-tabela row">

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Avaliador</span>

                        <input
                            formControlName="NomeAvaliador"
                            class="form-input-box form-control"
                        >
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Código</span>

                        <input
                            class="form-input-box form-control"
                            formControlName="Codigo"
                        >
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Descrição</span>

                        <input
                            class="form-input-box form-control"
                            formControlName="Descricao"
                        >
                    </div>
                    <!-- 2024-05-09T20:40:13.746Z -->
                    <div class="col-2 mt-2"></div>


                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Data de início de cadastro</span>

                        <input
                            class="form-input-box form-control"
                            formControlName="DataInicio"
                            type="date"
                         />
                        <!-- <p-calendar formControlName="DataInicio"></p-calendar> -->
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Data final de cadastro</span>

                        <input
                        class="form-input-box form-control"
                        formControlName="DataFim"
                        type="date"
                        
                        />

                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Situação</span>

                        <select
                            formControlName="Situacao"
                            class="form-control form-input-select"
                        >
                            <option value="">Selecione</option>

                            <option [value]="true">Ativo</option>
                            <option [value]="false">Inativo</option>
                        </select>
                    </div>

                    <div class="col-9"></div>

                    <div class="col-12 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button"
                                (click)="atualizarListaCupons()"
                                [disabled]="loadingCupons"
                                >
                            {{
                            loadingCupons ? 'Carregando...' : 'Pesquisar'
                            
                            }}
                        </button>
                    </div>
                </form>
            </div>

        </div>


        <div class="mt-5">
            <h6 class="titulo-usuarios text-cinza-claro-plataforma-safe">
                Ações
            </h6>
            <span
                class="text-help text-cinza-claro-plataforma-safe">Para ativar alguma ação, selecione algum paciente.</span>
            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="button"
                            (click)="novoCupom()"
                        >
                            Novo
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            (click)="alterarCupom()"
                            type="button"
                            [disabled]="!cupomSelecionado"
                        >
                            Alterar
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div class="container-tabela mt-5" style="max-height: 600px; overflow-y: auto;">

            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Cupons Cadastrados
            </h2>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="th-tabela text-azul-claro-plataforma-safe">
                        <!--                        <input (change)="selectAll($event)" class="form-checkbox-input" id="selectAll"-->
                        <!--                               type="checkbox">-->
                    </th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Avaliador</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Código</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Descrição</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Ativo</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Cadastro</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Vencimento</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Registrados</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="cupons.length  == 0 && !loadingCupons">
                    <td class="text-center" colspan="8">Nenhum cupom encontrado</td>
                </tr>

                <ng-container *ngIf="cupons.length > 0 && !loadingCupons">
                    <tr *ngFor="let cupom of cupons">
                        <td>
                            <input
                                (click)="selecionarCupom(cupom)"
                                [value]="cupom"
                                class="form-checkbox-input"
                                type="checkbox"
                                [checked]="cupom === cupomSelecionado"
                            >
                        </td>
                        <td>{{ cupom.NomeAvaliador }}</td>
                        <td>{{ cupom.Codigo }}</td>
                        <td>{{ cupom.Descricao }}</td>
                        <td>{{ cupom.Ativo ? 'Sim' : 'Não' }}</td>
                        <td>{{ formatDate(cupom.Cadastro) }}</td>
                        <td>{{ formatDate(cupom.Vencimento) }}</td>
                        <td>{{ cupom.Registrados }}</td>
                    </tr>
                </ng-container>
                <tr *ngIf="loadingCupons">
                    <td class="text-center align-middle" colspan="8">
                        <div class="spinner-border text-azul-plataforma-safe" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>

    </div>
</div>
