<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->


    <div class="container-fluid bg-transparent">
 
 
            <div class="w-100 d-flex justify-content-between  ">
                <h6  >
                 </h6>
                <img src="assets/img/logoSafeTextoBranco.png" alt="plat-safe-logo" height="102px" width="134px">
            </div>
            <div class="divider-dash"></div>
         

        <h6 class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe">
            Anamnese
        </h6>

        <div class="container-tabela mt-4 mb-3">
           

           
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Visualizar Anamnese
                </h6>
              
                <p class="ml-4" style="font-size: 14px; color: #7C7C7C;">
                    Protocolo:  {{anamneseProtocolo || 'Carregando...'}}
                </p>
                <p class="ml-4" style="font-size: 14px; color: #7C7C7C;">
                    Clique no número para visualizar etapa preenchida.
                </p>
                <div *ngIf="loadingAnamnese" class="col-md-12 col-sm-12 mt-2 d-flex justify-content-center align-items-center">
                    <div class="spinner-border text-azul-plataforma-safe" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                  </div>
                <div class="col-md-12 col-sm-12 mt-2scrollable-content" *ngIf="!loadingAnamnese && anamneseProtocolo" >
                    <div class="stepper-wrapper">
                        <div class="stepper-item" [ngClass]="{'selected': stepperSelected === stepper.number}"
                        *ngFor="let stepper of steppers" (click)="onSelectStepper(stepper.number)">
                            <div class="step-counter">{{ stepper.number }}</div>
                    </div>
                </div>
             </div>
        </div>

       
                
        <div class="container-tabela"
            *ngIf="!loadingAnamnese && anamneseProtocolo"
        >
            <div *ngIf="stepperSelected === 1" class="p-4 scrollable-content">
                <div class="p-4">
                    <div class="row vertical-content">
                        <div class="col-md-12 row">
                                <p class="form-title p-3" style="font-size: 18px;">
                                    Método SAFE® - Check-up SAFE 
                                </p>
                               
                                <p class="p-3">
                                    (Avaliação da saúde funcional)<br/><br/>
                                    Parabéns por estar cuidando de você!<br/><br/>
                                    Orientamos que seja bem sincero nas respostas e que elas são confidenciais, caso não consiga
                                    terminar de preencher agora não tem problema.<br/><br/>
                                    Lembre-se, quanto mais ricas forem suas respostas neste formulário, mais conteúdo teremos para
                                    trabalhar.<br/>
                                    Preparado?
                                </p>
                            </div>
                            
                        </div>
                </div>
            </div>

            <div *ngIf="stepperSelected === 2" class="p-4 scrollable-content">
                <div class="p-4">
                    <div class="row vertical-content">
                        <p class="form-title p-3" style="font-size: 18px;">
                            Método SAFE® - Check-up SAFE 
                        </p>
                        <p class="form-title p-3" style="font-size: 18px;">
                            Dados Pessoais  
                        </p>
                        <form [formGroup]="formGroupEtapa2" class="form-form p-3">
                            <div class="col-md-12 row">
                                <div class="col-md-4">
                                    <span><span class="form-label-texts">Peso:</span><span
                                        class="form-label-texts-helper"><br/>Informe seu peso atual em KG</span></span>
                                        <input 
                                        class="form-control form-input-box" 
                                        formControlName="Peso"
                                        id="Peso"
                                        name="Peso"
                                         mask="00.00 || 000.00"
                                        [dropSpecialCharacters]="false"
                                        type="text"
                                     />
            
                                </div>
                                <div class="col-md-4">
                                    <span><span class="form-label-texts">Altura:</span><br/><span
                                        class="form-label-texts-helper">Informe sua altura em CM</span></span>
            
                                    <input  
                                        class="form-control form-input-box" 
                                        formControlName="Altura"
                                        id="Altura"
                                        name="Altura"
                                         mask="000.00"
                                        [dropSpecialCharacters]="false"
                                        type="text"
                                    />
                                </div>
                                <div class="col-md-4">
                                    <span><span class="form-label-texts">Pressão arterial:</span><br/><span
                                        class="form-label-texts-helper">Informe sua pressão em mmHg</span></span>
            
                                    <div class="d-flex align-items-center">
                                        <input class="form-control form-input-box mb-0 pb-0" formControlName="PressaoPart1"
                                               type="number"/>
                                        <div class="d-flex justify-content-center align-items-center">
                                            <span>por</span>
                                        </div>
                                        <input class="form-control form-input-box mb-0 pb-0" formControlName="PressaoPart2"
                                               type="number"/>
            
                                    </div>
                                </div>
                                <div class="col-3 mt-4">
                                    <div>
                                        <span class="form-label-texts">Tem Cônjuge:</span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TemConjuge', true)"
                                            [checked]="formGroupEtapa2.get('TemConjuge').value === true"
                                            class="form-input-checkbox"
                                            id="conjugueSim"
                                            name="conjugueSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="conjugueSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TemConjuge', false)"
                                            [checked]="formGroupEtapa2.get('TemConjuge').value === false"
                                            class="form-input-checkbox"
                                            id="conjugueNao"
                                            name="conjugueNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        />
                                        <label class="form-label-texts-helper-check" for="conjugueNao">Não</label>
                                    </div>
                                </div>
                                <div *ngIf="formGroupEtapa2.get('TemConjuge').value === true" class="col-md-6 mt-4">
                                    <span class="form-label-texts">Nascimento Cônjuge</span>
                                    <div>
            
                                        <input type="date"
                                               class="form-control form-input-box"
                                               style="width: 200px"
                                               formControlName="DataNascConjuge"
                                        />
                                    </div>
                                </div>
            
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Tem Filhos:</span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('temFilhos', true)"
                                            [checked]="formGroupEtapa2.get('temFilhos').value === true"
                                            class="form-input-checkbox"
                                            id="temFilhos"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="temFilhos">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('temFilhos', false)"
                                            [checked]="formGroupEtapa2.get('temFilhos').value === false"
                                            class="form-input-checkbox"
                                            id="temFilhosNao"
                                            [disabled]="desativarCampos" type="checkbox"
                                        >
                                        <label class="form-label-texts-helper-check" for="temFilhosNao">Não</label>
                                    </div>
                                </div>
            
            
                                <div *ngIf="formGroupEtapa2.get('temFilhos').value === true" class="col-md-12 mt-2">
                                    <span class="form-label-texts">Digite a data de nascimento do filho</span>
  
            
                                    <div *ngIf="listaFilhos.length > 0" class="mt-2">
                                        <span class="form-label-texts">Filhos cadastrados</span>
            
                                        <div class="row" style="gap: 10px">
                                            <div *ngFor="let filho of listaFilhos" class="col-3">
                                                <div class="row">
            
                                                    <div class="d-flex justify-content-center align-items-center"
                                                         style="gap: 10px;">
                                                        <input
                                                            [value]="formatarDataNascimento(filho.value)"
                                                            class="form-control form-input-box mb-0"
                                                            type="text"
                                                            [disabled]="desativarCampos"
                                                        >
            
                                                        <div (click)="deletarFilho(filho.id)"
                                                             class="bg-danger d rounded d-flex justify-content-center align-items-center p-1 btn btn-danger">
                                                            <img alt="" src="../../../../../assets/svg/delete.svg">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            
            
                                <div class="col-4 mt-4">
                                    <div>
                                        <span class="form-label-texts">Está fazendo alguma dieta?</span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('FazendoDieta', true)"
                                            [checked]="formGroupEtapa2.get('FazendoDieta').value === true"
                                            class="form-input-checkbox"
                                            id="FazendoDietaSim" type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="FazendoDietaSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('FazendoDieta', false)"
                                            [checked]="formGroupEtapa2.get('FazendoDieta').value === false"
                                            class="form-input-checkbox"
                                            id="FazendoDietaNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="FazendoDietaNao">Não</label>
                                    </div>
            
                                </div>
                                <div class="col-8 mt-4">
                                    <div>
                                        <span class="form-label-texts">Tem algum acompanhamento por nutricionista?</span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TemNutricionista', true)"
                                            [checked]="formGroupEtapa2.get('TemNutricionista').value === true"
                                            class="form-input-checkbox"
                                            id="TemNutricionistaSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
            
                                        <label class="form-label-texts-helper-check" for="TemNutricionistaSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TemNutricionista', false)"
                                            [checked]="formGroupEtapa2.get('TemNutricionista').value === false"
                                            class="form-input-checkbox"
                                            id="TemNutricionistaNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="TemNutricionistaNao">Não</label>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-4">
                                    <span class="form-label-texts">Descreva sua Dieta</span>
                                    <textarea class="form-control form-input-box" formControlName="DescricaoDieta"
                                              id="DescricaoDieta"
                                              name="DescricaoDieta" type="DescricaoDieta"></textarea>
            
                                    <span class="form-label-texts-helper">Descreva sua dieta atual, se não estiver fazendo nenhuma
                                        dieta, descreva sua dieta habitual.</span>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <p class="form-label-texts-helper">
                                        Este questionário tem objetivo de identificar a necessidade de avaliação clínica e médica
                                        antes do início da atividade física. Caso você marque um SIM, é fortemente sugerida a
                                        realização da avaliação clínica e médica. Contudo, qualquer pessoa pode participar de uma
                                        atividade física de esforço moderado, respeitando as restrições médicas.<br/>
                                        O PAR-Q foi elaborado para auxiliar você a se auto-ajudar. Os exercícios praticados
                                        regularmente estão associados a muitos benefícios de saúde. Completar o PAR-Q representa o
                                        primeiro passo importante a ser tomado, principalmente se você está interessado em incluir a
                                        atividade física com maior freqüência e regularidade no seu dia a dia. O bom senso é o seu
                                        melhor guia ao responder estas questões. Por favor, leia atentamente cada questão e marque
                                        SIM ou NÃO.
                                    </p>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Alguma vez seu médico disse que você possui algum problema
                                            cardíaco e recomendou que você só praticasse atividade física sob prescrição médica?
                                        </span>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('ProblemaCardiaco', true)"
                                            [checked]="formGroupEtapa2.get('ProblemaCardiaco').value === true"
                                            class="form-input-checkbox"
                                            id="ProblemaCardiacoSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="ProblemaCardiacoSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('ProblemaCardiaco', false)"
                                            [checked]="formGroupEtapa2.get('ProblemaCardiaco').value === false"
                                            class="form-input-checkbox"
                                            id="ProblemaCardiacoNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check" for="ProblemaCardiacoNao">Não</label>
                                    </div>
                                </div>
            
            
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Você sente dor no tórax quando pratica uma atividade física?
                                        </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('DorToraxAtivFisica', true)"
                                            [checked]="formGroupEtapa2.get('DorToraxAtivFisica').value === true"
                                            class="form-input-checkbox"
                                            id="DorToraxAtivFisicaSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="DorToraxAtivFisicaSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('DorToraxAtivFisica', false)"
                                            [checked]="formGroupEtapa2.get('DorToraxAtivFisica').value === false"
                                            class="form-input-checkbox"
                                            id="DorToraxAtivFisicaNao"
                                            [disabled]="desativarCampos"
                                            type="checkbox"
                                        >
                                        <label class="form-label-texts-helper-check" for="DorToraxAtivFisicaNao">Não</label>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> No último mês você sentiu dor torácica quando não estava
                                            praticando atividade física? </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('UltimoMesDorTorax', true)"
                                            [checked]="formGroupEtapa2.get('UltimoMesDorTorax').value === true"
                                            class="form-input-checkbox"
                                            id="UltimoMesDorToraxSim" type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="UltimoMesDorToraxSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('UltimoMesDorTorax', false)"
                                            [checked]="formGroupEtapa2.get('UltimoMesDorTorax').value === false"
                                            class="form-input-checkbox"
                                            id="UltimoMesDorToraxNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="UltimoMesDorToraxNao">Não</label>
                                    </div>
            
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Você perdeu o equilíbrio em virtude de tonturas ou perdeu a
                                            consciência quando estava praticando atividade física? </span>
                                    </div>
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('PerdeuEquilibrio', true)"
                                            [checked]="formGroupEtapa2.get('PerdeuEquilibrio').value === true"
                                            class="form-input-checkbox"
                                            id="PerdeuEquilibrioSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="PerdeuEquilibrioSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('PerdeuEquilibrio', false)"
                                            [checked]="formGroupEtapa2.get('PerdeuEquilibrio').value === false"
                                            class="form-input-checkbox"
                                            id="PerdeuEquilibrioNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="PerdeuEquilibrioNao">Não</label>
                                    </div>
            
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Você tem algum problema ósseo ou articular que poderia ser
                                            agravado com a prática de atividades físicas? </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('ProblemaOsseo', true)"
                                            [checked]="formGroupEtapa2.get('ProblemaOsseo').value === true"
                                            class="form-input-checkbox"
                                            id="ProblemaOsseoSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="ProblemaOsseoSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('ProblemaOsseo', false)"
                                            [checked]="formGroupEtapa2.get('ProblemaOsseo').value === false"
                                            class="form-input-checkbox"
                                            id="ProblemaOsseoNao"
                                            [disabled]="desativarCampos"
                                            type="checkbox"
                                        >
                                        <label class="form-label-texts-helper-check" for="ProblemaOsseoNao">Não</label>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Seu médico já recomendou o uso de medicamentos para controle
                                            da sua pressão arterial ou condição cardiovascular? </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('RemedioPressao', true)"
                                            [checked]="formGroupEtapa2.get('RemedioPressao').value === true"
                                            class="form-input-checkbox"
                                            id="RemedioPressaoSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="RemedioPressaoSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('RemedioPressao', false)"
                                            [checked]="formGroupEtapa2.get('RemedioPressao').value === false"
                                            class="form-input-checkbox"
                                            id="RemedioPressaoNao"
                                            [disabled]="desativarCampos"
                                            type="checkbox"
            
                                        >
                                        <label class="form-label-texts-helper-check" for="RemedioPressaoNao">Não</label>
                                    </div>
            
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Você tem conhecimento de alguma outra razão física que o
                                            impeça de participar de atividades físicas? </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('FisicoImpedeAtivFisica', true)"
                                            [checked]="formGroupEtapa2.get('FisicoImpedeAtivFisica').value === true"
                                            class="form-input-checkbox"
                                            id="FisicoImpedeAtivFisicaSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="FisicoImpedeAtivFisicaSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('FisicoImpedeAtivFisica', false)"
                                            [checked]="formGroupEtapa2.get('FisicoImpedeAtivFisica').value === false"
                                            class="form-input-checkbox"
                                            id="FisicoImpedeAtivFisicaNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="FisicoImpedeAtivFisicaNao">Não</label>
                                    </div>
            
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Realizou teste de esforço com eletrocardiograma com o
                                            cardiologista no último ano? </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TesteEsforco', true)"
                                            [checked]="formGroupEtapa2.get('TesteEsforco').value === true"
                                            class="form-input-checkbox"
                                            id="TesteEsforcoSim"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="TesteEsforcoSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('TesteEsforco', false)"
                                            [checked]="formGroupEtapa2.get('TesteEsforco').value === false"
                                            class="form-input-checkbox"
                                            id="TesteEsforcoNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="TesteEsforcoNao">Não</label>
                                    </div>
            
            
                                </div>
                                <div class="col-md-12 mt-1">
                                    <p class="form-label-texts-helper">
                                        Este instrumento é apenas uma ferramenta na tentativa da identificação dos riscos com a
                                        prática da atividade física e não é infalível.<br/>
                                        Instituições como o Colégio Americano de Medicina Desportiva e a Sociedade Brasileira de
                                        Cardiologia, recomendam a realização de exames complementares como o teste de esforço, e o
                                        exame sangüíneo.<br/>
                                        Procedimentos recomendados principalmente para indivíduos sintomáticos, indivíduos com
                                        doenças conhecidas como a hipertensão arterial, a diabetes, a obesidade, as doenças
                                        cardiovasculares ou para indivíduos acima dos trinta e cinco anos.
                                    </p>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts">
                                            Nível de atividade física:
                                        </span>
                                    </div>
                                    <div class="col-md-12 mt-1">
                                        <div class="mt-2 d-flex align-items-center">
                                            <input
                                                (click)="toggleCheckboxWidthValue('NivelAtividadeFisica', 1)"
                                                [checked]="formGroupEtapa2.get('NivelAtividadeFisica').value === 1"
                                                class="form-input-checkbox"
                                                id="sedentario"
                                                name="sedentario"
                                                type="checkbox"
                                                [disabled]="desativarCampos"
                                            >
                                            <label class="form-label-texts-helper-check" for="sedentario"> Não realiza nenhum tipo
                                                de atividade física: Sedentário</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-1">
                                        <div class="mt-2 d-flex align-items-center">
                                            <input
                                                (click)="toggleCheckboxWidthValue('NivelAtividadeFisica', 2)"
                                                [checked]="formGroupEtapa2.get('NivelAtividadeFisica').value === 2"
                                                class="form-input-checkbox"
                                                type="checkbox"
                                                [disabled]="desativarCampos"
                                            >
                                            <label class="form-label-texts-helper-check" for="">Exercita de forma regular 3-6 dias
                                                na semana: Ativo</label>
                                        </div>
                                    </div>
                                    <div class="col-md-12 mt-1">
                                        <div class="mt-2 d-flex align-items-center">
                                            <input
                                                (click)="toggleCheckboxWidthValue('NivelAtividadeFisica', 3)"
                                                [checked]="formGroupEtapa2.get('NivelAtividadeFisica').value === 3"
                                                class="form-input-checkbox"
                                                id="NivelAtividadeFisica3"
                                                type="checkbox"
                                                [disabled]="desativarCampos"
                                            >
            
                                            <label class="form-label-texts-helper-check" for="NivelAtividadeFisica3">Realiza de 5 a
                                                7 vezes na semana treinamento de alta
                                                intensidade de mais de 45' ou moderado acima de 90': Atleta</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-1">
                                    <div>
                                        <span class="form-label-texts">Tabagista?</span>
                                    </div>
            
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('Tabagista', true)"
                                            [checked]="formGroupEtapa2.get('Tabagista').value === true"
                                            class="form-input-checkbox"
                                            id="TabagistaSim" type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
            
                                        <label class="form-label-texts-helper-check" for="TabagistaSim">Sim</label>
                                    </div>
                                    <div class="form-check form-check-inline">
                                        <input
                                            (click)="toggleCheckbox('Tabagista', false)"
                                            [checked]="formGroupEtapa2.get('Tabagista').value === false"
                                            class="form-input-checkbox"
                                            id="TabagistaNao"
                                            type="checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check" for="TabagistaNao">Não</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Quantos cigarros por dia:</span>
                                        <input
                                            [disabled]="formGroupEtapa2.get('Tabagista').value === false"
                                            class="form-input-box mx-2"
                                            formControlName="QtdCigarros"
                                            id="QtdCigarros"
                                            name="QtdCigarros"
                                            type="number"
                                        />
                                    </div>
                                </div>
             
            
                           
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 3" class="p-4 scrollable-content">
                <div class="p-4">
                    <div class="row vertical-content">
                        <p class="form-title p-3" style="font-size: 18px;">
                            Método SAFE® - Check-up SAFE 
                        </p>
                        <p class="form-title p-3" style="font-size: 18px;">
                            Informações sobre saúde

                        </p>
                        <form class="form-form p-3" [formGroup]="formGroupEtapa3">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span><span class="form-label-texts"> Nome, telefone e e-mail do Médico:</span><span
                                        class="form-label-texts-helper"><br/> No espaço abaixo, informe o nome do médico, telefone e e-mail (incluir mais de um médico, se for o caso) </span></span>
                                    <input
                                        formControlName="DadosMedico"
                                        name="DadosMedico"
                                        id="DadosMedico"
                                        class="form-control form-input-box"
                                    />
                                </div>
            
                                <div class="col-md-12 mt-4 row">
                                    <div class="col-md-12 mt-4">
                                        <span><span class="form-label-texts"> Enfermidades pré-existentes: </span><span
                                            class="form-label-texts-helper"><br/> Informar mais de uma, se for o caso </span></span>
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="HipArtSist"
                                                name="HipArtSist"
                                                id="HipArtSist"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="HipArtSist"> Hipertensão arterial
                                                sistêmica</label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
            
                                        <input
                                            formControlName="HipArtSistMeses"
                                            name="HipArtSistMeses"
                                            id="HipArtSistMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Lupos"
                                                name="Lupos"
                                                id="Lupos"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Lupos"> Lúpus eritematoso sistêmico
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="LuposMeses"
                                            name="LuposMeses"
                                            id="LuposMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Hipotireoidismo"
                                                name="Hipotireoidismo"
                                                id="Hipotireoidismo"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Hipotireoidismo">
                                                Hipotireoidismo </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="HipotireoidismoMeses"
                                            name="HipotireoidismoMeses"
                                            id="HipotireoidismoMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Diabetes"
                                                name="Diabetes"
                                                id="Diabetes"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Diabetes"> Diabetes </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="DiabetesMeses"
                                            name="DiabetesMeses"
                                            id="DiabetesMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="SindromeSjogren"
                                                name="SindromeSjogren"
                                                id="SindromeSjogren"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="SindromeSjogren"> Síndrome de Sjogren
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="SindromeSjogrenMeses"
                                            name="SindromeSjogrenMeses"
                                            id="SindromeSjogrenMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="NefriteLupica"
                                                name="NefriteLupica"
                                                id="NefriteLupica"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="NefriteLupica"> Nefrite Lúpica
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="NefriteLupicaMeses"
                                            name="NefriteLupicaMeses"
                                            id="NefriteLupicaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Dislipidemia"
                                                name="Dislipidemia"
                                                id="Dislipidemia"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Dislipidemia"> Dislipidemia </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="DislipidemiaMeses"
                                            name="DislipidemiaMeses"
                                            id="DislipidemiaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="ArtriteReumatoide"
                                                name="ArtriteReumatoide"
                                                id="ArtriteReumatoide"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="ArtriteReumatoide"> Artrite reumatoide
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="ArtriteReumatoideMeses"
                                            name="ArtriteReumatoideMeses"
                                            id="ArtriteReumatoideMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Osteoartrite"
                                                name="Osteoartrite"
                                                id="Osteoartrite"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Osteoartrite"> Osteoartrite </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="OsteoartriteMeses"
                                            name="OsteoartriteMeses"
                                            id="OsteoartriteMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Osteoporose"
                                                name="Osteoporose"
                                                id="Osteoporose"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Osteoporose"> Osteoporose </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="OsteoporoseMeses"
                                            name="OsteoporoseMeses"
                                            id="OsteoporoseMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Neoplasia"
                                                name="Neoplasia"
                                                id="Neoplasia"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Neoplasia"> Neoplasia </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="NeoplasiaMeses"
                                            name="NeoplasiaMeses"
                                            id="NeoplasiaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Osteopenia"
                                                name="Osteopenia"
                                                id="Osteopenia"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Osteopenia"> Osteopenia </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="OsteopeniaMeses"
                                            name="OsteopeniaMeses"
                                            id="OsteopeniaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="NeoplasiaPreExistente"
                                                name="NeoplasiaPreExistente"
                                                id="NeoplasiaPreExistente"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="NeoplasiaPreExistente"> Neoplasia
                                                pré-existente
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="NeoplasiaPreExistenteMeses"
                                            name="NeoplasiaPreExistenteMeses"
                                            id="NeoplasiaPreExistenteMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Depressao"
                                                name="Depressao"
                                                id="Depressao"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Depressao"> Depressão </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="DepressaoMeses"
                                            name="DepressaoMeses"
                                            id="DepressaoMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="TranstornoDeAnsiedade"
                                                name="TranstornoDeAnsiedade"
                                                id="TranstornoDeAnsiedade"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="TranstornoDeAnsiedade"> Transtorno de
                                                ansiedade
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="TranstornoDeAnsiedadeMeses"
                                            name="TranstornoDeAnsiedadeMeses"
                                            id="TranstornoDeAnsiedadeMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="CriseDePanico"
                                                name="CriseDePanico"
                                                id="CriseDePanico"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="CriseDePanico"> Crise de pânico
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="CriseDePanicoMeses"
                                            name="CriseDePanicoMeses"
                                            id="CriseDePanicoMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Anorexia"
                                                name="Anorexia"
                                                id="Anorexia"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Anorexia"> Anorexia </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="AnorexiaMeses"
                                            name="AnorexiaMeses"
                                            id="AnorexiaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Bulimia"
                                                name="Bulimia"
                                                id="Bulimia"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Bulimia"> Bulimia </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="BulimiaMeses"
                                            name="BulimiaMeses"
                                            id="BulimiaMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Alcoolismo"
                                                name="Alcoolismo"
                                                id="Alcoolismo"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Alcoolismo"> Alcoolismo </label>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                        <input
                                            formControlName="AlcoolismoMeses"
                                            name="AlcoolismoMeses"
                                            id="AlcoolismoMeses"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                                        <div class="form-check form-check-inline">
                                            <input
                                                formControlName="Covid19"
                                                id="Covid19"
                                                type="checkbox"
                                                class="form-input-checkbox"
                                            />
                                            <label class="form-label-texts-helper-check" for="Covid19"> COVID-19
                                            </label>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4 mt-4 d-flex">
                                        <span class="form-label-texts"> Meses (diagnóstico):</span>
                                            <input
                                                formControlName="conjugueSim"
                                                name="conjugueSim"
                                                id="conjugueSim"
                                                type="number"
                                                class="form-control form-input-box"
                                            />
                                    </div> -->
                                    <div class="col-md-6 mt-4 ">
                                        <span
                                            class="form-label-texts"> Se você marcou COVID-19 informe a data do diagnóstico:</span>
            
            
                                        <div>
            
                                          <input type="date"
                                           class="form-control form-input-box"
                                           style="width: 200px"
                                           formControlName="DataCovid19" />
            
                                        </div>
            
                                    </div>
                                    <div class="col-md-6 mt-4 ">
                                        <span class="form-label-texts">  Se você marcou Osteoartrite ou Osteoporose ou Osteopenia ou Neoplasia informe o Local:  </span>
                                        <input
                                            formControlName="LocalStOsteoartrite"
                                            name="LocalStOsteoartrite"
                                            id="LocalStOsteoartrite"
                                            type="input"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 ">
                                        <span class="form-label-texts">  Caso tenha outra enfermidade favor descrever:  </span>
                                        <input
                                            formControlName="OutraEnfermidade"
                                            name="OutraEnfermidade"
                                            id="OutraEnfermidade"
                                            type="input"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4 ">
                                        <span class="form-label-texts">  Histórico de quedas no último ano:  </span>
                                        <input
                                            formControlName="HistQuedas"
                                            name="HistQuedas"
                                            id="HistQuedas"
                                            type="input"
                                            class="form-control form-input-box"
                                        />
                                    </div>
                                    <div class="col-md-6 mt-4">
                                        <span class="form-label-texts">  Favor informar data de diagnóstico da(s) enfermidade(s) pré-existentes (Ex: osteoporose em janeiro de 2014):  </span>
            
                                        <div>
            
            
                                        <input type="date"
                                        class="form-control form-input-box"
                                        style="width: 200px"
                                        formControlName="DtEnfermidadesPreExistentes"
                                        />
                                        </div>
            
                                    </div>
              
                                </div>
            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 4" class="p-4 scrollable-content">
                <div class="p-4">
                      <p class="form-title p-3" style="font-size: 18px;">
                            Método SAFE® - Check-up SAFE 
                        </p>
                        <p class="form-title p-3" style="font-size: 18px;">
                            Questionário Farmacêutico Preliminar 
                        </p>
                    <div class="row vertical-content">
                        <form class="form-form" [formGroup]="formGroupEtapa4">
                            <div class="col-md-12 row">
                                <div class="col-md-6 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Alergia a medicamentos: </span>
                                    </div>
            
                                    <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                                        <input class="form-input-checkbox" type="radio" formControlName="AlergiaMedicamentos"
                                               [checked]="formGroupEtapa4.get('AlergiaMedicamentos').value === opcao.value"
                                               [value]="opcao.value">
            
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
            
                                <div class="col-md-6 mt-1">
                                    <div *ngIf="formGroupEtapa4.get('AlergiaMedicamentos').value === true">
                                        <span class="form-label-texts"> Se você marcou "Sim", informe qual medicamento:
                                        </span>
                                    </div>
                                    <div class="mt-2" *ngIf="formGroupEtapa4.get('AlergiaMedicamentos').value === true">
                                        <input formControlName="MedicamentosAlergenicos" name="MedicamentosAlergenicos"
                                               id="MedicamentosAlergenicos" type="input" class="form-control form-input-box"/>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Alergias a outros produtos: </span>
                                    </div>
            
            
                                    <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                                        <input class="form-input-checkbox" type="radio" formControlName="AlergiaOutros"
                                               [checked]="formGroupEtapa4.get('AlergiaOutros').value === opcao.value"
                                               [value]="opcao.value">
            
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-1">
                                    <div *ngIf="formGroupEtapa4.get('AlergiaOutros').value === true">
                                        <span class="form-label-texts"> Se você marcou "Sim", informe qual produto:
                                        </span>
                                    </div>
                                    <div class="mt-2" *ngIf="formGroupEtapa4.get('AlergiaOutros').value === true">
                                        <input formControlName="Produtos" name="Produtos" id="Produtos" type="input"
                                               class="form-control form-input-box"/>
                                    </div>
                                </div>
            
                                <div class="col-md-12">
                                    <hr/>
                                </div>
                                <div class="col-12">
                                    <span class="form-label-texts"> Utiliza algum medicamento? Em caso positivo, informe qual(is) medicamento(s), finalidade, dose, frequência e horário: </span>
                                    <div class="form-check form-check-inline" >
                                        <input class="form-input-checkbox" type="radio"  
                                        [checked]="formGroupEtapa4.get('MedicamentosEmUsoSIM').value === true"
                                        [value]="true" disabled>
                                        <label class="form-label-texts-helper-check">Sim</label>

                                        <input class="form-input-checkbox" type="radio" 
                                        style="margin-left: 14px"
                                        [checked]="formGroupEtapa4.get('MedicamentosEmUsoSIM').value === false"
                                        [value]="false" disabled>
                                        <label class="form-label-texts-helper-check">Não</label>


                                    </div>
                                    
                                </div>

                                <!-- <div *ngFor="let medicamento of listaMedicamentos.controls; let i = index" [formGroup]="medicamento">
                                    <h3>Medicamento {{ i + 1 }}</h3>
                                    <p><strong>Produto:</strong> {{ medicamento.get('Produto').value }}</p>
                                    <p><strong>Nome:</strong> {{ medicamento.get('Nome').value }}</p>
                                    <p><strong>Dose:</strong> {{ medicamento.get('Dose').value }}</p>
                                    <p><strong>Periodicidade:</strong> {{ medicamento.get('Periodicidade').value }}</p>
                                  </div> -->


                                <div 
                                 *ngFor="let medicamento of listaMedicamentos.controls; let i = index" [formGroup]="medicamento" class=" row mt-4">
                                           <div class="col-12 col-md-12 col-sm-12">
                                               <span class="form-label-texts">Medicamentos cadastrados</span>
                                          </div>
                                         <div class="col-12 col-md-3 col-sm-6">
                                             <span class="form-label-texts">Produto</span>
                                         <input type="text" class="form-control form-input-box" 
                                          formControlName="Produto" 
                                         disabled />
                                       </div>
                                      <div class="col-12 col-md-3 col-sm-6">
                                            <span class="form-label-texts">Nome Comercial</span>
                                             <input type="text" class="form-control form-input-box" 
                                             formControlName="Nome"  
                                             disabled />
                                      </div>
                                      <div class="col-12 col-md-2 col-sm-6" >
                                        <span class="form-label-texts">Dose</span>
                                        <input type="text" class="form-control form-input-box" 
                                        
                                        formControlName="Dose" disabled />
                                     </div>
                                     <div class="col-12 col-md-2 col-sm-6" >
                                         <span class="form-label-texts">Periodicidade</span>
                                          <input type="text" class="form-control form-input-box"
                                          
                                          formControlName="Periodicidade" disabled />
                                     </div>
                                </div>

                            


                                <div class="col-md-12">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <span class="form-label-texts"> Faz exercício regularmente há mais de 3 meses:
                                    </span>
            
                                    <br>
            
                                    <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                                        <input class="form-input-checkbox" type="radio" formControlName="ExercicioRegular"
                                               [checked]="formGroupEtapa4.get('ExercicioRegular').value === opcao.value"
                                               [value]="opcao.value">
            
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2" *ngIf="formGroupEtapa4.get('ExercicioRegular').value === true">
                                    <span class="form-label-texts"> Em caso positivo. Qual exercício?
                                    </span>
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="Caminhada" name="Caminhada" id="Caminhada" type="checkbox"
                                                       class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check" for="Caminhada">Caminhada</label>
                                            </div>
                                        </div>
            
                                        <div *ngIf="formGroupEtapa4.get('Caminhada').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio"
                                                       formControlName="CaminhadaFrequencia" [value]="value"
                                                       [checked]="formGroupEtapa4.get('CaminhadaFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="CaminhadaIntensidade" type="radio"
                                                       class="form-input-checkbox" [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('CaminhadaIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.value}}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="CaminhadaDuracao" name="CaminhadaDuracao"
                                                       id="CaminhadaDuracao" type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- caminhada -->
            
                                    <!-- corrida -->
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="Corrida" name="Corrida" id="Corrida" type="checkbox"
                                                       class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check" for="Corrida">Corrida</label>
                                            </div>
                                        </div>
            
            
                                        <div *ngIf="formGroupEtapa4.get('Corrida').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio" formControlName="CorridaFrequencia"
                                                       [value]="value"
                                                       [checked]="formGroupEtapa4.get('CorridaFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="CorridaIntensidade" type="radio" class="form-input-checkbox"
                                                       [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('CorridaIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.label }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="CorridaDuracao" name="CorridaDuracao" id="CorridaDuracao"
                                                       type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- corrida -->
            
            
                                    <!-- musculação -->
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="Musculacao" name="Musculacao" id="Musculacao"
                                                       type="checkbox" class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check" for="Musculacao">Musculação</label>
                                            </div>
                                        </div>
            
            
                                        <div *ngIf="formGroupEtapa4.get('Musculacao').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio"
                                                       formControlName="MusculacaoFrequencia" [value]="value"
                                                       [checked]="formGroupEtapa4.get('MusculacaoFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="MusculacaoIntensidade" type="radio"
                                                       class="form-input-checkbox" [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('MusculacaoIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.label }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="MusculacaoDuracao" name="MusculacaoDuracao"
                                                       id="MusculacaoDuracao" type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- musculação -->
            
                                    <!-- Dança -->
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="Danca" name="Danca" id="Danca" type="checkbox"
                                                       class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check" for="Danca">Dança</label>
                                            </div>
                                        </div>
            
            
                                        <div *ngIf="formGroupEtapa4.get('Danca').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio" formControlName="DancaFrequencia"
                                                       [value]="value"
                                                       [checked]="formGroupEtapa4.get('DancaFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="DancaIntensidade" type="radio" class="form-input-checkbox"
                                                       [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('DancaIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.label }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="DancaDuracao" name="DancaDuracao" id="DancaDuracao"
                                                       type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- Dança -->
            
                                    <!-- Hidroginastica -->
            
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="Hidroginastica" name="Hidroginastica" id="Hidroginastica"
                                                       type="checkbox" class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check"
                                                       for="Hidroginastica">Hidroginástica</label>
                                            </div>
                                        </div>
            
            
                                        <div *ngIf="formGroupEtapa4.get('Hidroginastica').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio"
                                                       formControlName="HidroginasticaFrequencia" [value]="value"
                                                       [checked]="formGroupEtapa4.get('HidroginasticaFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="HidroginasticaIntensidade" type="radio"
                                                       class="form-input-checkbox" [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('HidroginasticaIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.label }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="HidroginasticaDuracao" name="HidroginasticaDuracao"
                                                       id="HidroginasticaDuracao" type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- Hidroginastica -->
            
                                    <!-- Outro -->
                                    <div>
                                        <div class="mt-2">
                                            <div class="d-flex align-items-center">
                                                <input formControlName="OutroExercicio" name="OutroExercicio" id="OutroExercicio"
                                                       type="checkbox" class="form-input-checkbox"/>
                                                <label class="form-label-texts-helper-check" for="OutroExercicio">Outro</label>
                                            </div>
                                        </div>
            
            
                                        <div *ngIf="formGroupEtapa4.get('OutroExercicio').value === true">
                                            <span class="form-label-texts"> Frequencia semanal</span>
            
                                            <br>
                                            <div class="form-check form-check-inline" *ngFor="let value of opcoesFrequencia">
                                                <input class="form-input-checkbox" type="radio"
                                                       formControlName="OutroExercicioFrequencia" [value]="value"
                                                       [checked]="formGroupEtapa4.get('OutroExercicioFrequencia').value === value">
            
                                                <label class="form-label-texts-helper-check">
                                                    {{ value }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Intensidade</span>
                                            <br>
            
                                            <div class="form-check form-check-inline" *ngFor="let opcao of opcoesIntensidade">
                                                <input formControlName="OutroExercicioIntensidade" type="radio"
                                                       class="form-input-checkbox" [value]="opcao.value"
                                                       [checked]="formGroupEtapa4.get('OutroExercicioIntensidade').value === opcao.value">
                                                <label class="form-label-texts-helper-check">
                                                    {{ opcao.label }}
                                                </label>
                                            </div>
            
                                            <br>
                                            <span class="form-label-texts">Duração (meses)</span>
                                            <br>
            
                                            <div class="form-check form-check-inline">
                                                <input formControlName="OutroExercicioDuracao" name="OutroExercicioDuracao"
                                                       id="OutroExercicioDuracao" type="number" class="form-control form-input-box">
                                            </div>
                                            <br>
                                            <br>
                                        </div>
                                    </div>
                                    <!-- Outro -->
                                </div>
                            </div>
            
                            <div class="col-md-12 row" *ngIf="formGroupEtapa4.get('ExercicioRegular').value === true">
                                <div class="col-md-12 mt-2">
                                    <span class="form-label-texts"> Informar se pratica "outro" tipo de exercício:
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="DescOutroExercicio" name="DescOutroExercicio"
                                                  id="DescOutroExercicio" type="input" class="form-control form-input-box w-100">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <span class="form-label-texts"> Quantas vezes por semana?
                                    </span>
                                    <div class="mt-2">
                                        <div class="form-check form-check-inline" *ngFor="let opcao of [
                                            { label: '2 vezes', value: 0 },
                                            { label: '3 vezes', value: 1 },
                                            { label: '4 vezes', value: 2 },
                                            { label: '5 vezes ou mais', value: 3 },
                                        ]">
                                            <input class="form-input-checkbox" type="radio" formControlName="QtdExercicioSemana"
                                                   [checked]="formGroupEtapa4.get('QtdExercicioSemana').value === opcao.value"
                                                   [value]="opcao.value">
            
                                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-2">
                                    <span class="form-label-texts"> Qual a intensidade?
                                    </span>
                                    <div class="mt-2">
                                        <div class="form-check form-check-inline" *ngFor="let opcao of [
                                        { label: 'Leve (2,5 mets)', value: 0 },
                                        { label: 'Moderada (4,0 mets)', value: 1 },
                                        { label: 'Intensa (6,0 mets)', value: 2 },
                                        { label: 'Muito intenso (8,5 mets)', value: 3 },
                                    ]">
                                            <input class="form-input-checkbox" type="radio" formControlName="Intensidade"
                                                   [checked]="formGroupEtapa4.get('Intensidade').value === opcao.value"
                                                   [value]="opcao.value">
            
                                            <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <span class="form-label-texts"> A duração de cada sessão (tempo)?
                                    </span>
                                    <span class="form-label-texts-helper"> Informe os minutos </span>
                                    <input formControlName="DuracaoSessao" type="number" name="DuracaoSessao" id="DuracaoSessao"
                                           class="form-control form-input-box">
                                </div>
                                <div class="col-md-12">
                                    <span class="form-label-texts"> Somatório semanal em minutos:
                                    </span>
                                    <span class="form-label-texts-helper"> Informe os minutos </span>
                                    <input formControlName="SomaSemanal" type="number" name="SomaSemanal" id="SomaSemanal"
                                           class="form-control form-input-box">
                                </div>
                                <div class="col-md-12">
                                    <hr/>
                                </div>
                            </div>
            
            
                            <div class="center col-md-12 mb-5 mt-4">
                                <h4>Suplementação Alimentar</h4>
                            </div>
                            <div class="col-md-12">
                                <span class="form-label-texts"> Faz uso de suplemento alimentar?
                                </span>
                                <div class="mt-2">
                                    <div class="form-check form-check-inline" *ngFor="let opcao of opcoesCheckboxSimOuNao">
                                        <input
                                            class="form-input-checkbox"
                                            type="radio"
                                            formControlName="SuplementoAlimentar"
                                            [checked]="formGroupEtapa4.get('SuplementoAlimentar').value === opcao.value"
                                            [value]="opcao.value"
                                        >
            
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                            </div>
            
                            <div class="col-md-12" *ngIf="formGroupEtapa4.get('SuplementoAlimentar').value === true">
                                <span class="form-label-texts"> Em caso positivo. Favor especificar qual suplemento alimentar,
                                    dose e frequëncia.
                                </span>
                                <div class="mt-2">
                                    <input
                                        formControlName="DescSuplemento"
                                        name="DescSuplemento"
                                        id="DescSuplemento"
                                        type="input"
                                        class="form-control form-input-box"
                                    >
                                </div>
                            </div>
            
                            <div class="col-md-12">
                                <hr/>
                            </div>
            
                            <div class="center col-md-12 mb-5">
                                <h4>Alimentação Diária</h4>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <span class="form-label-texts"> Café da manhã
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="CafeDaManha" name="CafeDaManha" id="CafeDaManha" type="input"
                                                  class="form-control form-input-box">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <span class="form-label-texts"> Lanche
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="Lanche1" name="Lanche1" id="Lanche1" type="input"
                                                  class="form-control form-input-box">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <span class="form-label-texts"> Almoço
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="Almoco" name="Almoco" id="Almoco" type="input"
                                                  class="form-control form-input-box">
                                    </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <span class="form-label-texts"> Lanche
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="Lanche2" name="Lanche2" id="Lanche2" type="input"
                                                  class="form-control form-input-box">
                                    </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <span class="form-label-texts"> Jantar
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="Jantar" name="Jantar" id="Jantar" type="input"
                                                  class="form-control form-input-box">
                                    </textarea>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <span class="form-label-texts">Ceia
                                    </span>
                                    <div class="mt-2">
                                        <textarea formControlName="Ceia" name="Ceia" id="Ceia" type="input"
                                                  class="form-control form-input-box">
                                        </textarea>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr/>
                            </div>
             
                             
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 5" class="p-4 scrollable-content">
                <div class="p-4">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                        Checklist
                    </p>
                    <div class="row vertical-content">
                        <form class="form-form p-3" [formGroup]="formGroupEtapa5">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span> Usando um escala de 1 a 7 , indique o quanto você concorda com cada item, marcando a opção mais apropriada na linha que depende de cada afirmação. </span>
                                </div>
                                <div class="col-md-6 sm-12 mt-3">
                                    <span
                                        class="form-label-texts"> Na maioria dos aspectos minha vida está próxima do meu ideal. </span>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 1"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 1)"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">1 - Discordo plenamente</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 2"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 2)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">2 - Discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 3"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 3)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 4"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 4)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">4 - Não concordo nem
                                            discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 5"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 5)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 6"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 6)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">6 - Concordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [checked]="formGroupEtapa5.get('ProxIdeal').value == 7"
                                            (click)="toggleCheckboxWidthValue('ProxIdeal', 7)"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">7 - Concordo plenamente</label>
                                    </div>
                                </div>
                                <div class="col-md-6 sm-12 mt-3">
                                    <span class="form-label-texts">As condições da minha vida são excelentes.</span>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 1"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 1)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">1 - Discordo
                                            plenamente</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 2"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 2)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">2 - Discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 3"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 3)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">3 - Discordo um
                                            pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 4"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 4)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">4 - Não concordo nem
                                            discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 5"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 5)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">5 - Concordo um
                                            pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 6"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 6)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">6 - Concordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('VidaExcelente').value == 7"
                                            (click)="toggleCheckboxWidthValue('VidaExcelente', 7)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">7 - Concordo
                                            plenamente</label>
                                    </div>
                                </div>
                                <div class="col-md-6 sm-12 mt-3">
                                    <span class="form-label-texts"> Estou inteiramente satisfeito com minha vida. </span>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 1"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 1)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">1 - Discordo
                                            plenamente</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 2"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 2)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">2 - Discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 3"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 3)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">3 - Discordo um
                                            pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 4"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 4)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">4 - Não concordo nem
                                            discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 5"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 5)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">5 - Concordo um
                                            pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 6"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 6)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">6 - Concordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('SatisfeitoVida').value == 7"
                                            (click)="toggleCheckboxWidthValue('SatisfeitoVida', 7)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">7 - Concordo
                                            plenamente</label>
                                    </div>
                                </div>
                                <div class="col-md-6 sm-12 mt-3">
                                    <span class="form-label-texts"> Até hoje, consegui as coisas mais importantes que desejei na vida. </span>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 1"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 1)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">1 - Discordo
                                            plenamente</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 2"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 2)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">2 - Discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 3"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 3)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 4"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 4)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">4 - Não concordo nem
                                            discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 5"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 5)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 6"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 6)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">6 - Concordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('DesejeiVida').value == 7"
                                            (click)="toggleCheckboxWidthValue('DesejeiVida', 7)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">7 - Concordo
                                            plenamente</label>
                                    </div>
                                </div>
                                <div class="col-md-6 sm-12 mt-3">
                                    <span class="form-label-texts"> Se pudesse viver outra vez, não mudaria nada. </span>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 1"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 1)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">1 - Discordo
                                            plenamente</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 2"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 2)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">2 - Discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 3"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 3)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">3 - Discordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 4"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 4)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">4 - Não concordo nem
                                            discordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 5"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 5)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">5 - Concordo um pouco</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 6"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 6)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
            
                                        >
                                        <label class="form-label-texts-helper-check">6 - Concordo</label>
                                    </div>
                                    <div class="mt-1 d-flex align-items-center">
                                        <input
                                            [checked]="formGroupEtapa5.get('MudariaNada').value == 7"
                                            (click)="toggleCheckboxWidthValue('MudariaNada', 7)"
                                            type="checkbox"
                                            class="form-input-checkbox"
                                            [disabled]="desativarCampos"
                                        >
                                        <label class="form-label-texts-helper-check">7 - Concordo
                                            plenamente</label>
                                    </div>
                                </div>
            
             
            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 6" class="p-4 scrollable-content">
                <div class="p-2">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                        
                        Percepção saúde
                    </p>
                    <div class="row vertical-content"   >
                        <form [formGroup]="formGroupEtapa6" class="form-form">
                            <div class="col-md-12 row" >
                                <div class="col-md-12 mb-4">
                                    <span>Essa etapa é muito interessante, preste atenção nas repostas, pois sempre com o valor 0
                                        sendo considerado o Melhor resultado e o valor 10 sendo considerado o Pior resultado.
                                    </span>
                                    <span class="form-label-texts mt-3"><br/> De acordo com a escala visual analógica (EVA) favor
                                        pontuar de 0 a 10 o que sentiu em média na última semana nos parâmetros abaixo. </span>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Dor: {{ formGroupEtapa6.get('Dor').value }}</span>
                                    </div>
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Dor"
                                                      styleClass="
                                                           {{ formGroupEtapa6.get('Dor').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Dor').value < 8 && formGroupEtapa6.get('Dor').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Dor').value <=3  ? 'p-slider-verde' : '' }}
                                            "></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div *ngIf="formGroupEtapa6.get('Dor').value > 0">
            
                                        <span class="form-label-texts">Citar local(is) onde sente dor:</span>
                                        <input class="form-control form-control-sm" formControlName="LocalDor"
                                               placeholder="Ex: Cabeça, pescoço, ombro, etc..." type="text">
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Tensão/ansiedade: {{ formGroupEtapa6.get('Ansiedade').value
                                            }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Ansiedade"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Ansiedade').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Ansiedade').value < 8 && formGroupEtapa6.get('Ansiedade').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Ansiedade').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Fadiga: {{ formGroupEtapa6.get('Fadiga').value }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Fadiga"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Fadiga').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Fadiga').value < 8 && formGroupEtapa6.get('Fadiga').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Fadiga').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Gratidão (Qual o seu nível de gratidão em relação a sua vida,
                                            família, trabalho, saúde e relacionamentos): {{ formGroupEtapa6.get('Gratidao').value
                                            }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Gratidao"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Gratidao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Gratidao').value < 8 && formGroupEtapa6.get('Gratidao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Gratidao').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Ânimo: {{ formGroupEtapa6.get('Animo').value }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Animo"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Animo').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Animo').value < 8 && formGroupEtapa6.get('Animo').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Animo').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Qualidade do sono: {{ formGroupEtapa6.get('Sono').value
                                            }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Sono"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Sono').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Sono').value < 8 && formGroupEtapa6.get('Sono').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Sono').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Disposição ao acordar: {{
                                            formGroupEtapa6.get('Disposicao').value }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Disposicao"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Disposicao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Disposicao').value < 8 && formGroupEtapa6.get('Disposicao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Disposicao').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Percepção de saúde geral: {{
                                            formGroupEtapa6.get('Percepcao').value }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Percepcao"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Percepcao').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Percepcao').value < 8 && formGroupEtapa6.get('Percepcao').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Percepcao').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Relato da capacidade funcional (atividades do dia a dia): {{
                                            formGroupEtapa6.get('CapacidadeFuncional').value }}</span>
                                    </div>
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="CapacidadeFuncional"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('CapacidadeFuncional').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('CapacidadeFuncional').value < 8 && formGroupEtapa6.get('CapacidadeFuncional').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('CapacidadeFuncional').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
            
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Nível de estresse: {{
                                            formGroupEtapa6.get('Estresse').value }}</span>
                                    </div>
            
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Estresse"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Estresse').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Estresse').value < 8 && formGroupEtapa6.get('Estresse').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Estresse').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-4">
                                    <div>
                                        <span class="form-label-texts">Percepção de felicidade: {{
                                            formGroupEtapa6.get('Felicidade').value }}</span>
                                    </div>
            
            
                                    <div class="mt-2">
                                        <div>
                                            <img alt="" class="imagem-slider" src="../../../../../assets/img/range-slider.png" style="max-width: 100%;">
                                            <p-slider [max]="10" [min]="0" [step]="1" formControlName="Felicidade"
                                                      styleClass="
                                                            {{ formGroupEtapa6.get('Felicidade').value >=8 ? 'p-slider-vermelho' : '' }}
                                                            {{ formGroupEtapa6.get('Felicidade').value < 8 && formGroupEtapa6.get('Felicidade').value >=4 ? 'p-slider-amarelo' : '' }}
                                                            {{ formGroupEtapa6.get('Felicidade').value <=3  ? 'p-slider-verde' : '' }}"
                                            ></p-slider>
                                        </div>
                                    </div>
                                </div>
             
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 7" class="p-4 scrollable-content">
                <div class="p-4">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                        Saúde emocional e saúde funcional 
                    </p>
                    <div class="row vertical-content">
                        <form class="form-form" [formGroup]="formGroupEtapa7">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span class="form-label-texts-helper"> Nós estamos interessados em saber que tipos de atividade
                                        física você realiza no seu cotidiano. As perguntas estão relacionadas ao tempo que cada
                                        pessoa gasta fazendo atividade física na ÚLTIMA SEMANA. As perguntas incluem as atividades,
                                        ou seja, o quanto se movimenta fazendo o seu trabalho (no escritório, em casa ou no jardim)
                                        nos momentos de lazer, praticando esporte ou outros tipos de exercício ou como parte das
                                        suas atividades. Por favor, responda cada questão mesmo que considere que não seja ativo.
                                    </span>
                                </div>
                                <div class="col-md-12">
                                    <span class="form-label-texts-helper"> <br/>Para responder as questões lembre que: - atividades
                                        físicas VIGOROSAS são aquelas que precisam de um grande esforço físico e que fazem respirar
                                        MUITO mais forte que o normal - atividades físicas MODERADAS são aquelas que precisam de
                                        algum esforço físico e que fazem respirar UM POUCO mais forte que o normal Para responder as
                                        perguntas pense somente nas atividades que você realiza por pelo menos 10 minutos contínuos
                                        de cada vez. </span>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Em quantos dias da última semana você caminhou por pelo
                                            menos 10 minutos contínuos em casa ou no trabalho, como forma de transporte para ir de
                                            um lugar para outro, por lazer, por prazer ou como forma de exercício? </span>
                                        <span class="form-label-texts-helper"> <br/> Informe o número de dias por semana. Escolha
                                            "0", caso não tenha feito atividades em nenhum dia da semana. </span>
                                    </div>
                                    <div class="mt-2">
                                        <select formControlName="IpacDiasCaminhando" class="form-control form-input-box"
                                                placeholder="Selecione">
                                            <option value="">Selecione</option>
                                            <option *ngFor="let dia of opcoesDiasDaSemana" [ngValue]="dia"
                                                    selected="{{ formGroupEtapa7.get('IpacDiasCaminhando').value == dia ? 'selected' : '' }}">
                                                {{ dia }}
                                            </option>
            
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Nos dias em que você caminhou por pelo menos 10 minutos
                                            contínuos quanto tempo no total você gastou caminhando por dia? </span>
                                        <span class="form-label-texts-helper"> <br/> Informe o total de horas e minutos. </span>
                                    </div>
                                    <div class="mt-2">
                                        <select formControlName="IpacTempoCaminhando" class="form-control form-input-box">
                                            <option value="">Selecione</option>
                                            <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                    selected="{{ formGroupEtapa7.get('IpacTempoCaminhando').value == opcao.value ? 'selected' : '' }}">
                                                {{ opcao.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Em quantos dias da última semana, você realizou atividades
                                            moderadas por pelo menos 10 minutos contínuos, como por exemplo, pedalar leve na
                                            bicicleta, nadar, dançar, fazer ginástica aeróbica leve, jogar vôlei recreativo,
                                            carregar pesos leves, fazer serviços domésticos na casa, no quintal ou no jardim como
                                            varrer, aspirar, cuidar do jardim, ou qualquer atividade que fez aumentar moderadamente
                                            sua respiração ou batimentos do coração (POR FAVOR NÃO INCLUA CAMINHADA) </span>
                                        <span class="form-label-texts-helper"> <br/>Informe o número de dias por semana. Selecione
                                            "0", caso seja nenhum dia da semana.</span>
                                    </div>
                                    <div class="mt-2">
                                        <select formControlName="IpacDiasAtivModerada" id="IpacDiasAtivModerada"
                                                class="form-control form-input-box" placeholder="Selecione">
            
                                            <option value="">Selecione</option>
                                            <option *ngFor="let dia of opcoesDiasDaSemana" [ngValue]="dia"
                                                    selected="{{ formGroupEtapa7.get('IpacDiasAtivModerada').value == dia ? 'selected' : '' }}">
                                                {{ dia }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
            
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Nos dias em que você fez essas atividades moderadas por pelo
                                            menos 10 minutos contínuos, quanto tempo no total você gastou fazendo essas atividades
                                            por dia? </span>
                                        <span class="form-label-texts-helper"> <br/>Informe o total de horas e minutos</span>
                                    </div>
                                    <div class="mt-2">
                                        <select formControlName="IpacTempoAtivModerada" class="form-control form-input-box">
                                             <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                    selected="{{ formGroupEtapa7.get('IpacTempoAtivModerada').value == opcao.value ? 'selected' : '' }}">
                                                {{ opcao.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
            
            
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Em quantos dias da última semana, você realizou atividades
                                            VIGOROSAS por pelo menos 10 minutos contínuos, como por exemplo, correr, fazer ginástica
                                            aeróbia, jogar futebol, pedalar rápido na bicicleta, jogar basquete, fazer serviços
                                            domésticos pesados em casa, no quintal ou cavoucar no jardim, carregar pesos elevados ou
                                            qualquer atividade que fez aumentar MUITO sua respiração ou batimentos do coração.
                                        </span>
                                        <span class="form-label-texts-helper"> <br/>Informe o número de dias por semana. Selecione
                                            "0", caso seja nenhum dia da semana.</span>
                                    </div>
                                    <div class="mt-2">
                                        <select
                                            formControlName="IpacDiasAtivVigorosa"
                                            class="form-control form-input-box"
                                            placeholder="Selecione"
                                        >
            
                                            <option value="">Selecione</option>
                                            <option *ngFor="let dia of opcoesDiasDaSemana" [ngValue]="dia"
                                                    selected="{{ formGroupEtapa7.get('IpacDiasAtivVigorosa').value == dia ? 'selected' : '' }}">
                                                {{ dia }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
            
            
                                <div class="col-md-12 mt-1">
                                    <div>
                                        <span class="form-label-texts"> Nos dias em que você fez essas atividades vigorosas por pelo
                                            menos 10 minutos contínuos, quanto tempo no total você gastou fazendo essas atividades
                                            por dia? </span>
                                        <span class="form-label-texts-helper"> <br/>Informe o total de horas e minutos</span>
                                    </div>
                                    <div class="mt-2">
                                        <select formControlName="IpacTempoAtivVigorosa" class="form-control form-input-box">
             
                                            <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                    selected="{{ formGroupEtapa7.get('IpacTempoAtivVigorosa').value == opcao.value ? 'selected' : '' }}">
                                                {{ opcao.label }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
            
            
                                <div class="col-md-12 mt-1">
                                    <span class="form-label-texts-helper">
                                        Estas últimas questões são sobre o tempo que você permanece sentado todo dia, no trabalho,
                                        na escola ou faculdade, em casa e durante seu tempo livre. Isto inclui o tempo sentado
                                        estudando, sentado enquanto descansa, fazendo lição de casa visitando um amigo, lendo,
                                        sentado ou deitado assistindo TV. Inclua o tempo gasto sentando durante o transporte em
                                        ônibus, trem, metrô ou carro.
                                    </span>
                                </div>
                                <span class="form-label-texts">
                                    <br/>Quanto tempo no total você gasta sentado durante um dia de semana?
                                </span>
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Trabalho
                                    </span>
            
                                    <select formControlName="IpacTempoTrabalhoDia" class="form-control form-input-box w-100">
            c                            <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTrabalhoDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Transporte
                                    </span>
                                    <select formControlName="IpacTempoTransporteDia" class="form-control form-input-box w-100">
                                         <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTransporteDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Assistindo TV
                                    </span>
                                    <select formControlName="IpacTempoTvDia" class="form-control form-input-box w-100">
             
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTvDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Celular / Computador
                                    </span>
                                    <select formControlName="IpacTempoComputadorDia" class="form-control form-input-box w-100">
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoComputadorDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Leitura
                                    </span>
                                    <select formControlName="IpacTempoLeituraDia" class="form-control form-input-box w-100">
             
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoLeituraDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Descanso
                                    </span>
                                    <select formControlName="IpacTempoDescansoDia" class="form-control form-input-box w-100">
             
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoDescansoDia').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <span class="form-label-texts">
                                    <br/>Quanto tempo no total você gasta sentado durante um final de semana? (total: {{ formGroupEtapa7.get('TotaisTempoSentado').value }}
                                    )
                                </span>
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Trabalho
                                    </span>
                                    <select formControlName="IpacTempoTrabalhoFDS" class="form-control form-input-box w-100">
            
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTrabalhoFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Transporte
                                    </span>
                                    <select formControlName="IpacTempoTransporteFDS" class="form-control form-input-box w-100">
            
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTransporteFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Assistindo TV
            
                                    </span>
            
                                    <select formControlName="IpacTempoTvFDS" class="form-control form-input-box w-100">
            
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoTvFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
            
            
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/> Celular / Computador
            
                                    </span>
            
                                    <select formControlName="IpacTempoComputadorFDS" class="form-control form-input-box w-100">
            
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoComputadorFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
            
            
                                </div>
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Leitura
                                    </span>
            
                                    <select formControlName="IpacTempoLeituraFDS" class="form-control form-input-box w-100">
            
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoLeituraFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
            
            
                                <div class="col-md-6 mt-2">
                                    <span class="form-label-texts">
                                        <br/>Descanso
                                    </span>
            
                                    <select formControlName="IpacTempoDescansoFDS" class="form-control form-input-box w-100">
                                        <option *ngFor="let opcao of opcoesTempo" [ngValue]="opcao.value"
                                                selected="{{ formGroupEtapa7.get('IpacTempoDescansoFDS').value == opcao.value ? 'selected' : '' }}">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
                                </div>
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 8" class="p-4 scrollable-content">
                <div class="p-4">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                       Checklist 2
                    </p>
                    <div class="row vertical-content">
                        <form class="form-form  " [formGroup]="formGroupEtapa8">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span>
                                        Instruções: Esta pesquisa nos dá informações sobre a sua saúde. As informações fornecidas
                                        nos mostrarão como você se sente e o quão bem você é capaz de fazer suas atividades da vida
                                        diária. Responda cada questão marcando a resposta como indicado. Caso você esteja inseguro
                                        em como responder, por favor, tente responder o melhor que puder.
                                    </span>
                                </div>
            
                                <!-- SAUDE -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Em geral, você diria que sua saúde é: </span>
                                    <div class="mt-2 d-flex align-items-center">
                                        <input formControlName="Saude" type="radio" [value]="1" class="form-input-checkbox"
                                            [checked]="formGroupEtapa8.get('Saude').value == 1" />
                                        <label class="form-label-texts-helper-check">Excelente</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input formControlName="Saude" type="radio" [value]="2" class="form-input-checkbox"
                                            [checked]="formGroupEtapa8.get('Saude').value == 2" />
                                        <label class="form-label-texts-helper-check">Muito Boa</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input formControlName="Saude" type="radio" [value]="3" class="form-input-checkbox"
                                            [checked]="formGroupEtapa8.get('Saude').value == 3" />
                                        <label class="form-label-texts-helper-check">Boa</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input formControlName="Saude" type="radio" [value]="4" class="form-input-checkbox"
                                            [checked]="formGroupEtapa8.get('Saude').value == 4" />
                                        <label class="form-label-texts-helper-check">Ruim</label>
                                    </div>
                                    <div class="d-flex align-items-center">
                                        <input formControlName="Saude" type="radio" class="form-input-checkbox" [value]="5"
                                            [checked]="formGroupEtapa8.get('Saude').value == 5" />
                                        <label class="form-label-texts-helper-check">Muito Ruim</label>
                                    </div>
                                </div>
                                <!-- SAUDE -->
            
                                <!-- SAUDE COMPARADA -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Comparada há um ano atrás, como você classifica sua saúde em
                                        geral, agora? </span>
                                    <div class="mt-2 d-flex align-items-center">
                                        <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="1"
                                            [checked]="formGroupEtapa8.get('SaudeComparada').value == 1" />
                                        <label class="form-label-texts-helper-check">Muito melhor agora do que há um ano
                                            atrás</label>
                                    </div>
            
                                    <div class="d-flex align-items-center">
                                        <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="2"
                                            [checked]="formGroupEtapa8.get('SaudeComparada').value == 2" />
                                        <label class="form-label-texts-helper-check"> Um pouco melhor agora do que há um ano
                                            atrás</label>
                                    </div>
            
                                    <div class="d-flex align-items-center">
                                        <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="3"
                                            [checked]="formGroupEtapa8.get('SaudeComparada').value == 3" />
                                        <label class="form-label-texts-helper-check"> Quase a mesma de um ano atrás</label>
                                    </div>
            
                                    <div class="d-flex align-items-center">
                                        <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="4"
                                            [checked]="formGroupEtapa8.get('SaudeComparada').value == 4" />
                                        <label class="form-label-texts-helper-check"> Um pouco pior agora do que um ano
                                            atrás</label>
                                    </div>
            
                                    <div class="d-flex align-items-center">
                                        <input formControlName="SaudeComparada" class="form-input-checkbox" type="radio" [value]="5"
                                            [checked]="formGroupEtapa8.get('SaudeComparada').value == 5" />
                                        <label class="form-label-texts-helper-check"> Muito pior agora do que há um ano
                                            atrás</label>
                                    </div>
                                </div>
                                <!-- SAUDE COMPARADA -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper"> Os seguintes itens são sobre atividades que você poderia
                                        fazer atualmente durante um dia comum. Devido a sua saúde, você tem dificuldades para fazer
                                        essas atividades? Neste caso, quanto? </span>
                                </div>
            
                                <!-- AtivVigorosas -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"><br />Atividades vigorosas, que exigem muito esforço, tais como
                                        correr, levantar objetos pesados, participar em esportes árduos </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="AtivVigorosas" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AtivVigorosas').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- AtivVigorosas -->
            
                                <!-- AtivModeradas -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Atividades moderadas, tais como mover uma mesa, passar aspirador
                                        de pó, jogar bola, varrer a casa </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="AtivModeradas" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AtivModeradas').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- AtivModeradas -->
            
                                <!-- COMPRAS -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Levantar ou carregar mantimentos (compras) </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="Compras" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value" [checked]="formGroupEtapa8.get('Compras').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- COMPRAS -->
            
                                <!-- SubirEscadas -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">Subir vários lances de escada </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="SubirEscadas" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('SubirEscadas').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- SubirEscadas -->
            
                                <!-- SubirEscada -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Subir um lance de escada </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="SubirEscada" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('SubirEscada').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- SubirEscada -->
            
                                <!-- Ajoelhar -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Curvar-se, ajoelhar-se ou dobrar-se </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="Ajoelhar" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('Ajoelhar').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- Ajoelhar -->
            
                                <!-- AndarAcimaUmKm -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Andar mais de 1 quilômetro </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="AndarAcimaUmKm" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AndarAcimaUmKm').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- AndarAcimaUmKm -->
            
                                <!-- AndarVariosQuarteiroes -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Andar vários quarteirões (quadras) </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="AndarVariosQuarteiroes" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AndarVariosQuarteiroes').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- AndarVariosQuarteiroes -->
            
                                <!-- AndarUmQuarteirao -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Andar um quarteirão (quadras) </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="AndarUmQuarteirao" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AndarUmQuarteirao').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{opcao.label }} </label>
                                    </div>
                                </div>
                                <!-- AndarUmQuarteirao -->
            
                                <!-- Banho -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Tomar banho ou vestir-se </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesDificuldade">
                                        <input formControlName="Banho" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value" [checked]="formGroupEtapa8.get('Banho').value == opcao.value" />
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <!-- Banho -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper"> Durante as últimas 4 semanas, você teve algum dos
                                        seguintes problemas com o seu trabalho ou com alguma atividade diária regular, como
                                        consequência de sua saúde física? </span>
                                </div>
            
                                <!-- DiminuiuTempoAtiv -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Você diminuiu a quantidade de tempo que se dedicava ao seu
                                        trabalho ou a outras atividades? </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="DiminuiuTempoAtiv" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('DiminuiuTempoAtiv').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
            
                                </div>
                                <!-- DiminuiuTempoAtiv -->
            
                                <!-- MenosTarefas -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Realizou menos tarefas do que você gostaria? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="MenosTarefas" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('MenosTarefas').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
            
                                </div>
                                <!-- MenosTarefas -->
            
                                <!-- NaoTrabalhou -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Esteve limitado no seu tipo de trabalho ou em outras atividades?
                                        (p. ex. necessitou de um esforço extra)? </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="NaoTrabalhou" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('NaoTrabalhou').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
            
                                </div>
                                <!-- NaoTrabalhou -->
            
                                <!-- DificuldadeTrabalho -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Teve dificuldade de fazer seu trabalho ou outras atividades
                                        (exemplo: necessitou de um esforço extra)? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="DificuldadeTrabalho" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('DificuldadeTrabalho').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- DificuldadeTrabalho -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper"> Durante as últimas 4 semanas, você teve algum dos
                                        seguintes problemas com seu trabalho ou outra atividade regular diária, como consequência de
                                        algum problema emocional (como sentir-se deprimido ou ansioso)? </span>
                                </div>
            
                                <!-- QtdTempoTrabalho -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Você diminuiu a quantidade de tempo que se dedicava ao seu
                                        trabalho ou a outras atividades? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="QtdTempoTrabalho" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('QtdTempoTrabalho').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- QtdTempoTrabalho -->
            
                                <!-- MenosTarefasGostaria -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">Realizou menos tarefa do que você gostaria? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                        <input formControlName="MenosTarefasGostaria" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('MenosTarefasGostaria').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- MenosTarefasGostaria -->
            
                                <!-- TarefasCuidado -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Não trabalhou ou não fez qualquer das atividades com tanto
                                        cuidado como geralmente faz? </span>
                                    <div class="mt-2">
                                        <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimOuNao">
                                            <input formControlName="TarefasCuidado" class="form-input-checkbox" type="radio"
                                                [value]="opcao.value"
                                                [checked]="formGroupEtapa8.get('TarefasCuidado').value === opcao.value" />
                                            <label class="form-label-texts-helper-check">
                                                {{ opcao.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <!-- TarefasCuidado -->
            
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts"> Durante as últimas 4 semanas, de que maneira sua saúde física ou
                                        problemas emocionais interferiam nas suas atividades sociais (festas, casa de amigos,
                                        aniversário) normais, em relação à família, vizinhos, amigos ou em grupo? </span>
            
                                    <!-- InterferenciaSocial -->
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                                        <input formControlName="InterferenciaSocial" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('InterferenciaSocial').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                    <!-- InterferenciaSocial -->
            
                                </div>
            
                                <!-- DorCorpo -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanta dor no corpo você teve durante as últimas 4 semanas?
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                                        <input formControlName="DorCorpo" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('DorCorpo').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- DorCorpo -->
            
                                <!-- InterferenciaDorCorpo -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Durante as últimas 4 semanas, quanto a dor interferiu com o seu
                                        trabalho normal (incluindo tanto o trabalho, fora de casa e dentro de casa)? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesGravidade">
                                        <input formControlName="InterferenciaDorCorpo" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('InterferenciaDorCorpo').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- InterferenciaDorCorpo -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper">As perguntas abaixo são para saber como você se sente e
                                        como tudo tem acontecido com você nas últimas quatro semanas. Para cada questão, por favor,
                                        dê uma resposta que mais se aproxime da maneira com você se sente. Em relação às últimas
                                        quatro semanas. </span>
                                </div>
            
                                <!-- Vigor -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">Cheio de vigor, cheio de vontade, cheio de força? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="Vigor" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value" [checked]="formGroupEtapa8.get('Vigor').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
            
                                </div>
                                <!-- Vigor -->
            
                                <!-- TempoNervoso -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido uma pessoa muito nervosa?
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoNervoso" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoNervoso').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoNervoso -->
            
                                <!-- TempoDeprimido -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido tão deprimido que nada pode te
                                        animar? </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoDeprimido" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoDeprimido').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoDeprimido -->
            
                                <!-- TempoCalmo -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Por quanto tempo você tem se sentido calmo ou tranquilo? </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoCalmo" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoCalmo').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoCalmo -->
            
                                <!-- TempoEnergia -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido com muita energia? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoEnergia" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoEnergia').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoEnergia -->
            
                                <!-- TempoAbatido -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido desanimado e abatido? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoAbatido" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoAbatido').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoAbatido -->
            
                                <!-- TempoEsgotado -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido esgotado? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoEsgotado" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoEsgotado').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoEsgotado -->
            
                                <!-- TempoFeliz -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido uma pessoa feliz? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoFeliz" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoFeliz').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoFeliz -->
            
                                <!-- TempoCansado -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quanto tempo você tem se sentido cansado? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoCansado" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoCansado').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoCansado -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts">Durante as últimas 4 semanas, quanto do seu tempo a sua saúde
                                        física ou problemas emocionais interferiam com as suas atividades sociais (como visitar
                                        amigos, parentes, festas etc)? </span>
                                </div>
            
                                <!-- TempoSaudeInterferiu -->
                                <div class="col-md-12 mt-3">
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesTempoCheck8">
                                        <input formControlName="TempoSaudeInterferiu" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('TempoSaudeInterferiu').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- TempoSaudeInterferiu -->
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper"> O quanto verdadeiro ou falso é cada uma das afirmações
                                        para você? </span>
                                </div>
            
                                <!-- AdoecerComparado -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Eu costumo adoecer um pouco mais facilmente que as outras
                                        pessoas </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                                        <input formControlName="AdoecerComparado" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('AdoecerComparado').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- AdoecerComparado -->
            
                                <!-- Saudavel -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Eu sou tão saudável quanto qualquer pessoa que eu conheço
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                                        <input formControlName="Saudavel" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('Saudavel').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- Saudavel -->
            
                                <!-- SaudePiorar -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Eu acho que a minha saúde vai piorar </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                                        <input formControlName="SaudePiorar" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('SaudePiorar').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                                <!-- SaudePiorar -->
            
                                <!-- SaudeExcelente -->
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Minha saúde é excelente </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidade">
                                        <input formControlName="SaudeExcelente" class="form-input-checkbox" type="radio"
                                            [value]="opcao.value"
                                            [checked]="formGroupEtapa8.get('SaudeExcelente').value === opcao.value" />
                                        <label class="form-label-texts-helper-check">
                                            {{ opcao.label }}
                                        </label>
                                    </div>
                                </div>
                         
             
                             
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 9" class="p-4 scrollable-content">
                <div class="p-4">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                     Sono
                    </p>
                    <div class="row vertical-content">
                        <form class="form-form" [formGroup]="formGroupEtapa9">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span>
                                        Qual a probabilidade de você cochilar ou dormir, e não apenas se sentir cansado, nas
                                        seguintes situações? Considere o modo de vida que você tem levado recentemente. Mesmo que
                                        você não tenha feito algumas destas coisas recentemente, tente imaginar como elas o
                                        afetariam.
                                        <br/> Escolha o número mais apropriado para responder cada questão.
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Sentado e lendo: </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="SentadoLendo" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('SentadoLendo').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Assistindo TV: </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="AssistindoTv" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('AssistindoTv').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Sentado, quieto, em lugar público (por exemplo, no teatro,
                                        reunião ou palestra): </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="QuietoLugarPublico" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('QuietoLugarPublico').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Andando de carro por uma hora sem parar, como passageiro:
                                    </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="AndandoCarro" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('AndandoCarro').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Sentado quieto após o almoço sem bebida alcoólica: </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="QuietoAlmoco" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('QuietoAlmoco').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Em um carro, parado no trânsito por alguns minutos: </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="ParadoCarro" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('ParadoCarro').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Sentado e conversando com alguém: </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="SentadoConversando" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('SentadoConversando').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Deitado para descansar a tarde quando as circunstâncias
                                        permitem: </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesCochilar">
                                        <input formControlName="DeitadoDescanso" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('DeitadoDescanso').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <h4 class="center">
                                        Muito bom, já estamos quase no fim.
                                    </h4>
                                </div>
                                <div class="col-md-12 mt-3 center">
                                    <span class="form-label-texts-helper">
                                        As questões seguintes referem-se às suas crenças pessoais, e o quanto elas afetam a sua
                                        qualidade de vida. As questões dizem respeito à religião, à espiritualidade e outras crenças
                                        que você possa ter. Uma vez mais, elas referem-se às duas últimas semanas
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Suas crenças dão sentido à sua vida: </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesExtremo">
                                        <input formControlName="SentidoVida" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('SentidoVida').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Em que medida você acha que sua vida tem sentido? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesExtremo">
                                        <input formControlName="MedidaSentidoVida" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('MedidaSentidoVida').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Em que medida suas crenças pessoais lhe dão força para enfrentar
                                        dificuldade? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesExtremo">
                                        <input formControlName="ForcaDificuldade" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('ForcaDificuldade').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Em que medida suas crenças pessoais lhe ajudam a entender as
                                        dificuldades da vida? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesExtremo">
                                        <input formControlName="EntenderDificuldades" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EntenderDificuldades').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Tem religião?* : </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimNao">
                                        <input formControlName="TemReligiao" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('TemReligiao').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
            
                                <div class="col-md-12" *ngIf="formGroupEtapa9.get('TemReligiao').value === true">
            
                                    <span class="form-label-texts"> Qual o religião?: </span>
            
                                    <input formControlName="Religiao" id="Religiao"
                                           class="form-input-box form-input-box-sm mx-2"
                                    />
                                </div>
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts"> Frequenta algum local semanalmente para realizar suas orações ou
                                        preces* : </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimNao">
                                        <input formControlName="FrequentaSemanalmente" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('FrequentaSemanalmente').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
            
                                <div class="col-md-12" *ngIf="formGroupEtapa9.get('FrequentaSemanalmente').value === true">
                                    <span class="form-label-texts"> Qual o local?: </span>
            
                                    <input formControlName="LocalFrequenta" id="LocalFrequenta"
                                           class="form-input-box form-input-box-sm mx-2"
                                    />
                                </div>
            
                                <div class="col-md-12" *ngIf="formGroupEtapa9.get('FrequentaSemanalmente').value === true">
                                    <span class="form-label-texts">Quantas vezes? : </span>
            
                                    <input formControlName="FrequentaVezes" id="FrequentaVezes"
                                           class="form-input-box form-input-box-sm mx-2" type="number"
                                    />
                                </div>
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Com que frequência você vai a uma igreja, templo ou outro
                                        encontro religioso? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesFrequenciaSemanal">
                                        <input formControlName="EspiritualidadeFrequenciaTemplo" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EspiritualidadeFrequenciaTemplo').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
            
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Com que frequência você dedica o seu tempo a atividades
                                        religiosas individuais, como preces, rezas, meditações, leitura da bíblia ou de outros
                                        textos religiosos? </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesFrequenciaDiaria">
                                        <input formControlName="EspiritualidadeLeituraFrequencia" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EspiritualidadeLeituraFrequencia').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
            
            
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts-helper">
                                        A seção seguinte contém três frases a respeito de crenças ou experiências religiosas. Por
                                        favor, anote o quanto cada frase se aplica a você.
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Em minha vida, eu sinto a presença de Deus (ou do Espírito Santo).
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidadeCheck9">
                                        <input formControlName="EspiritualidadePresencaDeus" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EspiritualidadePresencaDeus').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
            
                                </div>
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        As minhas crenças religiosas estão realmente por trás de toda a minha maneira de viver.
                                    </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidadeCheck9">
                                        <input formControlName="EspiritualidadeCrencasReligiosas" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EspiritualidadeCrencasReligiosas').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
                                </div>
            
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Eu me esforço muito para viver a minha religião em todos os aspectos da vida. </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesVeracidadeCheck9">
                                        <input formControlName="EspiritualidadeEsforcoViver" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa9.get('EspiritualidadeEsforcoViver').value === opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }} </label>
                                    </div>
            
                                </div>
            
                            
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div *ngIf="stepperSelected === 10" class="p-4 scrollable-content">
                <div class="p-4">
                    <p class="form-title p-3" style="font-size: 18px;">
                        Método SAFE® - Check-up SAFE 
                    </p>
                    <p class="form-title p-3" style="font-size: 18px;">
                     Reta Final
                    </p>
                    <div class="row vertical-content">
                        <form class="form-form " [formGroup]="formGroupEtapa10">
                            <div class="col-md-12 row">
                                <div class="col-md-12">
                                    <span>
                                        Usando como guia a escala apresentada a seguir, marque a opção ao lado de cada afirmativa,
                                        para indicar o quanto você concorda com ela.
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Tenho muito o que agradecer na vida. </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="AgradecerVida" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('AgradecerVida').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Se eu fizesse uma lista de tudo o que tenho a agradecer, seria
                                        uma lista muito longa. </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="ListaMuitoLonga" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('ListaMuitoLonga').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
            
            
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Quando olho para o mundo, não vejo muitos motivos para
                                        agradecer. </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="MotivosAgradecer" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('MotivosAgradecer').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Sou grato(a) a muita gente. </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="MuitaGente" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('MuitaGente').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Com o passar dos anos, cada vez mais me sinto inclinado (a) a
                                        apreciar as pessoas, os acontecimentos e as situações que fazem parte da história da minha
                                        vida. </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="HistoriaVida" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('HistoriaVida').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts"> Pode passar muito tempo ainda, antes que eu me sinta grato (a) a
                                        alguém ou alguma coisa. </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesConcordancia">
                                        <input formControlName="AlguemAlgumaCoisa" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('AlguemAlgumaCoisa').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
            
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <h4 class="center">
                                        Perdão
                                    </h4>
                                </div>
                                <div class="col-md-12 mt-3 center">
                                    <span class="form-label-texts-helper">
                                        Indicar em relações anteriores o quanto você perdoou seu parceiro ou pessoas de
                                        relacionamentos:
                                    </span>
                                </div>
            
                                <div class="col-md-12">
                                    <select formControlName="Perdao" class="form-control form-input-box">
                                        <option value="" selected="selected">Selecione</option>
                                        <option [ngValue]="1"
                                                [selected]="formGroupEtapa10.get('Perdao').value === 1 ? 'selected' : ''">Eu nunca
                                            perdôo
                                        </option>
                                        <option [ngValue]="2"
                                                [selected]="formGroupEtapa10.get('Perdao').value === 2 ? 'selected' : ''">Eu posso
                                            perdoá-lo
                                        </option>
                                        <option [ngValue]="3"
                                                [selected]="formGroupEtapa10.get('Perdao').value === 3 ? 'selected' : ''">Eu estou
                                            tentando perdoá-lo
                                        </option>
                                        <option [ngValue]="4"
                                                [selected]="formGroupEtapa10.get('Perdao').value === 4 ? 'selected' : ''">Eu
                                            perdôo-lhe
                                        </option>
                                    </select>
                                </div>
            
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts">
                                        Com o aumento da taxa de suicídio no mundo, existe uma preocupação grande da OMS, com isso
                                        estamos pesquisando pensamentos, tentativas e/ou convívio com pessoas com pensamento
                                        suicida, portanto:
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Você já pensou em suicídio?
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimNaoSuicidio">
                                        <input formControlName="SuicidioPensou" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SuicidioPensou').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
            
                                <div class="col-md-12" *ngIf="formGroupEtapa10.get('SuicidioPensou').value === 1">
                                     <span class="form-label-texts">
                                        Nos últimos 12 meses?
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimNaoSuicidio">
                                        <input formControlName="SuicidioUltimoAno" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SuicidioUltimoAno').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-12" *ngIf="formGroupEtapa10.get('SuicidioPensou').value === 1">
            
                                      <span class="form-label-texts">
                                        Você já tentou suicídio?
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSimNaoSuicidio">
                                        <input formControlName="SuicidioTentou" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SuicidioTentou').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
            
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts">
                                        Parabéns, últimas perguntas!
                                    </span>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Novas ideias às vezes me distraem das anteriores.*
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="DistraemAnteriores" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('DistraemAnteriores').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Os contratempos não me desanimam.
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="NaoDesanimam" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('NaoDesanimam').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Fiquei obcecado (a) por uma ideia ou projeto por um curto período de tempo, mas depois perdi
                                        o interesse.*
                                    </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="PerdiInteresse" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('PerdiInteresse').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Sou muito trabalhador(a).
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="MuitoTrabalhador" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('MuitoTrabalhador').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Com frequência me proponho uma meta que depois substituo por outra.*
                                    </span>
            
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="SubstituoOutra" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SubstituoOutra').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Tenho dificuldades em manter a atenção focada em projetos que exigiram mais do que uns
                                        poucos meses para serem concluídos.*
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
                                        <input formControlName="SeremConcluidos" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SeremConcluidos').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Eu termino tudo o que começo.
                                    </span>
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
            
                                        <input formControlName="QueConheco" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('QueConheco').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Sou esforçado(a)
                                    </span>
            
                                    <div class="mt-2 d-flex align-items-center" *ngFor="let opcao of opcoesSemelhancaComigo">
            
                                        <input formControlName="SouEsforcado" type="radio" [value]="opcao.value"
                                               class="form-input-checkbox"
                                               [checked]="formGroupEtapa10.get('SouEsforcado').value == opcao.value"/>
                                        <label class="form-label-texts-helper-check">{{ opcao.label }}</label>
                                    </div>
            
                                </div>
                                <div class="col-md-12 mt-3">
                                    <hr/>
                                </div>
                                <div class="col-md-12 mt-3">
                                    <span class="form-label-texts">
                                        Escala de Bristol
                                    </span>
                                </div>
                                <div class="center col-md-12 mt-3">
                                    <img src="../../../../../assets/img/escala-bristol.png" alt="Escala de Bristol"
                                         class="img-fluid">
                                </div>
                                <div class="col-md-6 mt-3">
                                    <span class="form-label-texts">
                                        Conforme imagem acima, classifique suas fezes.
                                    </span>
            
                                    <select formControlName="EscalaBristol" class="form-control form-input-box">
                                        <option value="" selected="selected">Selecione</option>
                                        <option *ngFor="let opcao of opcoesClassificacaoFezes" [ngValue]="opcao.value"
                                                [selected]="opcao.value === formGroupEtapa10.get('EscalaBristol').value ? 'selected' : ''">
                                            {{ opcao.label }}
                                        </option>
                                    </select>
            
                                </div>
                              
                                
                            </div>
                        </form>
                    </div>
                </div>
            </div>


        </div>


    </div>
</div>
