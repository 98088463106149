import { getHeaders } from '@/utils/getHeaders';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContadoresService {
  private api = environment.endpoint_api

  constructor(private http: HttpClient) {}

  getContadores() {
    const headers = getHeaders()
    return this.http.get(this.api + `/AcessoAdministrador/ConsultarContadores`, { headers })
  }
}
