import {getHeaders} from '@/utils/getHeaders';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {environment} from 'environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AnamneseService {
    private api = environment.endpoint_api
    public carregandoValidarAnamnese: boolean = false

    private _anamneseDadosValidarEtapas: BehaviorSubject<any> = new BehaviorSubject<any>({})
    private _anamnese: BehaviorSubject<any> = new BehaviorSubject<any>({})
    private _loadingAnamnese: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

    ananmese$: Observable<any> = this._anamnese.asObservable()
    ananmeseDadosValidarEtapas$: Observable<any> = this._anamneseDadosValidarEtapas.asObservable()
    loadingAnamnse$: Observable<any> = this._loadingAnamnese.asObservable()

    constructor(private http: HttpClient) {
    }

    getAnamneseDadosValidarEtapas() {
        return this._anamneseDadosValidarEtapas.getValue()
    }

    setAnamneseDadosValidarEtapas(newValue: any) {
        return this._anamneseDadosValidarEtapas.next(newValue)
    }

    getAnamnese() {
        return this._anamnese.getValue()
    }

    setAnamnese(newAnamnese: any) {
        this._anamnese.next(newAnamnese)
    }

    getAnamneseRequest(hashAnamnese: string) {
        const headers = getHeaders();
        this._loadingAnamnese.next(true);
    
        this.http.get(this.api + "/Anamnese/ObterPorHash/" + hashAnamnese, { headers }).subscribe({
            next: (response) => {
                this._anamnese.next(response);
                this._loadingAnamnese.next(false);
            },
            error: (error) => {
                this._loadingAnamnese.next(false);
                console.error('Erro ao obter anamnese:', error);
            }
        });
    }

    getObterLinkAvaliacao(hashAnamnese: string) {
        const headers = getHeaders()
        return this.http.get(this.api + "/Anamnese/ObterLinkParaAvaliacao/" + hashAnamnese, {headers})
    }

    //post anamnese/consultar
    postAnamneseConsultar(dataPesquisa: any) {
        const headers = getHeaders()
        return this.http.post(`${this.api}/Anamnese/Consultar`, { ...dataPesquisa }, { headers })
    }

    putDevolverAnamnese(data: any) {
        const headers = getHeaders()
        return this.http.put(`${this.api}/Anamnese/DevolverAnamnese`, { ...data }, { headers })
    }
     

    putAnamnese(dadosAnamneseAtualizados: any) {
        const headers = getHeaders()
        return this.http.put(this.api + "/Anamnese/AtualizarAnamnese", dadosAnamneseAtualizados, {headers})
    }

    comecarAnamnese(hash: string) {
        const headers = getHeaders()

        const body = {
            Hash: hash
        }
        return this.http.put(this.api + "/Anamnese/ComecarAnamnese", body, {headers})
    }

    obterTempoSentado(dadosAnamneseAtualizados: any) {
        const headers = getHeaders()

        return this.http.post(this.api + "/Anamnese/ObterTemposSentado", dadosAnamneseAtualizados, {headers})
    }

    validarAnamnese(dadosAnamneseAtualizados: any) {
        const headers = getHeaders()
        this.carregandoValidarAnamnese = true

        return this.http.put(this.api + "/Anamnese/ValidarEtapas", dadosAnamneseAtualizados, {headers})
    }


    retornarResultadoAnamnese(hashAnamnese: string) {
        const classeContexto = this
        const headers = getHeaders()
        return this.http.get(this.api + "/Checkup/RetornarResultados", {headers, params: {anamnese_hash: hashAnamnese}})
    }

    finalizarAnamnese() {
        const headers = getHeaders()
        return this.http.put(this.api + "/Anamnese/FinalizarAnamnese", this.getAnamnese(), {headers})
    }

    getVisualizarAnamnese(hashAnamnese: string) {
        const headers = getHeaders()
        return this.http.get(this.api + "/Anamnese/ObterPorHash/" + hashAnamnese, {headers})
    }

    getBuscarMedicamentos() {
        const headers = getHeaders()
        return this.http.get(this.api + "/Medicamento/BuscarMedicamentos", {headers})
    }
}
