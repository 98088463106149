import { Component } from '@angular/core';

@Component({
  selector: 'app-graficos-plataforma',
  templateUrl: './graficos-plataforma.component.html',
  styleUrls: ['./graficos-plataforma.component.scss']
})
export class GraficosPlataformaComponent {

}
