import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-usuarios-incluir',
  templateUrl: './usuarios-incluir.html',
  styleUrls: ['./usuarios-incluir.scss']
})
export class UsuariosIncluir {
  public stepperSelected: string = 'Cadastro';
  public loading: boolean = false;
  public steppers = [
    { name: 'Cadastro', isSelected: false },
    /* { name: 'Plano', isSelected: false },
    { name: 'Checkups', isSelected: false }, */
   ];
 
 
  public opcoesSexo = [
    { value: 3, label: 'Não Informar' },
    { value:  2, label: 'Masculino' },
    { value: 1, label: 'Feminino' },
  ];

  public opcoesEstadoCivil = [
    { value: 1, label: 'Solteiro(a)' },
    { value: 2, label: 'Casado(a)' },
    { value: 3, label: 'União Estável' },
    { value: 4, label: 'Divorciado(a)' },
    { value: 5, label: 'Viúvo(a)' },
    { value: 6, label: 'Outros' },
  ];

  public opcoesRaca = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Caboclo(a)' },
    { value: 2, label: 'Mulato(a)' },
    { value: 3, label: 'Cafuzo(a)' },
    { value: 4, label: 'Branco(a)' },
    { value: 5, label: 'Negro(a)' },
    { value: 6, label: 'Índio(a)' }
  ]

  public grauEscolaridade = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Ensino Fundamental' },
    { value: 2, label: 'Ensino Médio' },
    { value: 3, label: 'Ensino Superior' },
    { value: 4, label: 'Pós Graduação' },
    { value: 5, label: 'Doutorado' },
    { value: 6, label: 'Pós Doutorado' }
  ]

  public opcoesPerfil = [
    { value: 1, label: 'Administrador Geral' },
    { value: 2, label: 'Cliente' },
    { value: 3, label: 'Avaliador' },
    { value: 4, label: 'Avaliador/Cliente' },
  ]

  public opcoesCorrespondencia = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]

  public etapa1Data: FormGroup
  public etapa2Data: FormGroup
  public etapa3Data: FormGroup
 

  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private anamneseService: AnamneseService, private apiservice: ApiService) {}

  ngOnInit(): void {
    
    //inicio do form
    this.etapa1Data = new FormGroup({
      AceitaCorrespondencia: new FormControl(''),
      Ativo: new FormControl(true),
      Nome: new FormControl(''),
      Login: new FormControl(''),
      TelefoneCelular: new FormControl(''),
      Telefone: new FormControl(''),
      Email: new FormControl(''),
      DataNascimento: new FormControl(''),
      Ocupacao: new FormControl(''),
      Sexo: new FormControl(''),
      EstadoCivil: new FormControl(''),
      Raca: new FormControl(''),
      GrauEscolaridade: new FormControl(''),
      CEP: new FormControl(''),
      Cidade: new FormControl(''),
      UFEndereco: new FormControl(''),
      Bairro: new FormControl(''),
      Logradouro: new FormControl(''),
      Numero: new FormControl(''),
      PerfilID: new FormControl(''),
      Complemento: new FormControl(''),

    });
  }
 
      
  
  onSelectStepper(stepperSelected: string) {
    this.stepperSelected = stepperSelected;
    
  }

  onSalvar() {
    this.loading = true;
    
    let dadosParaSalvar = {
      ...this.etapa1Data.value,
      DataNascimento: DateTime.fromISO(this.etapa1Data.value.DataNascimento).toISODate(),
      PerfilID: parseInt(this.etapa1Data.value.PerfilID),
      Sexo: parseInt(this.etapa1Data.value.Sexo),
      EstadoCivil: parseInt(this.etapa1Data.value.EstadoCivil),
      Raca: parseInt(this.etapa1Data.value.Raca),
      GrauEscolaridade: parseInt(this.etapa1Data.value.GrauEscolaridade),
      AceitaCorrespondencia: this.etapa1Data.value.AceitaCorrespondencia === 'true' ? true : false,
      Ativo: this.etapa1Data.value.Ativo === 'true' ? true : false,
    }
      

    this.professorService.postIncluirUsuario(dadosParaSalvar).subscribe({ 
      next: (res: any) => {
        this.toastr.success('Usuário salvo com sucesso','Sucesso');
        this.loading = false;
        // window.history.back();
      },
      error: (erro: any) => {
        this.toastr.error(erro?.error?.Message,'Erro ao salvar os dados');
        this.loading = false;
      }
    });

  } 

  onSair() {
    window.history.back();
  }

  buscarEnderecoPorCEP() {
    var cep = this.etapa1Data.value.CEP.replace(/\D/g, '');
    if (cep != "") {
      var validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        this.apiservice.buscarViaCEP(cep).subscribe((dados: any) => {
          this.etapa1Data.patchValue({
            Logradouro: dados.logradouro,
            Bairro: dados.bairro,
            Cidade: dados.localidade,
            UFEndereco: dados.uf
          });
        });
      }
    }
      
  }

  getCurrentDate(): string {
    const today = new Date();
    const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1;
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    return `${today.getFullYear()}-${month}-${day}`;
  }


}

   

