<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">
    <div class="container-fluid bg-transparent">
      <div>
        <div class="w-100 d-flex justify-content-between mt-5">
          <h6 class="home-dash mt-5">CADASTRO</h6>
          <img src="assets/img/logoSafeTextoBranco.png" alt="plat-safe-logo" height="122px" width="154px">
        </div>
        <div class="divider-dash"></div>
      </div>
      <h6 class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe">Checkup</h6>
      <div class="container-tabela mt-5">
        <div class="p-4 scrollable-content">
          <div class="p-4">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">Informações Básicas</h6>
            <div class="loading-container d-flex justify-content-center align-items-center" *ngIf="loadingListaAvaliadores || loadingObterCheckup || loadingListaClientes">
              <div class="spinner-border text-azul-plataforma-safe" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="row vertical-content">
              <form class="form-form p-3" [formGroup]="dataForm">
                <div class="col-md-12 row">
                  <div class="col-md-4 mt-5">
                    <label class="form-label-texts">Avaliador</label>
                   <!--  <select class="form-control form-input-box" formControlName="AvaliadorID">
                      <option *ngFor="let opcao of listaAvaliadores" [ngValue]="opcao.ID" [selected]="opcao.ID == dataForm.get('AvaliadorID').value">
                        {{ opcao.Nome }}
                      </option>
                    </select> -->
                    <ng-select 
                    [items]="listaAvaliadores" 
                    bindLabel="Nome" 
                    bindValue="ID" 
                    class="form-input-box-select"
                    formControlName="AvaliadorID"
                    [virtualScroll]="true"
                    [searchable]="true"
                     >
                  </ng-select>
                  </div>
                  <div class="col-md-4 mt-5">
                    <label class="form-label-texts">Tipo do Check-up</label>
                    <select class="form-control form-input-box" formControlName="Tipo">
                      <option value="1">Individual</option>
                      <option value="2">Grupo</option>
                    </select>
                  </div>
                  <div class="col-md-4 mt-5" *ngIf="dataForm.get('Tipo')?.value == '1'">
                    <label class="form-label-texts">Cliente</label>
                    <ng-select 
                      [items]="listaClientes" 
                      bindLabel="Nome" 
                      bindValue="ID" 
                      class="form-input-box-select"
                      formControlName="ClienteID"
                      [virtualScroll]="true"
                      [searchable]="true"
                       >
                    </ng-select>
                   
                  </div>
                  <div class="col-md-4 mt-5" *ngIf="dataForm.get('Tipo').value == '2'">
                    <label class="form-label-texts">Grupo</label>
                     <ng-select 
                    [items]="listaClientesGrupo" 
                    bindLabel="Nome" 
                    bindValue="ID" 
                    class="form-input-box-select"
                    formControlName="GrupoID"
                    [virtualScroll]="true"
                    [searchable]="true"
                     >
                  </ng-select>
                  </div>
                  <div class="col-md-4 mt-5">
                    <label class="form-label-texts">Presencial</label>
                    <div>
                      <input formControlName="Presencial" type="radio" [value]="true" class="form-input-checkbox" [checked]="dataForm.get('Presencial').value == 'true'" (change)="toggleDateTimeFields(true)" />
                      <label class="form-label-texts-helper-check" for="Presencial">Sim.</label>
                    </div>
                    <div>
                      <input formControlName="Presencial" type="radio" [value]="false" class="form-input-checkbox" [checked]="dataForm.get('Presencial').value == 'false'" (change)="toggleDateTimeFields(false)" />
                      <label class="form-label-texts-helper-check" for="conjugueSim">Não.</label>
                    </div>
                  </div>
                  <div class="col-12 col-md-4 mt-5" *ngIf="showDateTimeFields">
                    <span class="form-label-texts">Data Agendamento</span>
                    <input class="form-control form-input-box mb-0" formControlName="DataAgendamento" type="date" [value]="dataForm.get('DataAgendamento').value | date: 'yyyy-MM-dd'">
                  </div>
                  <div class="col-12 col-md-4 mt-5" *ngIf="showDateTimeFields">
                    <span class="form-label-texts">Hora Agendamento</span>
                    <input class="form-control form-input-box mb-0" formControlName="HoraAgendamento" type="time" [value]="dataForm.get('HoraAgendamento').value | date: 'HH:mm'">
                  </div>
                  <div class="col-md-4 mt-5">
                    <label class="form-label-texts">Bloquear emissão do laudo?</label>
                    <div>
                      <input formControlName="BloquearEmissaoLaudo" type="radio" [value]="true" class="form-input-checkbox" [checked]="dataForm.get('BloquearEmissaoLaudo').value == 'true'" />
                      <label class="form-label-texts-helper-check">Sim.</label>
                    </div>
                    <div>
                      <input formControlName="BloquearEmissaoLaudo" type="radio" [value]="false" class="form-input-checkbox" [checked]="dataForm.get('BloquearEmissaoLaudo').value == 'false'" />
                      <label class="form-label-texts-helper-check">Não.</label>
                    </div>
                  </div>
                  <div class="col-md-4 mt-5">
                    <label class="form-label-texts">Tipo de Laudo</label>
                    <select class="form-control form-input-box" formControlName="TipoLaudo">
                      <option value="1">Mente</option>
                      <option value="2">Parcial</option>
                      <option value="3">Completo</option>
                    </select>
                  </div>
                  <div class="col-md-12 mt-5">
                    <label class="form-label-texts">Observação:</label>
                    <textarea class="form-control form-input-box h-100" formControlName="Observacoes"></textarea>
                  </div>
                  <div class="col-md-12 mt-5 d-flex justify-content-end">
                    <button class="btn btn-secondary mr-3 border-radius-10 btn-md" (click)="onSair()">Sair</button>
                    <button class="btn btn-primary mr-3 border-radius-10 btn-md" (click)="onSalvar()" [disabled]="!dataForm.valid || loadingSalvar">{{ loadingSalvar ? 'Salvando...' : 'Salvar' }}</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  