<section class="home-half" id="home">
    <!-- <div class="bg-overlay"></div> -->
    <div class="home-center">
        <div class="home-desc-center">
            <div class="container">
                <div class="row vertical-content" >
                    <div class="col-lg-12 text-center">
                        <div class="d-flex justify-content-center align-items-center flex-column">
                            <img src="../../../assets/img/logoSafeTextoBranco.png" alt="" class="logo-light" height="auto" width="auto">
                            <div class="spinner-border text-white mt-3" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
