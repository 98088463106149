<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Informações sobre saúde
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">3/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa3">
                <div class="col-md-12 row">
                    <div class="col-md-12">
                        <span><span class="form-label-texts"> Nome, telefone e e-mail do Médico:</span><span
                            class="form-label-texts-helper"><br/> No espaço abaixo, informe o nome do médico, telefone e e-mail (incluir mais de um médico, se for o caso) </span></span>
                        <input
                            formControlName="DadosMedico"
                            name="DadosMedico"
                            id="DadosMedico"
                            class="form-control form-input-box"
                            [ngClass]="{'input-error': campoinvalido === 'DadosMedico'}"
                        />
                    </div>

                    <div class="col-12 mt-4 row">
                        <div class="col-12 mt-4">
                            <span><span class="form-label-texts"> Enfermidades pré-existentes: </span><span
                                class="form-label-texts-helper"><br/> Informar mais de uma, se for o caso </span></span>
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'HipArtSist'}">

                                <input
                                    formControlName="HipArtSist"
                                    name="HipArtSist"
                                    id="HipArtSist"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="HipArtSist"> Hipertensão arterial
                                    sistêmica</label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>

                            <input
                                formControlName="HipArtSistMeses"
                                name="HipArtSistMeses"
                                id="HipArtSistMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'HipArtSistMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div [ngClass]="{'input-error': campoinvalido === 'Lupos'}">

                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="Lupos"
                                    name="Lupos"
                                    id="Lupos"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Lupos"> Lúpus eritematoso sistêmico
                                </label>
                            </div>
                            </div>
                            
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="LuposMeses"
                                name="LuposMeses"
                                id="LuposMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'LuposMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div [ngClass]="{'input-error': campoinvalido === 'Hipotireoidismo'}">

                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="Hipotireoidismo"
                                    name="Hipotireoidismo"
                                    id="Hipotireoidismo"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Hipotireoidismo">
                                    Hipotireoidismo </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="HipotireoidismoMeses"
                                name="HipotireoidismoMeses"
                                id="HipotireoidismoMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'HipotireoidismoMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Diabetes'}">
                                <input
                                    formControlName="Diabetes"
                                    name="Diabetes"
                                    id="Diabetes"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Diabetes"> Diabetes </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="DiabetesMeses"
                                name="DiabetesMeses"
                                id="DiabetesMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'DiabetesMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div [ngClass]="{'input-error': campoinvalido === 'SindromeSjogren'}">
                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="SindromeSjogren"
                                    name="SindromeSjogren"
                                    id="SindromeSjogren"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="SindromeSjogren"> Síndrome de Sjogren
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="SindromeSjogrenMeses"
                                name="SindromeSjogrenMeses"
                                id="SindromeSjogrenMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'SindromeSjogrenMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'NefriteLupica'}">

                                <input
                                    formControlName="NefriteLupica"
                                    name="NefriteLupica"
                                    id="NefriteLupica"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="NefriteLupica"> Nefrite Lúpica
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="NefriteLupicaMeses"
                                name="NefriteLupicaMeses"
                                id="NefriteLupicaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'NefriteLupicaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <input
                                    formControlName="Dislipidemia"
                                    name="Dislipidemia"
                                    id="Dislipidemia"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Dislipidemia"> Dislipidemia </label>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="DislipidemiaMeses"
                                name="DislipidemiaMeses"
                                id="DislipidemiaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'DislipidemiaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'ArtriteReumatoide'}">
                                <input
                                    formControlName="ArtriteReumatoide"
                                    name="ArtriteReumatoide"
                                    id="ArtriteReumatoide"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="ArtriteReumatoide"> Artrite reumatoide
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="ArtriteReumatoideMeses"
                                name="ArtriteReumatoideMeses"
                                id="ArtriteReumatoideMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'ArtriteReumatoideMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Osteoartrite'}">
                                <input
                                    formControlName="Osteoartrite"
                                    name="Osteoartrite"
                                    id="Osteoartrite"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Osteoartrite"> Osteoartrite </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="OsteoartriteMeses"
                                name="OsteoartriteMeses"
                                id="OsteoartriteMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'OsteoartriteMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Osteoporose'}">
                                <input
                                    formControlName="Osteoporose"
                                    name="Osteoporose"
                                    id="Osteoporose"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Osteoporose"> Osteoporose </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="OsteoporoseMeses"
                                name="OsteoporoseMeses"
                                id="OsteoporoseMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'OsteoporoseMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Neoplasia'}">
                                <input
                                    formControlName="Neoplasia"
                                    name="Neoplasia"
                                    id="Neoplasia"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Neoplasia"> Neoplasia </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="NeoplasiaMeses"
                                name="NeoplasiaMeses"
                                id="NeoplasiaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'NeoplasiaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Osteopenia'}">
                                <input
                                    formControlName="Osteopenia"
                                    name="Osteopenia"
                                    id="Osteopenia"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Osteopenia"> Osteopenia </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="OsteopeniaMeses"
                                name="OsteopeniaMeses"
                                id="OsteopeniaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'OsteopeniaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'NeoplasiaPreExistente'}">
                                <input
                                    formControlName="NeoplasiaPreExistente"
                                    name="NeoplasiaPreExistente"
                                    id="NeoplasiaPreExistente"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="NeoplasiaPreExistente"> Neoplasia
                                    pré-existente
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="NeoplasiaPreExistenteMeses"
                                name="NeoplasiaPreExistenteMeses"
                                id="NeoplasiaPreExistenteMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'NeoplasiaPreExistenteMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Depressao'}">
                                <input
                                    formControlName="Depressao"
                                    name="Depressao"
                                    id="Depressao"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Depressao"> Depressão </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="DepressaoMeses"
                                name="DepressaoMeses"
                                id="DepressaoMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'DepressaoMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'TranstornoDeAnsiedade'}">
                                <input
                                    formControlName="TranstornoDeAnsiedade"
                                    name="TranstornoDeAnsiedade"
                                    id="TranstornoDeAnsiedade"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="TranstornoDeAnsiedade"> Transtorno de
                                    ansiedade
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="TranstornoDeAnsiedadeMeses"
                                name="TranstornoDeAnsiedadeMeses"
                                id="TranstornoDeAnsiedadeMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'TranstornoDeAnsiedadeMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'CriseDePanico'}">
                                <input
                                    formControlName="CriseDePanico"
                                    name="CriseDePanico"
                                    id="CriseDePanico"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="CriseDePanico"> Crise de pânico
                                </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="CriseDePanicoMeses"
                                name="CriseDePanicoMeses"
                                id="CriseDePanicoMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'CriseDePanicoMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Anorexia'}">
                                <input
                                    formControlName="Anorexia"
                                    name="Anorexia"
                                    id="Anorexia"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Anorexia"> Anorexia </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="AnorexiaMeses"
                                name="AnorexiaMeses"
                                id="AnorexiaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'AnorexiaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Bulimia'}">
                                <input
                                    formControlName="Bulimia"
                                    name="Bulimia"
                                    id="Bulimia"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Bulimia"> Bulimia </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="BulimiaMeses"
                                name="BulimiaMeses"
                                id="BulimiaMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'BulimiaMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Alcoolismo'}">
                                <input
                                    formControlName="Alcoolismo"
                                    name="Alcoolismo"
                                    id="Alcoolismo"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Alcoolismo"> Alcoolismo </label>
                            </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                            <input
                                formControlName="AlcoolismoMeses"
                                name="AlcoolismoMeses"
                                id="AlcoolismoMeses"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'AlcoolismoMeses'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 d-flex" style="gap: 10px">
                            <div class="form-check form-check-inline">
                                <div [ngClass]="{'input-error': campoinvalido === 'Covid19'}">
                                <input
                                    formControlName="Covid19"
                                    id="Covid19"
                                    type="checkbox"
                                    class="form-input-checkbox"
                                />
                                <label class="form-label-texts-helper-check" for="Covid19"> COVID-19
                                </label>
                            </div>
                            </div>
                        </div>
                        <!-- <div class="col-md-4 mt-4 d-flex">
                            <span class="form-label-texts"> Meses (diagnóstico):</span>
                                <input
                                    formControlName="conjugueSim"
                                    name="conjugueSim"
                                    id="conjugueSim"
                                    type="number"
                                    class="form-control form-input-box"
                                />
                        </div> -->
                        <div class="col-md-6 mt-4 ">
                            <span
                                class="form-label-texts"> Se você marcou COVID-19 informe a data do diagnóstico:</span>


                            <div>

                              <input type="date"
                               class="form-control form-input-box"
                               style="width: 200px"
                               formControlName="DataCovid19" 
                               [ngClass]="{'input-error': campoinvalido === 'DataCovid19'}"
                               />

                            </div>

                        </div>
                        <div class="col-md-6 mt-4 ">
                            <span class="form-label-texts">  Se você marcou Osteoartrite ou Osteoporose ou Osteopenia ou Neoplasia informe o Local:  </span>
                            <input
                                formControlName="LocalStOsteoartrite"
                                name="LocalStOsteoartrite"
                                id="LocalStOsteoartrite"
                                type="input"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'LocalStOsteoartrite'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 ">
                            <span class="form-label-texts">  Caso tenha outra enfermidade favor descrever:  </span>
                            <input
                                formControlName="OutraEnfermidade"
                                name="OutraEnfermidade"
                                id="OutraEnfermidade"
                                type="input"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'OutraEnfermidade'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4 ">
                            <span class="form-label-texts">  Histórico de quedas no último ano:  </span>
                            <input
                                formControlName="HistQuedas"
                                name="HistQuedas"
                                id="HistQuedas"
                                type="input"
                                class="form-control form-input-box"
                                [ngClass]="{'input-error': campoinvalido === 'HistQuedas'}"
                            />
                        </div>
                        <div class="col-md-6 mt-4">
                            <span class="form-label-texts">  Favor informar data de diagnóstico da(s) enfermidade(s) pré-existentes (Ex: osteoporose em janeiro de 2014):  </span>

                            <div>


                            <input type="date"
                            class="form-control form-input-box"
                            style="width: 200px"
                            formControlName="DtEnfermidadesPreExistentes"
                            [ngClass]="{'input-error': campoinvalido === 'DtEnfermidadesPreExistentes'}"
                            />
                            </div>

                        </div>

                        <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                            <div class="alert alert-danger" role="alert">
                                {{ erroAvancarAnamnese }}
                            </div>
                        </div>

                        <div class="col-12 form-group-buttons row" style="margin-left: 20px;">
                            <div class="col-md-3 mb-3">
                                <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                        (click)="handleClickFecharModal()">Fechar
                                </button>
                            </div>
                            <div class="col-md-3 mb-3">
                                <button class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                        (click)="handleClickVoltarModal()">Voltar
                                </button>
                            </div>

                            <div class="col-md-6 mb-3" *ngIf="!carregandoAtualizarAnamnese">
                                <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                        (click)="handleClickProximoModal()">Próximo
                                </button>
                            </div>

                            <div class="col-md-6 mb-3" *ngIf="carregandoAtualizarAnamnese">
                                <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                        (click)="handleClickProximoModal()" disabled>Carregando
                                </button>
                            </div>
                        </div>
                       
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
