<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>
    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Enviar Anamnese
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <!-- <div class="identificador-pagina">
                <span class="text-yellow">1/</span>
                <span class="text-light">10</span>
            </div> -->
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <div class="row vertical-content">
            <form class="form-form p-3">
                <div class="col-md-12 row">
                    <div class="col-12 mt-1">
                        <h4>
                            Atenção!
                        </h4>
                    </div>
                    <div class="col-12 mt-1">
                        <span class="form-label-texts-helper">
                            Tem certeza que deseja enviar sua anamnese para avaliação agora?
                        </span>
                    </div>
                    <div class="col-12 mt-1">
                        <span class="form-label-texts-helper">
                            Após esse envio não conseguirá mais fazer nenhuma alteração, ok?
                        </span>
                    </div>
                    <div class="col-12 mt-1">

                        <span class="form-label-texts-helper">
                            Mas antes iremos verificar se ficou algum campo obrigatório sem preencher e mostraremos no
                            meio da tela e na cor vermelha qual é o campo pendente e em qual passo ele se encontra.
                        </span>
                    </div>
                    <div class="col-12 mt-1">

                        <span class="form-label-texts-helper">
                            E caso esteja tudo certo você receberá uma confirmação de envio com sucesso pelo seu e-mail
                            cadastrado.
                        </span>
                    </div>
                    <div class="col-12 mt-1">

                        <span class="form-label-texts-helper">
                            Parabéns,
                        </span>
                    </div>
                    <div class="col-12 mt-1">

                        <span class="form-label-texts-helper">
                            Equipe SAFE
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-4">
                    <hr />
                </div>

                <div class="col-12 mt-4" *ngIf="sucessoConfirmarAnamnese">
                    <div class="alert alert-success" role="alert">
                        Laudo gerado com sucesso, <a class="text-dark" [href]="urlLaudo" target="_blank" rel="noopener noreferrer">clique
                            aqui</a> para visualizar o laudo completo.
                    </div>
                </div>

                <div class="col-12 form-group-buttons ">
                    <div class="col-6 mt-4">
                        <button class="btn btn-secondary btn-block btn-sm form-bnt-voltar"
                            (click)="handleClickFecharModal()">Sair</button>
                    </div>

                    <div class="col-6 mt-4" *ngIf="!carregandoConfirmarAnamnese">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickConfirmarModal()">Confirmar</button>
                    </div>

                    <div class="col-6 mt-4" *ngIf="carregandoConfirmarAnamnese">
                        <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickConfirmarModal()" disabled>Carregando</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>