import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from "@angular/forms";
import {CupomService} from "@services/dashboard/cupom/cupom.service";
import {DateTime} from 'luxon';
import {Router} from "@angular/router";


@Component({
    selector: 'app-cupons', templateUrl: './cupons.component.html', styleUrls: ['./cupons.component.scss']
})
export class CuponsComponent implements OnInit {
    public formGroupCupom: FormGroup
    public cupons = []
    public loadingCupons = false
    public cupomSelecionado
    public ontem = DateTime.local().minus({days: 1}).toISODate()


    constructor(private cupomService: CupomService, private router: Router,) {
    }

    ngOnInit() {

        this.formGroupCupom = new FormGroup({
            NomeAvaliador: new FormControl({value: '', disabled: false}),
            Codigo: new FormControl({value: '', disabled: false}),
            Descricao: new FormControl({value: '', disabled: false}),
            DataInicio: new FormControl({value: this?.ontem, disabled: false}),
            DataFim: new FormControl({value: '', disabled: false}),
            Situacao: new FormControl({value: '', disabled: false}),
        })

        this.atualizarListaCupons()
    }

    atualizarListaCupons() {
        this.loadingCupons = true
        const params = this.formGroupCupom.getRawValue()
        params.Codigo = params.Codigo ? params.Codigo : undefined
        params.Descricao = params.Descricao ? params.Descricao : undefined
        params.Situacao = params.Situacao === '' ? undefined : params.Situacao
        params.NomeAvaliador = params.NomeAvaliador ? params.NomeAvaliador : undefined

        const classeContexto = this
        this.cupomService.getTodosCupons(params).subscribe({
            next(response) {
                classeContexto.cupons = response as []
                classeContexto.loadingCupons = false
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes');
                    }, 1000);
            }, error() {
                classeContexto.loadingCupons = false
            }
        })
    }

    scrollToElementById(id: string) {
     
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    
      }
    selecionarCupom(cupom) {
        this.cupomSelecionado = cupom
    }

    novoCupom() {
        this.router.navigate(['/dashboard/acesso-avaliador/cupons/adicionar'])
    }


    alterarCupom() {
        this.router.navigate([`/dashboard/acesso-avaliador/cupons/alterar`, this.cupomSelecionado?.IdentificadorHash])

    }


    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
    }

}
