import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from "@angular/router";

interface Checkup {
  ID: number;
  Idade: number;
  Sexo: number;
}

@Component({
  selector: 'app-checkups-adicionar',
  templateUrl: './checkups-adicionar.html',
  styleUrls: ['./checkups-adicionar.scss']
})
export class CheckupsAdicionar implements OnInit {

  public loadingListaAvaliadores: boolean = false;
  public loadingListaClientes: boolean = false;
  public listaAvaliadores: any[] = [];
  public listaClientes: any[] = [];
  public listaClientesGrupo: any[] = [];
  public loadingObterCheckup: boolean = false;
  public loadingSalvar: boolean = false;
  public showDateTimeFields: boolean = false;
  public urlCodeHash: string = '';
  public IDRes: number = 0;
  public dataForm = new FormGroup({
     Presencial: new FormControl(''),
    AvaliadorID: new FormControl(''),
    Tipo: new FormControl(''),
    GrupoID: new FormControl(''),
    ClienteID: new FormControl(''),
    TipoLaudo: new FormControl(''),
    DataAgendamento: new FormControl(''),
    HoraAgendamento: new FormControl(''),
    Observacoes: new FormControl(''),
    BloquearEmissaoLaudo: new FormControl(''),
  });
  public dataFormEdit = new FormGroup({
    ID: new FormControl(0),
    ClienteID: new FormControl(0),
    Cliente: new FormControl(null),
    AvaliadorID: new FormControl(0),
    Avaliador: new FormControl(null),
    DataConsulta: new FormControl(''),
    Situacao: new FormControl(0),
    Tipo: new FormControl(0),
    TipoLaudo: new FormControl(0),
    BloquearEmissaoLaudo: new FormControl(false),
    Presencial: new FormControl(false),
    DataAgendamento: new FormControl(null),
    HoraAgendamento: new FormControl(null),
    DataFimBloqueio: new FormControl(null),
    Observacoes: new FormControl(''),
    ChaveGrupo: new FormControl(null),
    GrupoID:new FormControl(null),
    Grupo:new FormControl(null),
    CupomID:new FormControl(null),
    Cupom:new FormControl(null),
    Anamnese:new FormControl(null),
    Avaliacao:new FormControl(null),
    IdentificadorHash: new FormControl(''),
    UsuarioInclusao: new FormControl(''),
    DataInclusao: new FormControl(''),
    UsuarioAlteracao: new FormControl(''),
    DataAlteracao: new FormControl(''),
  
  });

  public selectedClienteID: number = 0;

  public listaTipoCheckup = [
    {
      ID: 1,
      Descricao: 'Individual'
    },
    {
      ID: 2,
      Descricao: 'Grupo'
    }
  ];

  constructor(private professorService: ProfessorService, private toastr: ToastrService, private router: Router, private anamneseService: AnamneseService) { }

  ngOnInit(): void {
    this.onListarAvaliadores();
    this.onListarClientes();
    this.onListarGrupos();

    // obtem o codigo da url http://localhost:4200/dashboard/acesso-avaliador/checkups/alterar/c1ed506c-d115-4ac4-8a87-d212bca0ee1c
    this.urlCodeHash = this.router.url.split('/').pop();
    if (this.urlCodeHash) {
       this.onObterCheckup();
    }
  }

  onListarAvaliadores() {
    this.loadingListaAvaliadores = true;
    this.professorService.getObterListaAvaliadores().subscribe({
      next: (res: any) => {
        this.listaAvaliadores = res;
        this.loadingListaAvaliadores = false;
      },
      error: (erro: any) => {
        this.toastr.error(erro?.error?.Message, 'Erro ao buscar os avaliadores');
        this.loadingListaAvaliadores = false;
      }
    });
  }

  trackByClienteID(index: number, cliente: any): number {
    return cliente.ID;
  }
  
  onAvaliadorChange(event) {
     this.dataForm.controls['AvaliadorID'].setValue(event.target.value);
  }

  onListarClientes() {
    this.loadingListaClientes = true;
    this.professorService.getObterListaClientesSelect().subscribe({
      next: (res: any) => {
        this.listaClientes = res;
         this.loadingListaClientes = false;
      },
      error: (erro: any) => {
        this.toastr.error(erro?.error?.Message, 'Erro ao buscar os clientes');
        this.loadingListaClientes = false;
      }
    });
  }

  onListarGrupos() {
    this.professorService.getObterListaClientesGrupoSelect().subscribe({
      next: (res: any) => {
        this.listaClientesGrupo = res;
      },
      error: (erro: any) => {
        // this.toastr.error(erro?.error?.Message,'Erro ao buscar os grupos');
      }
    });
  }

  toggleDateTimeFields(isPresencial: boolean) {
    this.showDateTimeFields = isPresencial;
  }

  onObterCheckup() {
    this.loadingObterCheckup = true;
    this.professorService.getObterCheckup(this.urlCodeHash).subscribe({
      next: (res: any) => {
        this.IDRes = res?.ID;
    
        this.dataFormEdit = new FormGroup({
          ID: new FormControl(res?.ID),
          ClienteID: new FormControl(res?.ClienteID),
          Cliente: new FormControl(res?.Cliente),
          AvaliadorID: new FormControl(res?.AvaliadorID),
          Avaliador: new FormControl(res?.Avaliador),
          //dia de hoje
          DataConsulta: new FormControl(DateTime.now().toISO()),
          Situacao: new FormControl(res?.Situacao),
          Tipo: new FormControl(res?.Tipo),
          TipoLaudo: new FormControl(res?.TipoLaudo),
          BloquearEmissaoLaudo: new FormControl(res?.BloquearEmissaoLaudo),
          Presencial: new FormControl(res?.Presencial),
          DataAgendamento: new FormControl(res?.DataAgendamento),
          HoraAgendamento: new FormControl(res?.HoraAgendamento),
          DataFimBloqueio: new FormControl(res?.DataFimBloqueio),
          Observacoes: new FormControl(res?.Observacoes),
          ChaveGrupo: new FormControl(res?.ChaveGrupo),
          GrupoID: new FormControl(res?.GrupoID),
          Grupo: new FormControl(res?.Grupo),
          CupomID: new FormControl(res?.CupomID),
          Cupom: new FormControl(res?.Cupom),
          Anamnese: new FormControl(res?.Anamnese),
          Avaliacao: new FormControl(res?.Avaliacao),
          IdentificadorHash: new FormControl(res?.IdentificadorHash),
          UsuarioInclusao: new FormControl(res?.UsuarioInclusao),
          DataInclusao: new FormControl(res?.DataInclusao),
          UsuarioAlteracao: new FormControl(res?.UsuarioAlteracao),
          DataAlteracao: new FormControl(res?.DataAlteracao),
        });
        this.dataForm = new FormGroup({
          ClienteID: new FormControl(res?.ClienteID),
          AvaliadorID: new FormControl(res?.AvaliadorID),
          Tipo: new FormControl(res?.Tipo),
          GrupoID: new FormControl(res?.GrupoID),
          Presencial: new FormControl(res?.Presencial),
          BloquearEmissaoLaudo: new FormControl(res?.BloquearEmissaoLaudo),
          DataAgendamento: new FormControl(res?.DataAgendamento),
          HoraAgendamento: new FormControl(res?.HoraAgendamento),
          Observacoes: new FormControl(res?.Observacoes),
          TipoLaudo: new FormControl(res?.TipoLaudo),
        });

        this.toggleDateTimeFields(res?.Presencial);
        this.loadingObterCheckup = false;
      },
      error: (erro: any) => {
        this.toastr.error(erro?.error?.Message, 'Erro ao buscar o checkup');
        this.loadingObterCheckup = false;
      }
    });
  }

  onSalvar() {
  
    this.loadingSalvar = true;
    if (this.urlCodeHash !== 'adicionar') {
    
       let dataedit = {
        ...this.dataFormEdit.value,
        ID: this.IDRes,
        AvaliadorID: this.dataForm.controls['AvaliadorID'].value,
        ClienteID: this.dataForm.controls['ClienteID'].value  || 0,
        Tipo: this.dataForm.controls['Tipo'].value,
        GrupoID: this.dataForm.controls['GrupoID'].value,
        Presencial: this.dataForm.controls['Presencial'].value,
        BloquearEmissaoLaudo: this.dataForm.controls['BloquearEmissaoLaudo'].value,
        DataAgendamento: this.dataForm.controls['DataAgendamento'].value,
        TipoLaudo: this.dataForm.controls['TipoLaudo'].value,
        Observacoes: this.dataForm.controls['Observacoes'].value,
        DataAlteracao: DateTime.now().toISO(),
        DataConsulta: DateTime.now().toISO(),
        DataInclusao: DateTime.now().toISO(),
      };
      this.professorService.putIncluirCheckup(dataedit).subscribe({
          next: (res: any) => {
            this.toastr.success('Dados salvos com sucesso');
            this.router.navigate(['/dashboard/acesso-avaliador/checkups']);
            this.loadingSalvar = false;
          },
          error: (erro: any) => {
            this.toastr.error(erro?.error?.Message, 'Erro ao salvar os dados');
            this.loadingSalvar = false;
          }
      });
    return
    }
    let data = {
      ...this.dataForm.value,
      ClienteID: this.dataForm.controls['ClienteID'].value || 0,
     }
    this.professorService.postIncluirCheckup(data).subscribe({
      next: (res: any) => {
        this.toastr.success('Dados salvos com sucesso');
        this.router.navigate(['/dashboard/acesso-avaliador/checkups']);
        this.loadingSalvar = false;
      },
      error: (erro: any) => {
        this.toastr.error(erro?.error?.Message, 'Erro ao salvar os dados');
        this.loadingSalvar = false;
      }
    });
  }

  onSair() {
    window.history.back();
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }
}
