import {Component, OnInit} from '@angular/core';
import {RelatorioPreCadastroService} from "@services/relatorios/relatorio-pre-cadastro/relatorio-pre-cadastro.service";

@Component({
    selector: 'app-relatorio-pre-cadastro',
    templateUrl: './relatorio-pre-cadastro.component.html',
    styleUrls: ['./relatorio-pre-cadastro.component.scss']
})
export class RelatorioPreCadastroComponent implements OnInit {
    public relatorioPreCadastroList: Array<any>
    public loading = false
    public nenhumRegistro = true


    constructor(private relatorioPreCadastroService: RelatorioPreCadastroService) {
    }


    ngOnInit() {
        this.atualizarListaPreCadastro()
    }

    atualizarListaPreCadastro() {
        const classeContexto = this
        this.loading = true
        this.nenhumRegistro = false
        this.relatorioPreCadastroService.getRelatorioPreCadastro().subscribe({
            next(response: any) {
                classeContexto.relatorioPreCadastroList = response as Array<any>
                classeContexto.loading = false
                classeContexto.nenhumRegistro = response.length === 0 ? true : false
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false

            }
        })

    }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

}
