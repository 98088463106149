<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">
    <div class="container-fluid bg-transparent">
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5">E-books</h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logo-plat_branco(1).png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="ebooks-container mt-5">
            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe p-3">E-books SAFE®</h2>

            <div class="row p-3">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="ebook-card borda-arrendondada text-white">
                        <div class="ebook-content">
                            <div class="ebook-image">
                                <img src="../../../../../assets/img/checkupdasaudefunconal.png" alt="E-book Cover">
                            </div>
                            <div class="ebook-details">
                                <h6 class="text-destaque">Check-up da Saúde Funcional</h6>
                                <p class="ebook-description">
                                    Avaliar e melhorar a Saúde Funcional das pessoas, que é definida como o bem-estar e equilíbrio físico e mental do indivíduo. 
                                </p>
                                <button class="download-button" (click)="downloadCheckupdaSaude()">Download</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="ebook-card borda-arrendondada text-white">
                        <div class="ebook-content">
                            <div class="ebook-image">
                                <img src="../../../../../assets/img/ritualMatinal.png" alt="E-book Cover">
                            </div>
                            <div class="ebook-details">
                                <h6 class="text-destaque">Ritual Matinal</h6>
                                <p class="ebook-description">Ritual Matinal, 1% que transformará seu estilo de vida.</p>
                                <button class="download-button" (click)="downloadRitual()">Download</button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="ebook-card borda-arrendondada text-white">
                        <div class="ebook-content">
                            <div class="ebook-image">
                                <img src="../../../../../assets/img/guiaDefinitivo.png" alt="E-book Cover">
                            </div>
                            <div class="ebook-details">
                                <h6 class="text-destaque">
                                    <!-- Ícone de Cadeado SVG -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 17C11.45 17 11 16.55 11 16V13C11 12.45 11.45 12 12 12C12.55 12 13 12.45 13 13V16C13 16.55 12.55 17 12 17ZM17 10V8C17 5.79 15.21 4 13 4C10.79 4 9 5.79 9 8V10H8C6.9 10 6 10.9 6 12V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V12C18 10.9 17.1 10 16 10H17ZM10 8C10 6.9 10.9 6 12 6C13.1 6 14 6.9 14 8V10H10V8ZM16 19H8V12H16V19Z" fill="currentColor"/>
                                    </svg>
                                    Guia Definitivo de Prescrição do Treinamento de Força para Doenças Reumáticas Autoimunes
                                </h6>
                                <p class="ebook-description">Mente & Corpo em equilíbrio.</p>
                                <button class="download-button" (click)="downloadGuiaDef()">
                                    <!-- Ícone de Cadeado SVG -->
                                    <svg width="16" height="16" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M12 17C11.45 17 11 16.55 11 16V13C11 12.45 11.45 12 12 12C12.55 12 13 12.45 13 13V16C13 16.55 12.55 17 12 17ZM17 10V8C17 5.79 15.21 4 13 4C10.79 4 9 5.79 9 8V10H8C6.9 10 6 10.9 6 12V19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V12C18 10.9 17.1 10 16 10H17ZM10 8C10 6.9 10.9 6 12 6C13.1 6 14 6.9 14 8V10H10V8ZM16 19H8V12H16V19Z" fill="currentColor"/>
                                    </svg>
                                    Download
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                
              
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="ebook-card borda-arrendondada text-white">
                        <div class="ebook-content">
                            <div class="ebook-image">
                                <img src="../../../../../assets/img/guiaPratico.jpg" alt="E-book Cover">
                            </div>
                            <div class="ebook-details">
                                <h6 class="text-destaque">  Guia Prático Prescrição <br/>
                                9 Pilares Método SAFE®</h6>
                                <p class="ebook-description">
                                    70 Recomendações Da Saúde Funcional Integrativa.
                                </p>
                                    
                                <button class="download-button" (click)="downloadGuiaPratico()">Download</button>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
    </div>
</div>
