import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AppService } from '@services/app.service';
 
@Component({
  selector: 'app-checkups-aluno',
  templateUrl: './checkups-aluno.html',
  styleUrls: ['./checkups-aluno.scss']
})
export class checkupsAluno {
  public listaPacientes: any;
  public nenhumUsuarioEncontrado: boolean = true;
  public loadingListaPacientes: boolean = false;
  public usuarioLogado: any
  
  


  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private appService: AppService
    ) {}

  ngOnInit(): void {
    this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
    if (this.usuarioLogado?.ID) {
      this.professorService.getObterCheckupsCliente(this.usuarioLogado.ID).subscribe((res: any) => {
        this.listaPacientes = res
         this.nenhumUsuarioEncontrado = this.listaPacientes.length === 0 ? true : false
        this.loadingListaPacientes = false
      }, err => {
        this.loadingListaPacientes = false
        this.toastr.error('Erro ao buscar checkups do cliente')
      }  
      )
    }

  }

  abrirLaudo(hash) {
    window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${hash}`, "_blank")

  }
  

formatDate(date) {
  return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
}

}
