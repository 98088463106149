<section class="home-half" id="home">
    <!-- <div class="bg-overlay"></div> -->
    <div class="home-center">
        <div class="home-desc-center">
            <div class="container">
                <div class="row vertical-content">
                    <div class="col-lg-5 col-md-6 col-sm-8 mr-auto">
                        <div class="d-flex justify-content-center align-items-center">
                            <img src="../../../assets/img/logoSafeTextoBranco.png" alt="" class="logo-light"
                                 height="auto">
                        </div>

                        <div class="pt-4">
                            <p class="home-subtitle-text">
                                Bem-vindo a Nova Era da SAÚDE FUNCIONAL INTEGRATIVA
                            </p>
                            <p class="text-white mb-4 text-uppercase text-center">
                                Bem vindo a melhor e mais completa Plataforma de avaliação de saúde funcional integrativa do mundo.
                            </p>
                            <p class="text-white mb-4 text-uppercase text-center">
                                Você está na Nova Era da Saúde Funcional Integrativa com a Plataforma SAFE.
                            </p>

                        </div>
                    </div>
                    <div class="col-lg-5 col-md-6 col-sm-8">
                        <div class="home-registration-form bg-white p-5">
                            <h4 class="form-title">Recuperar Senha</h4>
                            <p class="form-subtitle mt-2 p-2">Para recuperar sua senha no SISTEMA SAFE, basta você
                                informar seu e-mail que está vinculado ao seu cadastro para receber uma nova senha. Caso
                                seu usuário não esteja ativo ou não seja localizado na base de dados, entre em contato
                                através do email ise@metodosafe.com.br. </p>
                            <form class="registration-form p-3" [formGroup]="formRecover" (ngSubmit)="onClickRecover()">

                                <label class="form-texts">Email</label>
                                <input
                                    formControlName="email"
                                    name="email"
                                    id="email"
                                    type="email"
                                    class="form-control registration-input-box"
                                />
                                <div class="col-12 row mt-2">
                                    <div class="col-md-12 col-lg-6">
                                        <button class="btn btn-cinza-cadastrar w-100 text-uppercase mt-4" type="button"
                                                (click)="handleVoltarClick()">Voltar
                                        </button>
                                    </div>
                                    <div class="col-md-12 col-lg-6">
                                        <button *ngIf="carregandoEnviar" disabled
                                                class="btn btn-azul-entrar w-100 text-uppercase mt-4" type="submit">
                                            <div class="loader"></div>
                                        </button>
                                        <button *ngIf="carregandoEnviar === false"
                                        [disabled]="submitRecover === true" 
                                                class="btn btn-azul-entrar w-100 text-uppercase mt-4" type="submit">
                                            Enviar
                                        </button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
