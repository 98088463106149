<!-- <section class="home-half" id="home">
     <div class="logo-container">
        <img
            src="../../../assets/img/logoSafeTextoBranco.png"
            alt="Logo SAFE"
            class="logo-fixed"
        />
    </div> -->

<!-- Conteúdo principal -->
<iframe
    class="responsive-iframe"
    title="Base Teste - Prof Sandor PLAT SAFE"
    width="100%"
    height="100%"
    src="https://app.powerbi.com/view?r=eyJrIjoiODI1OWRkZmYtZmI4OS00MmNhLWFkNmUtZjU4MzRlZjU0ZGQ4IiwidCI6IjIzYTNhNTRiLTk3MmYtNDc3OS1hZTBiLThjNTZlMGJlZjA0ZiJ9"
    frameborder="0"
    allowFullScreen="true"
></iframe>

<!--  <div class="row content-row">
                <a href="#participantes" class="col text-center content-box">
                    <img
                        src="../../../assets/img/participantes.png"
                        alt="Logo Participantes"
                        class="content-logo"
                    />
                    <span class="content-link">Participantes</span>
                </a>
                <a href="#demografia" class="col text-center content-box">
                    <img
                        src="../../../assets/img/demografia.png"
                        alt="Logo Demografia"
                        class="content-logo"
                    />
                    <span class="content-link">Demografia</span>
                </a>
                <a href="#parametros" class="col text-center content-box">
                    <img
                        src="../../../assets/img/parametros.png"
                        alt="Logo Parâmetros"
                        class="content-logo"
                    />
                    <span class="content-link">Parâmetros</span>
                </a>
                <a
                    href="#parametros-individuais"
                    class="col text-center content-box"
                >
                    <img
                        src="../../../assets/img/parametrosindividuais.png"
                        alt="Logo Parâmetros Individuais"
                        class="content-logo"
                    />
                    <span class="content-link">Parâmetros Individuais</span>
                </a>
                <a href="#ocupacao" class="col text-center content-box">
                    <img
                        src="../../../assets/img/ocupacao.png"
                        alt="Logo Ocupação"
                        class="content-logo"
                    />
                    <span class="content-link">Ocupação</span>
                </a>
            </div> -->
<!-- </section> -->
