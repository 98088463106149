<div class="screen-container">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->


    <div class="container-fluid bg-transparent">
 
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h6 class="home-dash text-amarelo-plataforma-safe mt-5">
                    Usuários
                </h6>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logoSafeTextoBranco.png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="container-tabela">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Filtrar Usuários
            </h6>

            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-md-4 col-sm-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Pesquisar por</span>
                        <select [(ngModel)]="dataPesquisa.Tipo" class="form-control form-input-select" id="Tipo"
                                placeholder="Selecione">
                            <option disabled value="">Selecione</option>
                            <option *ngFor="let option of tipoPesquisa" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>
                    <div *ngIf="dataPesquisa.Tipo" class="col-md-4 col-sm-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Digite o {{dataPesquisa.Tipo}}</span>
                        <input [(ngModel)]="dataPesquisa.Valor" class="form-input-box form-control" id="Valor"
                               name="Valor"
                               type="input">

                    </div>
                    <div *ngIf="dataPesquisa.Tipo" class="col-md-4 col-sm-12 mt-2"></div>
                    <div *ngIf="!dataPesquisa.Tipo" class="col-6 col-md-6 mt-2"></div>
                    <!-- <div class="col-3 col-md-3 mt-2"></div> -->
                    <div class="col-md-4 col-sm-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Situação</span>
                        <select [(ngModel)]="dataPesquisa.Situacao" class="form-control form-input-select" id="Situacao"
                                placeholder="Selecione">
                            <option disabled value="">Selecione</option>
                            <option *ngFor="let option of situacaoPesquisa" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-4 col-sm-12 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Perfil</span>
                        <select [(ngModel)]="dataPesquisa.Perfil" class="form-control form-input-select" id="Perfil"
                                placeholder="Selecione">
                            <option disabled value="">Selecione</option>
                            <option *ngFor="let option of perfilPesquisa" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-3 col-sm-12 mt-2 d-flex align-items-center justify-content-center ">
                        <button (click)="getListaPacientes()" class="button-usuarios btn btn-secondary btn-block mt-4"
                                type="button"
                                [disabled]="loadingListaPacientes"
                                >
                             {{ loadingListaPacientes ? 'Carregando...' : 'Pesquisar' }}
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-5">
            <h6 class="titulo-usuarios text-cinza-claro-plataforma-safe">
                Ações
            </h6>
            <span
                class="text-help text-cinza-claro-plataforma-safe">Para ativar alguma ação, selecione algum paciente.</span>
            <div class="col-md-12 mt-6">
                <div class="row">
                    <div class="col-md-3 col-sm-6 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button" (click)="handleNovo()">
                            Novo
                        </button>
                    </div>
                    <div class="col-md-3 col-sm-6 mt-2">
                        <button [disabled]="pacientesSelecionados.length > 1 || !pacientesSelecionados.length"
                                class="button-usuarios btn btn-secondary btn-block mt-4"
                                (click)="handleAlterar()"
                                type="button">
                            Editar
                        </button>
                    </div>
                    <div class="col-md-3 col-sm-6 mt-2">
                        <button (click)="handleEnviarSenha()"
                                [disabled]="pacientesSelecionados.length > 1 || !pacientesSelecionados.length || loadingEnviarSenha"
                                class="button-usuarios btn btn-secondary btn-block mt-4"
                                type="button">
                                <span *ngIf="loadingEnviarSenha">
                                    Enviando...
                                </span>
                                <span *ngIf="!loadingEnviarSenha">
                                    Reenviar Senha
                                </span>
                        </button>
                    </div>
                    <div class="col-md-3 col-sm-6 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button">
                            Dados Avaliadores
                        </button>
                    </div>
                </div>

            </div>


        </div>


        <div class="container-tabela mt-5">

            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Usuários cadastrados
            </h2>

            <div style="max-height: 600px; overflow-y: auto;">

                <table class="table table-striped" >
                    <thead>
                    <tr>
                        <th class="th-tabela text-azul-claro-plataforma-safe">
                            <input (change)="selectAll($event)" class="form-checkbox-input" id="selectAll"
                                   type="checkbox">
                        </th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Nome</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Login</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">E-mail</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Perfil</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Ativo</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Data de Cadastro</th>
                        <th class="th-tabela text-azul-claro-plataforma-safe">Último acesso</th>
                    </tr>
                    </thead>
                    <tbody >
                    <tr *ngIf="nenhumUsuarioEncontrado && !loadingListaPacientes">
                        <td class="text-center" colspan="8">Nenhum usuário encontrado, por favor insira um filtro.</td>
                    </tr>
                    <ng-container *ngIf="!nenhumUsuarioEncontrado && !loadingListaPacientes">
                        <tr *ngFor="let paciente of listaPacientes">
                            <td><input (change)="selecionarPaciente(paciente.ID)" [value]="paciente.ID"
                                       class="form-checkbox-input"
                                       type="checkbox"></td>
                            <td>{{ paciente.Nome }}</td>
                            <td>{{ paciente.Login }}</td>
                            <td>{{ paciente.Email }}</td>
                            <td>{{ paciente.Perfil }}</td>
                            <td>{{ paciente.Ativo ? 'Ativo' : 'Inativo' }}</td>
                            <td>{{ formatDate(paciente.Cadastro) }}</td>
                            <td>{{ paciente.UltimoAcesso ? formatDate(paciente.UltimoAcesso) : "--" }}</td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="loadingListaPacientes">
                        <td class="text-center align-middle" colspan="8">
                            <div class="spinner-border text-azul-plataforma-safe" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        
    </div>
    <div id="listaPacientes" #target></div>
</div>
