import { Component, Input, OnInit } from '@angular/core';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-biomarcador-item',
  templateUrl: './biomarcador-item.component.html',
  styleUrls: ['./biomarcador-item.component.scss']
})
export class BiomarcadorItemComponent implements OnInit {
  @Input() item: any = null
  @Input() desativar: boolean = true
  constructor(
    public appService: AppService,
    private toastr: ToastrService,

  ) { }

  ngOnInit() { }

  handleClickAbrirBioMarcador() {
    if (this.desativar) {
      this.toastr.warning("Nenhuma anamnese disponível para preenchimento")
      return
    }

    // @ts-ignore
    if (this.item?.modal) {
      // @ts-ignore
      this.appService.openModal(this.item.modal)

    }
  }
}
