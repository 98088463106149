import {Component, OnDestroy, OnInit} from '@angular/core';
import {CheckupClienteService} from '@services/dashboard/checkup-cliente/checkup-cliente.service';
import Chart from 'chart.js';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-dados-teia',
    templateUrl: './dados-teia.component.html',
    styleUrls: ['./dados-teia.component.scss']
})
export class DadosTeiaComponent implements OnInit, OnDestroy {
    public chartRadar: any;
    public checkupSubscription: Subscription;

    constructor(private checkupClienteService: CheckupClienteService) {}

    ngOnInit(): void {
        this.createChartRadar(); 

        this.checkupSubscription =
            this.checkupClienteService.checkup$.subscribe(
                (checkupAtualizado) => {
                    this.createChartRadar(checkupAtualizado?.DadosTeia);
                }
            );

    //tamanho da largura da tela
    const width = window.innerWidth;
    console.log(width,'largura da tela');
    }

    ngOnDestroy() {
        this.checkupSubscription.unsubscribe();
    }

    createChartRadar(dadosTeia: any = {}) {
        const defaultData = {
            Dor: 0,
            Felicidade: 0,
            Gratidao: 0,
            Percepcao: 0,
            Sono: 0,
            Fadiga: 0,
            Ansiedade: 0,
            Estresse: 0,
            CapacidadeFuncional: 0,
            Animo: 0,
            Disposicao: 0
        };

        const mergedData = {...defaultData, ...dadosTeia};

        const data = {
            labels: [
                'Dor',
                'Felicidade',
                'Gratidao',
                'Percepcao',
                'Sono',
                'Fadiga',
                'Ansiedade',
                'Estresse',
                'Capacidade Funcional',
                'Animo',
                'Disposicao'
            ],
            datasets: [
                {
                    label: 'Teia SAFE',
                    data: [
                        mergedData.Dor,
                        mergedData.Felicidade,
                        mergedData.Gratidao,
                        mergedData.Percepcao,
                        mergedData.Sono,
                        mergedData.Fadiga,
                        mergedData.Ansiedade,
                        mergedData.Estresse,
                        mergedData.CapacidadeFuncional,
                        mergedData.Animo,
                        mergedData.Disposicao
                    ],
                    backgroundColor: 'rgba(0, 91, 133, 0.2)',
                    borderColor: '#005B85',
                    pointBackgroundColor: '#005B85',
                    pointBorderColor: '#fff',
                    pointHoverBackgroundColor: '#fff',
                    pointHoverBorderColor: '#005B85'
                }
            ]
        };

        const config = {
            type: 'radar',
            data: data,
            options: {
                scale: {
                    ticks: {
                        beginAtZero: true,
                        min: 0,
                        max: 10
                    }
                },
                elements: {
                    line: {
                        borderWidth: 3
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: true,
                        text: 'Teia SAFE',
                        font: {
                            size: 18,
                            weight: 'bold'
                        },
                        color: '#005B85'
                    }
                }
            }
        };

        // Destroy the previous chart instance to avoid errors
        if (this.chartRadar) {
            this.chartRadar.destroy();
        }

        this.chartRadar = new Chart('ChartRadar', config);
    }
}
