<div style="overflow-y: scroll; max-height: 100vh; padding-top: 100px">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->

    <div *ngIf="!loadingAnamnese" class="home-biomarcadores">
        <app-biomarcadores [usuarioId]="usuarioId"></app-biomarcadores>
    </div>
    <div
        class="d-flex justify-content-center align-items-center mb-3"
        *ngIf="loadingAnamnese"
    >
        <div
            class="spinner-border text-cinza-claro-plataforma-safe"
            role="status"
        >
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <div class="container-fluid bg-transparent" style="padding: 40px">
        <!-- Alunos -->

        <button
            class="btn btn-primary"
            *ngIf="!loadingAnamnese && !desativarCamposAnamnese"
            style="
                background-color: #ffd500;
                border-color: #ffd500;
                color: #0f65af;
            "
            (click)="startTour()"
        >
            Tutorial
        </button>

        <div class="graficos row mt-3">
            <div
                class="graficoAluno home-resultado"
                style="
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    flex-wrap: wrap;
                "
            >
                <div
                    style="
                        flex: 8;
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                    "
                >
                    <h6
                        class="titulo-aluno home-btn-1"
                        style="text-align: center"
                    >
                        Resultado SAFE®
                    </h6>

                    <div
                        class="info-perfil"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <span class="info-perfil-titulo" style="flex: 1">
                            Nome completo
                        </span>
                        <span class="info-perfil-descricao" style="flex: 2">
                            {{
                                dadosCadastraisService.dadosCadastrais.Nome
                                    ? dadosCadastraisService.dadosCadastrais
                                          .Nome
                                    : 'Sem informação'
                            }}
                        </span>
                    </div>

                    <div
                        class="info-perfil home-btn-2"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <span class="info-perfil-titulo" style="flex: 1">
                            Idade Atual
                        </span>
                        <span class="info-perfil-descricao" style="flex: 2">
                            {{
                                dadosCadastraisService.dadosCadastrais.Idade
                                    ? dadosCadastraisService.dadosCadastrais
                                          .Idade + ' anos'
                                    : 'Sem informação'
                            }}
                        </span>
                    </div>

                    <div
                        class="info-perfil"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <span class="info-perfil-titulo" style="flex: 1">
                            Idade Biológica Cárdio
                        </span>
                        <span class="info-perfil-descricao" style="flex: 2">
                            Idade biológica cárdio:
                            {{ idadeBiologicaAlunoCardio }}
                        </span>
                    </div>

                    <div
                        class="info-perfil"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <span class="info-perfil-titulo" style="flex: 1">
                            Idade Biológica Força
                        </span>
                        <span class="info-perfil-descricao" style="flex: 2">
                            Idade biológica força:
                            {{ idadeBiologicaAlunoForca }}
                        </span>
                    </div>

                    <div
                        class="info-perfil"
                        style="
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        "
                    >
                        <span class="info-perfil-titulo" style="flex: 1">
                            IMC
                        </span>
                        <span class="info-perfil-descricao" style="flex: 2">
                            IMC: {{ imcAluno }} kg/m²
                        </span>
                    </div>
                </div>
                <div style="flex: 2; margin-top: 30px">
                    <img
                        [src]="
                            tipoZ === 1
                                ? 'assets/img/bonecoVermelho.png'
                                : tipoZ === 2
                                ? 'assets/svg/idade-biologica-safe.svg'
                                : tipoZ === 3
                                ? 'assets/img/bonecoAmarelo.png'
                                : tipoZ === 4
                                ? 'assets/img/bonecoAzul.png'
                                : tipoZ === 5
                                ? 'assets/img/bonecoVerde.png'
                                : 'assets/svg/idade-biologica-safe.svg'
                        "
                        alt=""
                        style="
                            max-width: 100%;
                            height: auto;
                            margin-top: -25px;
                            z-index: 2;
                            position: relative;
                        "
                    />
                    <img
                        src="assets/img/peBoneco.png"
                        alt=""
                        style="max-width: 100%; height: auto; margin-top: -10px"
                    />
                </div>
            </div>

            <div class="graficoAluno home-piramide">
                <!-- Título e Loader -->
                <div
                    *ngIf="loadingAnamnese"
                    class="d-flex justify-content-center align-items-center mt-3"
                >
                    <div
                        class="spinner-border text-azul-plataforma-safe"
                        role="status"
                    >
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <div
                    class="h-100 d-flex flex-column flex-md-row justify-content-between align-items-center"
                >
                    <!-- Título da Pirâmide SAFE -->
                    <h6 class="titulo-aluno">Pirâmide SAFE®</h6>

                    <!-- Imagem da Pirâmide SAFE -->
                    <div
                        *ngIf="!loadingAnamnese"
                        class="d-flex align-items-center"
                    >
                        <div
                            style="flex: 2; display: flex"
                            *ngIf="loadingAnamnese === false"
                        >
                            <img
                                [src]="
                                    'assets/img/piramide-safe/z' +
                                    tipoZ +
                                    '.png'
                                "
                                alt="Pirâmide SAFE"
                                class="piramide-safe mr-3"
                            />

                            <div *ngIf="tipoZ != 0" style="margin-left: 5px">
                                <p class="titulo-aluno" style="font-size: 18px">
                                    Classificação <br />do estilo de <br />vida
                                    atual:
                                </p>
                                <p
                                    [ngClass]="{
                                        'titulo-aluno': true,
                                        'titulo-aluno-verde': tipoZ === 5,
                                        'titulo-aluno-azul': tipoZ === 4,
                                        'titulo-aluno-amarelo': tipoZ === 3,
                                        'titulo-aluno-laranja': tipoZ === 2,
                                        'titulo-aluno-vermelho': tipoZ === 1
                                    }"
                                >
                                    Z{{ tipoZ }}
                                </p>
                            </div>
                        </div>
                    </div>

                    <!-- Spinner de Carregamento -->
                </div>
            </div>

            <div>
                <app-dados-teia></app-dados-teia>
            </div>
            <div class="graficoAluno">
                <!-- <a href="" class="text-muted botao-ver-mais">Ver mais...</a> -->

                <div class="h-100 d-flex flex-column justify-content-between">
                    <div
                        *ngIf="loadingAnamnese"
                        class="d-flex justify-content-center align-items-center"
                    >
                        <div
                            class="spinner-border text-azul-plataforma-safe"
                            role="status"
                        >
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>

                    <div
                        *ngIf="!loadingAnamnese"
                        class="h-100 d-flex flex-column flex-md-row justify-content-between align-items-center p-3"
                    >
                        <!-- Título -->
                        <h6
                            class="titulo-aluno text-center text-md-left mb-3 mb-md-0"
                        >
                            Prescrição de estilo de vida
                        </h6>

                        <!-- Conteúdo da prescrição -->
                        <div
                            class="flex-grow-1 text-center text-md-left mt-3 mt-md-0 ml-md-3"
                        >
                            <p
                                class="text-muted mb-3 titulo-aluno"
                                [ngClass]="{
                                    'titulo-aluno-verde': tipoZ === 5,
                                    'titulo-aluno-azul': tipoZ === 4,
                                    'titulo-aluno-amarelo': tipoZ === 3,
                                    'titulo-aluno-laranja': tipoZ === 2,
                                    'titulo-aluno-vermelho': tipoZ === 1
                                }"
                            >
                                {{ prescricaoCandidato?.nivel }}
                            </p>
                            <p class="text-muted">
                                {{ prescricaoCandidato?.recomendacao }}
                            </p>
                            <p
                                class="text-muted text-center text-md-left mt-3"
                                *ngIf="tipoZ == 0"
                            >
                                Sem dados suficientes para prescrição
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- FIM Alunos -->
    </div>
</div>
