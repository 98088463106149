import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-senhas-provisorias',
  templateUrl: './senhas-provisorias.component.html',
  styleUrls: ['./senhas-provisorias.component.scss']
})
export class SenhasProvisoriasComponent {
  public loadingListaClientes: boolean = false;
  public listaClientes: any[] = []
  public nenhumaSenhaEncontrada: boolean = false;

  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    ) {}


  ngOnInit(): void {
    const classeContexto = this
    this.loadingListaClientes = true
    this.professorService.getListaSenhasProvisorias().subscribe((res: any) => {
      this.listaClientes = res
      this.loadingListaClientes = false
      this.nenhumaSenhaEncontrada = this.listaClientes.length === 0
    }
    ), (error) => {
      this.toastr.error('Erro ao carregar lista de clientes')
      this.loadingListaClientes = false
    }
  }

  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }

}
