<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">


        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h6 class="home-dash mt-5">
                    Cupons
                </h6>
                <img src="assets/img/logoSafeTextoBranco.png" alt="plat-safe-logo" height="122px" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <h6 class="titulo-tabela-meus-clientes text-cinza-claro-plataforma-safe">
            Alterar Cupom
        </h6>

        <div class="container-tabela mt-5">
            <div class="p-4 scrollable-content">
                <div class="p-4">
                    <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                        Informações do Cupom
                    </h6>
                    <div class="vertical-content">
                        <form class="form-form p-3" [formGroup]="formGroupCupom">
                            <div class="row">
                                <div class="col-12 mt-5">
                                    <label class="form-label-texts">Descrição</label>

                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="Descricao"
                                    >
                                </div>

                                <div class="col-12 col-md-4 mt-5">
                                    <label class="form-label-texts">Quantidade de pessoas</label>

                                    <input
                                        type="number"
                                        class="form-control form-input-box"
                                        formControlName="Quantidade"
                                    >
                                </div>

                                <div class="col-12 col-md-4 mt-5">
                                    <label class="form-label-texts"> Intervalo entre checkups</label>
                                    <select
                                        class="form-control form-input-box" formControlName="DiasProdutoCheckup"
                                        [value]="formGroupCupom.get('DiasProdutoCheckup').value"
                                    >
                                        <option value="">Selecione</option>

                                        <option
                                            *ngFor="let opcao of opcoesDiasCheckup"
                                            [value]="opcao.value"
                                        >
                                            {{ opcao.label}}
                                        </option>
                                    </select>
                                </div>


                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Avaliador</label>
                                    <select class="form-control form-input-box" formControlName="AvaliadorID" 
                                        [value]="formGroupCupom.get('AvaliadorID').value"
                                    >

                                        <option value="">Selecione</option>

                                        <option *ngFor="let avaliador of avaliadores" [value]="avaliador.ID">
                                            {{ avaliador.Nome }}
                                        </option>
                                    </select>
                                </div>

                                <div class="col-12 col-md-4 mt-5 d-flex flex-column">
                                    <label class="form-label-texts">Data fim</label>
                                     <input
                                        type="date"
                                        class="form-control form-input-box"
                                        formControlName="Vencimento"
                                    />
                                </div>

                                <div class="col-12 col-md-4 mt-5 d-flex flex-column">
                                    <label class="form-label-texts">Código Promocional</label>
                                     <input
                                        class="form-control form-input-box"
                                        formControlName="Codigo"
                                    />
                                </div>



                                <div class="col-12 col-md-4">

                                </div>


                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Tornar avaliador</label>

                                    <div class="row" style="gap: 30px;">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="TornarAvaliador"
                                                type="radio"
                                                [value]="true"
                                                class="form-input-checkbox"
                                                [checked]="formGroupCupom.get('TornarAvaliador').value === true"
                                            >
                                            <label class="form-label-texts-helper-check">Sim.</label>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="TornarAvaliador"
                                                type="radio"
                                                [value]="false"
                                                class="form-input-checkbox"
                                                [checked]="formGroupCupom.get('TornarAvaliador').value === false"
                                            >

                                            <label class="form-label-texts-helper-check">Não.</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Ativo</label>

                                    <div class="row" style="gap: 30px;">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="Ativo"
                                                type="radio"
                                                [value]="true"
                                                class="form-input-checkbox"
                                            >
                                            <label class="form-label-texts-helper-check">Sim.</label>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="Ativo"
                                                type="radio"
                                                [value]="false"
                                                class="form-input-checkbox"
                                            >

                                            <label class="form-label-texts-helper-check">Não.</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Bloquear laudo</label>

                                    <div class="row" style="gap: 30px;">
                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="BloquearLaudo"
                                                type="radio"
                                                [value]="true"
                                                class="form-input-checkbox"
                                            >
                                            <label class="form-label-texts-helper-check">Sim.</label>
                                        </div>

                                        <div class="d-flex align-items-center justify-content-center">
                                            <input
                                                formControlName="BloquearLaudo"
                                                type="radio"
                                                [value]="false"
                                                class="form-input-checkbox"
                                            >

                                            <label class="form-label-texts-helper-check">Não.</label>
                                        </div>
                                    </div>
                                </div>
                                <!-- url -->
                                <div class="col-12 mt-5">
                                    <label class="form-label-texts">URL</label>
                                    <input
                                        type="text"
                                        class="form-control form-input-box"
                                        formControlName="url"
                                        [disabled]=true

                                    />
                                    

                                </div>


                                <div class="col-12 mt-5">
                                    <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                                        Produtos
                                    </h2>
                                </div>

                                <div class="col-md-4 mt-5">
                                    <label class="form-label-texts">Produto</label>

                                    <select class="form-control form-input-box" formControlName="Produto">
                                        <option value="">Selecione</option>
                                        <option *ngFor="let ativo of ativos" [value]="ativo.ID"
                                        [selected]="formGroupCupom.get('Produto').value === ativo.ID"
                                        >{{ativo.Nome}}</option>
                                    </select>
                                   
                                </div>

                                <div class="col-md-4 mt-5 d-flex flex-column">
                                    <label class="form-label-texts">Valor de desconto</label>
                                    <input
                                        formControlName="Desconto"
                                        type="number"
                                        class="form-input-box"
                                    >
                                </div>
                                <div class="col-md-4 mt-5 d-flex align-items-center">
                                    <button
                                        class="btn btn-primary mr-3 border-radius-10 btn-md"
                                        (click)="onAdicionar()"
                                     >
                                        Adicionar
                                    </button>
                                </div>

                                 <div class="col-12 mt-5">
                                    <table class="table table-striped table-hover">
                                        <thead>
                                            <tr>
                                                <th>Produto</th>
                                                <th>Valor Original</th>
                                                <th>Desconto</th>
                                                <th>Valor Final</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let produto of ItensLista">
                                                <td>{{ produto?.Produto?.Nome }}</td>
                                                <td>R$ {{ produto?.Produto?.Valor }}</td>
                                                <td>R$ {{ produto?.Desconto }}</td>
                                                <td>R$ {{ produto?.ValorFinal }}</td>
                                                <td>
                                                    <button
                                                        class="btn btn-danger btn-sm"
                                                        (click)="onRemover(produto)"
                                                    >
                                                        Remover
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                                <div class="col-md-12 mt-5 d-flex justify-content-end">
                                    <button
                                        class="btn btn-secondary mr-3 border-radius-10 btn-md"
                                        (click)="onVoltar()"
                                    >
                                        Voltar
                                    </button>

                                    <button
                                        class="btn btn-primary mr-3 border-radius-10 btn-md"
                                        (click)="onSalvar()"
                                        [disabled]="loadingSalvarCupom"
                                    >

                                        {{ loadingSalvarCupom ? 'Salvando...' : 'Salvar' }}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
