import {Component, HostBinding, OnInit, Renderer2} from '@angular/core';
import {Observable} from "rxjs";
import {UiState} from "@/store/ui/state";
import {Store} from "@ngrx/store";
import {AppState} from "@/store/state";
import {AppService} from "@services/app.service";
import {DadosCadastraisService} from "@services/dashboard/dados-cadastrais/dados-cadastrais.service";
import {AnamneseService} from "@services/dashboard/anamnese/anamnese.service";
import {ToggleSidebarMenu} from "@/store/ui/actions";

@Component({
  selector: 'app-container-main-aluno',
  templateUrl: './container-main-aluno.component.html',
  styleUrls: ['./container-main-aluno.component.scss']
})
export class ContainerMainAlunoComponent implements OnInit {
  @HostBinding('class') class = 'wrapper';
  public ui: Observable<UiState>;
  public usuarioLogado = null

  constructor(private renderer: Renderer2, private store: Store<AppState>, private appService: AppService, public dadosCadastraisService: DadosCadastraisService, public anamneseService: AnamneseService) {
  }

  ngOnInit() {
    this.ui = this.store.select('ui');
    this.renderer.removeClass(document.querySelector('app-root'), 'login-page');
    this.renderer.removeClass(document.querySelector('app-root'), 'register-page');
    this.renderer.addClass(document.querySelector('app-root'), 'layout-fixed');
   
    this.ui.subscribe(({menuSidebarCollapsed, controlSidebarCollapsed, darkMode}) => {
      if (menuSidebarCollapsed) {
        this.renderer.removeClass(document.querySelector('app-root'), 'sidebar-open');
        this.renderer.addClass(document.querySelector('app-root'), 'sidebar-collapse');
      } else {
        this.renderer.removeClass(document.querySelector('app-root'), 'sidebar-collapse');
        this.renderer.addClass(document.querySelector('app-root'), 'sidebar-open');
      }

      if (controlSidebarCollapsed) {
        this.renderer.removeClass(document.querySelector('app-root'), 'control-sidebar-slide-open');
      } else {
        this.renderer.addClass(document.querySelector('app-root'), 'control-sidebar-slide-open');
      }

      if (darkMode) {
        this.renderer.addClass(document.querySelector('app-root'), 'dark-mode');
      } else {
        this.renderer.removeClass(document.querySelector('app-root'), 'dark-mode');
      }
    });

    this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
    if (this.usuarioLogado?.ID) {
      this.dadosCadastraisService.getDadosCadastrais(this.usuarioLogado.ID)
    }


    //se for dispositivo mobile, deixar o menu lateral fechado
   // @ts-ignore
if (window.innerWidth <  926 && this.store?.source?._value?.ui?.menuSidebarCollapsed == false) {
  this.store.dispatch(new ToggleSidebarMenu());
}
  }

  
}
