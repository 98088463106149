import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-usuarios-avaliacoes',
  templateUrl: './usuarios-avaliacoes.component.html',
  styleUrls: ['./usuarios-avaliacoes.component.scss']
})
export class UsuariosAvaliacoesComponent {
  public listaPacientes: any;
  public nenhumUsuarioEncontrado: boolean = true;
  public pacientesSelecionados: any = [];
  public dataPesquisa: any = {
    Avaliador: '', 
    Cliente: '', 
    Situacao: 0,
  };
  public clientePesquisa: any;
  public avaliadorPesquisa: any;
  public situacaoPesquisa = [
    { value: 0, descricao: 'Todos' },
    { value: 1, descricao: 'Pendentes' },
    { value: 2, descricao: 'Finalizadas' },
    { value: 3, descricao: 'Canceladas' }  
  ];
  public loading: boolean = false;
  public loadingDevolverAvaliacao: boolean = false;
 

  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private anamneseService: AnamneseService, private router: Router
    ) {}

  ngOnInit(): void {
    // Código de inicialização, se necessário
  }

  getListaPacientes(): void {
    this.loading = true;
    this.professorService.postListaUsuariosAvaliacoes(this.dataPesquisa).subscribe({
      next: (response) => {
        this.listaPacientes = response;
        this.nenhumUsuarioEncontrado = this.listaPacientes.length === 0;
        this.loading = false;
        setTimeout(() => {
        this.scrollToElementById('listaPacientes');
        }, 1000);

      },
      error: (error) => {
        this.toastr.error('Erro ao buscar avaliações, contate o suporte');
        this.loading = false;
      }
    });
  }

  selecionarPaciente(pacienteId: number): void {
    const index = this.pacientesSelecionados.indexOf(pacienteId);
    if (index === -1) {
        this.pacientesSelecionados.push(pacienteId);
    } else {
        this.pacientesSelecionados.splice(index, 1);
    }
  }

  visualizarAvaliacao(): void {
    //redirecionar para a página de visualização de avaliação
    this.router.navigate(['/dashboard/acesso-avaliador/usuarios-avaliacoes/alterar/', this.pacientesSelecionados[0]]);
  }

  scrollToElementById(id: string) {
     
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

  }

  devolverAvaliacao(): void {
    if (this.pacientesSelecionados.length === 0) {
      this.toastr.error('Por favor, selecione pelo menos um paciente para devolver a avaliação.');
      return;
    }

    this.loadingDevolverAvaliacao = true;
  
    const pacienteId = this.pacientesSelecionados[0]; 
  
     this.professorService.getAvaliacaoAnamnese(pacienteId).subscribe({
      next: (response) => {
        
        this.professorService.putDevolverAvaliacao(response).subscribe({
          next: () => {
            this.toastr.success('Avaliação devolvida com sucesso');
            this.loadingDevolverAvaliacao = false;
          },
          error: (error) => {
            this.toastr.error('Erro ao devolver avaliação, contate o suporte');
            this.loadingDevolverAvaliacao = false;
          }
        });
      },
      error: (error) => {
        this.toastr.error('Erro ao buscar avaliação, contate o suporte'); }
    });
  }
  


  formatDate(date) {
    if (!date) return 'Sem Informação';
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }

  selectAll(event: any): void {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
        checkbox.checked = event.target.checked;
    });
  }
}
