import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ContadoresService} from '@services/dashboard/contadores/contadores.service';
import { DadosCadastraisService } from '@services/dashboard/dados-cadastrais/dados-cadastrais.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-consultar-contadores',
    templateUrl: './consultar-contadores.component.html',
    styleUrls: ['./consultar-contadores.component.scss']
})
export class ConsultarContadoresComponent implements OnInit {
    public contador: any = localStorage.getItem('consultar-contadores') ? JSON.parse(localStorage.getItem('consultar-contadores')) : {}
    public usuarioLogado: any = {}
    public dadosCadastraisUser: any = {}
    public isAdministrador: boolean = false
    constructor(private contadoresService: ContadoresService, 
        private toastr: ToastrService,
        private appService: AppService,
     ) {
    }

    ngOnInit(): void {
        this.contadoresService.getContadores().subscribe({
            next: response => {
                this.contador = response
                localStorage.setItem('consultar-contadores', JSON.stringify(response))
            }, error: error => {
                if (typeof error?.error?.Message == 'string') {
                    this.toastr.error(error?.error?.Message)
                } else {
                    this.toastr.error('Ocorre um erro ao mostrar os contadores')
                }
            }
        })

        this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
        this.isAdministrador = this.usuarioLogado?.Perfil?.ID == '1'
      

    }
}
