import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppService } from './app.service'; // Importe o serviço necessário
import { ToastrService } from 'ngx-toastr';

@Injectable() // Decorador para permitir injeção de dependência
export class AppInterceptor implements HttpInterceptor {

  constructor(private appService: AppService, private toastr: ToastrService) {} // Injete o serviço no construtor

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          //mensagem de aviso com toast
          this.toastr.warning('Sua sessão expirou, faça login novamente');
          this.appService.logout();
        }
        return throwError(error);
      })
    );
  }
}
