import {Component, OnInit} from '@angular/core';
import {AppService} from '@services/app.service';
import {ModalDadosPessoaisComponent} from '../Modal2-DadosPessoais/modal-dadospessoais.component';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {Router} from "@angular/router";
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-modal-reenviarSenha',
    templateUrl: './modal-reenviarSenha.component.html',
    styleUrls: ['./modal-reenviarSenha.component.scss']
})
export class ModalReenviarSenhaComponent implements OnInit {
    public erroAvancarAnamnese: string | any = ""
    public carregandoEnviarSenha: boolean = false
    public desativarCampos: boolean = false
    public listaClientes: any[] = [];
    public loadingListaClientes: boolean = false;
    public dataForm = new FormGroup({
        ID: new FormControl(''),
    });

    constructor(
        private appService: AppService,
        private anamneseService: AnamneseService,
        private router: Router,
        private professorService: ProfessorService,
        private toastr: ToastrService
    ) {
    }

    ngOnInit(): void {
 

        this.onListarClientes()
    }

    handleClickFecharModal() {
        this.appService.closeModal()
    }

   

    onListarClientes() {
        this.loadingListaClientes = true;
        this.professorService.getObterListaClientesSelect().subscribe({
          next: (res: any) => {
            this.listaClientes = res;
             this.loadingListaClientes = false;
          },
          error: (erro: any) => {
            this.toastr.error(erro?.error?.Message, 'Erro ao buscar os clientes');
            this.loadingListaClientes = false;
          }
        });
      }

      handleClickEnviar() {
        if (!this.dataForm.get('ID')?.value) {
            this.toastr.error('Selecione um cliente para enviar a senha.', 'Erro ao reenviar senha');
            return;
          }
          this.carregandoEnviarSenha = true;
          this.professorService.postEnviarSenha(this.dataForm.value).subscribe({
              next: (res: any) => {
                  this.carregandoEnviarSenha = false;
                 this.toastr.success('Senha enviada com sucesso para o E-mail do cliente.');
                    this.appService.closeModal()
              },
              error: (erro: any) => {
                this.toastr.error(erro?.error?.Message, 'Erro ao reenviar senha');
                this.carregandoEnviarSenha = false;
          }
        });
    }
}
