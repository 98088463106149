<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>
    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">Biomarcadores SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Método SAFE® - CHECK-UP SAFE
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
            <div class="identificador-pagina">
                <span class="text-yellow">1/</span>
                <span class="text-light">10</span>
            </div>
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <div class="col-md-12 row">
                <p class="p-3">
                    (Avaliação da saúde funcional)<br/><br/>
                    Parabéns por estar cuidando de você!<br/><br/>
                    Orientamos que seja bem sincero nas respostas e que elas são confidenciais, caso não consiga
                    terminar de preencher agora não tem problema.<br/><br/>
                    Lembre-se, quanto mais ricas forem suas respostas neste formulário, mais conteúdo teremos para
                    trabalhar.<br/>
                    Preparado?
                </p>
            </div>
            <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                <div class="alert alert-danger" role="alert">
                    {{ erroAvancarAnamnese }}
                </div>
            </div>

            <div class="col-12 form-group-buttons">
                <div class="col-6 ">
                    <button class="btn btn-secondary btn-block btn-sm form-bnt-voltar"
                            (click)="handleClickVoltarModal()">Fechar
                    </button>
                </div>

                <div class="col-6" *ngIf="!carregandoAtualizarAnamnese">
                    <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickProximoModal()">Próximo
                    </button>
                </div>

                <div class="col-6" *ngIf="carregandoAtualizarAnamnese">
                    <button class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                            (click)="handleClickProximoModal()" disabled>Carregando
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
