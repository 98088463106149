<div class="content">

    <h2 class="mb-3 text-white">Estatísticas Pacientes</h2>


    <!-- Small boxes (Stat box) -->
    <div class="row" *ngIf="isAdministrador">
        
        <div class="col-lg-3 col-md-6 col-12" >
            <!-- small box -->
            <div
                class="small-box borda-arrendondada bg-azul-claro-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-amarelo-plataforma-safe text-destaque-adm">PACIENTES <br>AVALIADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/total-pacientes-avaliados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-amarelo-plataforma-safe text-destaque-adm w-100" style="flex-1">
                            {{ contador?.TotalClientes }}
                        </h6>

                        <h5>
                            Total geral
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <img src="assets/img/man-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalClientesHomens }}
                            </span>
                        </div>
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalClientesMulheres }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ultimo mes -->
        <div class="col-lg-3 col-md-6 col-12">
            <!-- small box -->
            <div class="small-box borda-arrendondada bg-verde-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-amarelo-plataforma-safe text-destaque-adm">PACIENTES <br>AVALIADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/total-pacientes-avaliados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-amarelo-plataforma-safe text-destaque-adm w-100" style="flex-1">
                            {{ contador?.TotalClientesUltimoMes }}
                        </h6>

                        <h5>
                            Último mês
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <img src="assets/img/man-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalClientesUltimoMesHomens }}
                            </span>
                        </div>
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalClientesUltimoMesMulheres }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- checkup realizado -->
        <div class="col-lg-3 col-md-6 col-12">
            <!-- small box -->
            <div
                class="small-box borda-arrendondada  bg-amarelo-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-azul-plataforma-safe text-destaque-adm">CHECK-UPS SAFE®<br> REALIZADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/checkups-realizados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-azul-plataforma-safe text-destaque-adm w-100" style="flex-1">
                            {{ (contador?.TotalCheckups || 0) }}
                        </h6>

                        <h5>
                            Total Geral
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <!-- <img src="assets/img/checkups-realizados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-azul-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalCheckupsHomens }}
                            </span>
                        </div> -->
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <!-- <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-azul-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalCheckupsMulheres }}
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

        <!-- avaliadores -->
        <div class="col-lg-3 col-md-6 col-12">
            <!-- small box -->
            <div class="small-box borda-arrendondada text-white borda-arrendondada px-2 py-3 bg-gray">

                <div class="card-top">
                    <div>
                        <p class="text-amarelo-plataforma-safe text-destaque-adm">AVALIADORES </p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/total-pacientes-avaliados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-amarelo-plataforma-safe text-destaque-adm w-100" style="flex-1">
                            {{ contador?.TotalAvaliadores }}
                        </h6>

                        <h5>
                            Total Geral
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <img src="assets/img/man-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalAvaliadoresHomens }}
                            </span>
                        </div>
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-amarelo-plataforma-safe text-destaque-adm">
                                {{ contador?.TotalAvaliadoresMulheres }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>





    <div class="row" *ngIf="!isAdministrador">
        
        <div class="col-lg-4 col-md-6 col-12" >
            <!-- small box -->
            <div
                class="small-box borda-arrendondada bg-azul-claro-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-amarelo-plataforma-safe text-destaque">PACIENTES <br>AVALIADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/total-pacientes-avaliados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-amarelo-plataforma-safe text-destaque w-100" style="flex-1">
                            {{ contador?.TotalClientes }}
                        </h6>

                        <h5>
                            Total geral
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <img src="assets/img/man-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-amarelo-plataforma-safe text-destaque">
                                {{ contador?.TotalClientesHomens }}
                            </span>
                        </div>
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-amarelo-plataforma-safe text-destaque">
                                {{ contador?.TotalClientesMulheres }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ultimo mes -->
        <div class="col-lg-4 col-md-6 col-12">
            <!-- small box -->
            <div class="small-box borda-arrendondada bg-verde-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-amarelo-plataforma-safe text-destaque">PACIENTES <br>AVALIADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/total-pacientes-avaliados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-amarelo-plataforma-safe text-destaque w-100" style="flex-1">
                            {{ contador?.TotalClientesUltimoMes }}
                        </h6>

                        <h5>
                            Último mês
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <img src="assets/img/man-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-amarelo-plataforma-safe text-destaque">
                                {{ contador?.TotalClientesUltimoMesHomens }}
                            </span>
                        </div>
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-amarelo-plataforma-safe text-destaque">
                                {{ contador?.TotalClientesUltimoMesMulheres }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- checkup realizado -->
        <div class="col-lg-4 col-md-6 col-12">
            <!-- small box -->
            <div
                class="small-box borda-arrendondada  bg-amarelo-plataforma-safe text-white borda-arrendondada px-2 py-3">

                <div class="card-top">
                    <div>
                        <p class="text-azul-plataforma-safe text-destaque">CHECK-UPS SAFE®<br> REALIZADOS</p>
                    </div>
                    <div>
                        <img src="../../../../../assets/img/checkups-realizados.svg" alt="pacientes avaliados">
                    </div>
                </div>

                <div class="card-bottom">
                    <div class="card-bottom-item flex-column">

                        <h6 class="text-azul-plataforma-safe text-destaque w-100" style="flex-1">
                            {{ (contador?.TotalCheckups || 0) }}
                        </h6>

                        <h5>
                            Total Geral
                        </h5>
                    </div>

                    <!-- homens -->
                    <div class="card-bottom-item">
                        <!-- <img src="assets/img/checkups-realizados.svg" alt="pacientes avaliados">
                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Homens</span>
                            <span class="text-azul-plataforma-safe text-destaque">
                                {{ contador?.TotalCheckupsHomens }}
                            </span>
                        </div> -->
                    </div>

                    <!-- mulheres -->
                    <div class="card-bottom-item">
                        <!-- <div>
                            <img src="assets/img/woman-icon-pacientes-avaliados.svg" alt="pacientes avaliados">
                        </div>

                        <div class="d-flex flex-column">
                            <span class="text-homens-mulheres">Mulheres</span>
                            <span class="text-azul-plataforma-safe text-destaque">
                                {{ contador?.TotalCheckupsMulheres }}
                            </span>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!-- ./col -->
</div>
<!-- /.row -->
