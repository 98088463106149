import { LoginModel } from '@/models/login.model';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-token-page',
  templateUrl: './token-page.component.html',
  styleUrls: ['./token-page.component.scss']
})
export class TokenPageComponent implements OnInit {
  public abrirNavbar: boolean = false
  public formToken: FormGroup;
  public carregandoLogin: boolean = false
  public router = window

  ngOnInit() {
    const login = new LoginModel()
    this.formToken = new FormGroup({
      email: new FormControl(login.email, [Validators.required]),
      senha: new FormControl(login.senha, [Validators.required])
    });

  }

  constructor(
    private toastr: ToastrService,
    private appService: AppService
  ) { }


  async onClickLogin() {
    if (this.formToken.valid) {
      this.carregandoLogin = true;
      try {
        await this.appService.loginByAuth(this.formToken.value);
        // Lógica para redirecionar ou realizar outras ações após o login bem-sucedido
      } catch (error) {
        this.toastr.error('Erro ao fazer login.', 'Erro');
      } finally {
        this.carregandoLogin = false; // Defina como false, independentemente do resultado
      }
    } else {
      this.toastr.error('Preencha os campos do formulário corretamente', 'Campos inválidos');
    }
  }

  async handleVoltarClick() {
    //redirecionar para a página de cadastro
    this.router.location.href = '/login'
    }
}
