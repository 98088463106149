<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div
            style="flex: 1"
            class="d-flex justify-content-center align-items-center"
        >
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe"
                        >Biomarcadores SAFE®</span
                    >
                </div>
                <div>
                    <span
                        class="text-yellow modal-biomarcador-header-tipo-biomarcador"
                    >
                        Será que você está tendo bons sonhos?
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px">
                <img src="assets/svg/biomarcador-1.svg" alt="" />
            </div>
        </div>

        <div
            style="flex: 1"
            class="d-flex justify-content-end align-items-center"
        >
            <div class="identificador-pagina">
                <span class="text-yellow">9/</span>
                <span class="text-light">10</span>
            </div>
        </div>

        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="" />
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Preencha os Dados</h1>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa9">
                <div class="col-md-12 row">
                    <div class="col-md-12">
                        <span>
                            Qual a probabilidade de você cochilar ou dormir, e
                            não apenas se sentir cansado, nas seguintes
                            situações? Considere o modo de vida que você tem
                            levado recentemente. Mesmo que você não tenha feito
                            algumas destas coisas recentemente, tente imaginar
                            como elas o afetariam.
                            <br />
                            Escolha o número mais apropriado para responder cada
                            questão.
                        </span>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'SentadoLendo'
                            }"
                            #SentadoLendo
                        >
                            <span class="form-label-texts">
                                Sentado e lendo:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="SentadoLendo"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('SentadoLendo')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'AssistindoTv'
                            }"
                            #AssistindoTv
                        >
                            <span class="form-label-texts">
                                Assistindo TV:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="AssistindoTv"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('AssistindoTv')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'QuietoLugarPublico'
                            }"
                            #QuietoLugarPublico
                        >
                            <span class="form-label-texts">
                                Sentado, quieto, em lugar público (por exemplo,
                                no teatro, reunião ou palestra):
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="QuietoLugarPublico"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'QuietoLugarPublico'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'AndandoCarro'
                            }"
                            #AndandoCarro
                        >
                            <span class="form-label-texts">
                                Andando de carro por uma hora sem parar, como
                                passageiro:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="AndandoCarro"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('AndandoCarro')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'QuietoAlmoco'
                            }"
                            #QuietoAlmoco
                        >
                            <span class="form-label-texts">
                                Sentado quieto após o almoço sem bebida
                                alcoólica:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="QuietoAlmoco"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('QuietoAlmoco')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'ParadoCarro'
                            }"
                            #ParadoCarro
                        >
                            <span class="form-label-texts">
                                Em um carro, parado no trânsito por alguns
                                minutos:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="ParadoCarro"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('ParadoCarro')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'SentadoConversando'
                            }"
                            #SentadoConversando
                        >
                            <span class="form-label-texts">
                                Sentado e conversando com alguém:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="SentadoConversando"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'SentadoConversando'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'DeitadoDescanso'
                            }"
                            #DeitadoDescanso
                        >
                            <span class="form-label-texts">
                                Deitado para descansar a tarde quando as
                                circunstâncias permitem:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesCochilar"
                            >
                                <input
                                    formControlName="DeitadoDescanso"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('DeitadoDescanso')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check">{{
                                    opcao.label
                                }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <hr />
                    </div>
                    <div class="col-12 mt-3">
                        <h4 class="center">
                            Muito bom, já estamos quase no fim.
                        </h4>
                    </div>
                    <div class="col-12 mt-3 center">
                        <span class="form-label-texts-helper">
                            As questões seguintes referem-se às suas crenças
                            pessoais, e o quanto elas afetam a sua qualidade de
                            vida. As questões dizem respeito à religião, à
                            espiritualidade e outras crenças que você possa ter.
                            Uma vez mais, elas referem-se às duas últimas
                            semanas
                        </span>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'SentidoVida'
                            }"
                            #SentidoVida
                        >
                            <span class="form-label-texts">
                                Suas crenças dão sentido à sua vida:
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesExtremo"
                            >
                                <input
                                    formControlName="SentidoVida"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('SentidoVida')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'MedidaSentidoVida'
                            }"
                            #MedidaSentidoVida
                        >
                            <span class="form-label-texts">
                                Em que medida você acha que sua vida tem
                                sentido?
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesExtremo"
                            >
                                <input
                                    formControlName="MedidaSentidoVida"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('MedidaSentidoVida')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'ForcaDificuldade'
                            }"
                            #ForcaDificuldade
                        >
                            <span class="form-label-texts">
                                Em que medida suas crenças pessoais lhe dão
                                força para enfrentar dificuldade?
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesExtremo"
                            >
                                <input
                                    formControlName="ForcaDificuldade"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('ForcaDificuldade')
                                            .value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'EntenderDificuldades'
                            }"
                            #EntenderDificuldades
                        >
                            <span class="form-label-texts">
                                Em que medida suas crenças pessoais lhe ajudam a
                                entender as dificuldades da vida?
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesExtremo"
                            >
                                <input
                                    formControlName="EntenderDificuldades"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EntenderDificuldades'
                                        ).value == opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <hr />
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'TemReligiao'
                            }"
                            #TemReligiao
                        >
                            <span class="form-label-texts">
                                Tem religião?* :
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSimNao"
                            >
                                <input
                                    formControlName="TemReligiao"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get('TemReligiao')
                                            .value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12"
                        *ngIf="
                            formGroupEtapa9.get('TemReligiao').value === true
                        "
                    >
                        <div
                            [ngClass]="{
                                'input-error': campoinvalido === 'Religiao'
                            }"
                            #Religiao
                        >
                            <span class="form-label-texts">
                                Qual o religião?:
                            </span>

                            <input
                                formControlName="Religiao"
                                id="Religiao"
                                class="form-input-box form-input-box-sm mx-2"
                            />
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'FrequentaSemanalmente'
                            }"
                            #FrequentaSemanalmente
                        >
                            <span class="form-label-texts">
                                Frequenta algum local semanalmente para realizar
                                suas orações ou preces* :
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesSimNao"
                            >
                                <input
                                    formControlName="FrequentaSemanalmente"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'FrequentaSemanalmente'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-12"
                        *ngIf="
                            formGroupEtapa9.get('FrequentaSemanalmente')
                                .value === true
                        "
                    >
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'LocalFrequenta'
                            }"
                            #LocalFrequenta
                        >
                            <span class="form-label-texts">
                                Qual o local?:
                            </span>

                            <input
                                formControlName="LocalFrequenta"
                                id="LocalFrequenta"
                                class="form-input-box form-input-box-sm mx-2"
                            />
                        </div>
                    </div>

                    <div
                        class="col-12"
                        *ngIf="
                            formGroupEtapa9.get('FrequentaSemanalmente')
                                .value === true
                        "
                    >
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'FrequentaVezes'
                            }"
                            #FrequentaVezes
                        >
                            <span class="form-label-texts"
                                >Quantas vezes? :
                            </span>

                            <input
                                formControlName="FrequentaVezes"
                                id="FrequentaVezes"
                                class="form-input-box form-input-box-sm mx-2"
                                type="number"
                            />
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido ===
                                    'EspiritualidadeFrequenciaTemplo'
                            }"
                            #EspiritualidadeFrequenciaTemplo
                        >
                            <span class="form-label-texts">
                                Com que frequência você vai a uma igreja, templo
                                ou outro encontro religioso?
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesFrequenciaSemanal"
                            >
                                <input
                                    formControlName="EspiritualidadeFrequenciaTemplo"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EspiritualidadeFrequenciaTemplo'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido ===
                                    'EspiritualidadeLeituraFrequencia'
                            }"
                            #EspiritualidadeLeituraFrequencia
                        >
                            <span class="form-label-texts">
                                Com que frequência você dedica o seu tempo a
                                atividades religiosas individuais, como preces,
                                rezas, meditações, leitura da bíblia ou de
                                outros textos religiosos?
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesFrequenciaDiaria"
                            >
                                <input
                                    formControlName="EspiritualidadeLeituraFrequencia"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EspiritualidadeLeituraFrequencia'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-3">
                        <span class="form-label-texts-helper">
                            A seção seguinte contém três frases a respeito de
                            crenças ou experiências religiosas. Por favor, anote
                            o quanto cada frase se aplica a você.
                        </span>
                    </div>
                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido ===
                                    'EspiritualidadePresencaDeus'
                            }"
                            #EspiritualidadePresencaDeus
                        >
                            <span class="form-label-texts">
                                Em minha vida, eu sinto a presença de Deus (ou
                                do Espírito Santo).
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesVeracidade"
                            >
                                <input
                                    formControlName="EspiritualidadePresencaDeus"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EspiritualidadePresencaDeus'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido ===
                                    'EspiritualidadeCrencasReligiosas'
                            }"
                            #EspiritualidadeCrencasReligiosas
                        >
                            <span class="form-label-texts">
                                As minhas crenças religiosas estão realmente por
                                trás de toda a minha maneira de viver.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesVeracidade"
                            >
                                <input
                                    formControlName="EspiritualidadeCrencasReligiosas"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EspiritualidadeCrencasReligiosas'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido ===
                                    'EspiritualidadeEsforcoViver'
                            }"
                            #EspiritualidadeEsforcoViver
                        >
                            <span class="form-label-texts">
                                Eu me esforço muito para viver a minha religião
                                em todos os aspectos da vida.
                            </span>

                            <div
                                class="mt-2 d-flex align-items-center"
                                *ngFor="let opcao of opcoesVeracidade"
                            >
                                <input
                                    formControlName="EspiritualidadeEsforcoViver"
                                    type="radio"
                                    [value]="opcao.value"
                                    class="form-input-checkbox"
                                    [checked]="
                                        formGroupEtapa9.get(
                                            'EspiritualidadeEsforcoViver'
                                        ).value === opcao.value
                                    "
                                />
                                <label class="form-label-texts-helper-check"
                                    >{{ opcao.label }}
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div
                        class="col-12 form-group-buttons row"
                        style="margin-left: 20px"
                    >
                        <div class="col-md-3 mb-3">
                            <button
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickFecharModal()"
                            >
                                Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3">
                            <button
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickVoltarModal()"
                            >
                                Voltar
                            </button>
                        </div>

                        <div
                            class="col-md-6 mb-3"
                            *ngIf="!carregandoAtualizarAnamnese"
                        >
                            <button
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()"
                            >
                                Próximo
                            </button>
                        </div>

                        <div
                            class="col-md-6 mb-3"
                            *ngIf="carregandoAtualizarAnamnese"
                        >
                            <button
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()"
                                disabled
                            >
                                Carregando
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
