import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import Chart from 'chart.js';

@Component({
  selector: 'app-consultar-por-idade',
  templateUrl: './consultar-por-idade.component.html',
  styleUrls: ['./consultar-por-idade.component.scss']
})
export class ConsultarPorIdadeComponent implements OnInit {
  public chart: any;
  public loadingGetDadosConsultaPorIdade: boolean = false

  constructor(
    private professorService: ProfessorService
  ) {

  }

  ngOnInit(): void {
    const classeContexto = this
    this.loadingGetDadosConsultaPorIdade = true
    this.professorService.getIdadePacientes().subscribe({
      next(response) {
        classeContexto.loadingGetDadosConsultaPorIdade = false;
        classeContexto.createChart(response)
      }
    })
  }

  createChart(data: any = {}) {
    let labels = []
    let totalHomens = [];
    let totalMulheres = [];
    if (data?.Feminino) {
      labels = data?.Feminino?.map(item => `${item?.Inicio} até ${item?.Fim} anos`)
      totalMulheres = data?.Feminino?.map(item => item?.Total)
      totalHomens = data?.Masculino?.map(item => item?.Total)
    }

    this.chart = new Chart("ChartConsultarPorIdade", {
      type: 'bar', //this denotes tha type of chart

      data: {// values on X-Axis
        labels: labels,
        datasets: [
          {
            label: "Homens",
            data: totalHomens,
            backgroundColor: '#0084B9'
          },
          {
            label: "Mulheres",
            data: totalMulheres,
            backgroundColor: '#E6A4B4'
          }
        ]
      },
      options: {
        aspectRatio: 2.5
      }

    });
    this.chart.update()
  }

}
