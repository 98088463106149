<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div
            style="flex: 1"
            class="d-flex justify-content-center align-items-center"
        >
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe"
                        >Biomarcadores SAFE®</span
                    >
                </div>
                <div>
                    <span
                        class="text-yellow modal-biomarcador-header-tipo-biomarcador"
                    >
                        Saúde emocional saúde funcional
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px">
                <img src="assets/svg/biomarcador-1.svg" alt="" />
            </div>
        </div>

        <div
            style="flex: 1"
            class="d-flex justify-content-end align-items-center"
        >
            <div class="identificador-pagina">
                <span class="text-yellow">7/</span>
                <span class="text-light">10</span>
            </div>
        </div>

        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="" />
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <div>
            <h1 class="form-title">Preencha os Dados</h1>
        </div>
        <div>
            <h6 class="form-title">
                Vamos ver o quanto você gosta de esportes
            </h6>
        </div>
        <div class="row vertical-content">
            <form class="form-form p-3" [formGroup]="formGroupEtapa7">
                <div class="col-md-12 row">
                    <div class="col-12">
                        <span class="form-label-texts-helper">
                            Nós estamos interessados em saber que tipos de
                            atividade física você realiza no seu cotidiano. As
                            perguntas estão relacionadas ao tempo que cada
                            pessoa gasta fazendo atividade física na ÚLTIMA
                            SEMANA. As perguntas incluem as atividades, ou seja,
                            o quanto se movimenta fazendo o seu trabalho (no
                            escritório, em casa ou no jardim) nos momentos de
                            lazer, praticando esporte ou outros tipos de
                            exercício ou como parte das suas atividades. Por
                            favor, responda cada questão mesmo que considere que
                            não seja ativo.
                        </span>
                    </div>
                    <div class="col-12">
                        <span class="form-label-texts-helper">
                            <br />Para responder as questões lembre que: -
                            atividades físicas VIGOROSAS são aquelas que
                            precisam de um grande esforço físico e que fazem
                            respirar MUITO mais forte que o normal - atividades
                            físicas MODERADAS são aquelas que precisam de algum
                            esforço físico e que fazem respirar UM POUCO mais
                            forte que o normal Para responder as perguntas pense
                            somente nas atividades que você realiza por pelo
                            menos 10 minutos contínuos de cada vez.
                        </span>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacDiasCaminhando'
                            }"
                            #IpacDiasCaminhando
                        >
                            <div>
                                <span class="form-label-texts">
                                    Em quantos dias da última semana você
                                    caminhou por pelo menos 10 minutos contínuos
                                    em casa ou no trabalho, como forma de
                                    transporte para ir de um lugar para outro,
                                    por lazer, por prazer ou como forma de
                                    exercício?
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />
                                    Informe o número de dias por semana. Escolha
                                    "0", caso não tenha feito atividades em
                                    nenhum dia da semana.
                                </span>
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacDiasCaminhando"
                                    class="form-control form-input-box"
                                    placeholder="Selecione"
                                >
                                    <option value="">Selecione</option>
                                    <option
                                        *ngFor="let dia of opcoesDiasDaSemana"
                                        [ngValue]="dia"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacDiasCaminhando'
                                            ).value == dia
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ dia }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoCaminhando'
                            }"
                            #IpacTempoCaminhando
                        >
                            <div>
                                <span class="form-label-texts">
                                    Nos dias em que você caminhou por pelo menos
                                    10 minutos contínuos quanto tempo no total
                                    você gastou caminhando por dia?
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />
                                    Informe o total de horas e minutos.
                                </span>
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacTempoCaminhando"
                                    class="form-control form-input-box"
                                >
                                    <option value="">Selecione</option>
                                    <option
                                        *ngFor="let opcao of opcoesTempo"
                                        [ngValue]="opcao.value"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacTempoCaminhando'
                                            ).value == opcao.value
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ opcao.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacDiasAtivModerada'
                            }"
                            #IpacDiasAtivModerada
                        >
                            <div>
                                <span class="form-label-texts">
                                    Em quantos dias da última semana, você
                                    realizou atividades moderadas por pelo menos
                                    10 minutos contínuos, como por exemplo,
                                    pedalar leve na bicicleta, nadar, dançar,
                                    fazer ginástica aeróbica leve, jogar vôlei
                                    recreativo, carregar pesos leves, fazer
                                    serviços domésticos na casa, no quintal ou
                                    no jardim como varrer, aspirar, cuidar do
                                    jardim, ou qualquer atividade que fez
                                    aumentar moderadamente sua respiração ou
                                    batimentos do coração (POR FAVOR NÃO INCLUA
                                    CAMINHADA)
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />Informe o número de dias por semana.
                                    Selecione "0", caso seja nenhum dia da
                                    semana.</span
                                >
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacDiasAtivModerada"
                                    id="IpacDiasAtivModerada"
                                    class="form-control form-input-box"
                                    placeholder="Selecione"
                                >
                                    <option value="">Selecione</option>
                                    <option
                                        *ngFor="let dia of opcoesDiasDaSemana"
                                        [ngValue]="dia"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacDiasAtivModerada'
                                            ).value == dia
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ dia }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoAtivModerada'
                            }"
                            #IpacTempoAtivModerada
                        >
                            <div>
                                <span class="form-label-texts">
                                    Nos dias em que você fez essas atividades
                                    moderadas por pelo menos 10 minutos
                                    contínuos, quanto tempo no total você gastou
                                    fazendo essas atividades por dia?
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />Informe o total de horas e
                                    minutos</span
                                >
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacTempoAtivModerada"
                                    class="form-control form-input-box"
                                >
                                    <option
                                        *ngFor="let opcao of opcoesTempo"
                                        [ngValue]="opcao.value"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacTempoAtivModerada'
                                            ).value == opcao.value
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ opcao.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacDiasAtivVigorosa'
                            }"
                            #IpacDiasAtivVigorosa
                        >
                            <div>
                                <span class="form-label-texts">
                                    Em quantos dias da última semana, você
                                    realizou atividades VIGOROSAS por pelo menos
                                    10 minutos contínuos, como por exemplo,
                                    correr, fazer ginástica aeróbia, jogar
                                    futebol, pedalar rápido na bicicleta, jogar
                                    basquete, fazer serviços domésticos pesados
                                    em casa, no quintal ou cavoucar no jardim,
                                    carregar pesos elevados ou qualquer
                                    atividade que fez aumentar MUITO sua
                                    respiração ou batimentos do coração.
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />Informe o número de dias por semana.
                                    Selecione "0", caso seja nenhum dia da
                                    semana.</span
                                >
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacDiasAtivVigorosa"
                                    class="form-control form-input-box"
                                    placeholder="Selecione"
                                >
                                    <option value="">Selecione</option>
                                    <option
                                        *ngFor="let dia of opcoesDiasDaSemana"
                                        [ngValue]="dia"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacDiasAtivVigorosa'
                                            ).value == dia
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ dia }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-1">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoAtivVigorosa'
                            }"
                            #IpacTempoAtivVigorosa
                        >
                            <div>
                                <span class="form-label-texts">
                                    Nos dias em que você fez essas atividades
                                    vigorosas por pelo menos 10 minutos
                                    contínuos, quanto tempo no total você gastou
                                    fazendo essas atividades por dia?
                                </span>
                                <span class="form-label-texts-helper">
                                    <br />Informe o total de horas e
                                    minutos</span
                                >
                            </div>
                            <div class="mt-2">
                                <select
                                    formControlName="IpacTempoAtivVigorosa"
                                    class="form-control form-input-box"
                                >
                                    <option
                                        *ngFor="let opcao of opcoesTempo"
                                        [ngValue]="opcao.value"
                                        selected="{{
                                            formGroupEtapa7.get(
                                                'IpacTempoAtivVigorosa'
                                            ).value == opcao.value
                                                ? 'selected'
                                                : ''
                                        }}"
                                    >
                                        {{ opcao.label }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <div class="col-12 mt-1">
                        <span class="form-label-texts-helper">
                            Estas últimas questões são sobre o tempo que você
                            permanece sentado todo dia, no trabalho, na escola
                            ou faculdade, em casa e durante seu tempo livre.
                            Isto inclui o tempo sentado estudando, sentado
                            enquanto descansa, fazendo lição de casa visitando
                            um amigo, lendo, sentado ou deitado assistindo TV.
                            Inclua o tempo gasto sentando durante o transporte
                            em ônibus, trem, metrô ou carro.
                        </span>
                    </div>
                    <span class="form-label-texts">
                        <br />Quanto tempo no total você gasta sentado durante
                        um dia de semana?
                    </span>
                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTrabalhoDia'
                            }"
                            #IpacTempoTrabalhoDia
                        >
                            <span class="form-label-texts">
                                <br />Trabalho
                            </span>

                            <select
                                formControlName="IpacTempoTrabalhoDia"
                                class="form-control form-input-box w-100"
                            >
                                c
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoTrabalhoDia'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTransporteDia'
                            }"
                            #IpacTempoTransporteDia
                        >
                            <span class="form-label-texts">
                                <br />
                                Transporte
                            </span>
                            <select
                                formControlName="IpacTempoTransporteDia"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoTransporteDia'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTvDia'
                            }"
                            #IpacTempoTvDia
                        >
                            <span class="form-label-texts">
                                <br />
                                Assistindo TV
                            </span>
                            <select
                                formControlName="IpacTempoTvDia"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get('IpacTempoTvDia')
                                            .value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoComputadorDia'
                            }"
                            #IpacTempoComputadorDia
                        >
                            <span class="form-label-texts">
                                <br />
                                Celular / Computador
                            </span>
                            <select
                                formControlName="IpacTempoComputadorDia"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoComputadorDia'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoLeituraDia'
                            }"
                            #IpacTempoLeituraDia
                        >
                            <span class="form-label-texts">
                                <br />Leitura
                            </span>
                            <select
                                formControlName="IpacTempoLeituraDia"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoLeituraDia'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoDescansoDia'
                            }"
                            #IpacTempoDescansoDia
                        >
                            <span class="form-label-texts">
                                <br />Descanso
                            </span>
                            <select
                                formControlName="IpacTempoDescansoDia"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoDescansoDia'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <span class="form-label-texts">
                        <br />Quanto tempo no total você gasta sentado durante
                        um final de semana? (total:
                        {{ formGroupEtapa7.get('TotaisTempoSentado').value }}
                        )
                    </span>
                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTrabalhoFDS'
                            }"
                            #IpacTempoTrabalhoFDS
                        >
                            <span class="form-label-texts">
                                <br />Trabalho
                            </span>
                            <select
                                formControlName="IpacTempoTrabalhoFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoTrabalhoFDS'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTransporteFDS'
                            }"
                            #IpacTempoTransporteFDS
                        >
                            <span class="form-label-texts">
                                <br />
                                Transporte
                            </span>
                            <select
                                formControlName="IpacTempoTransporteFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoTransporteFDS'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoTvFDS'
                            }"
                            #IpacTempoTvFDS
                        >
                            <span class="form-label-texts">
                                <br />
                                Assistindo TV
                            </span>

                            <select
                                formControlName="IpacTempoTvFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get('IpacTempoTvFDS')
                                            .value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoComputadorFDS'
                            }"
                            #IpacTempoComputadorFDS
                        >
                            <span class="form-label-texts">
                                <br />
                                Celular / Computador
                            </span>

                            <select
                                formControlName="IpacTempoComputadorFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoComputadorFDS'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoLeituraFDS'
                            }"
                            #IpacTempoLeituraFDS
                        >
                            <span class="form-label-texts">
                                <br />Leitura
                            </span>

                            <select
                                formControlName="IpacTempoLeituraFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoLeituraFDS'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-6 mt-2">
                        <div
                            [ngClass]="{
                                'input-error':
                                    campoinvalido === 'IpacTempoDescansoFDS'
                            }"
                            #IpacTempoDescansoFDS
                        >
                            <span class="form-label-texts">
                                <br />Descanso
                            </span>

                            <select
                                formControlName="IpacTempoDescansoFDS"
                                class="form-control form-input-box w-100"
                            >
                                <option
                                    *ngFor="let opcao of opcoesTempo"
                                    [ngValue]="opcao.value"
                                    selected="{{
                                        formGroupEtapa7.get(
                                            'IpacTempoDescansoFDS'
                                        ).value == opcao.value
                                            ? 'selected'
                                            : ''
                                    }}"
                                >
                                    {{ opcao.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="col-12 mb-2 mt-4" *ngIf="erroAvancarAnamnese">
                        <div class="alert alert-danger" role="alert">
                            {{ erroAvancarAnamnese }}
                        </div>
                    </div>

                    <div
                        class="col-12 form-group-buttons row"
                        style="margin-left: 20px"
                    >
                        <div class="col-md-3 mb-3">
                            <button
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickFecharModal()"
                            >
                                Fechar
                            </button>
                        </div>
                        <div class="col-md-3 mb-3">
                            <button
                                class="btn btn-secondary btn-block btn-lg form-bnt-voltar"
                                (click)="handleClickVoltarModal()"
                            >
                                Voltar
                            </button>
                        </div>

                        <div
                            class="col-md-6 mb-3"
                            *ngIf="!carregandoAtualizarAnamnese"
                        >
                            <button
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()"
                            >
                                Próximo
                            </button>
                        </div>

                        <div
                            class="col-md-6 mb-3"
                            *ngIf="carregandoAtualizarAnamnese"
                        >
                            <button
                                class="btn btn-primary btn-block btn-sm form-bnt-salvar"
                                (click)="handleClickProximoModal()"
                                disabled
                            >
                                Carregando
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
