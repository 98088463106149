import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DynamicRouteGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {

    //home/index#!/convite/Roberto-Assis/Mjg2MzU= envia para cadastro/Roberto-Assis#!/Mjg2MzU=
    const nomeUsuario = route.fragment.split('/')[1];
    const hash = route.fragment.split('/')[3];

 

    console.log('hash', hash);

    if (nomeUsuario && hash) {
      const redirectUrl = `cadastro/${nomeUsuario}#!/${hash}`;
      this.router.navigateByUrl(redirectUrl);
      return false; // Bloqueia a ativação da rota atual
    } else {
      return true; // Permite a ativação da rota atual
    }
  }
}
