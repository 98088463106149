<section class="screen-half" id="home">
  <!-- <div class="bg-overlay"></div> -->
  <div class="screen-center">
    <div class="screen-desc-center">
      <div class="container">
        <div class="row vertical-content">
          <div class="col-lg-6 col-md-12 col-sm-12 registration-logo-img">
            <img src="../../../assets/img/logoSafeTextoBranco.png" alt="" height="200" class="registration-logo-img">
          </div>
          <div class="col-lg-7 col-md-12 col-sm-12 ml-auto ">
            <div class="screen-registration-form bg-white p-5">
              <h4 class="form-title">CADASTRO</h4>
                           <p class="form-subtitle" *ngIf="avaliadorNome">VOCÊ FOI CONVIDADO PELO AVALIADOR</p>
                            <p class="form-subtitle2 mb-3">{{avaliadorNome}}</p>
               <div *ngIf="loadingObterDadosConvite">
                <div class="d-flex justify-content-center">
                  <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                  </div>
                </div>
              </div>
              <form class="registration-form p-3" [formGroup]="formRegister" (ngSubmit)="registerUser()">
                <!-- cadastro -->
                <div *ngIf="etapa === 1">
                  <label class="form-texts">Nome Completo</label>
                  <input
                    formControlName="Nome"
                    name="Nome"
                    id="Nome"
                    type="text"
                    class="form-control registration-input-box"
                     autocomplete="off" 
                  />

                  <label class="form-texts mt-2">Email</label>
                  <input
                    formControlName="Email"
                    type="Email"
                    id="Email"
                    name="Email"
                    class="form-control registration-input-box"
                     autocomplete="off" 

                  >
                  <label class="form-texts mt-2">Ocupação Atual</label>
                  <input
                    formControlName="Ocupacao"
                    id="Ocupacao"
                    name="Ocupacao"
                    class="form-control registration-input-box-ocupacao"
                    autocomplete="off" 
                  >
                     <label class="form-texts mt-2">País</label>
                    <select formControlName="CodigoPais" class="form-control registration-input-box-ocupacao">
                      <option *ngFor="let opcao of CodigoPais" [value]="opcao.value">
                        {{ opcao.label }}
                      </option>
                    </select>
                  <label class="form-texts mt-2">Telefone Celular</label>
                  <input
                    type="TelefoneCelular"
                    id="TelefoneCelular"
                    name="TelefoneCelular"
                    formControlName="TelefoneCelular"
                    [inputMask]="mascaraTelefone"
                    class="form-control registration-input-box-telefone"
                    autocomplete="off" 
                  >
                  <div class="col-md-12 row">
                    <div class="col-md-6">
                      <label class="form-texts mt-2">Gênero</label>
                      <select autocomplete="off" formControlName="Sexo" class="form-control registration-input-box-select">
                        <option *ngFor="let opcao of Sexo" [value]="opcao.value">
                          {{ opcao.label }}
                        </option>
                      </select>

                    </div>
                    <div class="col-md-6 ">
                      <label class="form-texts mt-2">Data de Nascimento</label>
                      <input
                        formControlName="DataNascimento"
                        type="date"
                        id="DataNascimento"
                        name="DataNascimento"
                        class="form-control registration-input-box-dataNascimento"
                    autocomplete="off" 
                      >
                    </div>
                  </div>

                  <label class="form-texts mt-2">CRIAR SENHA</label>
                  <p class="form-subtitle3">A senha deve ter entre seis e dez dígitos <br/>
                    contendo letras e números.</p>
                  <div class="col-md-12 row">
                    <div class="col-md-6">
                      <label class="form-texts mt-2">Senha</label>
                      <input
                        formControlName="Senha"
                        type="password"
                        id="Senha"
                        name="Senha"
                        class="form-control registration-input-box-senha"
                    autocomplete="off" 
                      >
                    </div>
                    <div class="col-md-6">
                      <label class="form-texts mt-2">Confirmar Senha</label>
                      <input
                        formControlName="confirmarSenha"
                        type="password"
                        id="confirmarSenha"
                        name="confirmarSenha"
                        class="form-control registration-input-box-senha"
                    autocomplete="off" 
                      >
                    </div>

                  </div>
                  <div class="col-md-12 row">
                    <div class="col-md-6">
                      <button class="btn btn-cinza-cadastrar w-100 text-uppercase mt-4"
                              (click)="voltarLogin()" [disabled]="carregandoLogin"
                      >Voltar
                      </button>
                    </div>
                    <div class="col-md-6">
                      <button class="btn btn-azul-entrar w-100 text-uppercase mt-4" type="submit"
                              [class.spinner]="carregandoLogin" [disabled]="carregandoLogin || loadingObterDadosConvite">
                       {{
                        carregandoLogin ? 
                       'Cadastrando...' : 'Cadastrar'

                       }}
                      </button>
                    </div>
                  </div>
                </div>
                <!-- cadastro -->


                <!-- envio sms -->
                <div *ngIf="etapa === 2">
                  <p class="form-subtitle4 mb-3">
                    Enviamos um e-mail para {{ formRegister.get('Email').value }} com o código de validação.
                  </p>
                  <p class="form-subtitle4 mb-3">
                    Após inserir o código clique apenas uma vez no botão validar.
                  </p>
                  <label class="form-texts">Código </label>
                  <input
                    (change)="onInputChangeSMS($event)"
                    formControlName="CodigoSMS"
                    name="CodigoSMS"
                    id="CodigoSMS"
                    type="Nome"
                    class="form-control registration-input-box w-100 mb-4 "
                    style="max-width: 200px;"
                  />
                 
                    <div class="row">
                      <div class="col-md-4 mb-3">
                        <button type="button" class="btn btn-block btn-sm btn-cinza-codigo"
                                (click)="voltarCadastro()"
                                style="font-size: 14px; padding: 6px 12px;">
                          Voltar
                        </button>
                      </div>
                      <div class="col-md-4 mb-3">
                        <button type="button" class="btn btn-azul-entrar btn-block"
                                (click)="validarCodigo()"
                                [disabled]="carregandoValida"
                                style="font-size: 14px; padding: 6px 12px;">
                          {{ carregandoValida ? 'Validando...' : 'Validar' }}
                        </button>
                      </div>
                      <div class="col-md-4 mb-3"></div>
                      <div class="col-md-6 mb-3">
                        <button type="button" class="btn btn-sm btn-cinza-codigo btn-block"
                                (click)="reenviarCodigo()"
                                [disabled]="carregandoReenvia"
                                style="font-size: 14px; padding: 6px 12px;">
                          Reenviar código
                        </button>
                      </div>
                    </div>
                 
                  
                  


                </div>
                <!-- envio sms -->

              </form>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
