import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ebooks',
  templateUrl: './ebooks.component.html',
  styleUrls: ['./ebooks.component.scss']
})
export class ebooksComponent {
 

  constructor(
   
    ) {}

  ngOnInit(): void {
    // Código de inicialização, se necessário
  }

  downloadCheckupdaSaude(): void {
    const link = document.createElement('a');
    link.href = '../../../../../assets/arquivos/ebookCheckup.pdf';
    link.download = 'ebookCheckup.pdf';
    link.target = '_blank'; // Abre o arquivo em uma nova aba
    link.click();
    link.remove(); // Remove o elemento do DOM após o download
  }


  downloadRitual(): void {
    const link = document.createElement('a');
    link.href = '../../../../../assets/arquivos/ebookRitualMatinal.pdf';
    link.download = 'ebookRitualMatinalSAFE.pdf';
    link.target = '_blank'; // Abre o arquivo em uma nova aba
    link.click();
    link.remove(); // Remove o elemento do DOM após o download
  }

  downloadGuiaPratico(): void {
    const link = document.createElement('a');
    link.href = '../../../../../assets/arquivos/ebookGuiaPratico.pdf';
    link.download = 'ebookGuiaPratico.pdf';
    link.target = '_blank'; // Abre o arquivo em uma nova aba
    link.click();
    link.remove(); // Remove o elemento do DOM após o download
  }

  downloadGuiaDef(): void {
     //leva para https://chk.eduzz.com/2205028
    window.open('https://chk.eduzz.com/2205028', '_blank');
  }

 

 
}
