import { DateTime } from 'luxon';
import { Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.scss']
})
export class UsuariosComponent implements OnInit, OnDestroy {
  public listaPacientes: any;
  public nenhumUsuarioEncontrado: boolean = true;
  public pacientesSelecionados: number[] = [];
  public loadingListaPacientes: boolean = false;
  public loadingEnviarSenha: boolean = false;

  public dataPesquisa: any = {
    Tipo: '', 
    Valor: '', 
    Situacao: '', 
    Perfil: '' 
  };

  public tipoPesquisa = [
    { value: 'Nome', descricao: 'Nome' },
    { value: 'Login', descricao: 'Login' },
    { value: 'Email', descricao: 'Email' },
    { value: 'Telefone', descricao: 'Telefone' }
  ];

  public situacaoPesquisa = [
    { value: '', descricao: 'Todos' },
    { value: 'true', descricao: 'Ativo' },
    { value: 'false', descricao: 'Inativo' }  
 
];
  

  public perfilPesquisa = [
    { value: '1', descricao: 'Administrador Geral' },
    { value: '2', descricao: 'Cliente' },
    { value: '3', descricao: 'Avaliador' },
    { value: '4', descricao: 'Avaliador/Cliente' }
  ];

  constructor(
    private professorService: ProfessorService,
    private toastr: ToastrService,
    private renderer: Renderer2, 
    private elementRef: ElementRef
    ) {}

  ngOnInit(): void {
    // Código de inicialização, se necessário
  }

  getListaPacientes(): void {
    this.loadingListaPacientes = true;
    this.professorService.postListaUsuariosAvaliador(this.dataPesquisa).subscribe({
      next: (response) => {
        this.listaPacientes = response;
        this.nenhumUsuarioEncontrado = this.listaPacientes.length === 0;
        this.loadingListaPacientes = false;
        setTimeout(() => {
        this.scrollToElementById('listaPacientes');
        }, 1000);
      },
      error: (error) => {
         if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar pacientes, contate o suporte')
        }

        this.loadingListaPacientes = false;
      }
    });
  }

  scrollToElementById(id: string) {
     
    const element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

  }

 

  
  
  selecionarPaciente(pacienteId: number): void {
    const index = this.pacientesSelecionados.indexOf(pacienteId);
    if (index === -1) {
        this.pacientesSelecionados.push(pacienteId);
    } else {
        this.pacientesSelecionados.splice(index, 1);
    }
  }

  handleEnviarSenha(): void {
    if (this.pacientesSelecionados.length === 0) {
      this.toastr.warning('Selecione pelo menos um paciente');
      return;
    }
    let body = {
      ID: this.pacientesSelecionados[0]
    }
    this.loadingEnviarSenha = true;
    this.professorService.postEnviarSenha({...body}).subscribe({
      next: (response) => {
        this.toastr.success('Senha enviada com sucesso');
        this.loadingEnviarSenha = false;
      },
      error: (error) => {
        this.toastr.error('Erro ao enviar senha, contate o suporte');
        this.loadingEnviarSenha = false;
      }
    });
  }

  getDescricaoPerfil(perfil: string): string {
    const perfilEncontrado = this.perfilPesquisa.find(item => item.value === perfil);
    return perfilEncontrado ? perfilEncontrado.descricao : '';
  }
  
  formatDate(date) {
    return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
  }

  handleNovo(): void {
    window.location.href = '/dashboard/acesso-avaliador/usuarios/incluir';
  }

  handleAlterar(): void {
    if (this.pacientesSelecionados.length === 0) {
      this.toastr.warning('Selecione pelo menos um paciente');
      return;
    }
    window.location.href = `/dashboard/acesso-avaliador/usuarios/alterar/${this.pacientesSelecionados[0]}`;
  }

  selectAll(event: any): void {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox: any) => {
        checkbox.checked = event.target.checked;
    });
    this.pacientesSelecionados = event.target.checked ? this.listaPacientes.map(item => item.ID) : [];
  }

  ngOnDestroy(): void {
    // Código de limpeza, se necessário
  }
}
